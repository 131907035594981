import { ComponentPortal, ComponentType, Portal, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { PhxUtil } from '@phoenix/ui/common';
import { TabDirective } from './tab.directive';
import { InternalTabOptions, InternalTabSetOptions, TabOptions, TabSetOptions } from './tabset.interface';

@Component({
  selector: 'phx-tabset',
  templateUrl: './tabset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TabsetComponent implements AfterViewInit, OnChanges, AfterContentInit, OnDestroy {
  activeID: string;
  currentTabInfo: InternalTabOptions | TabDirective;
  routedTabs = false;
  _options: InternalTabSetOptions;
  disableLeft: boolean = false;
  disableRight: boolean = false;

  public tabContentPortal: Portal<EmbeddedViewRef<any>>;

  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>;
  @ContentChild('phxTabRight') rightSideTemplate: TemplateRef<any>;
  @ContentChild('phxTabLeft') leftSideTemplate: TemplateRef<any>;
  // @ViewChildren('projectedTabs') projectedTabs: QueryList<ElementRef>;
  @ViewChildren('optionTabs') optionTabs: QueryList<ElementRef>;
  @ViewChildren('tabRow') tabElement;
  @ViewChildren('arrow') arrowElement;

  /**
   * Whether to use tabs or pills.
   */
  @Input() type: 'tabs' | 'pills' = 'tabs';

  /**
   * Allows to set the active Index. By default that tab will be selected
   */
  @Input() activeTab = 0;

  /**
   * Allows to set the disabled tab. By default all tabs are enable
   */
  @Input() disabledTab: number | number[] = -1;

  /**
   * provide a label that describes the purpose of the set of tabs
   */
  @Input() ariaLabel;

  /**
   * Pass tab info to populate the structure
   * Options is of Interface TabSetOptions
   */
  @Input() options: TabSetOptions;

  /**
   * Allows the user to override the default action of tab change
   */
  @Input() preventDefaultAction = false;
  /**
   * Pass OnSelect Callback Function
   * args: active tab Index
   */
  @Output()
  activeTabChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Tab clicked
   */
  @Output()
  tabClicked: EventEmitter<number> = new EventEmitter<number>();

  /**
   * This event will be triggered after tab has loaded
   */
  @Output()
  afterTabsInit: EventEmitter<void> = new EventEmitter<void>();

  get isTabContentStringType(): boolean {
    return this.currentTabInfo && typeof this.currentTabInfo.content === 'string';
  }

  get isLeftSideStringType(): boolean {
    return typeof this._options.leftSideTemplate === 'string';
  }

  get isRightSideStringType(): boolean {
    return typeof this._options.rightSideTemplate === 'string';
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue) {
      if (!Array.isArray(changes.options.currentValue.tabs)) {
        throw new Error('tabs must be an array');
      }
      this._options = { ...changes.options.currentValue };
      if (!changes.options.firstChange) {
        this.markTabActive(this._options.tabs[this.activeTab]);
      }
    }

    if (changes.activeTab && !changes.activeTab.firstChange) {
      this.markTabActive(this._options.tabs[changes.activeTab.currentValue]);
      this.doNavigate();
      this.focusTab(this.activeTab);
      this.changeDetectorRef.markForCheck();
    }
  }

  ngAfterContentInit() {
    if (!this.options) {
      this._options = {
        tabs: []
      };
      if (this.rightSideTemplate) {
        this._options.rightSideTemplate = this.rightSideTemplate;
      }
      if (this.leftSideTemplate) {
        this._options.leftSideTemplate = this.leftSideTemplate;
      }
      if (this.tabs.length) {
        this._options.tabs = this.tabs.toArray();
      }
    }
  }

  ngAfterViewInit() {
    this.routedTabs = this.hasRoute(this._options.tabs);
    this.afterTabsInit.emit();
    this.markTabActive(this._options.tabs[this.activeTab]);
  }

  activateTab(index: number): void {
    this.select(this._options.tabs[index], index);
  }

  select(tab: InternalTabOptions | TabDirective, index: number, $event?: KeyboardEvent): boolean {
    if ($event) {
      $event.preventDefault();
    }

    if (this.preventDefaultAction) {
      this.tabClicked.emit(index);
      this.focusTab(this.activeTab);
      return false;
    }

    if (this.isDisabled(index)) {
      return false;
    }
    if ((this.disabledTab === undefined) || (typeof this.disabledTab === 'number' && index !== this.disabledTab) || (Array.isArray(this.disabledTab) && this.disabledTab.indexOf(index) === -1)) {
      if (this.tabContentPortal && this.tabContentPortal.isAttached) {
        this.tabContentPortal.detach();
      }
      this.activeTab = index;
      this.markTabActive(tab);
      this.doNavigate();
    }
    return false;
  }

  focusEnabledTab(index, event?) {
    if (!this.isDisabled(index)) {
      this.focusTab(index);
    } else if (event.keyCode === 39 || event.keyCode === 37) {
      const isRight = event.keyCode === 39;
      let _index = index;
      while (true) {
        if (!this.isDisabled(_index)) {
          this.focusTab(_index);
          break;
        }
        _index += isRight ? 1 : -1;
        if (isRight) {
          _index = _index >= this._options.tabs.length ? 0 : _index;
        } else {
          _index = _index < 0 ? this._options.tabs.length - 1 : _index;
        }
      }
    }
  }

  focusTab(index, $event?): void {
    if ($event) {
      $event.preventDefault();
    }
    if (this.isDisabled(index)) {
      return;
    }
    const tabsRef: QueryList<ElementRef> = this.optionTabs;
    if (index + 1 === tabsRef.length) {
      this.tabElement._results[0].nativeElement.scrollLeft = this.tabElement._results[0].nativeElement.scrollWidth;
    }
    if (index < 0) {
      tabsRef.last.nativeElement.firstElementChild.focus();
    } else if (index >= tabsRef.length) {
      tabsRef.first.nativeElement.firstElementChild.focus();
    } else {
      tabsRef.find((element, idx) => idx === index).nativeElement.firstElementChild.focus();
    }
  }

  handleRoutedTabActivate(): void {
    const matchedTabByUrl = this.getTabOptionByUrl(this.router.url);
    if (PhxUtil.isUndefined(matchedTabByUrl)) {
      return;
    }
    if (this.activeID !== matchedTabByUrl.id) {
      this.markTabActive(matchedTabByUrl);
    }
  }

  clickScroll(element, direction) {
    element.scrollBy({
      left: direction === 'left' ? -60 : 60,
      behavior: 'smooth'
    });
  }

  scrollMaxCheck({ scrollLeft, scrollWidth, offsetWidth }) {
    const leftSide = scrollLeft - (scrollWidth - offsetWidth);
    if (scrollLeft === 0) {
      this.disableLeft = true;
    } else if (leftSide < 1 && leftSide >= -1) {
      this.disableRight = true;
    } else {
      this.disableLeft = false;
      this.disableRight = false;
    }
  }

  checkOverflow(slider) {
    this.scrollMaxCheck(slider);
    if (!!this.arrowElement && this.arrowElement.length > 0) {
      const sizeWithArrows = slider.offsetWidth + this.arrowElement._results[0].nativeElement.offsetWidth;
      return sizeWithArrows < slider.scrollWidth
    } else {
      return slider.offsetWidth < slider.scrollWidth;
    }
  }

  private hasRoute(options): boolean {
    return (options as Array<InternalTabOptions | TabDirective>).some(tab => tab.route);
  }

  private doNavigate() {
    if (!!this.currentTabInfo.route) {
      this.router.navigate([this.currentTabInfo.route], {
        relativeTo: this.activatedRoute
      });
    }
    this.activeTabChange.emit(this.activeTab);
  }

  private getTabOptionByUrl(url: string): InternalTabOptions | TabDirective {
    const urlTree: UrlTree = this.router.parseUrl(url);
    const urlSegments = this.getUrlSegments(urlTree.root);
    const route = urlSegments[urlSegments.length - 1];
    const path = route && route.path ? route.path : '';
    return (this._options.tabs as Array<TabDirective | TabOptions>).find(tab => tab.route === path);
  }

  private getUrlSegments(urlGroup: UrlSegmentGroup): UrlSegment[] {
    if (urlGroup.numberOfChildren > 0) {
      return this.getUrlSegments(urlGroup.children.primary);
    }
    return urlGroup.segments;
  }

  private markTabActive(tab: InternalTabOptions | TabDirective) {
    this.currentTabInfo = tab;
    this.activeID = this.currentTabInfo.id;
    this.embedTabContent(tab);
    this.changeDetectorRef.detectChanges();
  }

  private embedTabContent(activeTab: InternalTabOptions | TabDirective) {
    if (!activeTab.route) {
      if (this.options) {
        if (activeTab.content) {
          if (activeTab.content instanceof TemplateRef) {
            this.tabContentPortal = new TemplatePortal(activeTab.content, this.viewContainerRef);
          } else if (typeof activeTab.content !== 'string') {
            // component instance
            this.tabContentPortal = new ComponentPortal(activeTab.content as ComponentType<any>, this.viewContainerRef) as Portal<any>;
          }
        }
      } else if (activeTab.content === undefined && activeTab.contentTpl) {
        this.tabContentPortal = new TemplatePortal(activeTab.contentTpl, this.viewContainerRef);
      }
    }
  }

  isDisabled(index: number, tab?: TabOptions | TabDirective) {
    if (tab?.disabled || this._options.tabs[index]?.disabled || index < 0 || index > this._options.tabs.length) {
      return true;
    } else if (this.disabledTab === -1) {
      return false;
    }

    if (typeof this.disabledTab === 'number') {
      return this.disabledTab === index;
    } else if (Array.isArray(this.disabledTab) && this.disabledTab.length > 0) {
      return this.disabledTab.indexOf(index) > -1;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.tabContentPortal && this.tabContentPortal.isAttached) {
      this.tabContentPortal.detach();
    }
  }
}
