import {DataTableComponent} from './datatable.component';
import {Sorter} from './base-isc/data-service/datasorter';

// START: Table Configuration Items

export enum FilterUIType {
  INPUT = 'input',
  SELECT = 'select'
}

export enum MatchMode {
  STARTS_WITH = 'startswith',
  ENDS_WITH = 'endswith',
  CONTAINS = 'contains',
  EXACT = 'exact',
  GREATER_THAN = 'greater',
  GREATER_THAN_OR_EQUAL = 'greater_or_eq',
  LESS_THAN = 'less',
  LESS_THAN_OR_EQUAL = 'less_or_eq',
  NOT_EQUAL = 'not_eq'
}

export type FilterMatchMode = MatchMode | RegExp;

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortType {
  STRING = 'string',
  NUMBER = 'number',
  NUMBER_STRING = 'numberString',
  DATE = 'date',
  OBJECT = 'object',
  BOOLEAN = 'boolean'
}

export type RowSelectableFunc = (rowData: any) => boolean;
export type RowExpandableFunc = (rowData: any) => boolean;

export type FilterFunc = (rowData: any, filterEvent: FilterEventCol) => boolean;

export type SortFunc = (a: any, b: any) => any;

/**
 * Type of events which have to be processed by "onTableEvents" handler.
 */
export enum ActionTypes {
  LOAD_DATA = 'LOAD',
  FILTER = 'FILTER',
  SORT = 'SORT',
  SET_COUNTS = 'SET_COUNTS',
  SELECT_ALL_FILTERED = 'SELECT_ALL_FILTERED',
  SELECT_ROW = 'SELECT_ROW',
  SELECT_SINGLE_ROW = 'SELECT_SINGLE_ROW',
  ROW_CONTEXT_MENU = 'ROW_CONTEXT_MENU',
  COLUMN_RESIZE = 'COLUMN_RESIZE',
  SET_GROUP_INFO = 'SET_GROUP_INFO',
  GROUP_TOGGLE = 'GROUP_TOGGLE',
  ROW_TOGGLE = 'ROW_TOGGLE',
  CUSTOM = 'CUSTOM'
}

/*export class DataTableConstants {
    static readonly ASC = SortOrder.ASC; // Left for compatibility.
    static readonly DESC = SortOrder.DESC;
    static events = ActionTypes;
    static filter = {
        matchMode : FilterMatchMode,
        uiType: FilterUIType
    };
    static sort = {
        order: SortOrder,
        dataType: SortType
    }
}*/


/*
 * DataTable configuration.
 */
export interface DataTableOptions {

  /**
   * Smart view confiruration properties.
   */
  smartView: SmartViewOptions;

  /**
   * DataTable columns definitions list.
   */
  columnDefs: Array<ColumnDefs>;

  /**
   * DataTable data to show.
   */
  data: Array<any>;

  /**
   * Function to show/hide selection checkboxes base on some conditions for each row.
   */
  isRowSelectable?: RowSelectableFunc;

  /**
   * Function to show/hide expand/collapse controls base on some conditions for each row.
   */
  isRowExpandable?: RowExpandableFunc;

  /**
   * Hidden Table Caption string.
   */
  ariaCaption?: string;
}

export interface SmartViewOptions {

  /**
   * When all data loaded to browser we can use internal DataTable sorting, filtering features.
   * Most events will be handled internally by DataTable.
   * Controller will have just some events types, like 'ROW_CONTEXT_MENU'.
   * Default value is false.
   */
  handleEventsInternally?: boolean;

  /**
   * Message to be shown when table doesn't show any row whether on load or after filtering.
   */
  alertMessage?: string;

  /**
   * Type of alert shown when table is empty.
   * Possible values: 'info' | 'success' | 'warning' | 'danger'.
   * Default: 'warning'
   */
  alertType?: 'info' | 'success' | 'warning' | 'danger';

  /**
   * Flag to hide alert message.
   */
  hideAlertMessage?: boolean;

  // Page load related parameters:
  /**
   * Size of the rows loaded in browser.
   */
  pageSize?: number;

  /**
   * Total row number of data rows in the DataTable.
   */
  totalRowNumber?: number;

  /**
   * Total number of rows after filter applied.
   * If filters are not applied then the value equal to the {@link SmartViewOptions.totalRowNumber}.
   */
  filteredRowNumber?: number;

  // Rows selection related parameters:
  idField?: string;

  /**
   * Field name which keeps row selection status.
   */
  selectField?: string;

  /**
   * Total number of selected rows in table. Used just to show data on screen.
   */
  totalSelectedCount?: number;

  /**
   * Total number of selected rows among currently filtered rows.
   * If filters are not applied then the value equal to the {@link SmartViewOptions.totalSelectedCount};
   */
  filteredSelectedCount?: number;

  /**
   * The row number available for selection for the case when not all rows will have checkbox for selection.
   */
  filteredSelectableRowNumber?: number;

  /**
   * Define max filter expandable height if filter is combobox.
   */
  maxFilterSelectHeight?: number;

  /**
   * If some checkbox are not selectable the flag will indicate whether to show them disabled or hide.
   */
  hideDisabledSelect?: boolean;

  /**
   * Flag to control "select all" checkbox visibility. Default is false and checkbox is visible.
   */
  hideSelectAll?: boolean;

  /**
   * Enable row context menu event.
   */
  // enableRowEvents?: boolean;

  /**
   * Whether columns has filter.
   * Default is true.
   */
  filterable?: boolean;

  /**
   * Filter match mode. Default is 'startwith'
   */
  filtersMatchMode?: FilterMatchMode;

  /**
   * Whether filters case sensitive. Default false;
   */
  filtersCaseSensitive?: boolean;

  /**
   * Whether columns are sortable.
   * Default is true.
   */
  sortable?: boolean;

  /**
   * Sortable - Binary or inline.
   * Default is false.
   */
  binarySort?: boolean;

  /**
   * Whether to show or hide datatable header.
   */
  showHeader?: boolean;

  /**
   * Whether columns are resizable.
   * By default is true.
   */
  resizableCols?: boolean;

  /**
   * Whether table height have to shrink base on visible rows number.
   * By default is false which means table height stay constant.
   */
  dynamicTableHeight?: boolean;

  /**
   * Whether smart scroll have to be disabled or not. Very rare case. Mostly for POC.
   */
  smartScroll?: boolean;

  /**
   * Name of the field which will be used for rows grouping.
   * Items by default will be sorted by this field in {@link SortOrder.ASC} order.
   * To change the order - set the sorting direction for this field in {@link ColumnDefs}.
   */
  groupField?: string;

  /**
   * Example of using by index.
   * trackBy =  function (index: number, item: any): any {
        return index;
    };
   */
  rowsTrackBy?: (index: number, item: any) => any;

  /**
   * By default rowsTrackByIndex is true. Check "rowTrackBy" documentation for forEach.
   */
  rowsTrackByIndex?: boolean;

  /**
   * Aria label for row expand control.
   */
  rowExpandAriaLabel?: string;

  /**
   * Aria label for row collapse control.
   */
  rowCollapseAriaLabel?: string;

  /**
   * Number of pages shown in pagination. Default is 5;
   */
  pageLinks?: number;

  /**
   * Options for rows per page dropdown. Default is [10, 20, 40]
   */
  rowsPerPageOptions?: any[];

  /**
   * Options for displaying paginator dropdown. Default is true
   */
  showPaginatorDropdown?: boolean;

  /**
   * Flag to enable pagination.
   */
  pagination?: boolean;

  /**
   * External trigger for page changes. Default is false
   */
  externalPageTrigger?: boolean;

  /**
   * Row height value if different from default value.
   */
  rowHeight?: number;

  /**
   *  Flag to show expand all checkbox for expandable feature.
   *  Default is false.
   */
  showExpandAll?: boolean;

  /**
   * Flag to enable background colors for expanded content to match row color.
   * Default is false. Color for all expanded rows content is the same.
   */
  stripeExpandedRows?: boolean;

  /**
   * Contains object with information about highlighted rows.
   * The object key is "idField" value for the higlighted row and the value is boolean flag.
   * "idField" should be provided in SmartView config to enable row higlight feature.
   *
   */
  highlightedRows?: { [x: string]: boolean };

  /**
   * Column resize mode.
   * 'expand' mode will change columns size without affecting other columns.
   * 'fit' will keep total width of change column and nearest right the same. So any column changes
   * will be compensated by nearest right column width.
   * Default mode is 'expand'.
   */
  columnResizeMode?: 'fit' | 'expand';

  /**
   * Selection mode sorting enabled.
   * Default mode is false.
   */
  selectionSortEnabled?: boolean;

  /**
   * Tooltip for truncated items.
   * Default: false.
   */
  tooltipEnabled?: boolean;
}

export interface ColumnDefs {

  /**
   * Column id.
   */
  id?: string;

  /**
   * Data field to be rendered in column.
   */
  field: string;

  /**
   * Column name.
   */
  name?: string;

  /**
   * Column width.
   */
  width?: number | string;

  /**
   * Filter configuration information.
   */
  filter?: ColFilter;

  /**
   * Sort configuration information.
   */
  sort?: ColSort;

  /**
   * Allow tooltip show in  column field.
   */
  tooltip?: {
    position?: string;
  };

  /**
   * Whether column resizable or not.
   * All columns are resizable by default since SmartViewOptions.resizableCols is true by default.
   * Set explicitly to 'false' to disable resizing.
   * True value will not enable resize if SmartViewOptions.resizableCols is false.
   */
  resizable?: boolean;

  /**
   * Column style.
   */
  style?: any;

  /**
   * Column style class.
   */
  styleClass?: string;

  /**
   * Column header style.
   */
  headerStyle?: any;

  /**
   * Column header style class.
   */
  headerStyleClass?: string;
}

/**
 * Column filter interface.
 */
export interface ColFilter {
  label?: string;

  /**
   * Type of filter. Supported values: "input" (default), "select".
   */
  type?: FilterUIType;

  /**
   * Selection contentOptions should be defined if type="select".
   */
  selectOptions?: Array<ColFilterSelectOptions>;

  /**
   * Define max filter expandable height if filter is combobox.
   */
  maxFilterSelectHeight?: number;

  /**
   * Whether column filter enabled or not.
   * Default is true;
   */
  enabled?: boolean;

  /**
   * Default filtering value on table load.
   */
  value?: any;

  /**
   * Filter match mode.
   */
  matchMode?: FilterMatchMode;

  /**
   * Filter function for custom filtering.
   */
  filterFunction?: FilterFunc;

  /**
   * Whether filter case sensitive.
   */
  caseSensitive?: boolean;

  /**
   * Whether to show the filter and clear icons.
   */
  showIcons?: boolean;
}

export interface ColFilterSelectOptions {
  /**
   * Select value when option chosen.
   */
  value: any;

  /**
   * Select label to display.
   */
  label: string;
}

/**
 * Column sort interface.
 */
export interface ColSort {
  /**
   * Direction of sorting.
   * Possible values: 'asc' or 'desc'
   */
  direction?: SortOrder;

  /**
   * Priority of sorting when multiple sorting enabled.
   * Smaller number has higher sort priority.
   */
  priority?: number;

  /**
   * Whether column sorting enabled or not.
   * Default is true;
   */
  enabled?: boolean;

  /**
   * Type of the data for sorting.
   */
  type?: SortType;

  /**
   * Sort function for custom sorting.
   */
  sortFunction?: SortFunc;
}

/**
 * DataTable event
 */
export interface DataTableEventData {
  table?: DataTableComponent;
  events: Array<any>;
  processResultEvents?: (events: Array<any>) => void;
}

export interface BaseEvent {
  type?: any;
  result?: EventResult;

  [x: string]: any;
}

export interface EventResult {
  data?: any;
  groups?: Array<GroupInfo>;

  [x: string]: any;
}

export interface LoadDataEvent extends BaseEvent {
  readonly info: LoadEventInfo;
  _pageSize: number;
}

export interface LoadEventInfo {
  firstIndex: number;
  rowsNumber: number;

  /*
   * True in case if  handleEventsInternally=true and we don't have any data on load - we need all data.
   */
  allRows?: boolean;

  /*
   * Page number for the case when pagination is true;
   */
  page?: number;
}

export interface FilterEvent extends BaseEvent {
  readonly info: {
    filterCols: Array<FilterEventCol>;
  };
}

export interface FilterEventCol {
  matchMode?: FilterMatchMode;
  name?: string;
  field?: string;
  term: string | any;
  filterFunc?: FilterFunc;
  caseSensitive?: boolean;
}

export interface SortEvent extends BaseEvent {
  readonly info: {
    sortCols: Array<SortEventCol>;
  };
}

export interface SortEventCol {
  direction: SortOrder;
  name?: string;
  field?: string;
  priority?: number;
  sortType?: SortType;
  sorter?: Sorter;
}

export interface SelectEvent extends BaseEvent {
  readonly info: SelectEventInfo;
}

export interface SelectEventInfo {
  /**
   * In case of selection mode "multiple". We can replace 'y' and 'no' with true and false for consistency.
   */
  ids?: { [s: string]: 'y' | 'n' };

  /**
   * In case of selection mode "single".
   */
  id?: string;
}

export interface SelectAllEvent extends BaseEvent {
  readonly info: {
    isAll: boolean;
  };
}

/*
// Not implemented. Don't have such requirement from UX.
export interface RowContextEvent extends BaseEvent {
    readonly info: {
        event: any;
        rowData: any;
    };
}*/

export interface ColumnResizeEvent extends BaseEvent {
  readonly info: {
    initWidth: any;
    newWidth: any;
    colDefs: ColumnDefs;
  };
}

export interface GroupInfoEvent extends BaseEvent {
  readonly info?: {
    /**
     * Grouping collapse/expand state have to be reset to initial value.
     */
    reset?: boolean;
  };
}

export interface ExternalEventInfo {
  /**
   * Don't show loader on current action
   */
  hideLoader?: boolean;

  /**
   * Don't clear current sorting
   */
  keepSorting?: boolean;

  /**
   * Don't clear current filters
   */
  keepFiltering?: boolean;

  /**
   * Keep current scroll position/page. Don't reset to 0.
   */
  keepPage?: boolean;

  sortField?: string;

  /**
   * Sort order for the {@link ExternalEventInfo.sortField}
   */
  sortOrder?: number;

  /**
   * Whether reset selection or not.
   * Default value is false. Selection will be preserved.
   */
  resetSelection?: boolean;

  /**
   * Only in use if DataTable internal handler manage the data.
   * Make all groups expanded in case if value is true.
   */
  resetGrouping?: boolean;

  /**
   * Flag only for handleEventsInternally = true option.
   * Load data event will be triggered if value is true.
   */
  resetData?: boolean;
}

/**
 * Result object expected to sent back for {@link ActionTypes.SET_GROUP_INFO} type event.
 */
export interface GroupResultInfo {
  groups?: Array<GroupInfo>;
}

/**
 * Row group information.
 */
export interface GroupInfo {
  value: string;
  title?: string;
  count: number;
  collapsed?: boolean;

  [x: string]: any;
}

/**
 * Row collapse/expand action related information.
 * Not implemented. No use case.
 */
/*export interface RowActionInfo {
    isExpanded: boolean;
    rowData: any;
}*/

/**
 * Possible new events implementation.
 */
/*export interface DataTableEvent extends BaseEvent {
    readonly actions: Array<string>;
    readonly loadInfo: {
        readonly firstIndex: number;
        readonly rowsNumber: number;
    };
    readonly sortInfo: {
        sortCols: Array<{
            direction: string;
            name?: string;
            field?: string;
            priority?: string;
        }>;
    };
    readonly filterInfo: {
        filterCols: Array<{
            name?: string;
            field?: string;
            value: string;
            matchMode?: string;
        }>;
    };
}*/
// export type DataTableEvent  = LoadDataEvent | FilterEvent | SortEvent | DataTableEvents;
