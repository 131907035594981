import {Injectable} from '@angular/core';
import {PhxTranslateService, TranslationChangeEvent} from '@phoenix/ui/translate';
import {filter} from 'rxjs/operators';
import {merge} from 'rxjs';
import moment_ from 'moment-mini';

const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class DatepickerMomentInitService {

  constructor(private translateService: PhxTranslateService) {
    // initialize moment with default phx-translate lang 'phx_en' before any translationbundle is loaded
    this.setMomentLocale(true);

    const onLangChange$ = this.translateService.onLangChange.asObservable();
    const dateTranslateChange$ = this.translateService.onTranslationChange.asObservable().pipe(
      filter((event: TranslationChangeEvent) => event.translations && event.translations.phx && event.translations.phx.datepicker)
    );

    merge(onLangChange$, dateTranslateChange$).subscribe(() => {
      this.setMomentLocale();
    });
  }

  setMomentLocale(defaultLang?) {
    const datepickerTranslations = this.translateService.instant('phx.datepicker');
    const momentLocale = {};
    Object.keys(datepickerTranslations).map(momentKey => {
      momentLocale[momentKey] = Object.keys(datepickerTranslations[momentKey]).map(momentVal => datepickerTranslations[momentKey][momentVal]);
    });
    moment.updateLocale(defaultLang ? 'phx_en' : this.translateService.currentLang, momentLocale);
  }
}
