import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {PhxTranslateService} from '@phoenix/ui/translate';

@Component({
  selector: 'phx-loading',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <span
      role="alert"
      class="px-loader"
      [ngStyle]="{ display: show ? '' : 'none' }"
    >
      <i class="pxi px-spinner px-spin pxi-{{ iconSize }}" aria-hidden="true"></i>
      <span *ngIf="message" class="px-icon-label">{{ message }}</span>
      <span *ngIf="!message" class="px-sr-only">{{ ariaLabel }}</span>
    </span>
  `
})
export class LoadingComponent implements OnChanges, OnInit {
  iconSize = '3x'; // default md
  /**
   * Allow to set size of loading icon. Size's options: 'sm' | 'md' | 'lg',
   *  Default: 'md'.
   */
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  /**
   * Set custom aria-live politeness. Default = 'assertive'
   */
  @Input() ariaLivePoliteness: 'polite' | 'assertive' = 'assertive';

  /**
   *  Show/hide loading icon. Default: true
   */
  @Input() show = true;

  /**
   * Message display next to loading icon. Default: empty string
   */
  @Input() message = '';

  /**
   * Screenreader text to read out for accessibility. Not required if message is provided.
   */
  @Input() ariaLabel;

  constructor(private translateService: PhxTranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      switch (this.size) {
        case 'sm':
          this.iconSize = '2x';
          break;

        case 'lg':
          this.iconSize = '5x';
          break;

        default:
          this.iconSize = '3x';
          break;
      }
    }
  }

  ngOnInit() {
    if (!this.message) {
      if(!this.ariaLabel){
        this.ariaLabel = this.translateService.instant('phx.loading.loadingInProgress');
      }
    }
  }
}
