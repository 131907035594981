import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[phxTemplate]'
})
export class PhxTemplateDirective {

  /**
   * 'column' template is for column cell or filter input based on phxType value.
   */
  @Input('phxTemplate') name: 'rowexpansion'| 'column'| 'body'| 'header';

  /**
   * User only for name 'column' to identify column by field.
   */
  @Input() phxField: string;

  /**
   * Used only for name 'column'. Default is 'row'.
   */
  @Input() phxType: 'row' | 'filter' | 'label' = 'row';

  constructor(public template: TemplateRef<any>) {}

  getName(): string {
    return this.name;
  }
}
