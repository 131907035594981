import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DateUtilityService} from '../date-utility.service';
import {DatepickerService} from '../datepicker.service';
import {ActiveView, CalendarCell} from './calendar.interface';
import {DatepickerOptions} from '../datepicker-options.interface';

@Component({
  selector: 'phx-year-picker',
  templateUrl: './year-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class YearPickerComponent implements OnDestroy {

  @Input() inline: boolean;
  @Input() options: DatepickerOptions;
  yearsGrid: CalendarCell[][];
  activeView: ActiveView;

  disablePrev: boolean;
  disableNext: boolean;

  private destroy$ = new Subject<any>();

  constructor(public datePickerService: DatepickerService, private dateUtilityService: DateUtilityService) {
    this.datePickerService.activeView$.pipe(takeUntil(this.destroy$)).subscribe(view => {
      if (!this.activeView || !this.isEqualYearPickerView(view, this.activeView)) {
        this.activeView = view;
        this.yearsGrid = this.dateUtilityService.createYearPickerGrid(view, this.datePickerService.datePickerOptions);

        this.disableNext = !this.dateUtilityService.isDateRangeEnabled(this.datePickerService.datePickerOptions, [
          view.yearRange[0] + 10,
          view.yearRange[1] + 10
        ]);

        this.disablePrev = !this.dateUtilityService.isDateRangeEnabled(this.datePickerService.datePickerOptions, [
          view.yearRange[0] - 10,
          view.yearRange[1] - 10
        ]);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get activeYearRange(): string {
    if (!this.activeView) {
      return '';
    }
    return `${this.activeView.yearRange[0]} - ${this.activeView.yearRange[1]}`;
  }

  isEqualYearPickerView(view1: ActiveView, view2: ActiveView) {
    return view1.yearRange[0] === view2.yearRange[0];
  }

  showPrevYearRange() {
    const tmpView = Object.assign({}, this.activeView);
    tmpView.yearRange = this.dateUtilityService.parseYearRange(tmpView.yearRange[0] - 1);
    this.datePickerService.activeView = tmpView;
  }

  showNextYearRange() {
    const tmpView = Object.assign({}, this.activeView);
    tmpView.yearRange = this.dateUtilityService.parseYearRange(tmpView.yearRange[1] + 1);
    this.datePickerService.activeView = tmpView;
  }

  @Output() closeEvent = new EventEmitter();

  onCloseDatepicker() {
    this.closeEvent.emit();
  }
}
