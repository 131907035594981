import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhxCommonService } from '@phoenix/ui/common';

@Component({
  selector: 'phx-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    
      <input
        class="px-form-check-input"
        type="checkbox"
        [name]="name"
        #checkbox
        role="checkbox"
        [disabled]="checkboxDisabled"
        [checked]="checkboxChecked"
        [value]="value"
        [tabIndex]="0"
        [attr.aria-label]="label"
        [attr.id]="id"
        [attr.aria-describedby]="ariaDescribedBy"
        [attr.aria-checked]="!!checkboxChecked"
        (change)="onCheckboxChange($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
      />
      <label *ngIf="label" class="px-form-check-label" [for]="id">{{ label }}</label>
  `,
  host: {
    // Needs to be -1 so the `focus` event still fires.
    '[attr.tabindex]': '-1'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
/**
 * This is a stylised version of checkbox
 * based on the theme, along with basic functionality of html checkbox
 */
export class CheckboxComponent implements ControlValueAccessor {
  /**
   * This takes id. This value is also stored in label for attribute. @DefaultValue: some random value
   */
  @Input() id: string = this.phxCommonService.getRandID('Chx');
  /**
   * This takes name of the actual checkbox. @DefaultValue: empty
   */
  @Input() name = this.id;
  /**
   * This takes label as value. @DefaultValue: empty
   */
  @Input() label: string;

  /**
   * This takes aria-describedBy of the actual checkbox. @DefaultValue: empty
   */
  @Input() ariaDescribedBy: string;
  /**
   * Used for reactive way: pass control from outside into this component through this input
   */
  /**
   * This takes value of the actual checkbox. @DefaultValue: empty
   */
  @Input() value: string;

  checkboxDisabled = false;
  checkboxChecked = false;

  @ViewChild('checkbox', { static: true }) inputRef!: ElementRef;

  @HostBinding('class.focus') focusClass = false;
  // @HostBinding('class.active') checked = false
  @HostBinding('class.disabled') disabledChecked = false;
  @HostBinding('class.disablednotchecked') disabledUncheck = false;

  constructor(private phxCommonService: PhxCommonService, private cd: ChangeDetectorRef) { }

  protected onChange = (_: any) => { };
  protected onTouched = () => { };

  public onCheckboxChange($event) {
    this.checkboxChecked = !!$event.target.checked;
    $event.stopPropagation();
    if (this.value && typeof this.value === 'string') {
      this.onChange(this.checkboxChecked ? this.value : '');
    } else {
      this.onChange(this.checkboxChecked);
    }
    this.updateClassesForHostRef();
    this.cd.markForCheck();
  }

  public onFocus() {
    this.focusClass = true;
  }
  public onBlur() {
    this.focusClass = false;
    this.onTouched();
  }

  private updateClassesForHostRef() {
    this.disabledChecked = false;
    this.disabledUncheck = false;
    if (this.checkboxDisabled && this.checkboxChecked) {
      this.disabledChecked = true;
    } else if (this.checkboxDisabled && !this.checkboxChecked) {
      this.disabledUncheck = true;
    }
  }

  /**
   * ControlValueAccessor implementation ----------
   */
  public writeValue(newValue: any): void {
    if (this.inputRef) {
      if (this.checkboxDisabled && this.checkboxChecked !== newValue) {
        this.checkboxChecked = !!newValue;
        this.updateClassesForHostRef();
      } else {
        this.checkboxChecked = !!newValue;
      }
     

      /**
       * When using ngModel with predefined value, writeValue() will be executed twice,
       * yet setDisabledState() will only executed 1 time only --> checkbox did not get checked with disabled = true.
       * Need to double check again.
       */
      // if (this.checkboxChecked && this.checkboxDisabled) {
      //   this.updateClassesForHostRef();
      // }
      this.cd.markForCheck();
    }
  }

  public setDisabledState?(isDisabled: boolean) {
    this.checkboxDisabled = isDisabled;
    this.updateClassesForHostRef();
    this.cd.markForCheck();
  }
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
}
