import { SidebarOptions } from './sidebar-options.interface';
import { Subject } from 'rxjs';

export class SidebarServiceService {

  private openChangedSubject = new Subject<{open: boolean, options: SidebarOptions}>();

  public openChanged$ = this.openChangedSubject.asObservable();
  
  constructor() { }

  handleOpenChanged(open: boolean, options: SidebarOptions) {
    this.openChangedSubject.next({
      open, 
      options
    });
  }
}
