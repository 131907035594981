import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../common/shared';
import {PaginatorModule} from '@phoenix/ui/paginator';
import {BaseTable} from './table.component';
import {SortableColumn} from './sortablecolumn.directive';
import {SelectableRow} from './selectablerow.directive';
import {RowToggler} from './rowtoggler.directive';
import {ContextMenuRow} from './contextmenurow.directive';
import {ResizableColumn} from './resizablecolumn.directive';
import {EditableColumn} from './editablecolumn.directive';
import {CellEditor} from './celleditor.component';
import {ReorderableColumn} from './reorderablecolumn.directive';
import {SortIcon} from './sorticon.component';
import {TableRadioButton} from './tableradiobutton.component.';
import {TableCheckbox} from './tablecheckbox.component';
import {TableHeaderCheckbox} from './tableheadercheckbox.component';
import {ReorderableRowHandle} from './reorderablerowhandler.directive';
import {ReorderableRow} from './reorderablerow.directive';
import {SelectableRowDblClick} from './selectablerowdblclick.directive';
import {EditableRow} from './editablerow.directive';
import {InitEditableRow} from './initeditablerow';
import {SaveEditableRow} from './saveeditablerow.directive';
import {CancelEditableRow} from './canceleditablerow.directive';
import {TableBody} from './tablebody.component';
import {ScrollableView} from './scrollableview.component';
import {PhxTranslateModule} from '@phoenix/ui/translate';
import {MessageModule} from '@phoenix/ui/message';
import {CdkScrollableModule} from '@angular/cdk/scrolling';


@NgModule({
  imports: [CommonModule, PaginatorModule, PhxTranslateModule.forChild(), MessageModule, CdkScrollableModule],
  exports: [BaseTable, SharedModule, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn,
    EditableColumn, CellEditor, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle,
    ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow,
    TableBody, ScrollableView],
  declarations: [BaseTable, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn,
    EditableColumn, CellEditor, TableBody, ScrollableView, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox,
    ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow]
})
export class TableModule {
}
