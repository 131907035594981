import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { PhxCommonService } from '@phoenix/ui/common';

/**
 * This is a stylised version of checkbox
 * based on the theme, along with basic functionality of html checkbox
 */
@Component({
  selector: 'phx-radio',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="px-custom-control px-custom-radio px-custom-control-inline px-pb-2" #radioContainer>
      <input
        #radiobutton
        class="px-custom-control-input"
        type="radio"
        [value]="value"
        [attr.name]="name"
        role="radio"
        [attr.id]="id"
        [attr.aria-describedby]="ariaDescribedBy"
        (change)="onRadioChange($event)"
      />
      <label class="px-custom-control-label px-pl-1" [for]="id" *ngIf="label">{{ label }}</label>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  /**
   * This takes id. This value is also stored in label for attribute. @DefaultValue: some random value
   */
  @Input() id: string = this.phxCommonService.getRandID('Rd');
  /**
   * This takes name of the actual checkbox. @DefaultValue: empty
   */
  @Input() name: string;
  /**
   * This takes label as value. @DefaultValue: empty
   */
  @Input() label: string;

  /**
   * This takes aria-describedBy of the actual checkbox. @DefaultValue: empty
   */
  @Input() ariaDescribedBy: string;

  /**
   * This takes value of the actual radio. @DefaultValue: empty
   */
  @Input() value: string;

  @ViewChild('radiobutton',{static: true}) radioRef: ElementRef;
  @ViewChild('radioContainer', {static: true}) radioContainerRef: ElementRef;

  constructor(
    private phxCommonService: PhxCommonService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private focusMonitor: FocusMonitor
  ) { }

  ngAfterViewInit() {
    this.focusMonitor
      .monitor(this.radioContainerRef, true)
      .pipe(
        tap((origin: FocusOrigin) =>
          this.ngZone.run(() => {
            if (!origin) {
              // onBlur
              this.onTouched();
              this.cdr.markForCheck();
            } 
            // else if (origin === 'keyboard') {
            //   this.radioRef.nativeElement.click();
            //   this.cdr.markForCheck();
            // }
          })
        )
      )
      .subscribe();
  }

  protected onChange = (_: any) => { };
  protected onTouched = () => { };

  onRadioChange($event) {
    $event.stopPropagation();
    this.onChange(this.value);
  }

  /**
   * ControlValueAccessor implementation ----------
   */

  public writeValue(newValue: any): void {
    if (this.radioRef) {
      const isChecked = newValue === this.value;
      this.renderer.setProperty(this.radioRef.nativeElement, 'checked', isChecked);
    }
  }

  // called after ngViewInit()
  public setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.renderer.addClass(this.radioContainerRef.nativeElement, 'disabled');
      this.renderer.setAttribute(this.radioRef.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeClass(this.radioContainerRef.nativeElement, 'disabled');
      this.renderer.removeAttribute(this.radioRef.nativeElement, 'disabled');
    }
  }
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.radioContainerRef);
  }
}
