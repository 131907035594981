import { Component, OnChanges, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'phx-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorMessageComponent implements OnChanges {
  @Input() type: string;
  @Input() message: string;

  iconClass: string;
  iconText: string;

  ngOnChanges(): void {
    this.iconClass = null;
    this.iconText = null;
    
    switch (this.type) {
      case 'info':
        this.iconClass = 'px-info-circle px-text-information';
        this.iconText = 'phx.errorMessage.aria.info';
        break;
      case 'success':
        this.iconClass = 'px-check-circle px-text-success';
        this.iconText = 'phx.errorMessage.aria.success';
        break;
      case 'error':
        this.iconClass = 'px-minus-circle px-text-error';
        this.iconText = 'phx.errorMessage.aria.error';
        break;
      case 'warning':
        this.iconClass = 'px-exclamation-triangle px-text-warning';
        this.iconText = 'phx.errorMessage.aria.warning';
    }
  }

}
