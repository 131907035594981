export enum TouchEvents {
    pan = 'pan',
    panstart = 'panstart',
    panmove = 'panmove',
    panend = 'panend',
    pancancel = 'pancancel',
    panleft = 'panleft',
    panright = 'panright',
    panup = 'panup',
    pandown = 'pandown',
    pinch = 'pinch',
    pinchstart = 'pinchstart',
    pinchmove = 'pinchmove',
    pinchend = 'pinchend',
    pinchcancel = 'pinchcancel',
    pinchin = 'pinchin',
    pinchout = 'pinchout',
    press = 'press',
    pressup = 'pressup',
    rotate = 'rotate',
    rotatestart = 'rotatestart',
    rotatemove = 'rotatemove',
    rotateend = 'rotateend',
    rotatecancel = 'rotatecancel',
    swipe = 'swipe',
    swipeleft = 'swipeleft',
    swiperight = 'swiperight',
    swipeup = 'swipeup',
    swipedown = 'swipedown',
    tap = 'tap'
}