import {ESCAPE, hasModifierKey} from '@angular/cdk/keycodes';
import {GlobalPositionStrategy, OverlayRef} from '@angular/cdk/overlay';
import {ComponentRef} from '@angular/core';
import {asyncScheduler, Observable, Subject, Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {ModalComponent} from './modal.component';
import {ModalOptions, ModalPosition} from './modal.options.interface';
import {NavigationStart, Router, RouterEvent} from '@angular/router';

/**
 * ModalRef is the controller for modal which has responsibilities to handel open / close and clean up
 */
export class ModalRef {
  /** Subject for notifying the user that the dialog has finished opening. */
  private readonly afterOpened$ = new Subject<void>();

  /** Subject for notifying the user that the dialog has finished closing. */
  private readonly afterClosed$ = new Subject<any>();

  /** Subject for notifying the user that the dialog has started closing. */
  private readonly beforeClosed$ = new Subject<any>();

  private closeFallbackSubscription: Subscription;

  public modal: ComponentRef<ModalComponent>;

  private cdkOverlayContainer: HTMLElement;

  private currentRoute = '';
  private routerSub$;

  constructor(
    private overlayRef: OverlayRef,
    private modalComponent: ModalComponent,
    private modalOptions: ModalOptions,
    private router: Router
  ) {
    this.overrideOverlayHostElementStyles();
    this.setupEventBindings();
  }

  private overrideOverlayHostElementStyles() {
    // update overlay's z-index in case there are multiple modal opened as same time
    this.overlayRef.hostElement.style['z-index'] = 1001;

    this.cdkOverlayContainer = this.overlayRef.hostElement.parentElement;
    this.cdkOverlayContainer.style.zIndex = '1040';
  }

  private setupEventBindings() {
    this.currentRoute = this.router.url;
    // let user know when animation done with opening
    this.modalComponent.stateChanged$
      .pipe(
        filter((event: any) => event.phaseName === 'done' && event.toState === 'In'),
        take(1) // take only one and complete this observable
      )
      .subscribe(() => {
        this.afterOpened$.next();
        this.afterOpened$.complete();
      });

    // let user know when animation done with closing and dispose overlay
    this.modalComponent.stateChanged$
      .pipe(
        filter((event: any) => event.phaseName === 'done' && event.toState === 'Out'),
        take(1) // take only one and complete this observable
      )
      .subscribe(() => {
        this.unsubscribe(this.closeFallbackSubscription);
        if (this.overlayRef.hasAttached) {
          this.overlayRef.detach();
        }
      });

    // listen for overlay's observable when it is detached event
    this.overlayRef.detachments().subscribe(() => {
      this.beforeClosed$.next();
      this.beforeClosed$.complete();
      this.afterClosed$.next();
      this.afterClosed$.complete();
      // dum the component
      this.modalComponent = null;
      this.overlayRef.dispose();
      this.removeOverlayOverrides();
    });

    if (this.modalOptions.hasBackdrop) {
      if (this.modalOptions.disposeOnBackdropClick) {
        this.overlayRef.backdropClick().subscribe(() => this.close());
      }

      // Remove the backdrop as the same time as Modal
      this.modalComponent.stateChanged$
        .pipe(
          filter((event: any) => event.phaseName === 'start' && event.toState === 'Out'),
          take(1) // take only one and complete this observable
        )
        .subscribe(event => {
          this.beforeClosed$.next();
          this.beforeClosed$.complete();
          this.overlayRef.detachBackdrop();

          // if the parent view is destroyed while it's running. Add a fallback
          // timeout which will clean everything up if the animation hasn't fired within the specified
          // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
          // vast majority of cases the timeout will have been cleared before it has the chance to fire.
          this.closeFallbackSubscription = asyncScheduler.schedule(() => {
            if (this.overlayRef && this.overlayRef.hasAttached) {
              this.overlayRef.detach();
            }
          }, event.totalTime + 100);
        });
    }
    // close the modal if it is open whenever we navigate away
    if (this.modalOptions.disposeOn$) {
      this.routerSub$ = this.modalOptions.disposeOn$.pipe(take(1)).subscribe(() => {
        if (this.overlayRef.hasAttached && this.modalComponent) {
          this.unsubscribe(this.routerSub$);
          this.close();
        }
      });
    } else {
      this.routerSub$ = this.router.events.pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: RouterEvent) => {
          if (event instanceof NavigationStart) {
            if (this.overlayRef.hasAttached && this.modalComponent && event && this.currentRoute !== event.url) {
              this.unsubscribe(this.routerSub$);
              this.close();
            }
            this.currentRoute = event ? event.url : '';
          }
        });
    }

    // close modal when user hit esc key
    this.overlayRef
      .keydownEvents()
      .pipe(filter((event: any) => event.keyCode === ESCAPE && !hasModifierKey(event)))
      .subscribe(event => {
        event.preventDefault();
        event.stopPropagation();
        this.close();
      });
  }

  updatePosition(position: ModalPosition) {
    const positionStrategy = this.overlayRef.getConfig().positionStrategy as GlobalPositionStrategy;
    if (position && (position.left || position.right)) {
      position.left ? positionStrategy.left(position.left) : positionStrategy.right(position.right);
    } else {
      positionStrategy.centerHorizontally();
    }
    if (position && (position.top || position.bottom)) {
      position.top ? positionStrategy.top(position.top) : positionStrategy.bottom(position.bottom);
    } else {
      positionStrategy.centerVertically();
    }
    this.overlayRef.updatePosition();
  }

  public afterOpened(): Observable<void> {
    return this.afterOpened$.asObservable();
  }

  public afterClosed(): Observable<any> {
    return this.afterClosed$.asObservable();
  }

  public beforeClosed(): Observable<any> {
    return this.beforeClosed$.asObservable();
  }

  close() {
    if (this.modalComponent) {
      this.modalComponent.close();
    }
    this.unsubscribe(this.routerSub$);
  }

  private removeOverlayOverrides() {
    if (this.cdkOverlayContainer.children.length >= 2) {
      return;
    }
    this.cdkOverlayContainer.style.zIndex = '';
  }

  private unsubscribe(sub: Subscription) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }
}
