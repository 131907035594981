import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import {BaseTable} from './base/table.component';
import {TableService} from './base/table.service';
import * as opts from './datatable.options';
import {BaseEvent, ColumnDefs, DataTableOptions, SortFunc} from './datatable.options';
import {Logger, PhxCommonService, PhxTheme, PhxThemeService} from '@phoenix/ui/common';
import {LazyLoadEvent} from './common/lazyloadevent';
import {InternalEventType, LazyLoadMetaDataType} from './base-isc/datatable.model';
import {FilterMetadata} from './common/filtermetadata';
import {DataManager} from './base-isc/data-service/datamanager';
import {PhxTemplateDirective} from './base-isc/template.component';
import {DataTableSelection} from './base-isc/selection/selection.service';
import {InternalEventsService} from './base-isc/internalevents.service';
import {PhxTranslateService} from '@phoenix/ui/translate';
import {ObjectUtils} from './utils/objectutils';
import {DataTableUtils} from './datatableutil';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SelectionServiceProvider} from './base-isc/selection/selection-service-provider';

/**
 * All communication between client and component should be done via events. Don't set directly datatable data/options.
 */
@Component({
  selector: 'phx-datatable',
  templateUrl: './base/table.component.html',
  styleUrls: ['./base/table.component.scss', './datatable.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TableService, InternalEventsService]
})
export class DataTableComponent extends BaseTable implements AfterViewInit, AfterContentInit, OnInit, OnDestroy, PublicApi {
  // static MAX_EL_HEIGHT = 1400000; // browser related height limitation. IE10-11 max is 1533917.
  // static FILTER_DELAY = 500; // ms
  static DEFAULT_PAGE_SIZE = 50;
  // static ROWS_OFFSET_TO_LOAD = 5; // min number of rows which separated from blank screen when new data loading should be triggered.

  private static INIT_CALL_FLAG = 'init';

  @Input() @HostBinding('attr.id') id = this.pxCommonService.getRandID('Tbl');

  /**
   * contentOptions is of DataTableOptions.
   */
  @Input() options: DataTableOptions;

  /**
   * selection will allow the component to enable selection of each row.
   * Default: "" [empty]. Options-single, multiple
   */
  @Input() selectionType: 'single' | 'multiple'; // TODO ?

  /**
   * Table width exist in PX5. Not in use. Datatable suppose to be responsive. Otherwise style can be used.
   */
  // @Input() scrollWidth;

  /**
   * This is the main channel of communication with table.
   * The events will be emitted for the most of the actions withing the table. The events will have the information about table action
   * and some of them will require some results back.
   * The examples of the table actions: filtering, sorting, row selection, scrolling etc.
   */
    // __isAsync initialized with 'true' to eliminate "value changed after check" issue when table data set up immediately.
    // tslint:disable-next-line:no-output-on-prefix
  @Output() onTableEvents = new EventEmitter<opts.DataTableEventData>(true);

  /**
   * Expose user action events when datatable handle events internally.
   * Events doesn't require any result updated and processing.
   * @type {EventEmitter<DataTableEventData>}
   */
    // tslint:disable-next-line:no-output-on-prefix
  @Output() onInternalEvents = new EventEmitter<opts.DataTableEventData>(true);

  @ViewChild('bodyDefTemplate', {static: true}) bodyTpl: TemplateRef<any>;
  @ViewChild('headerDefTemplate', {static: true}) headTpl: TemplateRef<any>;
  @ViewChild('colgroupDefTemplate', {static: true}) colgroupTpl: TemplateRef<any>;

  @ViewChild('loading') loadingViewChild: ElementRef;

  /**
   * Possible values: 'rowexpansion'| 'column'| 'body'| 'filter'
   */
  @ContentChildren(PhxTemplateDirective) phxTemplates: QueryList<PhxTemplateDirective>;

  public rowGroupTemplate: TemplateRef<any>;

  /**
   * Row data represented in the table.
   */
  rowsData: any[] = [];

  private colMap: Map<string, opts.ColumnDefs>;

  private dataManager: DataManager;

  _latestEvents: Array<BaseEvent>;

  private dataManagerProcessedInitCall = false;

  private activeEvents = 1;

  selectHandler: DataTableSelection; // TODO add expected data info and result validation. Add shift selection.


  public expandable = false;

  /**
   * Template for column cell content.
   */
  cellTemplates: { [field: string]: TemplateRef<any> } = {};

  /**
   * Template for column header filter content.
   */
  filterTemplates: { [field: string]: TemplateRef<any> } = {};

  /**
   * Template for column header label content.
   */
  labelTemplates: { [field: string]: TemplateRef<any> } = {};

  private defaultAlertMessage: string;

  private defaultAlertType = 'warning';

  allExpanded = false;

  public extraColCount = 0;

  _hasFilters = false;

  public ariaStatus: string;

  public announceStatus = false;

  private destroy$ = new Subject<never>();

  isHelix = false;

  constructor(public el: ElementRef, public zone: NgZone, public tableService: TableService,
              public cd: ChangeDetectorRef, public logger: Logger, private pxCommonService: PhxCommonService,
              public internalEventsService: InternalEventsService, private translateService: PhxTranslateService,
              private phxThemeService: PhxThemeService) {
    super(el, zone, tableService, cd);
    this.loading = true;
  }

  ngOnInit() {
    /*this.rowTrackBy = function (index: number, item: any): any {
     return index;
     };*/
    this.phxThemeService.theme$.pipe(takeUntil(this.destroy$)).subscribe((theme: PhxTheme) => {
      this.isHelix = theme === PhxTheme.HELIX;
      this.cd.detectChanges();
    });
    this.defaultAlertMessage = this.translateService.instant('phx.datatable.alertMessage') || 'No rows found';
    const optionData = this.options.data || [];
    const sView = this.options.smartView;
    this.paginator = sView.pagination;
    let rowsToRender = optionData;
    this.initColumns(this.options.columnDefs);
    const sortFilterInfo = this.getColumnSortFilterInfo(); // After columns initialization

    if (sortFilterInfo.sort) {
      this.sortOrder = sortFilterInfo.sort.order;
      this.sortField = sortFilterInfo.sort.field;
    }

    // TODO reset case - do we need keep on reset?, handle matchmode
    if (sortFilterInfo.filter && sortFilterInfo.filter.length > 0) {
      for (const filter of sortFilterInfo.filter) {
        this.filters[filter.field] = {value: String(filter.value)};
      }
    }

    if (sView.handleEventsInternally) { // TODO have single config or custom sorter/filer? Pagination case.
      this.dataManager = new DataManager(optionData);
      this.dataManager.setRowIdField(sView.idField);
      this.dataManager.setGroupingField(sView.groupField);
      this.dataManager.setSelectField(sView.selectField);
      // this.api._setDataHandler(this.dataManager);
      if (sView.pageSize < optionData.length) {
        rowsToRender = this.dataManager.fetchData(sView.pageSize);
      }
    }

    this.setRowsData(rowsToRender); // Set value
    this.initOptions(optionData.length); // Be careful with location to call

    this.columns = this.options.columnDefs;
    this.setPageSize(sView.pageSize);
    this.totalRecords = this.getTotalRowNumber();

    if (sView.rowHeight) {
      this.virtualRowHeight = sView.rowHeight;
    }

    this.lazy = true; // handle all calls via this component.
    this.virtualScroll = this.paginator !== true;
    this.scrollable = this.scrollable || this.paginator !== true;
    this.scrollHeight = this.scrollHeight || '400px';
    this.dataKey = sView.idField;
    this.resizableColumns = sView.resizableCols;

    if (this.resizableColumns) {
      this.columnResizeMode = sView.columnResizeMode === 'fit' ? 'fit' : 'expand';
    }

    if (this.selectionType) {
      if (!sView.idField) {
        throw new Error(`Phx table option property 'idField' has to be defined when row selection is enabled`);
      }

      if (!sView.selectField) {
        throw new Error(`Phx table option property 'selectField' has to be defined when row selection is enabled`);
      }
      this.selectHandler = SelectionServiceProvider.getService(this, this.logger, this.paginator);
    }

    if (this.paginator) {
      if (sView.pageLinks) {
        this.pageLinks = sView.pageLinks;
      }
      this.rowsPerPageOptions = sView.showPaginatorDropdown ? (sView.rowsPerPageOptions || [sView.pageSize]) : null;
    }

    this.setAriaStatus();

    super.ngOnInit(); // Keep at the end
  }

  private setPageSize(pageSize) {
    this.rows = Math.floor(pageSize / (this.paginator ? 1 : 2)); // divide by 2 for smartscroll due to base implementation
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * Flow:
   * table.component.ts trigger event -> onLazyCall(event) -> loadData(event) -> this.onTableEvents.emit with events
   * -> delivery controller process events and set results
   * -> delivery side call  eventData.table.api.handleResultEvents(resultEvents);
   * -> set resultEvents -> update Datatable state.
   * @param metadata
   */
  onLazyCall(metadata: any): void { // Bofa added to override by parent.
    this.loadData(metadata as LazyLoadEvent); // Process use action. This callback triggered by table.component.ts.
  }

  /**
   * Bind delivery side templates for columns, headers, etc.
   */
  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    // this.logger.debug(`Table after content init`);
    this.bodyTemplate = this.bodyTpl;
    this.headerTemplate = this.headTpl;
    this.colGroupTemplate = this.colgroupTpl; // TODO set col width, skip if no width?

    this.phxTemplates.forEach(item => {
      switch (item.getName()) {
        case 'rowexpansion':
          this.expandedRowTemplate = item.template;
          // this.rowExpandTemplate = item.template;
          break;
        case 'rowgroupheader':
          this.rowGroupTemplate = item.template;
          break;
        case 'body':
          this.bodyTemplate = item.template;
          break;
        case 'column':
          if ('filter' === item.phxType) {
            this.filterTemplates[item.phxField] = item.template;
          } else if ('label' === item.phxType) {
            this.labelTemplates[item.phxField] = item.template;
          } else { // 'row' case
            this.cellTemplates[item.phxField] = item.template;
          }
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
      }
    });
    this.expandable = !!this.expandedRowTemplate;
    this.extraColCount += this.expandable ? 1 : 0;
    this.extraColCount += this.selectionType ? 1 : 0;
  }

  /**
   * This will return/set all the latest events for the table.
   */
  get resultEvents(): Array<BaseEvent> {
    return this._latestEvents;
  }

  /**
   * Main method to process events results set by delivery team
   * or internal data manager in case if handleEventsInternally=true.
   *
   * @param events update with results by delivery team
   */
  set resultEvents(events: Array<BaseEvent>) {
    this._latestEvents = events;
    this.logger.debug('Table: handle result: ', events);
    if (events && events.length > 0) {
      const loadEvent = events.find((event: BaseEvent) => event.type === opts.ActionTypes.LOAD_DATA);
      const initCall = loadEvent && !!loadEvent[DataTableComponent.INIT_CALL_FLAG];
      if (initCall && !this.dataManagerProcessedInitCall && this.dataManager) {
        this.dataManagerProcessedInitCall = true;
        this.dataManager.setData(loadEvent.result.data);
        this.dataManager.processEvents(events);
      }
      this.handleResults(events);
    } else {
      this.rowsData = [];
    }
    this.removeActivity();
    this.cd.markForCheck();
  }

  private addActivity() {
    this.activeEvents++;
    this.setLoading();
  }

  private removeActivity(reset?: boolean) {
    this.activeEvents = reset ? Math.max(0, --this.activeEvents) : 0;
    this.setLoading();
  }

  private setLoading() {
    this.loading = this.activeEvents > 0;
  }

  public getTotalRowNumber(): number {
    // Reset to 0 since pDataTable can try to calculate scroll on load without using correct row height.
    return this.rowsData.length === 0 ? 0 : this.options.smartView.filteredRowNumber;
  }

  /**
   * Initial event triggered based on some user action. Triggered by table.component.ts via onLazyCall.
   * All Events created in this method and send to delivery team component handler.
   *
   *
   * @param data event data.
   */
  loadData(data: LazyLoadEvent): void {
    const events = [];
    const isInitCall = data.action === LazyLoadMetaDataType.INIT_LOAD;
    const resetData = data.externalEventInfo && data.externalEventInfo.resetData; // only for internal handler.
    if (resetData) {
      this.dataManagerProcessedInitCall = false;
    }
    const initCallWithData = this.rowsData && this.rowsData.length > 0 && isInitCall && !resetData;
    const initCallNoDataNoSubscriberInternal = isInitCall && this.options.smartView.handleEventsInternally
      && this.onTableEvents.observers.length === 0;

    if (initCallWithData || initCallNoDataNoSubscriberInternal) {
      // Ignore initial load call if data present.
      this.logger.debug('Table: skip data load', data);
      this.removeActivity(true);
      // this.showLoaderWithTimout(false);
      return;
    } else {
      this.logger.debug('Table: load START ', data);
    }

    if (data.action === LazyLoadMetaDataType.SORT) { // TODO replace with switch
      this.doSort(data, events);
    } else if (data.action === LazyLoadMetaDataType.SCROLL) {
      this.pushLoadEvent(data, events, {doCheck: true});
    } else if (isInitCall) { // TODO move up
      this.pushFilterEvent(data, events, true);
      this.pushSortEvent(data, events);
      this.pushCountEvent(events);
      const loadEvt = this.pushLoadEvent(data, events, {doCheck: true});
      loadEvt[DataTableComponent.INIT_CALL_FLAG] = true;
      this.pushGroupInfoEvent(events);
    } else if (data.action === LazyLoadMetaDataType.FILTER) {
      this.doFiltering(data, events);
    } else if (data.action === LazyLoadMetaDataType.RESET) {
      if (this.selectHandler && data.externalEventInfo &&
        (data.externalEventInfo.resetSelection || data.externalEventInfo.resetData)) {

        this.selectHandler.resetAll(this);

        if (this.dataManager) {
          this.dataManager.resetSelection();
        }
      }
      this.onRefresh(data, events);
    } else if (data.action === LazyLoadMetaDataType.PAGE_RESIZE) {
      this.pushLoadEvent(data, events);
    }

    const hideLoader = isInitCall || (data.externalEventInfo && data.externalEventInfo.hideLoader);
    this.pushEvents(events, hideLoader, isInitCall || resetData);
    this.logger.debug('Table: load END');

  }

  private handleResults(resultEvents: Array<BaseEvent>) {
    // const loadEvt = <opts.LoadDataEvent> events[0];
    // this.rowsData = loadEvt.result.data;
    // let countParams = {};
    let loadEvent = null;
    let type = null;
    let filtered = false;
    let initCall = false;
    // let groupEvent = null;
    let countEvent = null;
    this.setPageSize(this.options.smartView.pageSize);
    const eCount = resultEvents.length;
    for (let i = 0; i < eCount; i++) {
      const evt = resultEvents[i];
      evt.result = evt.result ? evt.result : {};
      type = evt.type;
      if (type === opts.ActionTypes.LOAD_DATA) {
        loadEvent = evt;
        initCall = !!loadEvent[DataTableComponent.INIT_CALL_FLAG];
        // TODO clearSorting(self.state, evt.result.sort);
        /* if (evt.result.sort && evt.result.sort.field) {
         // Sort result added to load event since sort event not always present.
         // TODO clearSorting(self.state, evt.result.sort);
         } */
      } else if (type === opts.ActionTypes.SET_COUNTS) {
        const supportedProperties = [
          {name: 'filteredRowNumber', defVal: 0},
          {name: 'filteredSelectableRowNumber'},
          {name: 'filteredSelectedCount', defVal: 0},
          {name: 'totalSelectedCount'}
        ];
        this.updateCounts(evt.result, supportedProperties);
        countEvent = evt;
        // const countParams = this.updateCounts(evt.result, supportedProperties);
        // TODO self.state.total = sView.filteredRowNumber;
      } else if (type === opts.ActionTypes.FILTER) {
        filtered = true;
      } else if (type === opts.ActionTypes.SORT) {
        this.internalEventsService.onEvent({type: InternalEventType.ON_AFTER_SORT});
      }
    }

    if (loadEvent) {
      this.setLoadedData(loadEvent);
      this.internalEventsService.onEvent({
        type: InternalEventType.ON_AFTER_LOAD,
        info: {
          table: this
        }
      });
      this.syncRowExpansion();
    }
    if (countEvent) {
      this.totalRecords = this.getTotalRowNumber();
    }
    if (filtered) {
      this.tableService.onFilter();
      this.tableService.onTotalRecordsChange(this.getTotalRowNumber());
      this.internalEventsService.onEvent({type: InternalEventType.ON_AFTER_FILTER});
    } else if (initCall) {
      this.internalEventsService.onEvent({type: InternalEventType.ON_INIT});
    }
  }

  setLoadedData(event: opts.LoadDataEvent) {
    let data = event.result.data;
    data = data ? data : [];
    const info = event.info;
    this.logger.debug(`Page size, event: ${event._pageSize} vs options: ${this.options.smartView.pageSize}`);
    let pageSizeMismatch = false;
    if (event._pageSize !== this.options.smartView.pageSize) { // page size changed.
      pageSizeMismatch = data.length !== this.options.smartView.pageSize;
    }
    if (data.length > 0 && data.length !== info.rowsNumber && pageSizeMismatch
      && data.length !== this.options.smartView.filteredRowNumber) {
      throw new Error(`Smart scroll received ${data.length}
                 rows which doesn't match requested 'rowsNumber' ( ${info.rowsNumber} )
                 or 'filteredRowNumber' ( sViewOptions.filteredRowNumber ) limit`); // TODO
    }
    // this.logger.info(`Table: data loaded, size: ${data.length}`);
    this.setRowsData(event.result.data);
  }

  setRowsData(rows: any[]) { // TODO replace with setter.
    this.rowsData = rows || [];
    this.value = this.rowsData;
    // this.options.data = this.rowsData;
    // this.createRowMetaData(this.rowsData);
  }

  doSort(data: LazyLoadEvent, events: any[]) {
    this.pushSortEvent(data, events);
    this.pushLoadEvent(data, events);
    this.internalEventsService.onEvent({type: InternalEventType.ON_SORT});
  }

  doFiltering(data: LazyLoadEvent, events: any[]) {
    this.pushFilterEvent(data, events);
    this.pushCountEvent(events);
    this.pushLoadEvent(data, events);
    this.pushGroupInfoEvent(events);
    this.internalEventsService.onEvent({type: InternalEventType.ON_FILTER});
  }

  onRefresh(data: LazyLoadEvent, events: any[]) {
    const sortEvt = this.pushSortEvent(data, events);
    if (!sortEvt) { // No default sorting. Add empty sorting to notify consuming component about reset.
      events.push(this.createSortEvent());
    }
    this.pushFilterEvent(data, events);
    this.pushCountEvent(events);
    const loadEvt = this.pushLoadEvent(data, events);
    const resetData = data.externalEventInfo && data.externalEventInfo.resetData;
    if (resetData) {
      loadEvt[DataTableComponent.INIT_CALL_FLAG] = true;
    }
    this.pushGroupInfoEvent(events, data);
    this.internalEventsService.onEvent({
      type: InternalEventType.ON_REFRESH,
      info: {
        externalEventInfo: data.externalEventInfo
      }
    });
  }

  pushLoadEvent(data: LazyLoadEvent, events: any[], params?: LoadActionParams): opts.LoadDataEvent {
    const doCheck = params && params.doCheck;
    const pageSize = data.rows;
    let rows = pageSize;
    let first = data.first;
    const total = this.getTotalRowNumber();

    if (doCheck) { // doesn't executed when grouping collapsed.
      rows = Math.min(pageSize, total - first) || pageSize;
      if (doCheck && rows === this.options.smartView.filteredRowNumber) {
        // this.showLoader(false);
        return;
      }
    }

    const rowNumChange = params ? params.totalRowNumberChange : null;

    if (this.isNumber(rowNumChange)) {
      const newTotal = total + rowNumChange;
      if (newTotal < (first + rows)) {
        first = Math.max(newTotal - rows, 0);
      }
      rows = newTotal < rows ? newTotal : rows;
    }

    const _info: opts.LoadEventInfo = {
      rowsNumber: rows,
      firstIndex: first,
      allRows: !!this.dataManager // When handle events internally we need all rows, will be executed only once on load if no data.
    };

    if (this.paginator) {
      _info.page = first / pageSize + 1;
    }

    const loadEvent: opts.LoadDataEvent = {
      type: opts.ActionTypes.LOAD_DATA,
      info: _info,
      _pageSize: pageSize // Initial page size from datatable.
    };
    events.push(loadEvent);

    return loadEvent;
  }

  pushSortEvent(data: LazyLoadEvent, events: any[]): opts.SortEvent {
    if (!data.sortField && !this.hasGrouping()) {
      return null;
    }
    const sortEvent: opts.SortEvent = this.createSortEventWithData(data);
    events.push(sortEvent);
    return sortEvent;
  }

  pushFilterEvent(data: LazyLoadEvent, events: any[], initCall?: boolean) {
    const filters: { [s: string]: FilterMetadata } = data.filters;
    const fields = Object.keys(filters);
    if (!initCall || fields.length > 0) {
      const filterEvent = this.createFilterEventWithData(data);
      events.push(filterEvent);
    }
  }

  pushCountEvent(events: any[]) { // TODO. add expected counts + validation.
    const countEvent: opts.BaseEvent = {
      type: opts.ActionTypes.SET_COUNTS
    };
    events.push(countEvent);
  }

  pushGroupInfoEvent(events: any[], data?: LazyLoadEvent) {
    /*if (this.hasGrouping()) {
      const groupInfoEvent: opts.GroupInfoEvent = {
        type: opts.ActionTypes.SET_GROUP_INFO,
        info: {
          reset: data && data.externalEventInfo && data.externalEventInfo.resetGrouping
        }
      };
      events.push(groupInfoEvent);
    }*/
  }

  updateCounts(result, props) {
    const view = this.options.smartView;
    const resultCounts = {};
    props.forEach(prop => {
      const value = result[prop.name];
      const resVal = this.isNumber(value) ? value : prop.defVal;

      if (resVal != null && this.isDef(resVal)) {
        view[prop.name] = resVal;
        resultCounts[prop.name] = value;
      }
    });
    return resultCounts;
  }

  /**
   * Send events to delivery team controller by 'onTableEvents' output.
   */
  pushEvents(events: Array<BaseEvent>, skipLoad?: boolean, initCall?: boolean) {
    this.logger.info('Table: emit events: ', events);
    if (!skipLoad) {
      this.addActivity();
    }
    if (!initCall && this.dataManager) { // use internal data handling
      const internalEvents = DataTableUtils.getInternalEvents(events);
      if (internalEvents && internalEvents.length > 0) {
        this.onInternalEvents.emit({
          table: this,
          events: internalEvents
        });
      }
      this.dataManager.processEvents(events);
      this.resultEvents = events;
    } else {
      this.onTableEvents.emit({
        table: this,
        events: events,
        processResultEvents: (evts: Array<BaseEvent>) => {
          this.resultEvents = evts;
        }
      });
    }
  }

  public createSortEventWithData(data: LazyLoadEvent): opts.SortEvent {
    const sortEvent: opts.SortEvent = this.createSortEvent();

    /*if (this.hasGrouping()) {
      sortEvent.info.sortCols.push({
        direction: this.groupingHandler.groupingSortOrder,
        field: this.getGroupField(),
        priority: -1
      });
    }*/

    if (data.sortField) {
      const evt: opts.SortEventCol = {
        direction: data.sortOrder > 0 ? opts.SortOrder.ASC : opts.SortOrder.DESC,
        field: data.sortField,
        priority: 0
      };

      const sortFunc = this.getFieldSortFunction(data.sortField);

      if (sortFunc) {
        evt.sorter = {sort: sortFunc};
      }

      const sortType = this.getFieldSortType(data.sortField);
      if (sortType) {
        evt.sortType = sortType;
      }

      sortEvent.info.sortCols.push(evt);
    }

    return sortEvent;
  }

  public createFilterEventWithData(data: LazyLoadEvent): opts.FilterEvent {
    const filters: { [s: string]: FilterMetadata } = data.filters;
    const fields = Object.keys(filters);
    this.logger.info('filter fields: ', fields);
    let filterEvent: opts.FilterEvent = null;
    const result: Array<opts.FilterEventCol> = [];

    fields.forEach(key => {
      const event: opts.FilterEventCol = {
        field: key,
        name: key,
        term: filters[key].value
      };

      const colFilter = this.colMap.get(key).filter;
      if (colFilter) {
        if (colFilter.matchMode) {
          event.matchMode = colFilter.matchMode;
        }

        if (colFilter.filterFunction) {
          event.filterFunc = colFilter.filterFunction;
        }

        if (colFilter.caseSensitive) {
          event.caseSensitive = colFilter.caseSensitive;
        }
      }

      result.push(event);
    });

    filterEvent = {
      type: opts.ActionTypes.FILTER,
      info: {filterCols: result}
    };

    return filterEvent;
  }

  /**
   * Override parent method execute when page is changed.
   *
   * @param event new page info.
   */
  onPageChange(event) {
    this.first = event.first; // (event.page - 1) * this.rows;
    this.firstChange.emit(this.first);

    if (this.rows !== event.rows) {
      this.rows = event.rows;
      this.options.smartView.pageSize = this.rows;
      this.rowsChange.emit(this.rows);
    }

    this.tableService.onPagination(event);
    this.onLazyCall(this.createLazyLoadMetadata(LazyLoadMetaDataType.PAGE_RESIZE));
  }

  private createSortEvent(): opts.SortEvent {
    return {
      type: opts.ActionTypes.SORT,
      info: {sortCols: []}
    };
  }

  private getFieldSortFunction(field: string): SortFunc {
    const colSort = this.colMap.get(field)?.sort;
    return colSort ? colSort.sortFunction : null;
  }

  private getFieldSortType(field: string): opts.SortType {
    const colSort = this.colMap.get(field)?.sort;
    return colSort ? colSort.type : null;
  }

  callFilter(filterValue, column: ColumnDefs) {
    const matchMode = column.filter ? column.filter.matchMode : null;
    this.filter(filterValue, column.field, matchMode);
  }

  refreshData(events?: any[]): void {
    this.setPageSize(this.options.smartView.pageSize);
    const sortFilterInfo = this.getColumnSortFilterInfo();
    const eventInfo: opts.ExternalEventInfo = this.getExternalEventsInfo(events);
    this.reset(sortFilterInfo, eventInfo);
  }

  getExternalEventsInfo(events: any[]): opts.ExternalEventInfo {
    if (!events) {
      return null;
    }

    const result: opts.ExternalEventInfo = {};

    events.forEach(event => {
      const info: opts.ExternalEventInfo = event.info || {};
      result.keepSorting = result.keepSorting || info.keepSorting;
      result.keepFiltering = result.keepFiltering || info.keepFiltering;
      result.hideLoader = result.hideLoader || info.hideLoader;
      result.keepPage = result.keepPage || info.keepPage;
      result.resetSelection = result.resetSelection || info.resetSelection;
      result.resetGrouping = result.resetGrouping || info.resetGrouping;
      result.resetData = result.resetData || info.resetData;
    });
    return result;
  }

  // Selection methods START
  toggleRowCheckbox(event, rowData: any) {
    const events = this.selectHandler.toggleRow(event, rowData, this);
    this.pushEvents(events);
  }

  toggleAllWithCheckbox(value) {
    const events = this.selectHandler.toggleAll(value, this);
    this.pushEvents(events);
  }

  resetAllSelection() {
    const events = this.selectHandler.resetAll(this);
    this.pushEvents(events);
  }

  getSelectedRowCount() {
    return this.selectHandler.getSelectedCount(); // TODO
  }

  selectRowWithRadio(event, rowData: any) {
    const events = this.selectHandler.toggleRadioRow(event, rowData, this);
    this.pushEvents(events);
  }

  rowNotSelectable(rowData: any) {
    return this.options.isRowSelectable && !this.options.isRowSelectable(rowData);
  }

  isSelectionHidden(rowData: any) {
    const sView = this.options.smartView;
    return this.rowNotSelectable(rowData) && sView.hideDisabledSelect;
  }

  isRowHighlighted(rowData: any): boolean {
    return (this.selectHandler ? this.selectHandler.isRowSelected(rowData) : false)
      || (this.options.smartView.highlightedRows && this.options.smartView.highlightedRows[rowData[this.dataKey]]);
  }

  isRowExpandable(rowData: any) {
    return this.options.isRowExpandable ? this.options.isRowExpandable(rowData) : this.expandable;
  }

  hasGrouping(): boolean {
    return false; // !!this.groupingHandler;
  }

  getAlertMessage(): string {
    return this.options.smartView.alertMessage || this.defaultAlertMessage;
  }

  getAlertType(): string {
    return this.options.smartView.alertType || this.defaultAlertType;
  }

  initOptions(rowCount: number) {
    const sView = this.options.smartView;
    sView.pageSize = sView.pageSize || DataTableComponent.DEFAULT_PAGE_SIZE;
    sView.filterable = sView.filterable !== false;
    sView.showHeader = sView.showHeader !== false;
    sView.resizableCols = sView.resizableCols !== false;
    sView.totalRowNumber = sView.totalRowNumber || sView.filteredRowNumber;
    sView.totalRowNumber = this.isDef(sView.totalRowNumber) ? sView.totalRowNumber : Math.max(0, rowCount);
    sView.filteredRowNumber = this.isDef(sView.filteredRowNumber) ? sView.filteredRowNumber : sView.totalRowNumber;
    sView.totalSelectedCount = this.isDef(sView.totalSelectedCount) ? sView.totalSelectedCount : 0;
    sView.filteredSelectedCount = this.isDef(sView.filteredSelectedCount)
      ? sView.filteredSelectedCount : sView.totalSelectedCount;
    sView.showPaginatorDropdown = sView.showPaginatorDropdown === null || sView.showPaginatorDropdown === undefined || sView.showPaginatorDropdown;
  }

  initColumns(columns: Array<ColumnDefs>) {
    if (!columns || columns.length === 0) {
      return;
    }
    this._hasFilters = false;
    this.colMap = new Map<string, opts.ColumnDefs>();
    if (this.options.smartView.selectionSortEnabled) {
      const selectColumn: ColumnDefs = {
        field: this.options.smartView.selectField,
        name: this.options.smartView.selectField,
        sort: {enabled: true},
      };
      this.colMap.set(selectColumn.field, selectColumn);
    }
    columns.forEach(colDef => {
      this.colMap.set(colDef.field, colDef);
      const col = colDef;
      if (col) {
        col.name = col.name || col.field;
        col.sort = col.sort || {};
        col.sort.enabled = !(col.sort.enabled === false || (this.options.smartView.sortable === false && col.sort.enabled !== true));

        col.filter = col.filter || {};
        col.filter.enabled = !(col.filter.enabled === false || (this.options.smartView.filterable === false && col.filter.enabled !== true));
        this._hasFilters = this._hasFilters || col.filter.enabled;

        if (col.width) {
          col.style = col.style || {};
          const cWidth = col.width;
          const hasUnit = typeof cWidth === 'string'
            && (cWidth.indexOf('px') > 0 || cWidth.indexOf('%') > 0);
          col.style.width = hasUnit ? cWidth : cWidth + 'px'; // 'px' is default unit.
        }

        col.id = col.id || this.pxCommonService.getRandID('Dcl');
      }
    });
  }

  /**
   * Supporting sorting only by single column.
   * @returns {{sort: {field?, order?}, filter: [{field?, value?}]}}
   */
  private getColumnSortFilterInfo(): DTSortFilterInfo {
    const result: DTSortFilterInfo = {};
    if (this.options.columnDefs) {
      this.options.columnDefs.forEach(colDef => {
        if (colDef.sort && colDef.sort.enabled && colDef.sort.direction) {
          result.sort = {field: colDef.field};
          result.sort.order = colDef.sort.direction === opts.SortOrder.DESC ? -1 : 1;
        }
        if (colDef.filter && colDef.filter.value) { // TODO -1, 0, blank check??  handle matchmode
          result.filter = result.filter || [];
          result.filter.push({
            field: colDef.field,
            value: colDef.filter.value
          });
        }
      });
    }
    return result;
  }

  /**
   * Create event for column resize action.
   * TODO Have to be updated for 'fit' resize mode to provide information for second column.
   * @param param
   */
  onColumnResizeEndBofa(param: any) {
    this.logger.debug(param);
    const colId = param.element.id;
    let colDf: opts.ColumnDefs = null;
    this.options.columnDefs.forEach(colDef => {
      if (colId === colDef.id) {
        colDf = colDef;
      }
    });
    const newW = param.element.offsetWidth;
    const initW = Math.round(newW - param.delta);

    const resizeEvent: opts.ColumnResizeEvent = {
      type: opts.ActionTypes.COLUMN_RESIZE,
      info: {
        initWidth: initW,
        newWidth: newW,
        colDefs: colDf
      },
    };

    this.pushEvents([resizeEvent], true);
  }

  syncRowExpansion() {
    if (this.options.smartView.showExpandAll) {
      const rows = this.rowsData;
      for(let i=0; i<rows.length; i++) {
        const dataKey = String(ObjectUtils.resolveFieldData(rows[i], this.dataKey));
        if (!this.expandedRowKeys[dataKey]) {
          this.allExpanded = false;
          break;
        } else {
          this.allExpanded = true;
        }
      }
    }
  }

  toggleRowsAll(event) {
    event.preventDefault();
    this.toggleAllInternal(true);
  }

  toggleAllRows(value?: boolean) {
    this.toggleAllInternal(false, value);
  }

  toggleAllInternal(fromControl: boolean, value?: boolean) {
    if (fromControl && (value === true && this.allExpanded || value === false && !this.allExpanded)) {
      return;
    }

    this.allExpanded = fromControl ? !this.allExpanded : !!value;
    if (this.rowsData.length > 0 && this.expandable) { // only multiple rowExpandMode in use by spec
      if (this.allExpanded) {
        this.rowsData.forEach(rowData => {
          const expanded = this.options.isRowExpandable ? this.options.isRowExpandable(rowData) : this.expandable;
          const dataKey = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
          this.expandedRowKeys[dataKey] = expanded;
        });
      } else {
        this.expandedRowKeys = {};
      }
    }
  }

  tabHandler($event: KeyboardEvent) {
    if (this.loading && this.showLoader) {
      if ($event.target !== this.loadingViewChild.nativeElement) {
        this.loadingViewChild.nativeElement.focus();
      }
    }
  }

  shiftTabHandler($event: KeyboardEvent) {
    if (this.loading && this.showLoader) {
      if ($event.target !== this.containerViewChild.nativeElement) {
        this.containerViewChild.nativeElement.focus();
      }
    }
  }

  // public API START

  get api(): PublicApi {
    return this;
  }

  handleResultEvents(events: Array<BaseEvent>) {
    this.resultEvents = events;
  }

  getSelectedCount(): number {
    return this.getSelectedRowCount();
  }

  doFilter(filterValue, column: ColumnDefs) {
    this.callFilter(filterValue, column);
  }

  getFilter(column: ColumnDefs) {
    return this.filters[column.field];
  }

  public clearFilters() {
    this.filteredValue = null;
    this.filters = {};

    this.first = 0;
    this.firstChange.emit(this.first);

    if (this.lazy) {
      this.onLazyCall(this.createLazyLoadMetadata(LazyLoadMetaDataType.FILTER));
    } else {
      this.totalRecords = (this._value ? this._value.length : 0);
    }
  }

  refresh(...events: any[]) {
    this.refreshData(events);
  }

  getFilterInfo(): opts.FilterEvent {
    const metaData = this.createLazyLoadMetadata();
    const filterInfo = this.createFilterEventWithData(metaData);
    const hasFilters = filterInfo && filterInfo.info && filterInfo.info.filterCols && filterInfo.info.filterCols.length > 0;
    return hasFilters ? filterInfo : null;
  }

  getSortInfo(): opts.SortEvent {
    const metaData = this.createLazyLoadMetadata();
    const sortInfo = this.createSortEventWithData(metaData);
    const hasSort = sortInfo && sortInfo.info && sortInfo.info.sortCols && sortInfo.info.sortCols.length > 0;
    return hasSort ? sortInfo : null;
  }

  toggleAllCheckbox(value: boolean) {
    this.toggleAllWithCheckbox(value);
  }

  resetSelection() {
    this.resetAllSelection();
  }

  setAriaStatus(): void {
    this.tableService.sortSource$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((status: any) => {
        if (status) {
          if (status.order === 1) {
            this.ariaStatus = this.translateService.instant('phx.datatable.sort.ariaDescOrder');
          } else if (status.order === -1) {
            this.ariaStatus = this.translateService.instant('phx.datatable.sort.ariaAscOrder');
          }
          this.announceStatus = true;
        }
      });

    this.tableService.filterSource$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((status: any) => {
      this.ariaStatus = this.translateService.instant('phx.datatable.aria.onFiltering', {
        count: this.options.smartView.filteredRowNumber,
        total: this.options.smartView.totalRowNumber
      });
    });

    this.tableService.paginationSource$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((event: any) => {
      this.ariaStatus = 'phx.datatable.aria.onPagination';
      this.ariaStatus = this.translateService.instant('phx.datatable.aria.onPagination', {
        first: event.first + 1,
        last: Math.min((event.first + event.rows), this.options.smartView.filteredRowNumber),
        total: this.options.smartView.filteredRowNumber
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // public API END

  // Utility methods START
  private isDef(obj) {
    return typeof obj !== 'undefined';
  }

  private isNumber(value) {
    return typeof value === 'number';
  }

  // Utility methods END

  isTooltipDisabled(container: HTMLElement, elm: HTMLElement) {
    if (!this.options.smartView.tooltipEnabled) {
      return true;
    }
    const cs = getComputedStyle(container);
    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    const borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    const containerWidth = container.offsetWidth - paddingX - borderX;
    return containerWidth >= elm.offsetWidth + 3;
  }
}


export interface PublicApi {

  handleResultEvents(events: Array<BaseEvent>);

  getSelectedCount(): number;

  doFilter(filterValue, column: ColumnDefs);

  getFilter(column: ColumnDefs): FilterMetadata;

  clearFilters();

  refresh(...events: any[]);

  getFilterInfo(): opts.FilterEvent;

  getSortInfo(): opts.SortEvent;

  /**
   * Toggle all checkboxes.
   * @param value true if select all otherwise false.
   */
  toggleAllCheckbox(value: boolean);

  /**
   * Reset all checkboxes selections.
   */
  resetSelection();

  /**
   * Toggle all expandable rows.
   * @param value. True to expand. False to collapse. Not boolean - will toggle rows between expand/collapse states.
   */
  toggleAllRows(value?: boolean);
}

export interface LoadActionParams {
  /**
   * Verify if all rows present and load event is not needed.
   */
  doCheck?: boolean;

  /**
   * Used for first index recalculation when group collapsed.
   */
  totalRowNumberChange?: number;
}

export interface DTSortFilterInfo {
  sort?: {
    field?: string;
    order?: number;
  };
  filter?: DTFilterItem[];
}

export interface DTFilterItem {
  field: any;
  value: any;
}

// TODO try to create another component with templates and keep it inside datatable.
// 1. Scrollable - always or not? in case of pagination - do we have side scroll or not?
// 2. TODO - check if rows number odd case, since / 2 send to base table
