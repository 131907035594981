import { ComponentFactoryResolver, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PhxCommonService } from '@phoenix/ui/common';
import { IntroComponent } from './intro.component';
import { Intro } from './intro.interface';
import { IntroService } from './intro.service';

/**
 * Allows the user to put intro on any element
 * with inbuilt feature to trigger the intro based on configuration object passed to the directive.
 */
@Directive({ selector: '[phxTour]', exportAs: 'phxTour' })
export class IntroDirective implements OnInit, OnDestroy {
  /**
   * Pass the Intro configuration with step definitions
   */
  @Input() phxTour: Intro;
  triggerElement: HTMLElement;
  // tslint:disable-next-line: ban-types
  private deRegisterEvents: Array<Function> = [];

  constructor(
    private compFactoryResolver: ComponentFactoryResolver,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public phxCommonService: PhxCommonService,
    private $intro: IntroService
  ) {
    this.triggerElement = elementRef.nativeElement;
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'px-intro-trigger');
    this.deRegisterEvents.push(
      this.renderer.listen(this.triggerElement, 'click', (event: Event) => {
        this.open();
        return false;
      })
    );
  }

  open() {
    this.$intro.phxIntro = this.compFactoryResolver.resolveComponentFactory(IntroComponent);
    const config: Intro = {
      ...this.phxTour,
      steps: [...this.phxTour.steps]
    };
    this.$intro.start(config, this.triggerElement);
  }

  close() {
    this.$intro.end();
  }

  ngOnDestroy() {
    if (Array.isArray(this.deRegisterEvents)) {
      // tslint:disable-next-line: ban-types
      this.deRegisterEvents.forEach((fn: Function) => {
        fn.apply(this);
      });
      this.deRegisterEvents = [];
    }
    this.$intro.end();
  }
}
