import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import {PhxCommonModule} from '@phoenix/ui/common';
import {PopupComponent, PopupPosition} from './popup.component';

export {PopupComponent, PopupPosition} from './popup.component';

@NgModule({
  declarations: [PopupComponent],
  imports: [
    CommonModule,
    PhxCommonModule,
    OverlayModule,
  ],
  exports: [
    PopupComponent
  ]
})
export class PopupModule {
}
