/**
 * Forked implementation of CleaveJS (v1.5.3) with added and removed features, written in Typescript
 * Removed features: Time, Date, Credit Card, Built-in Phone Masks (Using custom mask for Phone)
 * Added features: Block-level pattern masks
 *
 * Custom additions to logic are commented with '@Addition'
 */
import { CharType } from './cleave-options.interface';

export class CleaveUtil {

    // @Addition
    private static readonly acode = 'a'.charCodeAt(0);
    private static readonly zerocode = '0'.charCodeAt(0);

    // @Addition - check if character is alphabetic
    static isAlphabet(char): boolean {
        const lettercode = char.toLowerCase().charCodeAt(0);
        if ((lettercode - CleaveUtil.acode >= 0) && (lettercode - CleaveUtil.acode < 26)) {
            return true;
        }
        return false;
    }

    // @Addition - check if character is numeric
    static isNumeric(char): boolean {
        const lettercode = char.charCodeAt(0);
        if ((lettercode - CleaveUtil.zerocode >= 0) && (lettercode - CleaveUtil.zerocode < 10)) {
            return true;
        }
        return false;
    }

    noop() {}

    strip(value, re) {
        return value.replace(re, '');
    }

    getPostDelimiter(value, delimiter, delimiters, selectionEnd?) {
        // single delimiter
        if (delimiters.length === 0) {
            return value.slice(-delimiter.length) === delimiter ? delimiter : '';
        }

        // multiple delimiters
        let matchedDelimiter = '';
        delimiters.forEach((current) => {
            if (selectionEnd && value.slice(0, selectionEnd).slice(-current.length) === current) {
                matchedDelimiter = current;
            } else if (value.slice(-current.length) === current) {
                matchedDelimiter = current;
            }
        });

        return matchedDelimiter;
    }

    getDelimiterREByDelimiter(delimiter) {
        return new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g');
    }

    getNextCursorPosition(prevPos, oldValue, newValue, delimiter, delimiters) {
      // If cursor was at the end of value, just place it back.
      // Because new value could contain additional chars.
      if (oldValue.length === prevPos) {
          return newValue.length;
      }

      return prevPos + this.getPositionOffset(prevPos, oldValue, newValue, delimiter, delimiters);
    }

    getPositionOffset(prevPos, oldValue, newValue, delimiter, delimiters) {
        let oldRawValue;
        let newRawValue;
        let lengthOffset;

        oldRawValue = this.stripDelimiters(oldValue.slice(0, prevPos), delimiter, delimiters);
        newRawValue = this.stripDelimiters(newValue.slice(0, prevPos), delimiter, delimiters);
        lengthOffset = oldRawValue.length - newRawValue.length;

        return (lengthOffset !== 0) ? (lengthOffset / Math.abs(lengthOffset)) : 0;
    }

    stripDelimiters(value, delimiter, delimiters) {
        const owner = this;

        // single delimiter
        if (delimiters.length === 0) {
            const delimiterRE = delimiter ? owner.getDelimiterREByDelimiter(delimiter) : '';

            return value.replace(delimiterRE, '');
        }

        // multiple delimiters
        delimiters.forEach((current) => {
            current.split('').forEach((letter) => {
                value = value.replace(owner.getDelimiterREByDelimiter(letter), '');
            });
        });

        return value;
    }

    headStr(str, length) {
        return str.slice(0, length);
    }

    getMaxLength(blocks) {
        return blocks.reduce((previous, current) => {
            return previous + current;
        }, 0);
    }

    // strip prefix
    // Before type  |   After type    |     Return value
    // PEFIX-...    |   PEFIX-...     |     ''
    // PREFIX-123   |   PEFIX-123     |     123
    // PREFIX-123   |   PREFIX-23     |     23
    // PREFIX-123   |   PREFIX-1234   |     1234
    getPrefixStrippedValue(value, prefix, prefixLength, prevResult, delimiter, delimiters, noImmediatePrefix = false) {
        // No prefix
        if (prefixLength === 0) {
          return value;
        }

        // Pre result prefix string does not match pre-defined prefix
        if (prevResult.slice(0, prefixLength) !== prefix) {
          // Check if the first time user entered something
          if (noImmediatePrefix && !prevResult && value) { return value; }

          return '';
        }

        const prevValue = this.stripDelimiters(prevResult, delimiter, delimiters);

        // New value has issue, someone typed in between prefix letters
        // Revert to pre value
        if (value.slice(0, prefixLength) !== prefix) {
          return prevValue.slice(prefixLength);
        }

        // No issue, strip prefix for new value
        return value.slice(prefixLength);
    }

    getFirstDiffIndex(prev, current) {
        let index = 0;

        while (prev.charAt(index) === current.charAt(index)) {
            if (prev.charAt(index++) === '') {
                return -1;
            }
        }

        return index;
    }

    getFormattedValue(value, blocks, blocksLength, delimiter, delimiters, delimiterLazyShow) {
        let result = '';
        const multipleDelimiters = delimiters.length > 0;
        let currentDelimiter;

        // no contentOptions, normal input
        if (blocksLength === 0) {
            return value;
        }

        blocks.forEach((length, index) => {
            if (value.length > 0) {
                const sub = value.slice(0, length);
                const rest = value.slice(length);

                if (multipleDelimiters) {
                    currentDelimiter = delimiters[delimiterLazyShow ? (index - 1) : index] || currentDelimiter;
                } else {
                    currentDelimiter = delimiter;
                }

                if (delimiterLazyShow) {
                    if (index > 0) {
                        result += currentDelimiter;
                    }

                    result += sub;
                } else {
                    result += sub;

                    if (sub.length === length && index < blocksLength - 1) {
                        result += currentDelimiter;
                    }
                }

                // update remaining string
                value = rest;
            }
        });

        return result;
    }

    // move cursor to the end
    // the first time user focuses on an input with prefix
    fixPrefixCursor(el, prefix, delimiter, delimiters) {
        if (!el) {
            return;
        }

        const val = el.value;
        const appendix = delimiter || (delimiters[0] || ' ');

        if (!el.setSelectionRange || !prefix || (prefix.length + appendix.length) < val.length) {
            return;
        }

        const len = val.length * 2;

        // set timeout to avoid blink
        setTimeout(() => {
            el.setSelectionRange(len, len);
        }, 1);
    }

    // Check if input field is fully selected
    checkFullSelection(value) {
      try {
        const selection = window.getSelection() || document.getSelection() || {};
        return selection.toString().length === value.length;
      } catch (ex) {
        // Ignore
      }

      return false;
    }

    setSelection(element, position, doc) {
        if (element !== this.getActiveElement(doc)) {
            return;
        }

        // cursor is already in the end
        if (element && element.value.length <= position) {
          return;
        }

        if (element.createTextRange) {
            const range = element.createTextRange();

            range.move('character', position);
            range.select();
        } else {
            try {
                element.setSelectionRange(position, position);
            } catch (e) {
                // eslint-disable-next-line
                console.warn('The input element type does not support selection');
            }
        }
    }

    getActiveElement(parent) {
        const activeElement = parent.activeElement;
        if (activeElement && activeElement.shadowRoot) {
            return this.getActiveElement(activeElement.shadowRoot);
        }
        return activeElement;
    }

    isAndroid() {
        return navigator && /android/i.test(navigator.userAgent);
    }

    // On Android chrome, the keyup and keydown events
    // always return key code 229 as a composition that
    // buffers the user’s keystrokes
    // see https://github.com/nosir/cleave.js/issues/147
    isAndroidBackspaceKeydown(lastInputValue, currentInputValue) {
        if (!this.isAndroid() || !lastInputValue || !currentInputValue) {
            return false;
        }

        return currentInputValue === lastInputValue.slice(0, -1);
    }

  /**
   * @Addition - strip each character in provided string that doesn't match it's corresponding mask character type (letter, alphanumeric, numeric)
   */
    stripEach(value, maskCharTypes): string {
        let ret = '';
        for (let i = 0; i < value.length; i++) {
            const type = maskCharTypes[i];
            switch (type) {
                case CharType.ALPHABET:
                    ret += CleaveUtil.isAlphabet(value[i]) ? value[i] : '';
                    break;
                case CharType.ALPHANUMERIC:
                    ret += (CleaveUtil.isAlphabet(value[i]) || CleaveUtil.isNumeric(value[i])) ? value[i] : '';
                    break;
                case CharType.NUMERIC:
                    ret += CleaveUtil.isNumeric(value[i]) ? value[i] : '';
                    break;
            }
        }
        return ret;
    }

  /**
   * @Addition - given input value and maskPattern, generate the guiding placeholder string to use as the element value until the user satisfies the mask pattern
   */
  static createNextGuidingPlaceholderString(value: string, maskPatternBuffer: string[], blocks: number[], delimiters: string[]): string {
    const ret = [...maskPatternBuffer];
    let bufferIdx = 0;
    let valueIdx = 0;
    blockLoop:
      for (let i = 0; i < blocks.length; i++) {
        for (let j = 0; j < blocks[i]; j++) {
          ret[bufferIdx++] = value[valueIdx++];
          if (valueIdx === value.length) {
            break blockLoop;
          }
        }
        if (delimiters[i]) {
          bufferIdx += delimiters[i].length;
        }
    }
    return ret.join('');
  }

}
