import { DataTableSelection } from "./selection.service";
import { DataTableComponent } from '../../datatable.component';
import * as opts from '../../datatable.options';
/**
 * Created by NBKPYZL on 12/8/2021.
 */

/**
 * Selection service which handle selection for the tables with pagination.
 */
export class PageRowSelectionService extends DataTableSelection {

  private _selectedAll = false;

  public get selectedAll() {
    return this._selectedAll;
  }

  resetAll(table: DataTableComponent): Array<opts.BaseEvent> {
    this.selectedCount = 0;
    this.selectedRowsNotFiltered = 0;
    table.rowsData.forEach((row, index) => {
      if (!table.rowNotSelectable(row)) {
          row[this.selField] = false;
      }
    });
    this._selectedAll = false;
    return this.eventUtil.createToggleAllEvent(false);
  }

  toggleAll(isSelected: boolean, table: DataTableComponent): Array<opts.BaseEvent> {
    this.logger.debug('toggle all: ', isSelected, ', size: ', table.rowsData.length);
    let newSelected = 0;
    const inc = isSelected ? 1 : -1;
    //rowsData - data rendered for current page.
    let ids = {};
    table.rowsData.forEach((row, index) => {
      if (!table.rowNotSelectable(row)) {
        if (row[this.selField] != isSelected) {
          row[this.selField] = isSelected;
          newSelected += inc;
          ids[row[this.idField]] = isSelected ? 'y' : 'n';
        }
      }
    });

    this._selectedAll = isSelected;
    this.selectedCount += newSelected;

    return this.eventUtil.createRowsSelectEvent(ids);
  }

  syncSelectAllState(table: DataTableComponent) {
    let allSelected = true;
    let allDisabled = true;
    table.rowsData.forEach((row, index) => {
      if (!table.rowNotSelectable(row)) {
        allDisabled = false;
        allSelected = allSelected && row[this.selField];
      }
    });
    this._selectedAll = table.rowsData.length > 0 && allSelected && !allDisabled;
  }

  protected afterLoad(table: DataTableComponent): void {
    this.syncSelectAllState(table);
  }

}
