import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@phoenix/ui/modal';

import { ImageViewerComponent } from './image-viewer.component';
import { ImgDirective } from './img.directive';
import { SlideComponent } from './phx-slide.component';

export { ImageViewerComponent } from './image-viewer.component';
export { ImgDirective } from './img.directive';
export { ImageViewerOptions, IDefaultLables } from './image-viewer.options';

const PHX_IMAGEVIEWER_COMP = [ImageViewerComponent, ImgDirective, SlideComponent];

@NgModule({
  imports: [CommonModule, ModalModule],
  exports: PHX_IMAGEVIEWER_COMP,
  declarations: PHX_IMAGEVIEWER_COMP
})
export class ImageViewerModule {}
