import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const Animations: {
    readonly fade: AnimationTriggerMetadata;
    readonly dropdown: AnimationTriggerMetadata;
} = {
    fade: trigger('fadeAnimation', [
        state('default', style({opacity: 1})),
        transition('void => *', [style({opacity: 0}), animate('{{ fadeIn }}ms')]),
        transition('default => closing', animate('{{ fadeOut }}ms', style({opacity: 0})))
    ]),
    dropdown: trigger('dropdownAnimation', [
        state('show', style({
            opacity: 1
        })),
        state('hide', style({
            opacity: 0
        })),
        transition('show => hide', [style({transform: 'scaleY(100%)'}), animate('2000ms ease-out')]),
        transition('hide => show', [style({transform: 'scaleY(0)'}), animate('2000ms ease-in')])
    ]),
};

export type AnimationState = 'default' | 'closing';
