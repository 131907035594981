import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Cleave } from '../cleave-fork/cleave';
import { CleaveNumeralOptions, NumeralThousandsGroupStyleType } from '../cleave-fork/cleave-options.interface';
import { CleaveUtil } from '../cleave-fork/cleave-utils';

export type CurrencyFormat = 'xxxxx' | 'xx,xx,xxx.xx' | 'xxx,xxx,xxx.xx' | 'xx,xx,xx,xxx.xx' | 'xxx.xxx.xxx,xx';

/**
 * Currency mask directive that restricts and automatically formats input as currency
 * Note: Please ensure when using this directive that you provide a label which describes the mask pattern for accessibility
 */
@Directive({
  selector: 'input[phxCurrency]',
  host: {
    '(blur)': '_onTouched()',
    '(paste)': 'handlePaste($event)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyDirective),
      multi: true
    }
  ]
})
export class CurrencyDirective implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  private cleave: Cleave;

  /**
   * Currency format from which the decimal character, thousands delimiter, decimal precision and currency type will be parsed.
   * Providing a decimal character is optional (if you do not provide decimal character the decimal precision will be set to 0 and only integer values will be allowed).
   */
  @Input() phxCurrency: CurrencyFormat;

  /**
   * Controls whether negative value allowed. Default is false.
   */
  @Input() allowNegative = false;

  // TODO: remove or uncomment this as input depending on delivery team need
  // /**
  //  * maximum number of digits to the right of decimal point
  //  */
  // @Input()
  private decimalPrecision = 2;

  // TODO: remove or uncomment this as input depending on delivery team need
  // /**
  //  * the character used to mark the decimal point
  //  */
  // @Input()
  private decimalMarker = '.';

  /**
   * maximum number of digits to the left of a decimal point
   */
  @Input() integerScale = 15;

  // TODO: remove or uncomment this as input depending on delivery team need
  // /**
  //  * the character used to separate thousands in the currency value
  //  */
  // @Input()
  private thousandsDelimiter = ',';

  /**
   * character to use as the currency symbol prefix
   */
  @Input() currencySymbol = '$';

  _onTouched = () => {};
  private _onChange = (value: any) => {};

  constructor(private elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2) {}

  writeValue(value: any) {
    const cleaveValue = this.cleave.getRawValue();
    if (!value || cleaveValue !== value + '') {
      this.cleave.setRawValue(value);
      this.changeDetectorRef.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elRef.nativeElement, 'disabled', isDisabled);
  }

  ngOnInit() {
    this.initCleave();
  }

  ngOnDestroy() {
    if (this.cleave) {
      this.cleave.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).some((key: string) => !changes[key].isFirstChange())) {
      if (this.cleave) {
        this.cleave.destroy();
      }
      this.initCleave();
    }
  }

  private initCleave() {
    let decimalMark;
    let thousandDelimiter;
    let thousandsGroupStyle;
    let decimalPrecision;
    if (this.phxCurrency) {
      [decimalMark, thousandDelimiter, thousandsGroupStyle, decimalPrecision] = this.parseFormat(this.phxCurrency);
    }
    const options: CleaveNumeralOptions = {
      numeral: true,
      numeralDecimalScale: decimalPrecision !== undefined ? decimalPrecision : this.decimalPrecision,
      numeralDecimalMark: decimalMark !== undefined ? decimalMark : this.decimalMarker,
      numeralIntegerScale: this.integerScale,
      numeralThousandsGroupStyle: thousandsGroupStyle || 'thousand',
      delimiter: thousandDelimiter !== undefined ? thousandDelimiter : this.phxCurrency === 'xxxxx' ? '' : this.thousandsDelimiter,
      prefix: this.currencySymbol,
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeralPositiveOnly: !this.allowNegative,
      onValueChanged: (event: any): void => {
        this._onChange(event.target.rawValue);
        this.changeDetectorRef.markForCheck();
      }
    };
    this.cleave = new Cleave(this.elRef.nativeElement, options);
  }

  handlePaste($event) {
    const pasteText = $event.clipboardData.getData('text/plain');
    const cleaveValue = this.cleave.getRawValue();
    if (!pasteText || cleaveValue !== pasteText) {
      this.cleave.setRawValue(pasteText);
      this.changeDetectorRef.markForCheck();
    }
    this.changeDetectorRef.markForCheck();
    $event.preventDefault();
  }

  private parseFormat(format: string): [string, string, NumeralThousandsGroupStyleType, number] {
    let decimalMark;
    let thousandsDelimiter;
    let countBetweenThousands = 0;
    let decimalPrecision;
    let thousandsGroupStyle: NumeralThousandsGroupStyleType;
    // exit loop once we determine thousandGroupStyle, as it is parsed last
    for (let i = format.length - 1; i >= 0 && !thousandsGroupStyle; i--) {
      if (!CleaveUtil.isNumeric(format[i]) && !CleaveUtil.isAlphabet(format[i])) {
        // first non-alphanumeric character we find from the end will be the decimalMarker
        if (decimalMark === undefined) {
          decimalMark = format[i];
          decimalPrecision = format.length - i - 1;
        } else {
          thousandsGroupStyle = this.parseGroupStyle(countBetweenThousands);
          thousandsDelimiter = format[i];
        }
      } else if (thousandsGroupStyle !== undefined) {
        countBetweenThousands++;
      }
    }
    // if user passed in format without a decimalMark (they want no decimalPrecision)
    if (!thousandsDelimiter || thousandsDelimiter === decimalMark) {
      decimalPrecision = 0;
    }
    return [decimalMark, thousandsDelimiter, thousandsGroupStyle, decimalPrecision];
  }

  // use the digit count between thousandsDelimiter to determine thousandsGroupStyle
  private parseGroupStyle(countBetweenThousands: number): NumeralThousandsGroupStyleType {
    switch (countBetweenThousands) {
      case 2:
        return 'lakh';
      case 3:
        return 'thousand';
      case 4:
        return 'wan';
      default:
        return null;
    }
  }
}
