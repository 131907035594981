import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { ButtonDirective } from '@phoenix/ui/button';
import {PhxTranslateService} from '@phoenix/ui/translate';

@Component({
  selector: 'phx-button-toggle',
  templateUrl: './button-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(ButtonDirective) buttons: QueryList<ButtonDirective>;

  private _selectedIndex = 0;

  /**
   * Set a button as selected. By default the first button is selected.
   */
  @Input()
  set selectedIndex(value: number) {
    if (this._selectedIndex !== value) {
      this._selectedIndex = value;
      if (this.isContentInit) {
        this.setSelectedButton();
      }
    }
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  /**
   * label for screenreaders
   */
  @Input() ariaLabel = this.translateService.instant('phx.buttonToggle.buttonToggleGroup');

  /**
   * Id of existing label for ADA. If a rendered label exists for the button-toggle, pass the id of the label instead of providing the ariaLabel input property.
   */
  @Input() ariaLabelledBy: string = null;

  /**
   * Whenever the selected button changes this event will be triggered.
   */
  @Output() selectedIndexChange = new EventEmitter<number>();

  private isContentInit = false;

  constructor(private translateService: PhxTranslateService) { }

  ngAfterContentInit() {
    if (this.buttons && this.buttons.length > 0) {
      this.buttons.forEach((buttonDirective, idx) => {
        buttonDirective.buttonElement.addEventListener('click', this.phxChangeSelectedIndex.bind(this, idx), false);
        buttonDirective.buttonElement.setAttribute('aria-pressed', 'false');
        if (idx === this.selectedIndex) {
          buttonDirective.buttonElement.classList.add('px-selected');
          buttonDirective.buttonElement.setAttribute('aria-pressed', 'true');
        }
      });
      this.isContentInit = true;
    }
  }

  private setSelectedButton() {
    this.buttons.forEach((buttonDirective, idx) => {
      buttonDirective.buttonElement.classList.remove('px-selected');
      buttonDirective.buttonElement.setAttribute('aria-pressed', 'false');
      if (idx === this.selectedIndex) {
        buttonDirective.buttonElement.classList.add('px-selected');
        buttonDirective.buttonElement.setAttribute('aria-pressed', 'true');
      }
    });
  }

  private phxChangeSelectedIndex(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChange.emit(index);
  }

  ngOnDestroy(): void {
    if (this.buttons && this.buttons.length > 0) {
      this.buttons.forEach((buttonDirective, idx) => {
        buttonDirective.buttonElement.removeEventListener('click', this.phxChangeSelectedIndex.bind(this, idx), false);
      });
    }
  }
}
