import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DatepickerService} from '../datepicker.service';
import {ActiveView, CalendarCell, CalendarView} from './calendar.interface';
import moment from 'moment-mini';
import {DateUtilityService} from '../date-utility.service';
import {DatepickerOptions} from '../datepicker-options.interface';

@Component({
  selector: '[phx-calendar-body]',
  templateUrl: './calendar-body.component.html',
  host: {
    class: 'mat-calendar-body'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CalendarBodyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() options: DatepickerOptions;
  @ViewChildren('calendarBtn') calendarBtns: QueryList<ElementRef>;
  public tabbableCell: number;
  today: Date = new Date();
  todayInActiveView: boolean;
  private activeView: ActiveView;
  private destroy$ = new Subject<any>();
  private initialFocus: boolean;

  ////////////////////////////////
  // used for single datepicker //
  ////////////////////////////////
  selectedCellIdx: number;
  private selectedDate: Date;

  ////////////////////////
  // used for daterange //
  ////////////////////////
  fromDateHoverOutOfBound = false; // flag for from date selection hover going past current toDate
  // currently selected fromDate calendar cell idx, used to help show middle-range highlighting
  fromDateIdx: number;
  // currently selected toDate calendar cell idx, used to help show middle-range highlighting
  toDateIdx: number;
  hoveredCellIdx: number;
  // if we hover before selected fromDate, need to unselect fromDate temporarily
  hoveredBeforeFromDate: boolean;
  // if we hover after selected toDate, need to unselect toDate temporarily
  hoveredAfterToDate: boolean;
  // current fromDate and toDate selections
  private fromDate: Date;
  private toDate: Date;
  private fromDateTimestamp: number;
  private toDateTimestamp: number;
  // activeView converted to Date for comparison
  private viewDate: Date;

  @Input() grid: CalendarCell[][];
  @Input() type: 'year' | 'month' | 'day';
  @Input() inline: boolean;

  constructor(private elementRef: ElementRef, public datePickerService: DatepickerService, private dateUtilityService: DateUtilityService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.datePickerService.dateRangeMode) {
      this.datePickerService.dateRangeSelection$.pipe(takeUntil(this.destroy$)).subscribe(([fromDate, toDate]) => {
        this.fromDate = fromDate;
        this.toDate = toDate;
        if (fromDate) {
          this.fromDateTimestamp = new Date(fromDate).setHours(0, 0, 0, 0);
        }
        if (toDate) {
          this.toDateTimestamp = new Date(toDate).setHours(0, 0, 0, 0);
        }
        this.dateRangeInActiveView(this.activeView);
      });
    } else {
      this.datePickerService.latestDateSelection$.pipe(takeUntil(this.destroy$)).subscribe(date => {
        this.selectedDate = date;
        if (!this.inline) {
          this.selectedCellIdx = -1;
        }
      });
    }

    this.datePickerService.activeView$.pipe(takeUntil(this.destroy$)).subscribe((view: ActiveView) => {
      this.activeView = view;
      const selectedDate = new Date(view.year, view.month);
      const minDate = this.options?.minDate ? moment(this.options.minDate, this.dateUtilityService.currentFormat) : null;
      const maxDate = this.options?.maxDate ? moment(this.options.maxDate, this.dateUtilityService.currentFormat) : null;
      // If minDate activeView should not be before the minDate
      // If maxDate activeView should not be after the maxDate
      if (minDate && moment(selectedDate).isBefore(minDate)) {
        this.viewDate = new Date(view.year, moment(minDate).month());
      } else if (maxDate && moment(selectedDate).isAfter(maxDate)) {
        this.viewDate = new Date(view.year, moment(maxDate).month());
      } else {
        this.viewDate = new Date(view.year, view.month);
      }
      this.activeView.month = moment(this.viewDate).month();
      this.activeView.year = moment(this.viewDate).year();
      this.todayInActiveView = this.inActiveView(view, this.today) && view.calendarView === CalendarView.DAY && this.datePickerService.datePickerOptions.mode !== 'month';
      if (this.datePickerService.dateRangeMode) {
        this.dateRangeInActiveView(view);
      } else {
        this.singleDateInActiveView(view);
      }
      if (!this.initialFocus && !this.datePickerService.shouldFocusOnMonth) {
        this.setFocusOnLoad();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grid && changes.grid.currentValue) {
      const grid = changes.grid.currentValue;
      this.tabbableCell = this.getTabbableValue(grid);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTabbableValue(grid): number {
    for (let i = 0; i < grid.length; i++) {
      const row = grid[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (cell.enabled && !cell.hidden) {
          return cell.value;
        }
      }
    }
  }

  setFocusOnLoad(): void {
    setTimeout(() => {
      const firstSelectedCell = this.elementRef.nativeElement.querySelector('button.px-selected');
      if (firstSelectedCell) {
        firstSelectedCell.focus();
      } else {
        const todayCell = this.elementRef.nativeElement.querySelector('button.px-btn-today');
        if (todayCell) {
          todayCell.focus();
        } else if (this.activeView.calendarView === CalendarView.MONTH) {
          this.tabbableCell = this.activeView.month;
          this.moveFocusToCell();
        } else if (
          this.activeView.calendarView === CalendarView.YEAR &&
          this.activeView.year >= this.activeView.yearRange[0] &&
          this.activeView.year <= this.activeView.yearRange[1]
        ) {
          this.tabbableCell = this.activeView.year;
          this.moveFocusToCell();
        } else {
          const firstBtn = this.elementRef.nativeElement.querySelector('button:not([hidden])');
          if (firstBtn) {
            firstBtn.focus();
          }
        }
      }
      this.initialFocus = true;
    });
  }

  singleDateInActiveView(view) {
    const selectedInActiveView = this.inActiveView(view, this.selectedDate);
    this.selectedCellIdx = selectedInActiveView ? this.getSelectedDateCell(view, this.selectedDate) : -1;
  }

  dateRangeInActiveView(view: ActiveView) {
    const fromInActiveView = this.inActiveView(view, this.fromDate);
    const toInActiveView = this.inActiveView(view, this.toDate);
    this.fromDateIdx = fromInActiveView ? this.getSelectedDateCell(view, this.fromDate) : undefined;
    this.toDateIdx = toInActiveView ? this.getSelectedDateCell(view, this.toDate) : undefined;
    if (!this.fromDate || !this.toDate) {
      return;
    }
    // if selected fromDate is in another view, and this view comes before that view chronologically, then set fromDateIdx to -1 to mark hovered range
    if (this.fromDateIdx === undefined && this.fromDate > this.viewDate) {
      this.fromDateIdx = 32;
    } else if (this.fromDateIdx === undefined && this.fromDate < this.viewDate) {
      this.fromDateIdx = -1;
    }
    // if selected toDate is in another view, and this view comes after that view chronologically, then set toDateIdx to 32 to mark hovered range
    if (this.toDateIdx === undefined && this.toDate < this.viewDate) {
      this.toDateIdx = -1;
    } else if (this.toDateIdx === undefined && this.toDate > this.viewDate) {
      this.toDateIdx = 32;
    }
  }

  getSelectedDateCell(view: ActiveView, date: Date): number {
    let cell;
    switch (view.calendarView) {
      case CalendarView.MONTH:
        if (this.datePickerService.datePickerOptions.mode === 'month') {
          cell = new Date(date).setHours(0, 0, 0, 0);
        } else {
          cell = view.month;
        }
        break;
      case CalendarView.YEAR:
        cell = view.year;
        break;
      case CalendarView.DAY:
        cell = new Date(date).setHours(0, 0, 0, 0);
        break;
    }
    return cell;
  }

  inActiveView(view: ActiveView, target: Date): boolean {
    if (!view || !target) {
      return false;
    }
    switch (view.calendarView) {
      case CalendarView.DAY:
        if (this.datePickerService.dateRangeMode && this.datePickerService.datePickerOptions.mode === 'month') {
          return view.year === target.getFullYear();
        }
        return view.month === target.getMonth() && view.year === target.getFullYear();
      case CalendarView.MONTH:
        return view.year === target.getFullYear();
      case CalendarView.YEAR:
        return target.getFullYear() >= view.yearRange[0] && target.getFullYear() <= view.yearRange[1];
    }
  }

  onCellClick(cell: CalendarCell) {
    if (cell.enabled) {
      switch (this.activeView.calendarView) {
        case CalendarView.MONTH:
          this.selectedCellIdx = cell.value;
          this.activeView.month = cell.value;
          this.activeView.calendarView = CalendarView.DAY;
          this.datePickerService.activeView = this.activeView;
          this.datePickerService.shouldFocusOnMonth = false;
          if (this.datePickerService.datePickerOptions.mode === 'month') {
            cell.value = 1;
            this.onCellClick(cell);
          }
          break;
        case CalendarView.YEAR:
          this.selectedCellIdx = cell.value;
          this.activeView.year = cell.value;
          this.activeView.calendarView = this.datePickerService.datePickerOptions.mode === 'month' ? CalendarView.MONTH : CalendarView.DAY;
          this.datePickerService.activeView = this.activeView;
          this.datePickerService.shouldFocusOnMonth = this.datePickerService.datePickerOptions.mode !== 'month';
          break;
        case CalendarView.DAY:
          const newDate = new Date(this.activeView.year, this.activeView.month, cell.value);
          if (this.datePickerService.dateRangeMode) {
            this.datePickerService.updateDateRange(newDate);
            this.dateRangeInActiveView(this.activeView);
          } else {
            this.selectedCellIdx = cell.value;
            this.datePickerService.setDateFromCalendar(newDate);
          }
          if (this.datePickerService.datePickerOptions.mode === 'month') {
            this.activeView.calendarView = CalendarView.MONTH;
          }
          this.datePickerService.shouldFocusOnMonth = false;
          break;
      }
    }
  }

  /**
   * For DateRange only:
   * -if given cell date value is before currently selected fromDate, highlight all cells between given cell and toDate
   * -if given cell date value is after currently selected toDate, highlight all cells between given cell and fromDate
   * -set focus to fromDate input (if not already focused) when a date less than current fromDate is hovered.
   * -reset focus to toDate input (if not already focused) when a date equal to or greater than current fromDate is hovered
   */
  onCellMouseOver(cell: CalendarCell) {
    if (!cell.enabled || !this.datePickerService.dateRangeMode) {
      return;
    }
    this.hoveredCellIdx = cell.timestamp || cell.value;
    if (cell.timestamp < this.fromDateIdx) {
      this.hoveredBeforeFromDate = true;
    } else if (cell.timestamp >= this.fromDateIdx && (this.datePickerService.dateRangeOrigin === 'toDate' || this.datePickerService.initialDateSelected)) {
    }

    if (cell.timestamp > this.toDateIdx) {
      this.hoveredAfterToDate = true;
      if (this.datePickerService.dateRangeOrigin === 'fromDate' && !this.datePickerService.initialDateSelected) {
        this.fromDateHoverOutOfBound = true;
      }
    }
  }

  onCellMouseLeave(cell: CalendarCell) {
    if (!this.datePickerService.dateRangeMode) {
      return;
    }
    this.hoveredCellIdx = undefined;
    this.hoveredBeforeFromDate = false;
    this.hoveredAfterToDate = false;
    this.fromDateHoverOutOfBound = false;
  }

  isMiddleRange(cell) {
    const calendarView = this.datePickerService.datePickerOptions.mode === 'month' ? CalendarView.MONTH : CalendarView.DAY;

    return (
      this.activeView.calendarView === calendarView &&
      cell.enabled &&
      !this.fromDateHoverOutOfBound &&
      this.hoveredCellIdx !== cell.timestamp &&
      (
        (cell.timestamp > this.hoveredCellIdx && cell.timestamp < this.toDateIdx) ||
        (cell.timestamp < this.hoveredCellIdx && cell.timestamp > this.fromDateTimestamp) ||
        (cell.timestamp > this.fromDateTimestamp && cell.timestamp < this.toDateTimestamp)
      )
    );
  }

  isSelected(cell) {
    const value = cell.timestamp || cell.value;
    return (
      cell.enabled &&
      (this.hoveredCellIdx === value ||
        this.selectedCellIdx === value ||
        (this.fromDateIdx === value && !this.hoveredBeforeFromDate && !this.fromDateHoverOutOfBound) ||
        (this.toDateIdx === value && !this.hoveredAfterToDate))
    );
  }

  isTabbable(cell) {
    if (this.isSelected(cell)) {
      this.tabbableCell = cell.value;
      return true;
    } else if (cell.enabled && this.todayInActiveView && (this.today.getDate() === cell.value)) {
      this.tabbableCell = cell.value;
      return true;
    } else {
      // if not selected cell and not today, the check if cell matches with previous tabbable value
      // which usually is usually the 1st available date in the calendar.
      return cell.value === this.tabbableCell;
    }
  }

  private moveFocusToCell() {
    this.calendarBtns.forEach(btn => {
      if (
        (Number(btn.nativeElement.dataset.cellValue) === this.tabbableCell) &&
        !btn.nativeElement.disabled
      ) {
        btn.nativeElement.focus();
      }
    });
  }

  handleRightLeft($event: KeyboardEvent, weekIndex, dayIndex) {
    $event.preventDefault();

    const week = this.grid[weekIndex];

    if (!week) {
      return;
    }

    let dayIdx = dayIndex;
    let weekIdx = weekIndex;

    if ($event.key === 'ArrowRight') {
      dayIdx = dayIndex == null ? 0 : dayIndex + 1;
      weekIdx = weekIndex + 1;
    } else if ($event.key === 'ArrowLeft') {
      dayIdx = dayIndex == null ? week.length - 1 : dayIndex - 1;
      weekIdx = weekIndex - 1;
    }

    const day = week[dayIdx];

    if (!day) {
      this.handleRightLeft($event, weekIdx, null);
    } else if (!day.enabled || day.hidden) {
      this.handleRightLeft($event, weekIndex, dayIdx);
    } else if (day.enabled && !day.hidden) {
      this.tabbableCell = day.value;
      this.moveFocusToCell();
    }
  }

  handleUpDown($event: KeyboardEvent, weekIndex, dayIndex) {
    $event.preventDefault();

    let weekIdx = weekIndex;
    const dayIdx = dayIndex;

    if ($event.key === 'ArrowUp') {
      weekIdx = weekIndex - 1;
    } else if ($event.key === 'ArrowDown') {
      weekIdx = weekIndex + 1;
    }

    const week = this.grid[weekIdx];

    if (!week) {
      return;
    }

    const day = week[dayIdx];

    if (!day.enabled || day.hidden) {
      this.handleUpDown($event, weekIdx, dayIdx);
    } else if (day.enabled && !day.hidden) {
      this.tabbableCell = day.value;
      this.moveFocusToCell();
    }
  }
}
