import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {PhxCommonModule} from '@phoenix/ui/common';
import {PhxTranslateModule} from '@phoenix/ui/translate';

import {DropdownPanelComponent, PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER,} from './dropdown-panel.component';
import {DropdownComponent} from './dropdown.component';
import {HeadingComponent} from './heading.component';
import { PhxInfiniteScrollDirective } from './infinite-scroll-options';

export {
  PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER,
  PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER_FACTORY,
  DROPDOWN_DIRECTION,
  DropdownPanelComponent,
  PHX_DROPDOWN_SCROLL_STRATEGY
} from './dropdown-panel.component';
export {DropdownComponent} from './dropdown.component';
export {HeadingComponent} from './heading.component';
export { PhxInfiniteScrollDirective } from './infinite-scroll-options';
const COMPONENTS = [DropdownPanelComponent, DropdownComponent, HeadingComponent, PhxInfiniteScrollDirective];
@NgModule({
  imports: [OverlayModule, CommonModule, PhxCommonModule, PhxTranslateModule, ReactiveFormsModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER]
})
export class PhxDropdownModule {
}
