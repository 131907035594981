export enum KeyCode {
    TAB = 9,
    ENTER = 13,
    ESC = 27,
    SPACE = 32,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    HOME = 36,
    END = 35,
    BACKSPACE = 8,
    DELETE = 46,
    COMMAND = 91
}
