import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { PhxCommonModule } from '@phoenix/ui/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

export { TooltipComponent } from './tooltip.component';
export { TooltipDirective } from './tooltip.directive';
export * from './tooltip.interface';

@NgModule({
    declarations: [TooltipComponent, TooltipDirective],
    imports: [CommonModule, PhxTranslateModule.forChild(), OverlayModule, PortalModule, PhxCommonModule],
    exports: [TooltipDirective],
    providers: []
})
export class TooltipModule { }
