import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'phx-back-to-top',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <button
      [attr.aria-label]="'phx.backToTop.ariaLabel' | translate"
      class="px-btn px-btn-warn px-btn-sm px-scroll-top"
      [ngClass]="{ 'px-move-left': alignLeft }"
      type="button"
      (click)="scrollTo()"
      [hidden]="isHidden"
      title="Scroll back to top"
    >
      <i class="pxi px-arrow-up" aria-hidden="true"></i>
    </button>
  `
})
export class BackToTopComponent implements OnInit, OnDestroy {
  isHidden = true;
  private windowScrollUnbind: () => void;

  /**
   * The height in px after which we want to show the back to top button
   */
  @Input()
  scrollHeight = 200;

  /**
   * Boolean value to decide if we want to show the back to top botton on the left
   */
  @Input()
  alignLeft = false;

  constructor(private render: Renderer2, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.windowScrollUnbind = this.render.listen(window, 'scroll', () => {
      const verticalScroll = window.scrollY || window.pageYOffset || document.body.scrollTop;
      if (verticalScroll > this.scrollHeight) {
        this.isHidden = false;
        this.cd.detectChanges();
      } else {
        this.isHidden = true;
        this.cd.detectChanges();
      }
    });
  }

  scrollTo() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    if (this.windowScrollUnbind) {
      this.windowScrollUnbind();
    }
  }
}
