import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  AbstractFormGroupDirective,
  ControlContainer,
  ControlValueAccessor,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PhxUtil} from '@phoenix/ui/common';

@Component({
  selector: 'phx-error-control',
  encapsulation: ViewEncapsulation.None,
  template: `
    <phx-error-message [id]="id" [type]="'error'" [message]="errorMsg"></phx-error-message>
    <phx-announcer [ariaLive]="'assertive'" [(announceMessage)]="reshow">
      {{errorMsg ? ('phx.errorMessage.aria.error' | translate) : ''}} {{errorMsg}}
    </phx-announcer>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveErrorControlComponent),
      multi: true
    }
  ]
})
export class ReactiveErrorControlComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, OnChanges {
  private control: AbstractControl | FormControlName | AbstractFormGroupDirective;
  private destroy$ = new Subject();
  private errorArray = [];
  public reshow = false;

  /**
   * Takes either an array of error object or a single error object.
   * Error object is an object of key/value pairs. Key: 'error name' such as 'required, minlength', value:'display message for this error'
   */
  @Input() errors: object | Array<any>;

  /**
   * Array of form controls.
   */
  @Input() formControlArray: Array<NgControl> = [];

  @Input() id: any;

  public writeValue() {
  }

  public registerOnChange() {
  }

  public registerOnTouched() {
  }

  constructor(private injector: Injector, private host: ElementRef, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errors && !changes.errors.firstChange) {
      this.updateErrorArray();
    }
  }

  ngAfterViewInit(): void {
    if (this.formControlArray.length > 0) {
      this.formControlArray.forEach(formCtrl => {
        formCtrl.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.updateErrorMsgAda();
          this.changeDetectorRef.markForCheck();
        });
      });
    }
  }

  get errorMsg(): any {
    if (this.errorArray.length < 1) {
      return '';
    } else {
      return this.getFirstErrorMessage();
    }
  }

  private initialize() {
    if (this.formControlArray.length < 1) {
      let controlType: any = NgControl;
      if (this.host.nativeElement.hasAttribute('formarrayname')) {
        controlType = ControlContainer;
      }
      this.control = this.injector.get(controlType);
      this.formControlArray.push(this.control as any);
    }

    this.updateErrorArray();
  }

  private updateErrorArray() {
    this.errorArray = (PhxUtil.isArray(this.errors) ? this.errors : [this.errors]) as Array<any>;
  }

  private getFirstErrorMessage(): string {
    let idx = 0;
    for (const formCtrl of this.formControlArray) {
      if (formCtrl.errors && (formCtrl.touched || formCtrl.dirty) && (this.errorArray[idx])) {
        for (const err of Object.keys(formCtrl.errors)) {
          if (this.errorArray[idx][err]) {
            return this.errorArray[idx][err];
          }
        }
      }
      idx++;
    }
    return '';
  }

  // separate error message getter for screen-readers so that the message is repeated every time screen-reader user changes control value
  private updateErrorMsgAda(): any {
    this.reshow = !!this.errorMsg;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
