import { Component, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'phx-backdrop',
  template: ``,
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent {
  @Input() closeOnClick = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onBackdropClick: EventEmitter<boolean> = new EventEmitter();
  @HostBinding('class.modal-backdrop') backdrop = true;
  @HostBinding('class.show') backdropVisible = true;

  @HostListener('click', [])
  onClick() {
    if (this.closeOnClick) {
      this.backdrop = false;
      this.backdropVisible = false;
      this.onBackdropClick.emit(true);
    }
  }

  constructor(public renderer: Renderer2) {}
}
