import { Component, Input } from '@angular/core';
import { DataTableComponent as Table } from '../datatable.component';

@Component({
  selector: 'phx-rowradio',
  template: `
    <div class="px-custom-control px-custom-radio">
      <input
        [id]="rowId"
        class="px-custom-control-input px-tbl-radio"
        type="radio"
        name="{{tableId + '-radio'}}"
        [checked]="value[dt.selectHandler.selField]"
        [attr.aria-describedby]="ariaDescribedBy"
        [attr.disabled]="disabled ? 'disabled' : null"
        (change)="doChange($event)"
      />
      <label class="px-custom-control-label"></label>
    </div>
  `
})

// <span class="px-radio-indicator"></span> not required nor utilized
export class RowRadioComponent {
  /**
   * This takes aria-describedBy of the actual checkbox. @DefaultValue: empty
   */
  @Input() ariaDescribedBy: string;

  /**
   * This takes value of the actual radio. @DefaultValue: empty
   */
  @Input() value: boolean;

  @Input() disabled: boolean;

  @Input() rowId: string;

  @Input() tableId: string;

  constructor(public dt: Table) {}

  doChange(event: any) {
    this.dt.selectRowWithRadio(event.target.checked, this.value);
  }
}
