import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhxTranslateModule } from '@phoenix/ui/translate';

import { TextToggleButtonComponent } from './text-toggle-button.component';
import { TextToggleDirective } from './text-toggle.directive';
import { PhxCommonModule } from '@phoenix/ui/common';

export { TextToggleButtonComponent } from './text-toggle-button.component';
export { TextToggleDirective } from './text-toggle.directive';

@NgModule({
  imports: [CommonModule, PhxTranslateModule.forChild(), PhxCommonModule],
  declarations: [TextToggleDirective, TextToggleButtonComponent],
  exports: [TextToggleDirective, TextToggleButtonComponent]
})
export class TextToggleModule { }
