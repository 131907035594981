import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {PageChangeData} from './paginator.model';
import {PhxTranslateService} from '@phoenix/ui/translate';
import {PhxTheme, PhxThemeService} from '@phoenix/ui/common';
import {Subscription} from 'rxjs';

@Component({
  selector: 'phx-paginator',
  styleUrls: ['./paginator.component.scss'],
  template: `
    <div [class]="styleClass" [ngStyle]="style"
         [ngClass]="'px-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix'">
      <ng-container *ngIf="alwaysShow ? true : (pageLinks && pageLinks.length > 1)">
        <div class="px-paginator-left-content" *ngIf="templateLeft">
          <ng-container *ngTemplateOutlet="templateLeft; context: {$implicit: paginatorState}"></ng-container>
        </div>
        <ng-container *ngIf="!isHelix">
          <a [attr.tabindex]="isFirstPage() ? '-1' : '0'"
             [attr.aria-disabled]="isFirstPage()"
             title="{{ 'phx.paginator.aria.showFirstPage' | translate }}"
             class="pxi px-step-backward px-paginator-first px-paginator-element ui-state-default ui-corner-all"
             (click)="changePageToFirst($event)" (keydown.enter)="changePageToFirst($event)"
             [ngClass]="{'px-paginator-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
            <span class="px-paginator-icon pi pi-step-backward" aria-hidden="true"></span>
            <span class="px-sr-only">{{ 'phx.paginator.aria.showFirstPage' | translate }}</span>
          </a>
          <a tabindex="0" [attr.tabindex]="isFirstPage() ? '-1' : '0'"
             [attr.aria-disabled]="isFirstPage()"
             title="{{ 'phx.paginator.aria.showPrevPage' | translate }}"
             class="pxi px-angle-double-left px-paginator-prev px-paginator-element ui-state-default ui-corner-all"
             (click)="changePageToPrev($event)" (keydown.enter)="changePageToPrev($event)"
             [ngClass]="{'px-paginator-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
            <span class="px-paginator-icon pi pi-caret-left" aria-hidden="true"></span>
            <span class="px-sr-only">{{ 'phx.paginator.aria.showPrevPage' | translate }}</span>
          </a>
          <span class="px-paginator-pages">
          <a tabindex="0" *ngFor="let pageLink of pageLinks"
             class="px-paginator-page px-paginator-element ui-state-default ui-corner-all"
             (click)="onPageLinkClick($event, pageLink - 1)"
             (keydown.enter)="onPageLinkClick($event, pageLink - 1)"
             [ngClass]="{'px-paginator-active': (pageLink-1 == getPage())}">
              <span aria-hidden="true">{{pageLink}}</span>
              <span *ngIf="showCurrentPageReport"
                    class="px-sr-only">{{ 'phx.paginator.aria.loadPage' | translate }} {{pageLink}}.</span>
              <span *ngIf="(pageLink-1) == getPage()"
                    class="px-sr-only">{{ 'phx.paginator.aria.selected' | translate }}</span>
          </a>
        </span>
          <a [attr.tabindex]="isLastPage() ? '-1' : '0'"
             [attr.aria-disabled]="isLastPage()"
             title="{{ 'phx.paginator.aria.showNextPage' | translate }}"
             class="pxi px-angle-double-right px-paginator-next px-paginator-element ui-state-default ui-corner-all"
             (click)="changePageToNext($event)" (keydown.enter)="changePageToNext($event)"
             [ngClass]="{'px-paginator-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
            <span class="px-paginator-icon pi pi-caret-right" aria-hidden="true"></span>
            <span class="px-sr-only">{{ 'phx.paginator.aria.showNextPage' | translate }}</span>
          </a>
          <a [attr.tabindex]="isLastPage() ? '-1' : '0'"
             [attr.aria-disabled]="isLastPage()"
             title="{{ 'phx.paginator.aria.showLastPage' | translate }}"
             class="pxi px-step-forward px-paginator-last px-paginator-element ui-state-default ui-corner-all"
             (click)="changePageToLast($event)" (keydown.enter)="changePageToLast($event)"
             [ngClass]="{'px-paginator-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
            <span class="px-paginator-icon pi pi-step-forward" aria-hidden="true"></span>
            <span class="px-sr-only">{{ 'phx.paginator.aria.showLastPage' | translate }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="isHelix">
          <label for="showPage" aria-hidden="true">
            <span>Page</span>
          </label>
          <phx-dropdown id="showPage" class="px-ml-3 px-dd-helix-paginator"
                        [ariaLabel]="'phx.paginator.showPerPage' | translate"
                        [(ngModel)]="page"
                        (ngModelChange)="pageChangeHelix($event)"
                        [options]="pageNumbers"></phx-dropdown>

          <button phxButton="link" tabindex="0" [attr.tabindex]="isFirstPage() ? '-1' : '0'"
             [attr.aria-disabled]="isFirstPage()"
             title="{{ 'phx.paginator.aria.showPrevPage' | translate }}"
             (click)="changePageToPrev($event)" (keydown.enter)="changePageToPrev($event)"
             [ngClass]="{'px-helix-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
                Previous
            <span class="px-sr-only">{{ 'phx.paginator.aria.showPrevPage' | translate }}</span>
          </button>
          <span class="px-helix-separator">|</span>
          <button phxButton="link" [attr.tabindex]="isLastPage() ? '-1' : '0'"
             [attr.aria-disabled]="isLastPage()"
             title="{{ 'phx.paginator.aria.showNextPage' | translate }}"
             (click)="changePageToNext($event)" (keydown.enter)="changePageToNext($event)"
             [ngClass]="{'px-helix-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
            Next
            <span class="px-sr-only">{{ 'phx.paginator.aria.showNextPage' | translate }}</span>
          </button>
        </ng-container>
      </ng-container>
      <div *ngIf="rowsPerPageOptions" class="px-paginator-right-content">
        <label for="showPerPage" aria-hidden="true">
          <span *ngIf="!isHelix">{{'phx.paginator.showPerPage' | translate }}</span>
          <span *ngIf="isHelix">Rows per page</span>
        </label>
        <phx-dropdown id="showPerPage" class="px-ml-3 px-d-inline px-paginator-dd"
                      [ariaLabel]="'phx.paginator.showPerPage' | translate"
                      [(ngModel)]="rows"
                      (ngModelChange)="onRppChange($event)"
                      [options]="rowsPerPageOptions"></phx-dropdown>
      </div>
    </div>
    <span [attr.aria-live]="ariaLive" *ngIf="showCurrentPageReport">{{currentPageReport}}</span>
  `
})
export class PaginatorComponent implements OnInit, OnDestroy {

  @Input() pageLinkSize = 5;

  @Output() onPageChange: EventEmitter<PageChangeData> = new EventEmitter();

  @Input() style: any;

  @Input() styleClass: string;

  @Input() alwaysShow = true;

  @Input() templateLeft: TemplateRef<any>;

  @Input() templateRight: TemplateRef<any>;

  @Input() currentPageReportTemplate: string;

  @Input() showCurrentPageReport = true;

  @Input() ariaLive: 'off' | 'polite' | 'assertive' = 'polite';

  pageLinks: number[];

  _totalRecords = 0;

  _first = 0;

  _rows = 10;

  onInitRows: number; // to prevent onChange() call on load.

  _rowsPerPageOptions: any[];

  rowsPerPageItems: any[]; // SelectItem

  paginatorState: any;

  isHelix = false;

  subscription: Subscription;

  pageNumbers: number[];

  page = 1;

  constructor(private cd: ChangeDetectorRef, private translateService: PhxTranslateService, private phxThemeService: PhxThemeService) {
  }

  ngOnInit() {
    this.onInitRows = this.rows;
    this.updatePaginatorState();
    const pageSummary = this.translateService.instant('phx.paginator.summary');
    this.currentPageReportTemplate = this.currentPageReportTemplate || pageSummary;
    this.updatePageLinks();
    this.subscription = this.phxThemeService.theme$.subscribe((theme: PhxTheme) => {
      this.isHelix = theme === PhxTheme.HELIX;
      this.cd.detectChanges();
    });
  }

  @Input() get totalRecords(): number {
    return this._totalRecords;
  }

  set totalRecords(val: number) {
    this._totalRecords = val;
    this.updatePageLinks();
    this.updatePaginatorState();
    this.updateFirst();
    this.updateRowsPerPageOptions();
  }

  @Input() get first(): number {
    return this._first;
  }

  set first(val: number) {
    this._first = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input() get rows(): number {
    return this._rows;
  }

  set rows(val: number) {
    if (this._rows === val) {
      return;
    }
    this._rows = val;
    this.updatePageLinks();
    this.updatePaginatorState();
  }

  @Input() get rowsPerPageOptions(): any[] {
    return this._rowsPerPageOptions;
  }

  set rowsPerPageOptions(val: any[]) {
    this._rowsPerPageOptions = val;
    this.updateRowsPerPageOptions();
  }

  updateRowsPerPageOptions() {
    if (this.rowsPerPageOptions) {
      this.rowsPerPageItems = [];
      for (const opt of this.rowsPerPageOptions) {
        if (typeof opt === 'object' && opt['showAll']) {
          this.rowsPerPageItems.push({label: opt['showAll'], value: this.totalRecords});
        } else {
          this.rowsPerPageItems.push({label: String(opt), value: opt});
        }
      }
    }
  }

  isFirstPage() {
    return this.getPage() === 0;
  }

  isLastPage() {
    return this.getPage() === this.getPageCount() - 1;
  }

  getPageCount() {
    const pageCount = Math.ceil(this.totalRecords / this.rows) || 1;
    this.pageNumbers = [...Array(pageCount).keys()].map(i => i + 1);

    return pageCount;
  }

  calculatePageLinkBoundaries() {
    const numberOfPages = this.getPageCount();
    const visiblePages = Math.min(this.pageLinkSize, numberOfPages);

    // calculate range, keep current in middle if necessary
    let start = Math.max(0, Math.ceil(this.getPage() - ((visiblePages) / 2)));
    const end = Math.min(numberOfPages - 1, start + visiblePages - 1);

    // check when approaching to last page
    const delta = this.pageLinkSize - (end - start + 1);
    start = Math.max(0, start - delta);

    return [start, end];
  }

  updatePageLinks() {
    this.pageLinks = [];
    const boundaries = this.calculatePageLinkBoundaries();
    const start = boundaries[0];
    const end = boundaries[1];

    for (let i = start; i <= end; i++) {
      this.pageLinks.push(i + 1);
    }
  }

  changePage(p: number) {
    const pc = this.getPageCount();

    if (p >= 0 && p < pc) {
      this.first = this.rows * p;
      const state: PageChangeData = {
        page: p,
        first: this.first,
        rows: this.rows,
        pageCount: pc
      };
      this.updatePageLinks();
      this.page = p + 1;
      this.onPageChange.emit(state);
      this.updatePaginatorState();
    }
  }

  updateFirst() {
    const page = this.getPage();
    if (page > 0 && (this.first >= this.totalRecords)) {
      Promise.resolve(null).then(() => this.changePage(page - 1));
    }
  }

  getPage(): number {
    return Math.floor(this.first / this.rows);
  }

  changePageToFirst(event) {
    if (!this.isFirstPage()) {
      this.changePage(0);
    }

    event.preventDefault();
  }

  changePageToPrev(event) {
    this.changePage(this.getPage() - 1);
    event.preventDefault();
  }

  changePageToNext(event) {
    this.changePage(this.getPage() + 1);
    event.preventDefault();
  }

  changePageToLast(event) {
    if (!this.isLastPage()) {
      this.changePage(this.getPageCount() - 1);
    }

    event.preventDefault();
  }

  onPageLinkClick(event, page) {
    this.changePage(page);
    event.preventDefault();
  }

  onRppChange(event) {
    if (this.onInitRows) {
      if (this.onInitRows === this.rows) {
        return;
      } else {
        this.onInitRows = null;
      }
    }
    this.changePage(this.getPage());
  }

  pageChangeHelix(event) {
    this.changePage(+event - 1);
  }

  updatePaginatorState() {
    this.paginatorState = {
      page: this.getPage(),
      pageCount: this.getPageCount(),
      rows: this.rows,
      first: this.first,
      totalRecords: this.totalRecords
    };
  }

  get currentPageReport() {
    // 'Showing {first} - {last} of {total} things'
    let result = '';
    if (this.totalRecords) {
      result = this.currentPageReportTemplate
        .replace('{first}', (this.first + 1).toString())
        .replace('{last}', this.getRowsOnPage().toString())
        .replace('{total}', this.totalRecords.toString());
    }
    return result;
  }

  getRowsOnPage(): number {
    return Math.min(this.first + this.rows, this.totalRecords);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

