import { trigger, state, style, animate, transition, query } from '@angular/animations';
export class Effects {
    public static growShrink = trigger('growShrink', [
        state('1', style({ height: '0px' })),
        state('0', style({ height: '*' })),
        transition('1 => 0', animate('1500ms ease-in')),
        transition('0 => 1', animate('1500ms ease-out'))
    ]);

    public static slideUpDown = trigger('slideUpDown', [
        state('Active', style({
            transform: 'scaleY(1)',
            transformOrigin: 'left top',
            opacity: 1
        }), { params: { padding_top_bottom: '0px' } }),
        state('Inactive', style({
            transform: 'scaleY(0)',
            opacity: 0
        })),
        state('ActiveUp', style({
            transform: 'scaleY(1)',
            opacity: 1,
            transformOrigin: 'bottom center'
        })),
        state('ActiveDown', style({
            transform: 'scaleY(1)',
            opacity: 1,
            transformOrigin: 'top center'
        })),
        transition('* <=> *', animate('200ms ease'))
    ]);

    public static easeInOut = trigger('easeInOut', [
        transition(':leave', [
            style({
                transform: 'scaleY(1)',
                opacity: 1
            }),
            animate('0.2s ease', style({
                transform: 'scaleY(0)',
                opacity: 0
            }))]),
        transition(':enter',
            [
                style({
                    transform: 'scaleY(0)',
                    opacity: 0,
                }),
                animate('0.2s ease', style({
                    transform: 'scaleY(1)',
                    opacity: 1
                }))])
    ]);

    public static fadeInOut = trigger('fadeInOut', [
        state('Active', style({
            opacity: 1
        })),
        state('Inactive', style({
            opacity: 0,
            display: 'none'
        })),
        transition('Active <=> Inactive', animate('200ms ease'))
    ]);

    public static SlideLeftRight = trigger('SlideLeftRight', [
        state('Active', style({
            transform: 'translateX(30px)'
        })),
        state('Inactive', style({
            transform: 'translateX(4px)'
        })),
        transition('Active <=> Inactive', animate('300ms ease'))
    ]);

    public static progress = trigger('progress', [
        state('top-height', style({
            transition: 'top .2s, height .2s'
        })),
        state('left-width', style({
            transition: 'left .2s, width .2s'
        })),
        transition('*<=>*', animate('200ms'))
    ]);

    public static zoomInOut = trigger('zoomInOut', [
        state('In', style({
            opacity: 1
        })),
        state('Out', style({
            opacity: 0,
            transform: 'scale3d(.1, .1, .1)'
        })),
        transition(':enter', [
            style({
                opacity: 0,
                transform: 'scale3d(.3, .3, .3)'
            }),
            animate(200)
        ]),
        transition('In => Out', animate('150ms ease'))
    ]);

    public static showUpDown = trigger('showUpDown', [
        state('Active', style({
            height: '*'
        })),
        state('Inactive', style({
            height: '20px'
        })),
        transition('Active <=> Inactive', animate('500ms ease'))
    ]);

    public static slideInLeft = trigger('slideInLeft', [

        transition('* => *', [
            query(
                ':enter',
                [style({ transform: 'translate3d(-100%, 0, 0)' }), animate(300, style({ transform: 'translate3d(0, 0, 0)' }))],
                { optional: true }
            )
        ])
    ]);


}
