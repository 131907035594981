import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FocusTrapService {
  constructor() {}

  /**
   * Toggles aria-hidden attribute on all direct children of <body> except for the overlay container
   * @param enabled when enabled, set aria-hidden to true, otherwise remove the attribute
   */
  toggleCDKOverlayFocusTrap(enabled: boolean) {
    const bodyChildren = document.body.children;
    for (let i = 0; i < bodyChildren.length; i++) {
      const child = bodyChildren.item(i);
      if (child.tagName.toLowerCase() === 'script' || child.className.indexOf('cdk-overlay-container') > -1) {
        continue;
      }
      if (enabled) {
        child.setAttribute('aria-hidden', 'true');
      } else {
        child.removeAttribute('aria-hidden');
      }
    }
  }
}
