import * as opts from "./datatable.options";

export class DataTableUtils {

  public static getInternalEvents(events: Array<opts.BaseEvent>): Array<opts.BaseEvent> {
    if (!events) {
      return null;
    }
    const result = [];
    events.forEach(item => {
      switch (item.type) {
        case opts.ActionTypes.LOAD_DATA:
        case opts.ActionTypes.FILTER:
        case opts.ActionTypes.COLUMN_RESIZE:
        case opts.ActionTypes.ROW_CONTEXT_MENU:
        case opts.ActionTypes.SELECT_ALL_FILTERED:
        case opts.ActionTypes.SELECT_SINGLE_ROW:
        case opts.ActionTypes.SELECT_ROW:
        case opts.ActionTypes.SORT: {
          result.push(item);
          break;
        }
        default:
          break;
      }
    });
    return result;
  }
}
