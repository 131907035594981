import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { DomHandler } from '../dom/domhandler';
import { Subscription } from 'rxjs';
import { DataTableComponent as Table} from "../datatable.component";
import { TableService } from "../base/table.service";


@Component({
    selector: 'phx-rowcheckbox',
    template: `
          <phx-checkbox [id]="rowId" [ngModel]="value[dt.selectHandler.selField]" (ngModelChange)="doChange($event)"
            [disabled]="disabled"></phx-checkbox>
    `
})
export class RowCheckbox  {

    @Input() disabled: boolean;

    @Input() value: any;

    @Input() rowId: string;

    constructor(public dt: Table) {}

    doChange(event: any) {
      this.dt.toggleRowCheckbox(event, this.value);
    }
}
