import { DataTableSelection } from "./selection.service";
import { DataTableComponent } from '../../datatable.component';
import * as opts from '../../datatable.options';
/**
 * Created by NBKPYZL on 12/8/2021.
 */

/**
 * Selection service which handle selection for the tables with smart scroll feature enabled.
 */
export class ScrollRowSelectionService extends DataTableSelection {

  private _selectedAll = false;

  public get selectedAll() {
    return this._selectedAll;
  }

  resetAll(table: DataTableComponent): Array<opts.BaseEvent> {
    this.selectedCount = 0;
    this.selectedRowsNotFiltered = 0;
    return this.toggleAll(false, table);
  }

  toggleAll(isSelected: boolean, table: DataTableComponent): Array<opts.BaseEvent> {
    this.logger.debug('toggle all: ', isSelected, ', size: ', table.rowsData.length);

    table.rowsData.forEach((row, index) => {
      row[this.selField] = !table.rowNotSelectable(row) ? isSelected : row[this.selField];
    });

    this._selectedAll = isSelected;
    this.selectedCount = this.selectedRowsNotFiltered + (isSelected ? this.totalRows : 0);

    return this.eventUtil.createToggleAllEvent(isSelected);
  }

  syncSelectAllState(table: DataTableComponent) {
    this._selectedAll =
      this.totalRows > 0 &&
      this.selectedCount - this.selectedRowsNotFiltered === this.totalRows;
  }
}
