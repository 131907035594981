import {
  ComponentFactoryResolver,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {ShowPasswordButtonComponent} from './show-password-button.component';
import {Subscription} from 'rxjs';

/**
 * ShowPasswordToggleDirective adds an input-group-addon that toggles input type between type="password" and type="text"
 */
@Directive({
  selector: '[phxShowPasswordToggle]'
})
export class ShowPasswordToggleDirective implements OnDestroy, OnChanges {
  @Input() phxShowPasswordToggle = true;
  @HostBinding('attr.type') type = 'password';
  @HostBinding('class.px-password') passwordClass = true;

  clickSub: Subscription;

  constructor(private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.phxShowPasswordToggle) {
      this.createButton(typeof changes.phxShowPasswordToggle.currentValue === 'boolean' ? changes.phxShowPasswordToggle.currentValue : true);
    }
  }

  createButton(addButtonToggle: boolean) {
    if (addButtonToggle) {
      const showPasswordButtonCmpFactory = this.componentFactoryResolver.resolveComponentFactory(ShowPasswordButtonComponent);
      const showPasswordButtonCmpRef = this.viewContainerRef.createComponent(showPasswordButtonCmpFactory);
      const showPasswordInstance = showPasswordButtonCmpRef.instance;
      this.passwordClass = true;
      this.type = 'password';
      this.clickSub = showPasswordInstance.clickEmitter.subscribe(() =>
        this.type = this.type === 'password' ? 'text' : 'password'
      );
    } else {
      this.clickSub?.unsubscribe();
      this.viewContainerRef.clear();
      this.passwordClass = false;
      this.type = 'text';
    }
  }

  ngOnDestroy(): void {
    this.clickSub?.unsubscribe();
  }
}
