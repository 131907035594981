 /**
 * Forked implementation of CleaveJS (v1.5.3) with added and removed features, written in Typescript
 * Removed features: Time, Date, Credit Card, Built-in Phone Masks (Using custom mask for Phone)
 * Added features: Block-level pattern masks
 *
 * Custom additions to logic are commented with '@Addition'
 */

import { charTypeMapping } from './cleave-options.interface';

/**
 * Props Assignment
 */
export class CleaveDefaultProperties {
    // Maybe change to object-assign
    // for now just keep it as simple
    assign(target, opts) {
        target = target || {};
        opts = opts || {};

        // numeral
        target.numeral = !!opts.numeral;
        target.numeralIntegerScale = opts.numeralIntegerScale > 0 ? opts.numeralIntegerScale : 0;
        target.numeralDecimalScale = opts.numeralDecimalScale >= 0 ? opts.numeralDecimalScale : 2;
        target.numeralDecimalMark = opts.numeralDecimalMark || '.';
        target.numeralThousandsGroupStyle = opts.numeralThousandsGroupStyle || 'thousand';
        target.numeralPositiveOnly = !!opts.numeralPositiveOnly;
        target.stripLeadingZeroes = opts.stripLeadingZeroes !== false;
        target.signBeforePrefix = !!opts.signBeforePrefix;

        // others
        target.numericOnly = !!opts.numericOnly;

        target.uppercase = !!opts.uppercase;
        target.lowercase = !!opts.lowercase;

        target.prefix = opts.prefix || '';
        target.noImmediatePrefix = !!opts.noImmediatePrefix;
        target.prefixLength = target.prefix.length;
        target.rawValueTrimPrefix = !!opts.rawValueTrimPrefix;
        target.copyDelimiter = !!opts.copyDelimiter;

        target.initValue = (opts.initValue !== undefined && opts.initValue !== null) ? opts.initValue.toString() : '';

        target.delimiter =
            (opts.delimiter || opts.delimiter === '') ? opts.delimiter : (opts.numeral ? ',' : ' ');
        target.delimiterLength = target.delimiter.length;
        target.delimiterLazyShow = !!opts.delimiterLazyShow;
        target.delimiters = opts.delimiters || [];

        target.blocks = opts.blocks || [];
        target.blocksLength = target.blocks.length;

        // @ts-ignore
        target.root = (typeof global === 'object' && global) ? global : window;
        target.document = opts.document || target.root.document;

        target.maxLength = 0;

        target.backspace = false;
        target.result = '';

        target.onValueChanged = opts.onValueChanged || (() => {});

        // @Addition - add blockPatterns array for block-level pattern masking
        const maskPattern = opts.maskPattern || '';
        const charTypes = [];
        for (let i = 0; i < maskPattern.length; i++) {
            const token = maskPattern[i];
            if (charTypeMapping[token]) {
              charTypes.push(charTypeMapping[token]);
            }
        }
        target.maskCharTypes = charTypes;

        // @Addition - add guidingPlaceholder flag
        target.guidingPlaceholder = opts.guidingPlaceholder;

        return target;
    }
}
