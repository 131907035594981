import { LoggerType, LogMode } from './logger.interface';

/**
 * Base LogConfig object extended by ServerLogger and ConsoleLogger
 */
export class LogConfig {
  /** type of logger (server or console) */
  loggerType: LoggerType;
  /** log level below which no logs will be sent */
  logLevel: LogMode;
}

/**
 * LogConfig object for ServerLogger
 */
export class ServerLogConfig extends LogConfig {
  /** url for POST'ing logs to server */
  url: string;

  requestMethod?: 'POST' | 'GET' = 'POST';

  /** flag for logging to server in batches */
  batchMode?: boolean;

  batchSize? = 5;

  /**
   * batch timeout: after a message is added to batch, logger will log batch to server after this timeout in ms, regardless of batch size.
   * Adding a new log message to batch refreshes the timer
   */
  batchTimer? = 3000;

  /** flag to enable logging analytics (useragent, screensize etc) */
  logAnalytics?: boolean;

  /** Level to which the analytics will be attached (DEBUG, INFO, WARN, ERROR). Default is LogMode.ERROR  */
  logAnalyticsLevel?: LogMode = LogMode.ERROR;
}

/**
 * LogConfig object for ConsoleLogger. For now, has no additional properties
 */
export class ConsoleLogConfig extends LogConfig {}
