import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild,
    ElementRef,
    ContentChildren,
    QueryList,
    AfterContentInit,
    SecurityContext,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';
import { NavItem } from './breadcrumb.interface';
import { BreadCrumbItemComponent } from './breadcrumb.item.component';
import { DomSanitizer } from '@angular/platform-browser';
import {PhxTranslateService} from '@phoenix/ui/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'phx-breadcrumb',
    templateUrl: './bread-crumb.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BreadCrumbComponent implements AfterContentInit, OnDestroy {

    public $list: NavItem[];

    @ViewChild('nav') navContainer: ElementRef;

    @ContentChildren(BreadCrumbItemComponent) breadcrumbItems: QueryList<BreadCrumbItemComponent>;

    /**
     *  Array of Navigation items to render Bradcrumb
     */
    @Input() set list(items: NavItem[]) {
        if (items && items.length) {
            items.forEach((item: NavItem) => {
                if (item.link) {
                    item.link = this.sanitizer.sanitize(SecurityContext.URL, item.link);
                }
            })
            this.$list = [...items];
        }
    };

    get list(): NavItem[] {
        return this.$list;
    }

    /**
     *  Provide a meaningful label based on your application context. Default value is 'breadcrumb'.
     */
    @Input() label = this.translateService.instant('phx.breadcrumb.breadcrumb');

    private destroy$ = new Subject<never>();

    constructor(
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private translateService: PhxTranslateService
    ) { }

    ngAfterContentInit() {
        if (this.breadcrumbItems.length > 0) {
            this.cdr.markForCheck();
        }

        this.breadcrumbItems.changes.pipe(takeUntil(this.destroy$)).subscribe((newItems: QueryList<BreadCrumbItemComponent>) => {
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}