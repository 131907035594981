import {ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Location} from '@angular/common';
import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';

import {GestureService, PhxCommonService, OverlayPositionService} from '@phoenix/ui/common';
import {TooltipComponent} from './tooltip.component';
import {ComponentBindings, TooltipContentType, TooltipPosition, TooltipTriggerType} from './tooltip.interface';

@Directive({selector: '[phxTooltip]', exportAs: 'phxTooltip'})
export class TooltipDirective implements OnDestroy, AfterViewInit {
  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent>;
  private isVisible = false;
  private readonly offset = 10;
  private tooltipId = this.phxCommonService.getRandID('Tlp');

  private hasFocus = false;

  private webEventListeners = new Map<string, EventListenerOrEventListenerObject>();

  private destroy$ = new Subject<any>();
  private subscriptions: Subscription = new Subscription();

  private tooltipInstance;

  /**
   * TooltipContentType = string | ComponentType | TemplateRef. Default: string;
   */
  @Input('phxTooltip') content: TooltipContentType = '';

  /**
   * Position of tooltip.
   * TooltipPosition = 'left' | 'top' | 'right' | 'bottom'. Default: 'top'
   */
  @Input() tooltipPosition: TooltipPosition = 'top';

  /**
   * Action triggers tooltip.
   * TooltipTriggerType = 'click' | 'hover'. Default: 'hover'
   */
  @Input() tooltipTrigger: TooltipTriggerType = 'hover'; // 'click' | 'hover'

  /**
   * Use when tooltip's content = ComponentType and we want to pass data as inputs and callback as outputs
   */
  @Input() tooltipComponentBindings: ComponentBindings;

  /**
   * Allow to toggle x - close button of tooltip. Default: false
   */
  @Input() tooltipShowCloseButton = false;

  /**
   * Can pass one classname which will add as wrapper class in tooltip component
   */
  @Input() tooltipClass: string;

  /**
   * User defines custom width of tooltip.
   * tooltipWidth takes 'px' or '%' value.
   */
  @Input() tooltipWidth: string;

  /**
   * Delay the opening of tooltip: Numbers
   */
  @Input() tooltipDelay = 0;

  /**
   * If the tooltip is disabled
   * Default: false
   */
  @Input() disabled = false;


  @Output() tooltipOpen: EventEmitter<any> = new EventEmitter<any>();

  // --- for mobile devices with enable hammerjs ----
  @HostListener('press') handleLongPress() {
    this.showTooltip();
  }

  @HostListener('pressup') handleLongPressUp() {
    if (this.tooltipTrigger === 'hover') {
      this.hideTooltip();
    }
  }

  constructor(
    private overlay: Overlay,
    private hostRef: ElementRef,
    private location: Location,
    private gestureService: GestureService,
    private phxCommonService: PhxCommonService,
    private renderer: Renderer2,
    private overlayPositionService: OverlayPositionService
  ) {
    // check if mobile devices since we don't want to open tooltip for click
    if (this.gestureService.isWebBrowser) {
      this.webEventListeners
        .set('mouseenter', () => {
          if (this.tooltipTrigger === 'hover') {
            this.showTooltip();
          }
        })
        .set('mouseleave', () => {
          if (this.tooltipTrigger === 'hover' && !this.hasFocus) {
            this.hideTooltip();
          }
        })
        .set('click', () => {
          if (this.tooltipTrigger === 'click') {
            if (this.isVisible) {
              this.hideTooltip();
            } else {
              this.showTooltip();
            }
          }
          return false;
        })
        .set('focus', () => {
          if (this.tooltipTrigger === 'hover') {
            this.hasFocus = true;
            this.showTooltip();
          }
        })
        .set('blur', () => {
          if (this.tooltipTrigger === 'hover') {
            this.hasFocus = false;
            this.hideTooltip();
          }
        });
    } else if (!this.gestureService.hasHammerJsLoaded) {
      // fallback if Hammerjs isn't loaded
      this.webEventListeners
        .set('touchstart', () => this.showTooltip())
        .set('touchend', () => {
          if (this.tooltipTrigger === 'hover') {
            this.hideTooltip();
          }
        });
    }

    this.webEventListeners.forEach((listener, event) => this.hostRef.nativeElement.addEventListener(event, listener));
  }

  ngAfterViewInit() {
    if (this.isVisible) {
      this.showTooltip();
    }
  }

  showTooltip() {
    if (this.isVisible || this.disabled) {
      return;
    }
    this.isVisible = true;

    this.setupOverlay();
    // create tooltip portal
    const tooltipPortal = new ComponentPortal(TooltipComponent);

    // attach tooltip portal to overlay
    this.tooltipRef = this.overlayRef.attach(tooltipPortal);
    this.tooltipInstance = this.tooltipRef.instance;

    this.tooltipInstance.id = this.tooltipId;
    this.tooltipInstance.content = this.content;
    this.tooltipInstance.componentBindings = this.tooltipComponentBindings;
    this.tooltipInstance.tooltipPosition = this.tooltipPosition;
    this.tooltipInstance.classes = this.tooltipClass;
    this.tooltipInstance.tooltipWidth = this.tooltipWidth;
    if (this.tooltipTrigger === 'click') {
      this.tooltipInstance.showCloseButton = this.tooltipShowCloseButton;
    }
    this.tooltipOpen.emit();
    this.tooltipInstance.show(this.tooltipDelay);

    this.tooltipInstance.oncanClose
      .pipe(
        tap(() => this.hideTooltip()),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.setHostAttribute();
    this.listenForEvents();
  }

  private listenForEvents() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    const mouseDown$ = fromEvent(window, 'mousedown')
      .pipe(
        filter(($event: any) =>
          this.isVisible &&
          $event.target.tagName !== 'HTML' &&
          !this.tooltipRef.location.nativeElement.contains($event.target) &&
          !this.hostRef.nativeElement.contains($event.target)
        ),
        tap(() => this.hideTooltip())
      )
      .subscribe();

    const scroll$ = fromEvent(window, 'scroll', true as any)
      .pipe(
        filter(($event: any) => this.isVisible && $event.srcElement !== document),
        tap(() => this.hideTooltip())
      ).subscribe();

    const keyup$ = fromEvent(window, 'keyup')
      .pipe(
        filter(($event: KeyboardEvent) => this.isVisible && $event.key === 'Escape'),
        tap(() => this.hideTooltip())
      )
      .subscribe();

    const keydownTooltipRef$ = fromEvent(this.tooltipRef.location.nativeElement, 'keydown')
        .pipe(
          filter(($event: KeyboardEvent) => $event.key === 'Escape'),
          tap(($event: KeyboardEvent) => {
            this.hideTooltip();
            $event.stopPropagation();
          })
        )
        .subscribe();

    const keydownHostRef$ = fromEvent(this.hostRef.nativeElement, 'keydown')
        .pipe(
          filter(($event: KeyboardEvent) => $event.key === 'Escape'),
          tap(($event: KeyboardEvent) => {
            this.hideTooltip();
            $event.stopPropagation();
          })
        )
        .subscribe();

    // subscribe to browser's popState events to close tooltip if it is opened when navigate
    const location$ = this.location.subscribe(() => this.hideTooltip());

    this.subscriptions.add(mouseDown$);
    this.subscriptions.add(scroll$);
    this.subscriptions.add(keyup$);
    this.subscriptions.add(location$);
    this.subscriptions.add(keydownHostRef$);
    this.subscriptions.add(keydownTooltipRef$);
  }

  hideTooltip() {
    if (this.tooltipRef && this.isVisible) {
      this.isVisible = false;
      this.tooltipRef.instance.hide(this.tooltipDelay);
      this.overlayRef.dispose();
      this.removeHostAttribute();
      this.subscriptions.unsubscribe();
    }
  }

  private setHostAttribute() {
    const hostElement = this.hostRef.nativeElement as HTMLElement;
    this.renderer.setAttribute(hostElement, 'aria-describedby', this.tooltipId);
  }

  private removeHostAttribute() {
    const hostElement = this.hostRef.nativeElement as HTMLElement;
    this.renderer.removeAttribute(hostElement, 'aria-describedby');
  }

  private setupOverlay() {
    const connectedPositions: ConnectedPosition[] = this.overlayPositionService.getConnectedPositions(this.tooltipPosition, this.offset, true);
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.hostRef)
      .withPositions(connectedPositions)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withLockedPosition(false);

    positionStrategy.positionChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged((a, b) => a.connectionPair.panelClass === b.connectionPair.panelClass)).subscribe(e => {
      if (this.tooltipRef) {
        this.tooltipInstance.tooltipPosition = e.connectionPair.panelClass;
        this.tooltipInstance.cdr.detectChanges();
      }
    });

    // overlayRef works as remote controller which allows us to insert some dynamically create components somewhere on top of the document tree.
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.tooltipTrigger !== 'click' ? this.overlay.scrollStrategies.close() : this.overlay.scrollStrategies.reposition()
    });

    // scrollStrategies.close() will trigger overlayRef's detach() --> will emit observable's value --> set visible to false so user can reclick again
    this.overlayRef
      .detachments()
      .pipe(
        filter(() => this.tooltipTrigger !== 'click'),
        tap(() => (this.isVisible = false)),
        takeUntil(this.destroy$)
      )
      .subscribe();

  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.tooltipRef = null;
    this.webEventListeners.forEach((listener, event) => {
      this.hostRef.nativeElement.removeEventListener(event, listener);
    });
    this.webEventListeners.clear();

    this.destroy$.next();
    this.destroy$.complete();

    this.subscriptions.unsubscribe();
  }
}
