import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {PhxCommonService} from '@phoenix/ui/common';
import {PhxTranslateService} from '@phoenix/ui/translate';
import {FileDragDropResponse} from './file-drag-drop-response.class';
import {FileDragDropOptions, InvalidFileError} from './file-drag-drop.interface';
import {FileDragDropService, IActionResponse} from './file-drag-drop.service';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {filter, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'phx-filedragdrop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileDragDropComponent),
      multi: true
    }
  ]
})
export class FileDragDropComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges {
  // need access to the file upload input to manually trigger a click
  @ViewChild('fileUploadInput') fileInput: ElementRef;
  // this is the default template for the file upload
  @ViewChild('defaultFileSelectTemplate') defaultFileSelectTemplate: TemplateRef<any>;
  // this is the default template for the success list
  @ViewChild('defaultSuccessTemplate') defaultSuccessTemplate: TemplateRef<any>;

  // this is custom template for the file upload
  @ContentChild('successTemplate') customSuccessTemplate: TemplateRef<any>;
  // this is the custom template for the success list
  @ContentChild('fileSelectTemplate') customFileSelectTemplate: TemplateRef<any>;

  // this is the set of contentOptions that can be passed into file upload component
  @Input() options?: FileDragDropOptions;

  /**
   * id for the file drag and drop. This is needed mainly the user needs to call the output functions themselves.
   */
  @Input() id?: string;

  /**
   * Required for ADA. id of element with visible text that can describe the purpose of this filedragdrop component to screenreader users
   */
  @Input() ariaDescribedBy: string;

  /**
   * Files to pass model for file drag drop to initialize with default value
   */
  @Input() files: FileDragDropResponse;

  /**
   * filesChange function will be trigger after file is selected from dialogbox
   * The output will be the FileDragDropResponse.
   */
  @Output() filesChange = new EventEmitter<FileDragDropResponse>();

  /**
   * filesDrop function will be trigger after files dropped.
   * The output will be the FileDragDropResponse.
   */
  @Output() filesDrop = new EventEmitter<FileDragDropResponse>();

  /**
   * delete function will be triggered after a file has been deleted
   * from the success list. The output will be the FileDragDropResponse.
   */
  @Output() delete = new EventEmitter<FileDragDropResponse>();

  /**
   * reset function will be triggered after all files has been deleted
   * from the success list. The output will be the FileDragDropResponse.
   */
  // tslint:disable-next-line:no-output-native
  @Output() reset = new EventEmitter<FileDragDropResponse>();

  /**
   * disabled: To disable the file drag drop
   */
  @Input() disabled: boolean;

  /**
   * formControl: To bind control to component when used in reactive approach.
   */
  @Input() formControl: FormControl;

  componentId: string;
  componentOptions: FileDragDropOptions = {
    accept: '.doc,.docx,.jpeg,.jpg,.pdf,.ppt,.pptx,.tif,.tiff,.xls,.xlsx',
    reject: '',
    allowNoExtension: false,
    multiple: true,
    maxFileSize: '25MB',
    maxFileCount: Infinity,
    showErrors: true,
    noFileMessage: this.translateService.instant('phx.filedragdrop.noFileUploaded'),
    required: false,
    preserveFiles: true
  };
  fileResponseList: FileDragDropResponse;
  errorFileList: Array<{ file: File; error: InvalidFileError }> = [];
  successFileList: Array<File> = [];
  successTemplate: TemplateRef<any>;
  fileSelectTemplate: TemplateRef<any>;
  formControl$: Subscription;

  private destroy$ = new Subject<any>();

  _onChange: (value: any) => void = () => {
  };
  _onTouched = () => {
  };

  constructor(
    private fileDragDropService: FileDragDropService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: PhxCommonService,
    private translateService: PhxTranslateService,
  ) {
  }

  ngOnInit() {
    this.generateId();
    this.setOptions();

    this.fileDragDropService.announcedAction$.pipe(
      filter((resp: IActionResponse) => resp.id === this.componentId),
      tap((resp: IActionResponse) => {
        if (resp.action === 'click') {
          this.openFileUpload();
        } else if (resp.action === 'clear') {
          this.fileInput.nativeElement.value = null;
        } else if (resp.action === 'delete') {
          this.fileInput.nativeElement.value = null;
          this.fileResponseList = resp.fileList;
          this.successFileList = this.fileResponseList.success;
          this.delete.emit(this.fileResponseList);
        } else if (resp.action === 'reset') {
          this.fileInput.nativeElement.value = null;
          this.successFileList = this.fileResponseList.success = [];
          this.reset.emit(this.fileResponseList);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    if (this.formControl) {
      this.initializeValue(this.formControl.value);
      this.formControl$ = this.formControl.valueChanges.subscribe((val: FileDragDropResponse) => {
        this.initializeValue(val);
      });
    }
  }

  // Check if any custom template have been passed in. If no custom templates
  // are passed in use the default ones.
  ngAfterViewInit() {
    this.fileSelectTemplate = this.customFileSelectTemplate ? this.customFileSelectTemplate : this.defaultFileSelectTemplate;
    this.successTemplate = this.customSuccessTemplate ? this.customSuccessTemplate : this.defaultSuccessTemplate;
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.files) {
      this.fileResponseList = changes.files.currentValue || new FileDragDropResponse();
      this.showResult();
      this.changeDetectorRef.markForCheck();
    }
  }

  // Initialize as a new response object if no value passed.
  initializeValue(val: FileDragDropResponse = new FileDragDropResponse()) {
    this.fileResponseList = val;
    this.showResult();
    this.changeDetectorRef.markForCheck();
  }

  handleDragOver($event: DragEvent) {
    $event.preventDefault();
  }

  handleDragLeave($event: DragEvent) {
    $event.preventDefault();
  }

  // Handling the file drop. Once the function recieves the files
  // it will emit the drop event
  handleOnDrop($event: DragEvent) {
    const dataTransfer = $event.dataTransfer;
    if (!dataTransfer) {
      return false;
    }
    if (!this.hasFiles(dataTransfer.types)) {
      return false;
    }
    dataTransfer.dropEffect = 'copy';
    $event.preventDefault();
    this.fileResponseList = this.fileDragDropService.filterFiles(dataTransfer.files, this.componentOptions, this.fileResponseList);
    this.showResult();
    this.filesDrop.emit(this.fileResponseList);
  }

  hasFiles(types): boolean {
    if (!types) {
      return false;
    }

    if (types.indexOf) {
      return types.indexOf('Files') !== -1;
    }

    if (types.contains) {
      return types.contains('Files');
    }

    return false;
  }

  // Events from HostListener //

  // Events from default template //
  //
  //  This is the click event from the default template.
  // Since the file input is hidden, the click event from the default template
  // is used to trigger a click on the file input.
  openFileUpload() {
    this.fileInput.nativeElement.click();
  }

  //  This function is triggered by the file input whenever there is a change
  //  in the files list.
  //  Once the function receives the files it should call the showResult to
  //  display the results to user and also emit the select event
  onFilesAdded(event: any) {
    if (this.fileInput.nativeElement.value) {
      const inputs: FileList = this.fileDragDropService.getFiles(this.fileInput.nativeElement);
      this.fileResponseList = this.fileDragDropService.filterFiles(inputs, this.componentOptions, this.fileResponseList || new FileDragDropResponse());
      this.showResult();
      this.filesChange.emit(this.fileResponseList);
    }
  }

  //  This shows the result of the file upload. It will show a list of
  //  files that have been successfully uploaded followed by a list of errors.
  //  The errors can be controlled by using the showErrors contentOptions
  showResult() {
    this.errorFileList = [];
    if (this.fileResponseList) {
      this.successFileList = this.fileResponseList.success;
    }
    if (this.componentOptions.showErrors && this.fileResponseList.error) {
      const errorFiles = [...this.fileResponseList.error.keys()];
      errorFiles.forEach(file => {
        const error = this.fileResponseList.error.get(file);
        this.errorFileList.push({
          file,
          error
        });
      });
    }
  }

  //  Triggered when we click on delete on the default success template
  //  @param idx
  handleDelete(idx: number, errorFile: File = null) {
    this.fileDragDropService.announceDelete(this.fileResponseList, idx, this.componentId, errorFile);
    this.showResult();
  }

  //  Generates a unique id for the component. The custom templates need to pass in
  //  this unique id when they make the calls to the serivce.
  generateId() {
    this.componentId = this.id ? this.id : this.commonService.getRandID('Fldrdp');
  }

  //  If there are any user contentOptions they will override the default contentOptions
  setOptions() {
    this.componentOptions = Object.assign(this.componentOptions, this.options || {});
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = !!isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  writeValue(value: FileDragDropResponse): void {
    this.fileResponseList = value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.formControl$) {
      this.formControl$.unsubscribe();
    }
  }
}
