import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {PhxCommonService} from '@phoenix/ui/common';
import {DatepickerCalendarComponent} from '../datepicker-calendar.component';
import {DatepickerInputDirective} from '../datepicker-input.directive';
import {DatepickerOptions, InternalDatepickerOptions} from '../datepicker-options.interface';
import {DatepickerService} from '../datepicker.service';
import {DateUtilityService} from '../date-utility.service';
import moment from 'moment-mini';

export type DateRangeOrigin = 'fromDate' | 'toDate';

const defaultDateRangePickerOptions: InternalDatepickerOptions = {
  hideFooter: true
};

/**
 * DateRange component allows a user to select a from date and a to date.
 */
@Component({
  selector: 'phx-date-range',
  templateUrl: './date-range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[attr.aria-labelledby]': 'ariaLabelledBy'
  },
  providers: [DateUtilityService, DatepickerService]
})
export class DateRangeComponent implements AfterViewInit, OnDestroy {
  fromDateInputRef: ElementRef;
  toDateInputRef: ElementRef;
  private _disabled: boolean;
  private _datePicker: DatepickerCalendarComponent;
  private _datePickerOptions: DatepickerOptions = Object.assign({}, defaultDateRangePickerOptions);
  private datePickerService: DatepickerService;
  private destroy$ = new Subject<any>();

  /**
   * Calendar popup id.
   */
  calId: string;

  @ContentChildren(DatepickerInputDirective, {descendants: false})
  datePickerInputDirectives: QueryList<DatepickerInputDirective>;
  private fromDate: DatepickerInputDirective;
  private toDate: DatepickerInputDirective;

  @ViewChild('datepicker')
  set datePicker(datePicker: DatepickerCalendarComponent) {
    this.datePickerService = datePicker.registerDateRange(this);
    this._datePicker = datePicker;
    let fromInput: DatepickerInputDirective;

    this.datePickerInputDirectives.forEach((datePickerInput: DatepickerInputDirective) => {
      if (this._disabled) {
        datePickerInput.setDisabledState(true);
      }
      datePickerInput.phxDatepicker = this._datePicker;
      this.setUpDateRangeRef(datePickerInput);
      fromInput = fromInput || datePickerInput;
    });
    this.setCalendarId(fromInput);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * id of the daterange.
   */
  @Input() id: string;

  /**
   * sets the disabled status of the daterange
   * @param disabled boolean flag, when true, disables the daterange, otherwise enables it
   */
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (this.datePickerInputDirectives) {
      this.datePickerInputDirectives.forEach((datePickerInput: DatepickerInputDirective) => {
        datePickerInput.setDisabledState(disabled);
      });
    }
  }

  // TODO: when delivery teams use phx6 enough to give feedback, either remove or uncomment this depending on needs
  // /**
  //  * Whether to allow the same date to be selected for the from and to date.
  //  * When false, the date selections can be 1 day apart at minimum
  //  */
  // @Input() allowSameDate = true;

  /**
   * Required for ADA. id of the element which labels this daterange. Will be used as value for aria-labelledby attribute of this component.
   */
  @Input() ariaLabelledBy: string;

  /**
   * the options object to pass to the datepicker
   * @param options object of type DatepickerOptions
   */
  @Input() set options(options: DatepickerOptions) {
    this._datePickerOptions = { ...options };
    if (this.datePickerService) {
      this.datePickerService.datePickerOptions = {...options};
    }
  }

  get options(): DatepickerOptions {
    return this._datePickerOptions;
  }

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private phxCommonService: PhxCommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dateUtilityService: DateUtilityService
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeDatePicker() {
    if (this._datePicker) {
      this._datePicker.close();
    }
  }

  /**
   * Housekeeping on the projected datepicker inputs:
   * Adds id's to inputs if not present
   * Adds focus listeners to keep track of last focused input in datepickerservice
   */
  private setUpDateRangeRef(inputDirective: DatepickerInputDirective) {
    const isFrom = inputDirective.dateRangeInputType === 'fromDate';

    if (isFrom) {
      this.fromDateInputRef = inputDirective.elementRef;
      this.fromDate = inputDirective;
      this.datePickerService.fromDate = this.dateUtilityService.convertToDate(inputDirective.value, inputDirective.format);
    } else {
      this.toDateInputRef = inputDirective.elementRef;
      this.toDate = inputDirective;
      this.datePickerService.toDate = this.dateUtilityService.convertToDate(inputDirective.value, inputDirective.format);
    }

    const nativeInput = inputDirective.elementRef.nativeElement;
    const button = nativeInput.nextElementSibling.querySelector('button');
    const dateRangeOrigin: DateRangeOrigin = isFrom ? 'fromDate' : 'toDate';
    this.addFocusDetection(nativeInput, dateRangeOrigin);
    this.addFocusDetection(button, dateRangeOrigin);
  }

  addFocusDetection(elem: HTMLElement, lastFocused: DateRangeOrigin) {
    fromEvent(elem, 'focus')
      .pipe(
        takeUntil(this.destroy$),
        tap(() => (this.datePickerService.lastFocusedInput = lastFocused))
      )
      .subscribe();
  }

  setCalendarId(datePickerInput: DatepickerInputDirective): void {
    const inpId = datePickerInput ? datePickerInput.id : null;
    this.calId = `pxCal-${this.id || inpId || this.phxCommonService.uuid()}`;
  }

  ngAfterViewInit() {
    this.fromDate?.valueChange.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.datePickerService.fromDate = this.dateUtilityService.convertToDate(val, this.fromDate.format);
    });
    this.toDate?.valueChange.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      const toDate = this.dateUtilityService.convertToDate(val, this.toDate.format);
      this.datePickerService.toDate = moment(toDate).isSameOrAfter(this.datePickerService.fromDate) ? toDate : null;
    });
  }
}
