import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, OnInit, ViewContainerRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ClearInputButtonComponent } from './clear-input-button.component';
import { takeUntil } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { fromEvent, asyncScheduler, Subscription } from 'rxjs';

/**
 * Clear Input Button directive adds an input-group-addon with a clear icon that clears the input value when pressed.
 */
@Directive({
    selector: '[phxClearButton]'
})
export class ClearInputDirective implements OnInit, OnDestroy {

    private clearInputButtonCmpRef: ComponentRef<ClearInputButtonComponent>;
    private clearInstance: ClearInputButtonComponent;

    private clearSubscription: Subscription;

    /**
     * clear event triggered when click on clear icon button.
     */
    @Output() clear = new EventEmitter();

    constructor(private elRef: ElementRef,
        private ngControl: NgControl,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        const clearInputButtonCmpFactory = this.componentFactoryResolver.resolveComponentFactory(ClearInputButtonComponent);
        this.clearInputButtonCmpRef = this.viewContainerRef.createComponent(clearInputButtonCmpFactory);
        this.clearInstance = this.clearInputButtonCmpRef.instance;

        this.ngControl.valueChanges.pipe(
            takeUntil(this.clearInstance.destroy$)
        ).subscribe(val => this.toggleClearButton(val));

        this.clearInstance.clickEmitter.pipe(
            takeUntil(this.clearInstance.destroy$)
        ).subscribe(() => {
            this.ngControl.control.setValue('');
            this.focusInput();
            this.clear.emit();
        });

        fromEvent(this.elRef.nativeElement, 'focus').pipe(
            takeUntil(this.clearInstance.destroy$)
        ).subscribe(() => this.toggleClearButton(this.ngControl.value));

        fromEvent(this.elRef.nativeElement, 'focusout').pipe(
            takeUntil(this.clearInstance.destroy$)
        ).subscribe(($event: any) => {
            if (this.isSafari || this.iOS){ 
                // handle for tab 
                if (this.clearInstance.elRef.nativeElement.contains($event.relatedTarget)) {
                    return;
                } else { // handle for click somehow 'relatedTarget is not <button>' for Safari
                    this.unsub(this.clearSubscription);
                    this.clearSubscription = asyncScheduler.schedule(() => this.toggleClearButton(null), 300);
                }
            }else if (this.clearInstance.elRef.nativeElement.contains($event.relatedTarget)) {
                return;
            } else {
                this.toggleClearButton(null);
            }
        });
    }

    toggleClearButton(val: any): void {
        if (this.isNil(val) && !this.clearInstance.isHidden) {
            this.clearInstance.isHidden = true;
            this.clearInputButtonCmpRef.changeDetectorRef.detectChanges();
        } else if (!this.isNil(val) && this.clearInstance.isHidden && document.activeElement === this.elRef.nativeElement) {
            this.clearInstance.isHidden = false;
            this.clearInputButtonCmpRef.changeDetectorRef.detectChanges();
        }
    }

    isNil(val: any): boolean {
        return !val && val !== 0;
    }

    get isSafari(): boolean {
        return (
            /Safari/.test(navigator.userAgent) &&
            /Apple Computer/.test(navigator.vendor)
        );
    }

    get iOS(): boolean {
        return [
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.userAgent)
        // iPad in iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }
  

    private unsub(sub: Subscription) {
        if (sub && !sub.closed) {
            sub.unsubscribe();
        }
    }

    private focusInput() {
        const input = this.elRef.nativeElement;
        if (input.focus) {
            input.focus();
        }
    }

    ngOnDestroy() {
        this.unsub(this.clearSubscription);
    }
}

