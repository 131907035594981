import {
    Directive,
    AfterViewInit,
    Renderer2,
    ElementRef,
    Input
} from "@angular/core";

@Directive({ selector: "[phxAutofocus]" })
export class AutofocusDirective implements AfterViewInit {
    /**
     * Automatically focus the control when element is added in document
     */
    @Input()
    phxAutofocus: boolean;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {


        if (this.phxAutofocus) {
            const hostElement = this.elementRef.nativeElement as HTMLElement;
            this.renderer.setAttribute(hostElement, "autofocus", "");

            /**
             *Fallback code to set the focus on non page load scenario, element should receive focus only when its not hidden or disabled
             *
             */
            if (
                hostElement.getAttribute("hidden") ||
                hostElement.getAttribute("disabled") ||
                hostElement.getAttribute("type") === "hidden"
            ) {
                return;
            }

            hostElement.focus();
        }
    }
}
