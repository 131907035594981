import {LiveAnnouncer} from '@angular/cdk/a11y';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {PhxTranslateService} from '@phoenix/ui/translate';
import {ActiveView} from './calendar/calendar.interface';
import {DateRangeComponent, DateRangeOrigin} from './date-range/date-range.component';
import {DateUtilityService} from './date-utility.service';
import {InternalDatepickerOptions} from './datepicker-options.interface';

const DefaultOptions: InternalDatepickerOptions = {
  closeOnSelect: true,
  position: 'auto',
  mode: 'day'
};

/**
 * Component Instance-level service for managing a Datepicker
 */
@Injectable()
export class DatepickerService {
  datePickerInitSubject = new Subject<any>();
  // single date selection observable from calendar
  calendarDateSelection$: Observable<Date>;
  // single date selection observable from manual input
  inputDateSelection$: Observable<Date>;
  // single date selection observable that contains latest of both input and calendar selections
  latestDateSelection$: Observable<Date>;
  activeView$: Observable<ActiveView>;
  // daterange selection observable that contains latest date range selection
  dateRangeSelection$: Observable<[Date, Date]>;

  // only used if dealing with date range
  lastFocusedInput: DateRangeOrigin;
  dateRangeOrigin: DateRangeOrigin;
  fromDate: Date;
  toDate: Date;
  initialDateSelected: boolean;

  // for putting focus on the month button
  shouldFocusOnMonth = false;

  private _datePickerOptions: InternalDatepickerOptions = Object.assign({}, DefaultOptions);
  private activeViewSubject: ReplaySubject<ActiveView>;
  private calendarDateSelectionSubject: ReplaySubject<Date>;
  private inputDateSelectionSubject: ReplaySubject<Date>;
  private latestDateSelectionSubject: ReplaySubject<Date>;
  private dateRangeSelectionSubject: ReplaySubject<[Date, Date]>;
  private dateRangeRef: DateRangeComponent;
  datePickerOptions$: Subject<InternalDatepickerOptions> = new Subject();

  set activeView(activeView: ActiveView) {
    this.activeViewSubject.next(activeView);
  }

  /*
   * If date is selected from Input-level, we only need to update Calendar.
   * Likewise, we only need to update Input if date is selected from the Calendar-level
   * Track Date selection separately, coming from either Calendar selection or Input (programmatic or manual entry)
   */
  private set calendarDateSelection(date: Date) {
    this.calendarDateSelectionSubject.next(date);
    this.latestDateSelectionSubject.next(date);
  }

  private set inputDateSelection(date: Date) {
    this.inputDateSelectionSubject.next(date);
    this.latestDateSelectionSubject.next(date);
  }

  private set dateRangeSelection([fromDate, toDate]) {
    this.dateRangeSelectionSubject.next([fromDate, toDate]);
  }

  set datePickerOptions(options: InternalDatepickerOptions) {
    this._datePickerOptions = Object.assign({}, DefaultOptions);
    if (!options) {
      return;
    }
    Object.keys(options).forEach(key => (this._datePickerOptions[key] = options[key]));

    // store disabled dates in a hashset for O(1) lookup
    if (options.disableDates) {
      const set = new Set<string>();
      for (let i = 0; i < options.disableDates.length; i++) {
        const date = this.dateUtilityService.parseDate(options.disableDates[i]);
        if (date) {
          set.add(this.dateUtilityService.getDateKey(date));
        }
      }
      this._datePickerOptions.disableDateSet = set;
    }
    this.datePickerOptions$.next(this._datePickerOptions);
  }

  get datePickerOptions(): InternalDatepickerOptions {
    return this._datePickerOptions;
  }

  get dateRangeMode(): boolean {
    return !!this.dateRangeRef;
  }

  constructor(
    private dateUtilityService: DateUtilityService,
    private liveAnnouncer: LiveAnnouncer,
    private translateService: PhxTranslateService
  ) {
    this.activeViewSubject = new ReplaySubject<ActiveView>(1);
    this.activeView$ = this.activeViewSubject.asObservable();

    this.calendarDateSelectionSubject = new ReplaySubject<Date>(1);
    this.calendarDateSelection$ = this.calendarDateSelectionSubject.asObservable();

    this.inputDateSelectionSubject = new ReplaySubject<Date>(1);
    this.inputDateSelection$ = this.inputDateSelectionSubject.asObservable();

    this.latestDateSelectionSubject = new ReplaySubject<Date>(1);
    this.latestDateSelection$ = this.latestDateSelectionSubject.asObservable();

    this.dateRangeSelectionSubject = new ReplaySubject<[Date, Date]>(1);
    this.dateRangeSelection$ = this.dateRangeSelectionSubject.asObservable();
  }

  setDateFromCalendar(date: Date) {
    this.calendarDateSelection = date;
  }

  setDateFromInput(date: Date) {
    this.inputDateSelection = date;
  }

  /////////////////////////////////////////
  // Start of DateRange-specific methods //
  /////////////////////////////////////////
  focusFromDate() {
    if (document.activeElement !== this.dateRangeRef.fromDateInputRef.nativeElement) {
      this.dateRangeRef.fromDateInputRef.nativeElement.focus();
    }
  }

  focusToDate() {
    if (document.activeElement !== this.dateRangeRef.toDateInputRef.nativeElement) {
      this.dateRangeRef.toDateInputRef.nativeElement.focus();
    }
  }

  emitDateRangeUpdate(fromDate?: Date, toDate?: Date) {
    if (fromDate !== undefined) {
      this.fromDate = fromDate;
    }
    if (toDate !== undefined) {
      this.toDate = toDate;
    }
    this.dateRangeSelection = [this.fromDate, this.toDate];
  }

  updateDateRange(date: Date) {
    if (this.lastFocusedInput === 'fromDate') {
      this.updateFromDate(date);
    } else if (this.lastFocusedInput === 'toDate') {
      this.updateToDate(date);
    } else if (this.dateRangeOrigin === 'fromDate') {
      // IF for some reason neither input had focus
      this.updateFromDate(date);
    } else {
      this.updateToDate(date);
    }
    this.emitDateRangeUpdate();
  }

  registerDateRange(dateRange: DateRangeComponent) {
    this.dateRangeRef = dateRange;
  }

  private getClosestToDate(fromDate: Date) {
    // TODO: uncomment or delete this code after phx6 evaluated by delivery teams
    // if (this.dateRangeRef.allowSameDate) {
    return new Date(fromDate);
    // } else {
    // return this.dateUtilityService.nextValidDate(fromDate, this._datePickerOptions);
    // }
  }

  private getClosestFromDate(toDate: Date) {
    // TODO: uncomment or delete this code after phx6 evaluated by delivery teams
    // if (this.dateRangeRef.allowSameDate) {
    return new Date(toDate);
    // } else {
    // return this.dateUtilityService.previousValidDate(toDate, this._datePickerOptions);
    // }
  }

  /**
   * When daterange is triggered by fromDate input, any date selected will be the new fromDate.
   * Once a date is selected, focus is then set on the toDate without triggering closing of datepicker.
   */
  private updateFromDate(date: Date) {
    if (date) {
      const fromStr = `${this.translateService.instant('phx.datepicker.selectedFromDate')} ${date.toDateString()}`;
      this.liveAnnouncer.announce(fromStr);
    }
    if (!date) {
      this.emitDateRangeUpdate(date);
      return;
    }

    // TODO: remove or uncomment this depending on whether we keep allowsamedate
    // if (!this.dateRangeRef.allowSameDate && this.dateUtilityService.isSameDate(this.toDate, date)) {
    //   // if user selected same date as to date, do nothing
    //   return;
    // }

    this.fromDate = date;
    this.initialDateSelected = true;
    this.lastFocusedInput = 'toDate';
    // if we are selecting a date after or equal to current todate, reset toDate value
    if (this.toDate && this.toDate <= date) {
      this.toDate = this.getClosestToDate(date);
    }

    return;
  }

  /**
   * When daterange is triggered by toDate input, a date selection after/equal to the current fromDate will update toDate.
   * A date selection before the current fromDate will shift focus to fromDate input, and update fromDate value.
   *
   * If toDate input is currently focused, and a valid toDate is selected, this triggers the datepicker to close.
   */
  private updateToDate(date: Date) {
    if (date) {
      const toStr = `${this.translateService.instant('phx.datepicker.selectedToDate')} ${date.toDateString()}`;
      this.liveAnnouncer.announce(toStr);
    }
    if (!date) {
      this.emitDateRangeUpdate(this.fromDate, date);
      return;
    }
    if (!this.fromDate && !this.toDate) {
      this.toDate = date;
      this.initialDateSelected = false;
      this.fromDate = this.getClosestFromDate(date);
      return;
    }

    // TODO: remove or uncomment this depending on whether we keep allowsamedate
    // if (!this.dateRangeRef.allowSameDate && this.dateUtilityService.isSameDate(this.fromDate, date)) {
    //   // if user selected same date as from date, do nothing
    //   return;
    // }

    // if we are selecting a date before current fromDate, always terminate to avoid closing datepicker
    if (this.fromDate && date < this.fromDate) {
      this.toDate = this.fromDate;
      this.fromDate = date;
      this.dateRangeRef.closeDatePicker();
      return;
    }
    // if we are selecting a date after or equal to current fromdate, emit selection to close datepicker
    if (this.fromDate) {
      this.toDate = date;
      this.dateRangeRef.closeDatePicker();
    }
  }
}
