import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[phxFloatingLabel]'
})
export class FloatingLabelDirective implements AfterViewInit, OnDestroy {
  private inputHasFocus = false;
  private destroy$ = new Subject<void>();

  floatingContainer: HTMLElement;
  floatingInput: HTMLInputElement;

  constructor(private elemRef: ElementRef, private ngControl: NgControl) {}

  ngAfterViewInit() {
    this.floatingInput = this.elemRef.nativeElement;
    this.floatingContainer = this.elemRef.nativeElement.parentElement;
    this.ngControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLabel(value);
    });
  }

  @HostListener('focus', ['$event'])
  setInputFocus($event) {
    this.inputHasFocus = true;
    this.toggleLabel($event.target.value);
  }

  @HostListener('blur', ['$event'])
  removeInputFocus($event) {
    this.inputHasFocus = false;
    this.toggleLabel($event.target.value);
  }

  toggleLabel(value) {
    if (value || this.inputHasFocus) {
      this.floatingContainer.classList.add('px-move-up');
    } else {
      this.floatingContainer.classList.remove('px-move-up');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
