import { SidebarContentComponent } from './sidebar-content.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarBaseComponent } from './sidebar-base.component';

export { SidebarComponent } from './sidebar.component';
export { SidebarBaseComponent } from './sidebar-base.component';
export * from './sidebar-options.interface';

const Dirs = [SidebarComponent, SidebarContentComponent, SidebarBaseComponent];
@NgModule({
    declarations: [...Dirs],
    imports: [ CommonModule ],
    exports: [...Dirs]
})
export class SidebarModule {}