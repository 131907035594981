import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Allows User to use different types of buttons
 */
@Directive({
  selector: '[phxButton]'
})
export class ButtonDirective implements OnInit {
  public buttonElement: HTMLElement;

  /**
   * Specify the type of button.
   */
  @Input() phxButton: 'primary' | 'secondary' | 'link' = 'primary';

  /**
   * To change size of button from the default 'md'
   */
  @Input() size: 'sm' | 'lg';

  constructor(element: ElementRef, private renderer: Renderer2) {
    this.buttonElement = element.nativeElement;
    this.renderer.addClass(this.buttonElement, 'px-btn');
  }

  ngOnInit() {
    if (!this.phxButton) {
      this.phxButton = 'primary';
    }
    const typeClassToAdd = `px-btn-${this.phxButton}`;
    this.renderer.addClass(this.buttonElement, typeClassToAdd);

    if (this.size) {
      this.renderer.addClass(this.buttonElement, `px-btn-${this.size}`);
    }
  }
}
