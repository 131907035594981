import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTableComponent as Table} from '../datatable.component';
import {PhxTranslateService} from '@phoenix/ui/translate';

@Component({
    selector: 'p-sortIcon',
    template: `
        <i class="ui-sortable-column-icon pxi px-sort" *ngIf="sortOrder === 0">
          <span class="px-sr-only">{{ ariaStatus }}</span>
        </i>
        <ng-container *ngIf="sortOrder !== 0">
          <i class="ui-sortable-column-icon pxi px-sort-up" *ngIf="sortOrder === 1  || binarySort" [ngClass]="{'px-column-sorted': sortOrder === 1}">
              <span class="px-sr-only">{{ ariaStatus }}</span>
          </i>
          <i class="ui-sortable-column-icon pxi px-sort-down" *ngIf="sortOrder === -1 || binarySort" [ngClass]="{'px-column-sorted': sortOrder === -1, 'px-binary-sort': binarySort}">
            <span class="px-sr-only">{{ ariaStatus }}</span>
          </i>
        </ng-container>
    `
})
export class SortIcon implements OnInit, OnDestroy {

    @Input() field: string;
    @Input() binarySort: boolean;

    public ariaStatus = this.translateService.instant('phx.datatable.sort.iconAriaLabel');

    subscription: Subscription;

    sortOrder: number;

    constructor(public dt: Table, private translateService: PhxTranslateService) {
        this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
            this.updateSortState();
        });
    }

    ngOnInit() {
        this.updateSortState();
    }

    onClick(event){
        event.preventDefault();
    }

    updateSortState() {
        if (this.dt.sortMode === 'single') {
            this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
        }
        else if (this.dt.sortMode === 'multiple') {
            let sortMeta = this.dt.getSortMeta(this.field);
            this.sortOrder = sortMeta ? sortMeta.order: 0;
        }
        this.updateAriaKey();
    }

    private updateAriaKey() {
        if (this.sortOrder ===  1) {
            this.ariaStatus = this.translateService.instant('phx.datatable.sort.ariaDescOrder');
        } else if (this.sortOrder === -1) {
            this.ariaStatus = this.translateService.instant('phx.datatable.sort.ariaAscOrder');
        } else {
            this.ariaStatus = this.translateService.instant('phx.datatable.sort.iconAriaLabel');
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
