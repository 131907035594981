import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InternalEvent } from './datatable.model';

@Injectable()
export class InternalEventsService {

  private eventSource = new Subject<InternalEvent>();

  eventSource$ = this.eventSource.asObservable();

  onEvent(event: InternalEvent) {
    this.eventSource.next(event);
  }
}
