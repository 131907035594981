import {Logger} from '@phoenix/ui/common';
import {DataTableSelection} from './selection.service';
import {PageRowSelectionService} from './page-selection.service';
import {ScrollRowSelectionService} from './scroll-selection.service';
import {DataTableComponent} from '../../datatable.component';

/**
 * Created by NBKPYZL on 12/9/2021.
 */

/**
 * Utility to generate selection service based on datatable configuration.
 */
export class SelectionServiceProvider {

  public static getService(table: DataTableComponent, logger: Logger, pagination: boolean): DataTableSelection {
    const dt: DataTableSelection = pagination ? new PageRowSelectionService() : new ScrollRowSelectionService();
    dt.init(table, logger);
    return dt;
  }

}
