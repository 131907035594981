import {
  AfterViewInit, ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {TextToggleButtonComponent} from './text-toggle-button.component';
import {PhxTheme, PhxThemeService} from '@phoenix/ui/common';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[phxTextToggle]'
})
export class TextToggleDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private minChars = 100;
  private backupChars = 100;
  private fullText: string;
  private toggleButtonComponentRef: ComponentRef<TextToggleButtonComponent>;
  private toggleButtonInstance: TextToggleButtonComponent;
  private _toggleMoreLabel: string;
  private _toggleLessLabel: string;
  private subscription: Subscription;
  private isHelix = false;

  /**
   * The number of characters threshold for the toggle show more/less button to display.
   * String values will be converted to number value type. Default value is 100.
   */
  @Input()
  set phxTextToggle(value: string | number) {
    if (value !== null && value !== undefined) {
      this.minChars = +value;
      this.backupChars = +value;
    }
  }

  /**
   * Set a custom label for toggle show more text button.
   * The default value is resolved from translation key 'phx.textToggle.moreLabel' (see PhxTranslateModule)
   */
  @Input() set toggleMoreLabel(val: string) {
    this._toggleMoreLabel = val;
  }

  get toggleMoreLabel(): string {
    return this.isHelix ? `Show more` : this._toggleMoreLabel;
  }

  /**
   * Set a custom label for toggle show less button.
   * The default value is resolved from translation key 'phx.textToggle.lessLabel' (see PhxTranslateModule)
   */
  @Input() set toggleLessLabel(val: string) {
    this._toggleLessLabel = val;
  }

  get toggleLessLabel(): string {
    return this.isHelix ? `Show less` : this._toggleLessLabel;
  }

  /**
   * If using dynamic text that may change in length, provide the text in this input only
   */
  @Input()
  toggleText;

  constructor(
    private elRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private phxThemeService: PhxThemeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription = this.phxThemeService.theme$.subscribe((theme: PhxTheme) => {
      this.isHelix = theme === PhxTheme.HELIX;
      if (this.isHelix) {
        this.minChars = 0;
      } else {
        this.minChars = this.backupChars;
      }
      this.cdr.detectChanges();
    });
    const toggleButtonCmpFactory = this.componentFactoryResolver.resolveComponentFactory(TextToggleButtonComponent);
    this.toggleButtonComponentRef = this.viewContainerRef.createComponent(toggleButtonCmpFactory);
    this.toggleButtonInstance = this.toggleButtonComponentRef.instance;
    this.toggleButtonInstance.toggleLessLabel = this.toggleLessLabel;
    this.toggleButtonInstance.toggleMoreLabel = this.toggleMoreLabel;
    this.setAriaText();

    this.toggleButtonInstance.clickEmitter.pipe(takeUntil(this.toggleButtonInstance.destroy$)).subscribe(() => {
      if (!this.toggleButtonInstance.truncated) {
        this.setTextContent(this.toggleText || this.fullText);
      } else {
        this.setTextContent(this.elRef.nativeElement.textContent.substring(0, this.minChars));
      }
    });
  }

  ngAfterViewInit(): void {
    const textContent = this.toggleText ? this.toggleText : this.elRef.nativeElement.textContent;
    this.fullText = textContent;
    if (textContent.length >= this.minChars) {
      this.toggleButtonInstance.isHidden = false;
      this.toggleButtonComponentRef.changeDetectorRef.detectChanges();
      this.setTextContent(textContent.substring(0, this.minChars));
    } else if (this.toggleText) {
      this.setTextContent(this.toggleText);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggleText && !changes.toggleText.isFirstChange()) {
      this.setTextContent(changes.toggleText.currentValue.substring(0, this.minChars));
      if (this.toggleButtonInstance.isHidden && changes.toggleText.currentValue.length >= this.minChars) {
        this.toggleButtonInstance.truncated = true;
        this.toggleButtonInstance.isHidden = false;
        // in this case, view on buttoncomponent is only updated when calling detectchanges on instance instead of componentref
        this.toggleButtonInstance.changeDetectorRef.detectChanges();
      } else if (!this.toggleButtonInstance.isHidden && changes.toggleText.currentValue.length < this.minChars) {
        this.toggleButtonInstance.isHidden = true;
        this.toggleButtonComponentRef.changeDetectorRef.detectChanges();
      }
    }
  }

  private setAriaText() {
    const text = this.toggleText || this.fullText || this.elRef.nativeElement.textContent;
    const min = this.minChars < 20 ? this.minChars : 20;
    this.toggleButtonInstance.ariaText = text.substring(0, min);
  }

  private setTextContent(value: string): void {
    this.renderer.setProperty(this.elRef.nativeElement, 'textContent', value);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
