import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ConsoleLoggerService } from './console-logger.service';
import { LogConfig, ServerLogConfig } from './log.config';
import {Logger, LoggerImpl, LoggerMessage, LoggerType, LogMessage, LogMode} from './logger.interface';
import { ServerLoggerService } from './server-logger.service';

@Injectable()
export class LoggerService implements Logger {
  private loggers: LoggerImpl[] = [];
  private defaultLogger: LoggerImpl;

  constructor(
    @Optional() @Inject('LOG_CONFIG') readonly config: LogConfig[],
    @Optional() @Inject('LOG_MODE') readonly mode: LogMode,
    private readonly http: HttpClient
  ) {
    const consoleConfig = config && config.find(cfg => cfg.loggerType === LoggerType.CONSOLE);
    const consoleLogger = new ConsoleLoggerService(consoleConfig, mode);
    this.defaultLogger = consoleLogger;
    this.loggers.push(consoleLogger);
    const serverConfig = config && (config.find(cfg => cfg.loggerType === LoggerType.SERVER) as ServerLogConfig);
    if (serverConfig) {
      const cfg = Object.assign(new ServerLogConfig(), serverConfig);
      this.loggers.push(new ServerLoggerService(cfg, http));
    }
  }

  get loggerType(): LoggerType {
    return null;
  }

  type(loggerType: LoggerType): LoggerImpl {
    const loggers: LoggerImpl[] = this.loggers.filter(logger => !loggerType || logger.loggerType === loggerType);
    if (loggers.length > 0) {
      return loggers[0];
    } else {
      console.error(`Logger type ${LoggerType} is not found. Using default logger`);
      return this.defaultLogger;
    }
  };

  public setLogMode(newMode: LogMode, loggerType?: LoggerType) {
    this.loggers.filter(logger => !loggerType || logger.loggerType === loggerType).forEach(logger => logger.setMode(newMode));
  }

  public setMode(newMode: LogMode) {
    console.warn("dummy method");
  }

  debug(... args: LoggerMessage[]) {
    this.loggers.forEach(logger => logger.debug.apply(logger, args));
  }

  info(... args: LoggerMessage[]) {
    this.loggers.forEach(logger => logger.info.apply(logger, args));
  }

  warn(... args: LoggerMessage[]) {
    this.loggers.forEach(logger => logger.warn.apply(logger, args));
  }

  error(... args: LoggerMessage[]) {
    this.loggers.forEach(logger => logger.error.apply(logger, args));
  }
}
