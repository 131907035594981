export const defaultTranslations = {
  phx: {
    mobileDatepicker: {
      selected: 'selected',
      weekdays: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday'
      },
      weekdaysOneChar: {
        sunday: 'S',
        monday: 'M',
        tuesday: 'T',
        wednesday: 'W',
        thursday: 'T',
        friday: 'F',
        saturday: 'S'
      },
      weekdaysThreeChar: {
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat'
      },
      months: {
        january: 'January',
        feburary: 'Feburary',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      loadButton: 'Load More',
      fromDate: 'Selected from date is {{value}}.',
      toDate: 'Selected to date is {{value}}.',
      datesLoaded: 'Dates loaded.'
    },
    datepicker: {
      header: {
        navButtons: {
          monthView: 'Viewing Month: {{month}}. Switch to month view to choose different month',
          yearView: 'Viewing Year: {{year}}. Switch to year view to choose different year',
          previousMonth: 'Viewing Date: {{date}}. Go to previous month',
          previousYear: 'Viewing Year: {{year}}. Go to previous year',
          previousYearRange: 'Viewing Year Range: {{yearrange}}. Go to previous year range',
          nextMonth: 'Viewing Date: {{date}}. Go to next month',
          nextYear: 'Viewing Year: {{year}}. Go to next year',
          nextYearRange: 'Viewing Year Range: {{yearrange}}. Go to next year range'
        }
      },
      toggleButton: {
        ariaLabel: 'Toggle datepicker calendar'
      },
      footer: {
        selectToday: 'Select Today'
      },
      weekdaysShort: {
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat'
      },
      weekdays: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday'
      },
      monthsShort: {
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec'
      },
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      }
    },
    daterange: {
      fromLabel: 'From:',
      toLabel: 'To:'
    },
    input: {
      passwordToggle: {
        showPasswordText: 'Show password',
        hidePasswordText: 'Hide password'
      }
    },
    shuttle: {
      aria: {
        moveItemsToAssigned: 'Move selected items to the assigned list.',
        moveItemsToAssignedSuccess: 'Selected items moved to the assigned list.',
        moveItemsToUnAssigned: 'Move selected items to the unassigned list.',
        moveItemsToUnAssignedSuccess: 'Selected items moved to the unassigned list.',
        moveAllToAssigned: 'Move all items to the assigned list.',
        moveAllToAssignedSuccess: 'All items moved to the assigned list.',
        moveAllToUnAssigned: 'Move all items to the unassigned list.',
        moveAllToUnAssignedSuccess: 'All items moved to the unassigned list.',
        moveUp: 'Move selected items up.',
        moveUpSuccess: 'Selected items moved up.',
        moveDown: 'Move selected items down.',
        moveDownSuccess: 'Selected items moved down.',
        sortAscending: 'Sort items in ascending order.',
        sortDescending: 'Sort items in descending order.',
        sortSuccess: 'Items sorted.',
        filterSourceRecord: 'Filter source records.',
        shuttleSourceRecord: 'Shuttle source records.',
        filterDestinationRecord: 'Filter destination records.',
        shuttleDestinationRecord: 'Shuttle destination records'
      }
    },
    filedragdrop: {
      fileUploadButton: 'Drop here or<br>select to add.',
      uploadedFilesTitle: 'Uploaded Files List:',
      noFileUploaded: 'No file uploaded yet.',
      deleteFileButton: 'Delete File {{filename}}',
      errors: {
        fileType: 'File type not supported.',
        fileSize: 'File size exceeded.',
        fileCount: 'Number of files exceeded.',
        fileName: 'File name validation failed'
      }
    },
    textToggle: {
      moreLabel: '...More',
      lessLabel: '...Less',
      ariaConnector: 'on'
    },
    backToTop: {
      ariaLabel: 'Scroll to top of page'
    },
    thumbnailSlider: {
      thumbnailImageAriaLabel: 'Thumbnail image {{index}} of {{size}}: {{altText}}',
      noImageFound: 'Image not found',
      scrollUpButtonTitle: 'Click to scroll up',
      scrollDownButtonTitle: 'Click to scroll down',
      selected: 'selected'
    },
    datatable: {
      columnFieldFilter: 'Filter {{field}}',
      alertMessage: 'No data found',
      sort: {
        iconAriaLabel: 'Press enter to sort.',
        ariaDescOrder: 'Sorted ascending.',
        ariaAscOrder: 'Sorted descending.'
      },
      aria: {
        toggleAll: 'Select all rows',
        collapseAll: 'Collapse all rows.',
        expandAll: 'Expand all rows.',
        expandRow: 'Expand row {{index}} of {{total}}.',
        collapseRow: 'Collapse row {{index}} of {{total}}.',
        selectRow: 'Select row {{index}} of {{total}}.',
        radioRow: 'Radio button for row {{index}} of {{total}}.',
        onPagination: 'Showing {{first}} to {{last}} of {{total}} rows.',
        onFiltering: 'Showing {{count}} rows of {{total}}.'
      }
    },
    docsmot: {
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      fitToScreen: 'Fit to screen',
      fitToWidth: 'Fit to width',
      fitToHeight: 'Fit to height',
      rotateRight: 'Rotate right',
      rotateLeft: 'Rotate left',
      flipHorizontal: 'Flip horizontal',
      flipVertical: 'Flip vertical',
      pictureControl: 'Picture controls',
      crosshairGuide: 'Crosshair guide',
      horizontalGuide: 'Horizontal guide',
      grayScale: 'Gray scale',
      invert: 'Invert',
      reset: 'Reset',
      apply: 'Apply',
      cancel: 'Cancel',
      brightness: 'Brightness',
      contrast: 'Contrast',
      gamma: 'Gamma',
      open: 'Open', 
      close: 'Close'
    },
    paginator: {
      summary: 'Showing {first} - {last} of {total} things.',
      showPerPage: 'Show per page:',
      aria: {
        selected: 'selected.',
        showFirstPage: 'Navigate to first page',
        showNextPage: 'Navigate to next page',
        showPrevPage: 'Navigate to previous page',
        showLastPage: 'Navigate to last page',
        loadPage: 'Load page'
      },
      pageSize: 'page size'
    },
    slider: {
      aria: {
        firstSlidingHandle: 'First sliding handle',
        secondSlidingHandle: 'Second sliding handle'
      }
    },
    amountRange: {
      startRange: 'Amount Range Start',
      endRange: 'Amount Range End',
    },
    breadcrumb: {
      breadcrumb: 'breadcrumb'
    },
    buttonToggle: {
      buttonToggleGroup: 'Buttons toggle'
    },
    carousel: {
      play: 'Play slide',
      pause: 'Pause slide',
      imgAltText: 'Image alt text',
      imgTitle: 'Image title',
      imgDesc: 'Image description',
      previous: 'Previous slide',
      next: 'Next slide',
      goToSlide: 'Go to slide',
      selected: 'Selected'
    },
    dropdown: {
      placeHolder: 'Please select a value',
      filterLabel: 'Filter input. Type to filter.'
    },
    intro: {
      doNotShowAgain: 'Do not show again',
      skipTour: 'Skip tour',
      aria: {
        close: 'Close intro modal',
        previous: 'Previous',
        next: 'Next',
        goTo: 'Go to step {{step}}',
        selected: 'selected'
      }
    },
    loading: {
      loadingInProgress: 'Loading in progress.'
    },
    message: {
      info: 'Info message',
      success: 'Success message',
      warning: 'Warning message',
      danger: 'Error message',
      close: 'Dismiss Message',
    },
    modal: {
      close: 'Close'
    },
    tooltip: {
      close: 'Close'
    },
    chart: {
      popup: {
        close: 'Close'
      }
    },
    errorMessage: {
      aria: {
        error: 'Error',
        warning: 'Warning',
        success: 'Success',
        info: 'Information'
      }
    },
    dragdrop: {
      aria: {
        released: 'Item released.',
        moved: 'Item moved.',
        grabItem: 'Use enter key to grab item.',
        upDownArrow: 'Item grabbed. Use Up and down arrows to move item.',
        rightLeftArrow: 'Item grabbed. Use left and right arrows to move item.',
        delete: 'Delete Row'
      }
    },
    progressTracker: {
      aria: {
        complete: 'Complete',
        error: 'Error'
      }
    },
    stepProgress: {
      aria: {
        heading: 'Step Progress Tracker',
        progress: 'progress',
        complete: 'complete',
        warning: 'warning',
        error: 'error',
        review: 'review'
      }
    },
    option: {
      aria: {
        selected: 'Selected '
      }
    }
  }
};
