import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OnDestroy, Injectable } from '@angular/core';
import { ServerLogConfig } from './log.config';
import {LoggerImpl, LoggerMessage, LoggerType, LogLevel, LogMessage, LogMode, LogModeMapping} from './logger.interface';

const noop = (): any => undefined;

// TODO: Add Angular decorator.
@Injectable()
export class ServerLoggerService implements LoggerImpl, OnDestroy {
  private static readonly _loggerType = LoggerType.SERVER;
  private cache: LogMessage[] = [];
  private timeout;

  get loggerType(): LoggerType {
    return ServerLoggerService._loggerType;
  }

  // TODO: investigate feasibility of this approach in multi-tiered phoenix applications without iframe
  get mode(): LogMode {
    return window['px'] && window['px']['serverlog'] && window['px']['serverlog']['mode']
      ? window['px']['serverlog']['mode']
      : LogMode.ERROR;
  }

  set mode(newMode: LogMode) {
    window['px']['serverlog']['mode'] = newMode;
  }

  constructor(private readonly config: ServerLogConfig, private readonly http: HttpClient) {
    if (!window['px']) {
      window['px'] = {};
    }
    window['px']['serverlog'] = {};
    this.mode = config.logLevel || LogMode.ERROR;
  }

  public setMode(newMode: LogMode) {
    this.mode = newMode;
  }

  /**
   * When service is destroyed, attempt to send unlogged messages to server
   */
  ngOnDestroy() {
    if (this.cache.length) {
      this.logToServer(this.cache.slice());
      this.cache = [];
    }
  }

  private getAnalytics() : LogMessage {
    return {
      a_tm: new Date().toISOString(),
      a_os: window.navigator.platform,
      a_agent: window.navigator.userAgent,
      a_lang: window.navigator.language,
      a_scrW: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      a_scrH: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    } as LogMessage;
  }

  private log(level: LogMode, args: LoggerMessage[]) {
    const message = args[0]; // TODO can be LogMessage, string or object. Should we turn object to string? Parse all args?
    const config = this.config;
    let logMessage: LogMessage;

    if (typeof message === 'object' && message !== null) {
      logMessage = Object.assign({}, message);
    } else {
      logMessage = {
         message : message as string // TODO in case if message is object but not LogMessage object
      } as LogMessage;
    }

    logMessage.level = LogModeMapping.get(level) as LogLevel;

    if (config.logAnalytics && level >= config.logAnalyticsLevel) {
      const analytics = this.getAnalytics();
      Object.assign(logMessage, analytics);
    }

    if (config.batchMode) {
      clearTimeout(this.timeout);
      this.cache.push(logMessage);
      this.timeout = setTimeout(() => {
        if (this.cache.length) {
          this.logToServer(this.cache.slice());
          this.cache = [];
        }
      }, this.config.batchTimer);
    }

    if (!config.batchMode) {
      this.logToServer([logMessage]);
    } else if (config.batchMode && this.cache.length >= config.batchSize) {
      clearTimeout(this.timeout);
      this.logToServer(this.cache.slice());
      this.cache = [];
    }
  }

  private logToServer(payload: LogMessage[]) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (this.config.requestMethod === 'GET') {
      const logMessage = payload[0];
      let httpParams = new HttpParams();
      Object.keys(logMessage).forEach(key => {
        httpParams = httpParams.append(key, logMessage[key] + '');
      });
      this.get(httpParams);
    } else {
      // if no request method set, default to POST
      this.post(payload, httpOptions);
    }
  }

  private get(params: any) {
    return this.http
      .get(this.config.url, { params })
      .pipe
      // TODO: perhaps cache failed logs to retry later
      // tap(
      //     data => noop(),
      //     error => console.error(error)
      // )
      ()
      .subscribe();
  }

  private post(payload: LogMessage[], options: any) {
    return this.http
      .post(this.config.url, { logs: payload }, options)
      .pipe
      // TODO: perhaps cache failed logs to retry later
      // tap(
      //     data => noop(),
      //     error => console.error(error)
      // )
      ()
      .subscribe();
  }

  debug(... args: LoggerMessage[]) {
    this.mode <= LogMode.DEBUG && this.log(LogMode.DEBUG, args);
  }

  info(... args: LoggerMessage[]) {
    this.mode <= LogMode.INFO && this.log(LogMode.INFO, args);
  }

  warn(... args: LoggerMessage[]) {
    this.mode <= LogMode.WARN && this.log(LogMode.WARN, args);
  }

  error(... args: LoggerMessage[]) {
    this.mode <= LogMode.ERROR && this.log(LogMode.ERROR, args);
  }
}
