import { ExternalEventInfo } from '../datatable.options';

/**
 * Type of lazy load meta data created by table for user actions.
 */
export enum LazyLoadMetaDataType {
    INIT_LOAD = 10,
    SCROLL = 11,
    SORT = 12,
    FILTER = 14,
    RESET = 18,
    PAGE_RESIZE = 20
}

/**
 * Event type for internal cross components communication.
 * To be used to reset some values, to do some recalculations when needed.
 */
export enum InternalEventType {
  ON_SORT = 210,
  ON_FILTER = 220,
  ON_REFRESH = 230,
  ON_AFTER_SORT = 240,
  ON_AFTER_FILTER = 250,
  ON_INIT = 260,
  ON_AFTER_LOAD = 270
}

export interface InternalEvent {
  type: InternalEventType;
  info?: {
    externalEventInfo?: ExternalEventInfo;
    [x: string]: any;
  }
}
