import { CleaveOptions } from './cleave-options.interface';
import { CleaveDefaultProperties } from './cleave-default-properties';
import { CleaveUtil } from './cleave-utils';
import { CleaveNumeralFormatter } from './cleave-numeral-formatter';

/**
 * Forked implementation of CleaveJS (v1.5.3) with added and removed features, written in Typescript
 * Removed features: Time, Date, Credit Card, Built-in Phone Masks (Using custom mask for Phone)
 * Added features: Block-level pattern masks
 *
 * Custom additions to logic are commented with '@Addition'
 */
export class Cleave {

    static DefaultProperties: CleaveDefaultProperties = new CleaveDefaultProperties();
    static Util: CleaveUtil = new CleaveUtil();
    element;
    properties;
    isAndroid;
    lastInputValue;
    onChangeListener;
    onKeyDownListener;
    onFocusListener;
    onCutListener;
    onCopyListener;
    hasBackspaceSupport;

    constructor(element: string | HTMLElement, opts: CleaveOptions) {
        let hasMultipleElements = false;
        if (typeof element === 'string') {
            this.element = document.querySelector(element);
            hasMultipleElements = document.querySelectorAll(element).length > 1;
        } else {
          if (typeof element[length] !== 'undefined' && element[length] > 0) {
            this.element = element[0];
            hasMultipleElements = element[length] > 1;
          } else {
            this.element = element;
          }
        }

        if (!this.element) {
          throw new Error('[cleave.js] Please check the element');
        }
        if (hasMultipleElements) {
          try {
            console.warn('[cleave.js] Multiple input fields matched, cleave.js will only take the first one.');
          } catch (e) {
            // Old IE
          }
        }

        opts.initValue = this.element.value;
        this.properties = Cleave.DefaultProperties.assign({}, opts);
        this.init();
    }

    init() {
        const owner = this;
        const pps = owner.properties;

        // no need to use this lib
        if (!pps.numeral && (pps.blocksLength === 0 && !pps.prefix)) {
            owner.onInput(pps.initValue);

            return;
        }

        pps.maxLength = Cleave.Util.getMaxLength(pps.blocks);

        owner.isAndroid = Cleave.Util.isAndroid();
        owner.lastInputValue = '';

        owner.onChangeListener = owner.onChange.bind(owner);
        owner.onKeyDownListener = owner.onKeyDown.bind(owner);
        owner.onFocusListener = owner.onFocus.bind(owner);
        owner.onCutListener = owner.onCut.bind(owner);
        owner.onCopyListener = owner.onCopy.bind(owner);

        owner.element.addEventListener('input', owner.onChangeListener);
        owner.element.addEventListener('keydown', owner.onKeyDownListener);
        owner.element.addEventListener('focus', owner.onFocusListener);
        owner.element.addEventListener('cut', owner.onCutListener);
        owner.element.addEventListener('copy', owner.onCopyListener);

        owner.initNumeralFormatter();

        // avoid touch input field if value is null
        // otherwise Firefox will add red box-shadow for <input required />
        if (pps.initValue || (pps.prefix && !pps.noImmediatePrefix)) {
            owner.onInput(pps.initValue);
        }
    }

    initNumeralFormatter() {
        const owner = this;
        const pps = owner.properties;

        if (!pps.numeral) {
            return;
        }

        pps.numeralFormatter = new CleaveNumeralFormatter(
            pps.numeralDecimalMark,
            pps.numeralIntegerScale,
            pps.numeralDecimalScale,
            pps.numeralThousandsGroupStyle,
            pps.numeralPositiveOnly,
            pps.stripLeadingZeroes,
            pps.prefix,
            pps.signBeforePrefix,
            pps.delimiter
        );
    }

    onKeyDown(event) {
        const owner = this;
        const pps = owner.properties;
        let charCode = event.which || event.keyCode;
        const Util = Cleave.Util;
        const currentValue = owner.element.value;

        // if we got any charCode === 8, this means, that this device correctly
        // sends backspace keys in event, so we do not need to apply any hacks
        owner.hasBackspaceSupport = owner.hasBackspaceSupport || charCode === 8;
        if (!owner.hasBackspaceSupport
          && Util.isAndroidBackspaceKeydown(owner.lastInputValue, currentValue)
        ) {
            charCode = 8;
        }

        owner.lastInputValue = currentValue;

        // hit backspace when last character is delimiter
        // @Addition - add selectionEnd to getPostDelimiter so that it supports guidingPlaceholder
        const postDelimiter = Util.getPostDelimiter(currentValue, pps.delimiter, pps.delimiters, pps.guidingPlaceholder ? owner.element.selectionEnd : null);
        if (charCode === 8 && postDelimiter) {
            pps.postDelimiterBackspace = postDelimiter;
        } else {
            pps.postDelimiterBackspace = false;
        }
    }

    onChange() {
        this.onInput(this.getRawValue());
    }

    onFocus() {
        const owner = this;
        const pps = owner.properties;

        Cleave.Util.fixPrefixCursor(owner.element, pps.prefix, pps.delimiter, pps.delimiters);
    }

    onCut(e) {
        if (!Cleave.Util.checkFullSelection(this.element.value)) { return; }
        this.copyClipboardData(e);
        this.onInput('');
    }

    onCopy(e) {
        if (!Cleave.Util.checkFullSelection(this.element.value)) { return; }
        this.copyClipboardData(e);
    }

    copyClipboardData(e) {
        const owner = this;
        const pps = owner.properties;
        const Util = Cleave.Util;
        const inputValue = owner.element.value;
        let textToCopy = '';

        if (!pps.copyDelimiter) {
            textToCopy = Util.stripDelimiters(inputValue, pps.delimiter, pps.delimiters);
        } else {
            textToCopy = inputValue;
        }

        try {
            if (e.clipboardData) {
                e.clipboardData.setData('Text', textToCopy);
            } else {
                // @ts-ignore
                window.clipboardData.setData('Text', textToCopy);
            }

            e.preventDefault();
        } catch (ex) {
            //  empty
        }
    }

    onInput(value) {
        const owner = this;
        const pps = owner.properties;
        const Util = Cleave.Util;

        // @Addition - use cursor position as end of input value when guidingplaceholder enabled
        const selectionEnd = owner.element.selectionEnd;
        // case 1: delete one more character "4"
        // 1234*| -> hit backspace -> 123|
        // case 2: last character is not delimiter which is:
        // 12|34* -> hit backspace -> 1|34*
        // note: no need to apply this for numeral mode
        const postDelimiterAfter = Util.getPostDelimiter(value, pps.delimiter, pps.delimiters, pps.guidingPlaceholder ? selectionEnd : null);
        if (!pps.numeral && pps.postDelimiterBackspace && !postDelimiterAfter) {
            value = Util.headStr(value, (pps.guidingPlaceholder ? selectionEnd : value.length) - pps.postDelimiterBackspace.length);
        }

        // numeral formatter
        if (pps.numeral) {
            // Do not show prefix when noImmediatePrefix is specified
            // This mostly because we need to show user the native input placeholder
            if (pps.prefix && pps.noImmediatePrefix && value.length === 0) {
                pps.result = '';
            } else {
                pps.result = pps.numeralFormatter.format(value);
            }
            owner.updateValueState();

            return;
        }

        // strip delimiters
        value = Util.stripDelimiters(value, pps.delimiter, pps.delimiters);

        // strip prefix
        value = Util.getPrefixStrippedValue(
            value, pps.prefix, pps.prefixLength,
            pps.result, pps.delimiter, pps.delimiters, pps.noImmediatePrefix
        );

        // strip non-numeric characters
        value = pps.numericOnly ? Util.strip(value, /[^\d]/g) : value;

        // @Addition - strip characters that don't match provided mask format
        value = pps.maskCharTypes.length > 0 ? Util.stripEach(value, pps.maskCharTypes) : value;
        // Util.filterPattern(value, pps.blocks, pps.blockPatterns) : value;

        // convert case
        value = pps.uppercase ? value.toUpperCase() : value;
        value = pps.lowercase ? value.toLowerCase() : value;

        // prevent from showing prefix when no immediate option enabled with empty input value
        if (pps.prefix && (!pps.noImmediatePrefix || value.length)) {
            value = pps.prefix + value;

            // no blocks specified, no need to do formatting
            if (pps.blocksLength === 0) {
                pps.result = value;
                owner.updateValueState();

                return;
            }
        }

        // strip over length characters
        value = Util.headStr(value, pps.maxLength);

        // apply blocks
        pps.result = Util.getFormattedValue(
            value,
            pps.blocks, pps.blocksLength,
            pps.delimiter, pps.delimiters, pps.delimiterLazyShow
        );

        owner.updateValueState();
    }

    updateValueState() {
        const owner = this;
        const Util = Cleave.Util;
        const pps = owner.properties;

        if (!owner.element) {
            return;
        }

        let endPos = owner.element.selectionEnd;
        const oldValue = owner.element.value;
        const newValue = pps.result;

        endPos = Util.getNextCursorPosition(endPos, oldValue, newValue, pps.delimiter, pps.delimiters);

        // fix Android browser type="text" input field
        // cursor not jumping issue
        if (owner.isAndroid) {
            window.setTimeout(() => {
                owner.element.value = newValue;
                Util.setSelection(owner.element, endPos, pps.document);
                owner.callOnValueChanged();
            }, 1);

            return;
        }

        owner.element.value = newValue;
        Util.setSelection(owner.element, endPos, pps.document);
        owner.callOnValueChanged();
    }

    callOnValueChanged() {
        const owner = this;
        const pps = owner.properties;

        pps.onValueChanged.call(owner, {
            target: {
                value: pps.result,
                rawValue: owner.getRawValue()
            }
        });
    }

    setRawValue(value) {
        const owner = this;
        const pps = owner.properties;

        value = value !== undefined && value !== null ? value.toString() : '';

        //! This condition below causing issue with currency format 'xxx.xxx.xxx,xx' --> since `pps.numeral` is always == true;
        // if (pps.numeral) {
        //     value = value.replace('.', pps.numeralDecimalMark);
        // }

        pps.postDelimiterBackspace = false;

        owner.element.value = value;
        owner.onInput(value);
    }

    // @Addition - replace raw input value with given mask pattern formatted value
    setRawGuidingPlaceholderValue(value) {
        const owner = this;
        const pps = owner.properties;
        owner.element.value = pps.prefix + value;

        let endPos = owner.element.selectionEnd;
        const oldValue = owner.element.value;
        const newValue = pps.result;
        endPos = Cleave.Util.getNextCursorPosition(endPos, oldValue, newValue, pps.delimiter, pps.delimiters);

        Cleave.Util.setSelection(owner.element, endPos, pps.document);
    }

    getRawValue() {
        const owner = this;
        const pps = owner.properties;
        const Util = Cleave.Util;
        let rawValue = owner.element.value;

        if (pps.rawValueTrimPrefix) {
            rawValue = Util.getPrefixStrippedValue(pps.guidingPlaceholder ? pps.result : rawValue, pps.prefix, pps.prefixLength, pps.result, pps.delimiter, pps.delimiters, pps.noImmediatePrefix);
        }

        if (pps.numeral) {
            rawValue = pps.numeralFormatter.getRawValue(rawValue);
        } else {
            rawValue = Util.stripDelimiters(pps.guidingPlaceholder ? pps.result : rawValue, pps.delimiter, pps.delimiters);
        }
        return rawValue;
    }

    getFormattedValue() {
        return this.element.value;
    }

    destroy() {
        const owner = this;
        owner.element.removeEventListener('input', owner.onChangeListener);
        owner.element.removeEventListener('keydown', owner.onKeyDownListener);
        owner.element.removeEventListener('focus', owner.onFocusListener);
        owner.element.removeEventListener('cut', owner.onCutListener);
        owner.element.removeEventListener('copy', owner.onCopyListener);
        // @Addition - properly destroy cleave by removing mask, to allow reinitialization
        owner.element.value = owner.getRawValue();
    }

    toString() {
        return '[Cleave Object]';
    }

}

