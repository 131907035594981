import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidatorFn, AbstractControl, FormControl, FormGroupDirective } from '@angular/forms';
export interface PhxFormArrayValidators {
    controlName: string;
    validator: { [key: string]: any } | ValidatorFn;
}
@Injectable()
export class ReactiveFormService {
    validateForm(formGroup: FormGroup | FormArray) {
        if (!formGroup) {
            return;
        }
        Object.keys(formGroup.controls).forEach(x => {
            const ctrl = formGroup.controls[x];
            if (ctrl instanceof FormGroup && ctrl.controls) {
                this.validateForm(ctrl);
            } else if (ctrl instanceof FormArray && ctrl.controls.length) {
                this.validateForm(ctrl);
                if (ctrl.status === 'INVALID') {
                    if (!ctrl.errors) {
                        // user only set validation to child level
                        ctrl.controls.some(control => {
                            if (!!control.errors) {
                                ctrl.setErrors(control.errors);
                                return true;
                            }
                        });
                    }
                    ctrl.markAsDirty();
                }
            } else {
                ctrl.markAsTouched();
                ctrl.markAsDirty();
                const currentVal = ctrl.value;
                ctrl.setValue(currentVal);
            }
        });
    }

    private validateFormArray(
        form: FormGroup,
        arrayCtrl: FormArray,
        validators: PhxFormArrayValidators[]
    ) {
        if (validators.length) {
            validators.some((validateObj: PhxFormArrayValidators, index: number) => {
                if (form.controls[validateObj.controlName] === arrayCtrl) {
                    arrayCtrl.markAsDirty();
                    if (typeof validateObj.validator === 'function') {
                        arrayCtrl.setValidators(validateObj.validator as ValidatorFn);
                        arrayCtrl.updateValueAndValidity();
                    } else if (typeof validateObj.validator === 'object') {
                        const key = Object.keys(validateObj.validator)[0];
                        if (
                            key === 'required' ||
                            key === 'minlength' ||
                            key === 'maxlength'
                        ) {
                            arrayCtrl.setErrors(validateObj.validator);
                        } else {
                            console.warn(`Only support 'required | minlength | maxlength | Validator function'`);
                        }
                    }
                    return true;
                }
            });
        }
    }

    getControlByName(ctrlName: string, parentFormGroup: FormGroupDirective): FormControl {
        if (!parentFormGroup) {
            throw new Error(`${ctrlName} controlName must be use in reactive Form. It can not be use alone.`);
        }
        const formControls = parentFormGroup.form.controls;
        if (formControls[ctrlName]) {
            return formControls[ctrlName] as FormControl;
        }
        let targetControl;
        Object.keys(formControls).some(key => {
            if (formControls[key] instanceof FormGroup) {
                // console.log('formControls[key]: ', formControls[key]);
                targetControl = this.getControlFromGroup((formControls[key] as FormGroup).controls, ctrlName);
                if (!!targetControl) {
                    return true;
                }
            }
        });

        if (!targetControl || !(targetControl instanceof FormControl)) {
            throw new Error(`${ctrlName} can not be found inside ${parentFormGroup.name} reactive form you are using`);
        }
        return targetControl;
    }

    private getControlFromGroup (controls: { [key: string]: AbstractControl}, ctrlName?: string): FormControl {
        let targetControl;
        if (controls[ctrlName]) {
            return controls[ctrlName] as FormControl;
        }
        Object.keys(controls).some((key: string) => {
            if (controls[key] instanceof FormGroup) {
                if ( (controls[key] as FormGroup).controls[ctrlName]) {
                    targetControl = (controls[key] as FormGroup).controls[ctrlName] as FormControl;
                    return true;
                } else {
                     this.getControlFromGroup((controls[key] as FormGroup).controls);
                }
            }
        });
        return targetControl;
    }
}
