import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {PhxTranslateModule} from '@phoenix/ui/translate';
import {ScalarsPipe} from './pipes/scalars.pipe';
import {SearchByPipe} from './pipes/searchby.pipe';
import {StringJoinPipe} from './pipes/stringjoin.pipe';
import {PhxNoSanitizePipe} from './pipes/phx.no.sanitize.pipe';

import {Meta} from '@angular/platform-browser';
import {BackdropComponent} from './components/backdrop/backdrop.component';
import {PhxOption} from './components/option.component';
import {AutofocusDirective} from './directives/autofocus/autofocus.directive';
import {CsrfTokenInterceptor} from './interceptors/csrf-token.interceptor';
import {PhxCommonService} from './services/common.service';
import {FocusTrapService} from './services/focus-trap.service';
import {Logger} from './services/log/logger.interface';
import {LoggerService} from './services/log/logger.service';
import {PhxCsrfConfigService} from './services/phx-csrf-config.service';
import {ReactiveFormService} from './services/reactive-form.service';
import {PhxTheme} from './enum/themes.enum';
import {PhxThemeService} from './services/phx.theme.service';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {PhxCspInlineStylesModule} from './csp-nonce/phx.csp.inline.styles.module';

export {PhxCommonService} from './services/common.service';
export {SearchByPipe} from './pipes/searchby.pipe';
export {StringJoinPipe} from './pipes/stringjoin.pipe';
export {ScalarsPipe} from './pipes/scalars.pipe';
export {PhxNoSanitizePipe} from './pipes/phx.no.sanitize.pipe';
export {ContentRef} from './classes/content-ref';
export {Rectangle} from './interface/rectangle.interface';
export {ConsoleLogConfig, ServerLogConfig} from './services/log/log.config';
export {Logger, LoggerType, LogMode, LogMessage} from './services/log/logger.interface';
export {PhxCsrfConfigService} from './services/phx-csrf-config.service';
export {FocusTrapService} from './services/focus-trap.service';
export * from './interceptors/mock-api.interceptor';
export {PHX_OPTION_PARENT_COMPONENT, PhxOption, PhxOptionsParent} from './components/option.component';
export {BackdropComponent} from './components/backdrop/backdrop.component';

export {DynamicComponentInputParams, DynamicComponentOutputParams} from './interface/component.model.interface';
export {PhxUtil} from './classes/phx-util';

export {GestureService} from './services/gesture.service';

export {Effects} from './animations/effects';
export {Animations} from './animations/animations';
export {KeyCode} from './enum/keycode.enum';
export * from './enum/touchEvents.enum';
export {PhxTheme} from './enum/themes.enum';
export {PhxThemeService} from './services/phx.theme.service';
export { OverlayPositionService } from './services/overlay.position.service';

export * from './classes/phx-gesture';

export {CurrencyConfig} from './interface/currency.config.interface';
export {ErrorMessageComponent} from './components/error-message/error-message.component';
export {PhxCspInlineStylesModule} from './csp-nonce/phx.csp.inline.styles.module';

export function csrfFactory(meta: Meta, injector: Injector) {
  return new CsrfTokenInterceptor(meta, injector);
}

const PIPES = [SearchByPipe, StringJoinPipe, ScalarsPipe, PhxNoSanitizePipe];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, PhxCspInlineStylesModule, PhxTranslateModule.forChild()],
  providers: [
    PhxCommonService,
    ReactiveFormService,
    PhxCsrfConfigService,
    FocusTrapService,
    PhxThemeService,
    {provide: 'APP_THEME', useValue: PhxTheme.LIGHT},
    {provide: Logger, useClass: LoggerService},
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: csrfFactory,
      deps: [Meta, Injector],
      multi: true
    }
  ],
  declarations: [...PIPES, AutofocusDirective, PhxOption, BackdropComponent, ErrorMessageComponent],
  exports: [...PIPES, PhxCspInlineStylesModule, AutofocusDirective, PhxOption, BackdropComponent, ErrorMessageComponent]
})
export class PhxCommonModule {
  static forRoot(): ModuleWithProviders<PhxCommonModule> {
    return {ngModule: PhxCommonModule};
  }
}
