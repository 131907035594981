import { Injectable, Inject, Optional, ElementRef } from '@angular/core';
import { HammerLoader, HAMMER_LOADER } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { Subject, fromEvent, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class GestureService {
    readonly hasHammerJsLoaded;
    readonly isWebBrowser;
    readonly doubleTap$ = new Subject<any> ();
    readonly trippleTap$ = new Subject<any>();

    private hostSubMap = new Map <ElementRef, Subscription>();

    constructor(
        private platform: Platform,
        @Optional() @Inject(HAMMER_LOADER) hamerLoader?: HammerLoader) {
        this.hasHammerJsLoaded = typeof window === 'undefined' || (window as any).Hammer || hamerLoader;

        // check if mobile devices since we don't want to open tooltip for click
        this.isWebBrowser = !this.platform.IOS && !this.platform.ANDROID;
    }

    bindToHost(hostRef: ElementRef) {
        if (hostRef && !this.isWebBrowser && this.hasHammerJsLoaded) {
            const sub = fromEvent(hostRef.nativeElement, 'tap').pipe(
                tap((e: any) => {
                   if (e.tapCount === 2) {
                       this.doubleTap$.next(e);
                   } else if (e.tapCount === 3) {
                       this.trippleTap$.next(e);
                   }
                })
            ).subscribe();

            this.hostSubMap.set(hostRef, sub);
        }
    }

    // call on component/directive onDestroy life cycle hook
    unbindFromHost(hostRef) {
        if (this.hostSubMap.get(hostRef)) {
            this.hostSubMap.get(hostRef).unsubscribe();
            this.hostSubMap.delete(hostRef);
        }
    }
}