import { FocusOrigin, FocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { asapScheduler, Subscription, Subject } from 'rxjs';
import { filter, takeUntil, delay } from 'rxjs/operators';
import { SidebarOptions } from './sidebar-options.interface';
import { SidebarComponent } from './sidebar.component';

/** Result of the toggle promise that indicates the state of the sidebar. */
export type ToggleResult = 'open' | 'close';

@Component({
  selector: 'phx-sidebar-content',
  template: `
    <div
      #sideBarContent
      class="phx-sidebar-content"
      [attr.role]="_isFocusTrapEnabled ? 'dialog' : null"
      [ngStyle]="{ 'z-index': sidebarContainer.zIndex }"
      [attr.aria-labelledby]="sidebarContainer.ariaLabelledBy"
      [attr.aria-describedby]="sidebarContainer.ariaDescribedBy"
    >
      <ng-container *ngIf="options.showCloseButton">
        <a class="px-close-icon" href="#" (click)="closeSidebar($event)">
          <i class="pxi px-times" aria-hidden="true"></i>
        </a>
      </ng-container>
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidebar.component.scss'],
  host: {
    tabIndex: '-1'
  }
})
export class SidebarContentComponent implements OnInit, OnDestroy, AfterViewInit {
  private _focusTrap: FocusTrap;
  private prevFocusedElem: HTMLElement | null = null;

  public options: SidebarOptions;
  public opened = false;

  private _destroy$ = new Subject<any>();
  private oppDir = 'right';
  // these variable for touch events only
  private _distance = 0;
  private scheduleSubscription: Subscription;
  private _startX = 0;
  private _startY = 0;
  private _focusOrigin: FocusOrigin;
  private swipable = false;

  @ViewChild('sideBarContent') sideBarContent: ElementRef<HTMLDivElement>;

  constructor(
    private _hostRef: ElementRef<HTMLElement>,
    private _focusTrapFactory: ConfigurableFocusTrapFactory,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private _document: any,
    @Inject(SidebarComponent) public sidebarContainer: SidebarComponent
  ) {}

  ngOnInit() {
    if (!this.sidebarContainer) {
      throw new Error(`Can not find 'side-bar' element.! Please check your template`);
    }

    this.options = this.sidebarContainer.sidebarOptions;
  }

  ngAfterViewInit() {
    if (this.sidebarContainer.isHorizontalPosition) {
      this.oppDir = this.options.position === 'left' ? 'right' : 'left';
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'width', `${this.options.panelWidth}`);
    } else {
      this.oppDir = this.options.position === 'top' ? 'bottom' : 'top';
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'height', `${this.options.panelHeight}`);
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'width', '100%');
    }

    this.renderer.setStyle(this.sideBarContent.nativeElement, 'transition', this.options.animationTime + 'ms');

    if (this.options.fullscreen) {
      if (this.options.action !== 'over') {
        throw new Error(`Fullscreen mode only support for 'over' action`);
      }
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'position', 'fixed');
      if (this.sidebarContainer.isHorizontalPosition) {
        this.renderer.setStyle(this.sideBarContent.nativeElement, 'top', this.options.topMargin);
      } else {
        this.renderer.setStyle(this.sideBarContent.nativeElement, 'left', 0);
      }
    } else {
      if (this.options.action !== 'over' && !this.sidebarContainer.sidebarBase) {
        throw new Error(
          `In order to use pushContent alone with containerSelector,
                    you need to use 'phx-sidebar-base' element as wrapper for container content`
        );
      }
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'position', 'absolute');
    }

    if (!this.sidebarContainer.openState && !this.sidebarContainer.isHorizontalPosition) {
      this.hideVerticalSidebarContent();
    }
    const openChanged$ = this.sidebarContainer._openedChange
      .asObservable()
      .pipe(
        filter(isOpen => typeof isOpen === 'boolean'),
        takeUntil(this._destroy$)
      );

    openChanged$.subscribe((open: boolean) => {
        this.opened = open;
        this.checkSideBarMode();
        if (open) {
          if (this._document) {
            this.prevFocusedElem = this._document.activeElement as HTMLEmbedElement;
          }
        } else {
          this._restoreFocus();
        }
        this.cdr.markForCheck();
      });

    openChanged$
      .pipe(
        delay(50),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        if (this._isFocusTrapEnabled) {
          this._trapFocus();
        } else if (this.opened) {
          asapScheduler.schedule(() => {
            this._setFirstElementFocus();
          }, this.options.animationTime);
        }
      });

    this.sidebarContainer.panStart$.pipe(takeUntil(this._destroy$)).subscribe(event => this.onPanStart(event));

    this.sidebarContainer.panX$.pipe(takeUntil(this._destroy$)).subscribe(event => this.onPanX(event));

    this.sidebarContainer.panEnd$.pipe(takeUntil(this._destroy$)).subscribe(event => this.onPanEnd(event));
  }

  private checkSideBarMode() {
    const sidebarBase = this.sidebarContainer.sidebarBase;
    if (this.options.action !== 'over') {
      this.toggleSideBar(this.opened, sidebarBase.baseElement);
    } else {
      this.toggleSideBar(this.opened);
    }
  }

  private toggleSideBar(show: boolean, targetElm?: HTMLElement) {
    this.renderer.removeStyle(this.sideBarContent.nativeElement, `${this.oppDir}`);
    if (show) {
      if (this.sidebarContainer.isHorizontalPosition) {
        this.showHorizontalSidebarContent();
      } else {
        this.showVerticalSidebarContent();
      }

      if (this.options.action !== 'over') {
        this.renderer.setStyle(targetElm, 'transition', this.options.animationTime + 'ms');
        this.renderer.setStyle(targetElm, 'position', 'absolute');
        if (this.options.action === 'push') {
          if (this.sidebarContainer.isHorizontalPosition) {
            this.renderer.setStyle(targetElm, this.options.position, this.options.position === 'left' ? this.panelWidth : this.panelWidth);
            this.renderer.setStyle(targetElm, this.oppDir, this.oppDir === 'right' ? -this.panelWidth : -this.panelWidth);
          } else {
            // vertical
            this.renderer.setStyle(targetElm, 'top', this.options.position === 'top' ? `${this.panelHeight}` : `-${this.panelHeight}`);
          }
        } else {
          // side position does not support for vertical position
          if (this.sidebarContainer.isHorizontalPosition) {
            this.renderer.setStyle(targetElm, this.options.position, this.options.panelWidth);
            this.renderer.setStyle(targetElm, this.oppDir, -this.options.panelWidth);
          }
        }
      }
      this.toggleVisible(true);
    } else {
      // hide
      if (this.sidebarContainer.isHorizontalPosition) {
        this.hideHorizontalSidebarContent();
      } else {
        this.hideVerticalSidebarContent();
      }

      if (this.options.action !== 'over') {
        if (this.sidebarContainer.isHorizontalPosition) {
          this.renderer.setStyle(targetElm, this.options.position, `0px`);
          this.renderer.setStyle(targetElm, this.oppDir, `0px`);
        } else {
          this.renderer.setStyle(targetElm, 'top', '0px');
        }
        this.unsubscribe(this.scheduleSubscription);
        this.scheduleSubscription = asapScheduler.schedule(() => {
          this.renderer.setStyle(targetElm, 'position', this.options.action === 'side' ? 'absolute' : '');
        }, this.options.animationTime);
      }
      this.toggleVisible(false);
    }
    this.sidebarContainer.openChange.emit(show);
  }

  public closeSidebar($event: Event): boolean {
    $event.stopPropagation();
    this.sidebarContainer.open = false;
    return false;
  }

  private showHorizontalSidebarContent() {
    this.renderer.setStyle(this.sideBarContent.nativeElement, this.options.position, '0px');
  }

  private hideHorizontalSidebarContent() {
    this.renderer.setStyle(this.sideBarContent.nativeElement, this.options.position, `-${this.options.panelWidth}`);
  }

  private showVerticalSidebarContent() {
    if (this.options.position === 'top') {
      this.renderer.setStyle(this.sideBarContent.nativeElement, 'top', '0px');
    } else {
      this.renderer.setStyle(
        this.sideBarContent.nativeElement,
        'top',
        `${Math.abs(this.sidebarContainer.sidebarHeight - parseInt(this.panelHeight, 10))}px`
      );
    }
  }

  private hideVerticalSidebarContent() {
    this.renderer.setStyle(this.sideBarContent.nativeElement, 'top', this.options.position === 'top' ? `-${this.panelHeight}` : '100%');
  }

  /** Traps focus inside the sidebar. */
  private _trapFocus() {
    this._focusTrap = this._focusTrapFactory.create(this._hostRef.nativeElement);
    if (!this.options.autoFocus) {
      return;
    }

    this._focusTrap.focusInitialElementWhenReady().then(hasMovedFocus => {
      // If there were no focusable elements, focus the sidenav itself so the keyboard navigation
      // still works. We need to check that `focus` is a function due to Universal.
      if (!hasMovedFocus && typeof this._hostRef.nativeElement.focus === 'function') {
        this._hostRef.nativeElement.focus();
      }
    });
  }

  private _setFirstElementFocus() {
    if (!this.options.autoFocus) {
      return;
    }

    const focusableElements = this._hostRef.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    const firstElement = (focusableElements.item(0) as HTMLElement);
    if (firstElement && firstElement.focus) {
      firstElement.focus();
    }
  }

  /**
   * If focus is currently inside the sidebar, restores it to where it was before the drawer
   * opened.
   */
  private _restoreFocus() {
    if (this._focusTrap) {
      this._focusTrap.destroy();
    }
    if (!this.options || !this.options.autoFocus) {
      return;
    }
    if (this.prevFocusedElem && typeof this.prevFocusedElem.focus === 'function') {
      this.prevFocusedElem.focus();
    }
  }

  /**
   * Toggle this sidebar content.
   * @param isOpen Whether the sidebar content should be open.
   * @param openedVia Whether the sidebar content was opened by a key press, mouse click or programmatically.
   * Used for focus management after the sidenav is closed.
   */
  toggle(isOpen: boolean, openedVia: FocusOrigin = 'program') {
    this.opened = isOpen;

    if (isOpen) {
      this._focusOrigin = openedVia;
    } else {
      this._restoreFocus();
    }
  }

  public onPanStart(event: any) {
    const firstTouch = event.changedPointers[0];

    this._startX = firstTouch.clientX;
    this._startY = firstTouch.clientY;
    this._distance = 0;
  }

  public onPanX(event: any): void {
    this.toggleVisible(true);

    if (Math.abs(Math.abs(event.deltaX) - Math.abs(this._distance)) <= 10) {
      return;
    }

    this.swipable = this.checkHorizontalSwipable(event);

    this._distance = event.deltaX;

    if (this.swipable) {
      this.renderer.setStyle(this.sideBarContent.nativeElement, this.options.position, `${event.distance - this.sideBarContentWidth}px`);
    }
  }

  public onPanEnd(event: any): void {
    if (this.swipable) {
      const isGreater = event.distance >= this.sideBarContentWidth * 0.25;
      if (isGreater) {
        if (this.opened) {
          this.sidebarContainer._openedChange.next(false);
        } else {
          this.sidebarContainer._openedChange.next(true);
        }
      } else {
        if (this.opened) {
          this.sidebarContainer._openedChange.next(true);
        } else {
          this.sidebarContainer._openedChange.next(false);
        }
      }
    }
  }

  get orientation(): number {
    return this.options.position === 'left' || this.options.position === 'top' ? 1 : -1;
  }
  get sideBarContentWidth(): number {
    return this.sideBarContent.nativeElement.clientWidth;
  }
  private checkHorizontalSwipable(event: any): boolean {
    if (this.opened) {
      if ((this.options.position === 'left' && event.deltaX >= 0) || (this.options.position === 'right' && event.deltaX <= 0)) {
        return false;
      }
    } else {
      // currently close
      if ((this.options.position === 'left' && event.deltaX <= 0) || (this.options.position === 'right' && event.deltaX >= 0)) {
        return false;
      }
    }
    return true;
  }

  toggleVisible(isVisible: boolean) {
    this.renderer.setStyle(this.sideBarContent.nativeElement, 'visibility', isVisible ? 'visible' : 'hidden');
  }

  get _isFocusTrapEnabled(): boolean {
    // The focus trap is only enabled when the drawer is open in full screen.
    return this.opened && this.options.fullscreen && this.options.hasBackdrop;
  }

  get panelWidth(): string {
    if (this.options.panelWidth.indexOf('px') > -1) {
      return this.options.panelWidth;
    } else if (this.options.panelWidth.indexOf('%') > -1) {
      return `${this.sideBarContent.nativeElement.offsetWidth}px`;
    }
  }

  get panelHeight(): string {
    if (this.options.panelHeight.indexOf('px') > -1) {
      return `${parseInt(this.options.panelHeight, 10)}px`;
    } else if (this.options.panelHeight.indexOf('%') > -1) {
      return `${this.sideBarContent.nativeElement.offsetHeight}px`;
    }
  }

  private unsubscribe(sub: Subscription) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.scheduleSubscription && !this.scheduleSubscription.closed) {
      this.scheduleSubscription.unsubscribe();
    }
    if (this._focusTrap) {
      this._focusTrap.destroy();
    }
  }
}
