import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CurrencyConfig } from '../interface/currency.config.interface';
import { Rectangle } from '../interface/rectangle.interface';

@Injectable({ providedIn: 'root' })
export class PhxCommonService {
  private count = 1;
  public readonly KEY = {
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    HOME: 36,
    END: 35,
    BACKSPACE: 8,
    DELETE: 46,
    COMMAND: 91
  };

  datePickerClosingSubject$ = new BehaviorSubject<boolean>(true);
  dataTableScrollingSubject$ = new Subject<boolean>();

  constructor() {
    this._addPxInfoInWindow();
  }

  private _addPxInfoInWindow() {
    let phoenixInfo = {
      version: '__phoenixVersion__'
    };
    if (window['px']) {
      phoenixInfo = { ...phoenixInfo, ...window['px'] };
    }
    window['phoenix'] = phoenixInfo;
    window['phx'] = phoenixInfo;
    window['px'] = phoenixInfo;
  }

  getRandID(prefix: string): string {
    const name = `px${prefix}-${this.count++}`;
    // name = name.replace(/[^a-z0-9_]/gi, '');
    if (this.count >= 1000000) {
      this.count = 1;
    }
    return name;
  }

  getOffset(element): Rectangle {
    const boundingClientRect = element.getBoundingClientRect();
    const rectangle: Rectangle = {
      width: boundingClientRect.width,
      height: boundingClientRect.height,
      top: boundingClientRect.top + (window.pageYOffset || document.documentElement.scrollTop),
      left: boundingClientRect.left + (window.pageXOffset || document.documentElement.scrollLeft),
      x: boundingClientRect.x,
      y: boundingClientRect.y
    };
    return rectangle;
  }

  setAttribute(element, attribute) {
    for (const key in attribute) {
      if (attribute.hasOwnProperty(key)) {
        element.setAttribute(key, attribute[key]);
      }
    }
  }

  uuid(): number {
    return Math.ceil(Math.random() * 10000);
  }

  /*
   * Supported Currency Formats are,
   * xx,xx,xxx.xx
   * xxx,xxx,xxx.xx
   * xx,xx,xx,xxx.xx
   * xxx.xxx.xxx,xx
   * Extract (xx,) and (.xx) from format
   */

  getCurrencyFormatConfig(currencyFormat: string): CurrencyConfig {
    const pattern = /^(x{2,3}(\,|\.))|((\,|\.)x{1,})$/gi;

    const patternMatch = currencyFormat.match(pattern);
    const config: CurrencyConfig = {
      thousandSep: ',',
      groupSize: 2,
      decimalChar: '.',
      decimalDigits: 2
    };

    if (Array.isArray(patternMatch) && patternMatch.length === 2) {
      const firstHalf = patternMatch[0];
      const secondHalf = patternMatch[1];
      const firstHalfStrLength = firstHalf.length - 1;

      config.thousandSep = firstHalf.charAt(firstHalfStrLength);
      config.groupSize = firstHalfStrLength;

      config.decimalChar = secondHalf.charAt(0);
      config.decimalDigits = secondHalf.length - 1;
    }

    return config;
  }

  /**
   * detect IE
   * returns version of IE or -1, if browser is not Internet Explorer
   */
  getIEBrowserVersion(): number {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return -1;
  }

  get isIEBrowser(): boolean {
    return this.getIEBrowserVersion() > -1;
  }
}
