import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { PhxCommonService } from '@phoenix/ui/common';

// Can be extended by other input directives. Do NOT add properties to directive decorator, as they do not get inherited
/**
 * Basic Input directive to add to input form controls.
 * Added automatically when using input directives for Datepicker/DateRange.
 */
@Directive({
  selector: '[phxInput]:not([phxDatepicker]):not([fromDate]):not([toDate])'
})
export class InputDirective implements OnInit {

  private _id = this.commonService.getRandID('Inp');

  get id() {
    return this._id;
  }

  @Input() set id(val) {
    this._id = val;
    this.inputId = val;
  }

  @Input() name: string;

  @HostBinding('class.px-form-control') formControl = true;
  @HostBinding('attr.id') inputId = this.id;
  @HostBinding('attr.name') inputName: string;

  constructor(private commonService: PhxCommonService) {
  }

  ngOnInit() {
    this.inputId = this.id;
    this.inputName = this.name || this.id;
  }
}
