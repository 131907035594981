import { NgModule } from '@angular/core';
import { ButtonDirective } from './button.directive';

export { ButtonDirective } from './button.directive';

const PHX_BUTTON_DIRECTIVES = [ButtonDirective];

@NgModule({
  declarations: PHX_BUTTON_DIRECTIVES,
  exports: PHX_BUTTON_DIRECTIVES
})
export class ButtonModule { }
