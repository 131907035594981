import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Location} from '@angular/common';
import {ComponentRef, Injectable, Optional, SkipSelf} from '@angular/core';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {ModalRef} from './modal-ref';
import {ModalComponent} from './modal.component';
import {ModalOptions} from './modal.options.interface';
import {Router} from '@angular/router';

/**
 * A service to open modal windows or close modal windows from your controller.
 */
const ModalConfig: ModalOptions = {
  hasBackdrop: true,
  dismissable: true,
  size: 'sm',
  title: '',
  draggable: false,
  content: null,
  footer: ''
};

@Injectable()
export class ModalService {
  private overlayRef: OverlayRef;
  private modalComponentRef: ComponentRef<ModalComponent>;
  private modalPortal: ComponentPortal<ModalComponent>;

  private modalRefMap = new Map<ComponentRef<ModalComponent>, ModalRef>();

  constructor(
    private overlay: Overlay,
    private router: Router,
    @Optional() private location: Location,
    @Optional() @SkipSelf() private parentModal: ModalService
  ) {
  }

  /**
   * Opens a new modal window with the specified content and using supplied contentOptions.
   * Content can be provided as a TemplateRef or a component type.
   * @param options
   */
  open(options: ModalOptions): ModalRef {
    options = {...ModalConfig, ...options};
    // create most overlay
    this.overlayRef = this.setupOverlay(options);
    this.modalPortal = new ComponentPortal(ModalComponent);
    // attach modalPortal to overlay
    this.modalComponentRef = this.overlayRef.attach(this.modalPortal);
    this.modalComponentRef.instance.options = options;
    this.modalComponentRef.instance.parentRef = this.modalComponentRef;

    const modalRef = new ModalRef(this.overlayRef, this.modalComponentRef.instance, options, this.router);

    modalRef.updatePosition(options.position);

    this.modalRefMap.set(this.modalComponentRef, modalRef);

    modalRef.modal = this.modalComponentRef;

    this.modalComponentRef.instance.closeEvent.pipe(
      filter((modalCompRef: ModalComponent) => !!modalCompRef), // if message --> user click on 'x' icon
      takeUntil(this.modalComponentRef.instance.destroy$),
      tap((modalCompRef: any) => this.close(modalCompRef.parentRef))
    ).subscribe();

    return modalRef;
  }

  /**
   *  Can be used to close a modal, passing an optional result.
   *  @param modal ComponentRef<ModalComponent>
   */
  close(modal?: ComponentRef<ModalComponent>): void {
    let modalRef = this.modalRefMap.get(modal ? modal : this.modalComponentRef);
    if (modalRef) {
      modalRef.close();
      modalRef = null;
      this.modalRefMap.delete(modal ? modal : this.modalComponentRef);
    }
  }

  setupOverlay(options: ModalOptions): OverlayRef {
    const nonOverlappingClass = 'px-non-overlapping-overlay';
    const backdropClasses = ['cdk-overlay-dark-backdrop'];
    if (options.isOpenedBySide) {
      backdropClasses.push(nonOverlappingClass);
    }
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: options.hasBackdrop ? this.overlay.scrollStrategies.block() : this.overlay.scrollStrategies.reposition(),
      hasBackdrop: options.hasBackdrop,
      disposeOnNavigation: !options.disposeOn$, // If dispose function exists set to false
      width: this.getModalWidthSize(options),
      backdropClass: backdropClasses
    });
    const ref = this.overlay.create(overlayConfig);
    // if we want to prevent this modal from overlapping other overlays (specifically, another modal)
    if (options.isOpenedBySide) {
      ref.hostElement.classList.add(nonOverlappingClass);
    }
    return ref;
  }

  private getModalWidthSize(options: ModalOptions): string {
    if (options.size === 'md') {
      return '500px';
    } else if (options.size === 'lg') {
      return '800px';
    } else if (options.size === 'xl') {
      return '1140px';
    } else {
      // default sm
      return '300px';
    }
  }

}
