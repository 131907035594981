import { AnimationEvent } from '@angular/animations';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Effects, FocusTrapService } from '@phoenix/ui/common';
import { ActiveView, CalendarView } from './calendar/calendar.interface';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateUtilityService } from './date-utility.service';
import { DatepickerInputDirective } from './datepicker-input.directive';
import { DatepickerOptions } from './datepicker-options.interface';
import { DatepickerOverlayService } from './datepicker-overlay.service';
import { DatepickerService } from './datepicker.service';

/* TODO: Address the following:
 * 4. Test if moment.isValid is robust enough to use, it can have unpredictable behaviour
 * 8. Make this component accessible (discuss with UX team about feasibility of implementing fallback component for screenreaders.. a calendar datepicker is not a very friendly screenreader component)
 *      i) We can add aria-hidden to datepicker calendar, and force screenreader user to input date manually, or give them a screenreader-only dropdown
 */
@Component({
  selector: 'phx-datepicker-calendar',
  templateUrl: './datepicker-calendar.component.html',
  providers: [DatepickerService, DatepickerOverlayService],
  animations: [Effects.easeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatepickerCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentView: CalendarView = null;
  calendarViewType = CalendarView;
  todayDate = new Date();
  todayDateStr = this.todayDate.toDateString();
  todayDisabled: boolean;
  private _defaultActiveView: ActiveView;
  private destroy$ = new Subject<any>();
  private focusTrap: FocusTrap;
  private dateField: HTMLElement;
  datepickerInput: DatepickerInputDirective;

  @ViewChild('hostTemplate', { static: true }) set hostTemplate(templateRef: TemplateRef<any>) {
    this.datePickerOverlayService.templateRef = templateRef;
  }

  /**
   * id for datepicker.
   */
  @Input() id: string;

  @Input() inline: boolean;

  /**
   * options object to configure the datepicker, see DatepickerOptions interface for defaults
   * @param options options object of type DatepickerOptions
   */
  @Input() set options(options: DatepickerOptions) {
    if (options && options.position) {
      this.datePickerOverlayService.position = options.position;
    }
    this.datePickerService.datePickerOptions = options;
  }

  get options(): DatepickerOptions {
    return this.datePickerService.datePickerOptions;
  }

  get opened(): boolean {
    return this.datePickerOverlayService.opened;
  }

  get defaultActiveView(): ActiveView {
    this._defaultActiveView = this.dateUtilityService.closestValidCalendarView(this.options);
    this._defaultActiveView.calendarView = this.currentView;
    return this._defaultActiveView;
  }

  constructor(
    private elementRef: ElementRef,
    private datePickerService: DatepickerService,
    private dateUtilityService: DateUtilityService,
    private datePickerOverlayService: DatepickerOverlayService,
    private changeDetectorRef: ChangeDetectorRef,
    private focusTrapFactory: FocusTrapFactory,
    private focusTrapService: FocusTrapService
  ) {
    this.datePickerService.activeView$.pipe(takeUntil(this.destroy$)).subscribe(view => {
      if (view.calendarView && view.calendarView !== this.currentView) {
        if (this.options.mode === 'month' && view.calendarView === 'day') {
          this.currentView = CalendarView.MONTH;
        } else {
          this.currentView = view.calendarView;
        }
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnInit() {
    if (this.options.mode === 'month') {
      this.currentView = CalendarView.MONTH;
    } else if (this.options.mode === 'day') {
      this.currentView = CalendarView.DAY;
    }
    this.datePickerService.datePickerInitSubject.next();
    this.datePickerService.datePickerInitSubject.complete();
  }

  ngAfterViewInit() {
    this.todayDisabled = !this.dateUtilityService.isDateEnabled(this.options, this.todayDate, CalendarView.DAY, this.datepickerInput && this.datepickerInput.format);
    if (!this.datePickerService.dateRangeMode) {
      this.datePickerService.latestDateSelection$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.options.closeOnSelect) {
          this.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.datePickerOverlayService.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click', ['$event'])
  hostClick($event: Event) {
    $event.stopPropagation();
  }

  onAnimationDone($event: AnimationEvent) {
    // Trap focus when not inline
    if (this.inline) {
      this.focusTrapService.toggleCDKOverlayFocusTrap(false);
    } else if (!$event.toState) {
      this.focusTrap = this.focusTrapFactory.create($event.element);
      this.focusTrapService.toggleCDKOverlayFocusTrap(true);
      // calendar-body component will handle initial focus of element
    } else {
      this.focusTrap.destroy();
      this.focusTrapService.toggleCDKOverlayFocusTrap(false);
      this.dateField.focus();
    }
  }

  // this will only ever be called for a single datepicker/input binding
  open(inputRef?: ElementRef) {
    this.datePickerService.shouldFocusOnMonth = false;
    this.currentView = this.options.mode === 'month' ? CalendarView.MONTH : CalendarView.DAY;
    this.datePickerOverlayService.open(inputRef);
  }

  close(): void {
    this.datePickerOverlayService.close();
  }

  /*
   * Attach reference to date picker input
   * Returns reference to the DatepickerService that manages this component instance
   */
  registerInput(datePickerInput: DatepickerInputDirective): DatepickerService {
    this.datepickerInput = datePickerInput;
    this.dateField = datePickerInput.elementRef.nativeElement;
    this.datePickerOverlayService.registerInput(datePickerInput);
    return this.datePickerService;
  }

  // Attach reference to dateRange component
  registerDateRange(dateRange: DateRangeComponent): DatepickerService {
    this.datePickerService.registerDateRange(dateRange);
    this.datePickerOverlayService.registerDateRange(dateRange);
    return this.datePickerService;
  }

  selectToday(event) {
    // both calendar and input need to be updated since we are changing date outside of both
    this.datePickerService.setDateFromCalendar(this.todayDate);
    this.datePickerService.setDateFromInput(this.todayDate);
  }
}
