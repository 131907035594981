import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, HostBinding, Input, QueryList, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { PhxGesture, PhxCommonService } from '@phoenix/ui/common';
import { ModalService, ModalOptions } from '@phoenix/ui/modal';
import { DEFAULT_OPTIONS } from './image-viewer.options';
import { ImgDirective } from './img.directive';
import { SlideOptions } from './slide-options.interface';

@Component({
    selector: 'phx-slide',
    template: `
    <ng-content></ng-content>
    <ng-template #lightbox>
        <phx-imageviewer>
            <phx-slide [options]="lightboxConfig">
                <img *ngFor="let imgSrc of imgSrcMap" src="{{imgSrc}}" />
            </phx-slide>
        </phx-imageviewer>
    </ng-template>
    `,
    styles: [
        `
        /deep/ img:not(:first-child){
            margin:0 0 0 10px!important;
        }
        :host {
            display: flex;
        }
        `
    ]
})

export class SlideComponent extends PhxGesture implements AfterContentInit {

    /**
     * Supply option to show images in modal, swipe timing and flipModal
     */
    @Input() options: SlideOptions;
    
    @HostBinding('class.px-flex-row') flexRow = true;
    @ContentChildren(ImgDirective) images: QueryList<ImgDirective>;
    @HostBinding('style.transform') transform;
    @HostBinding('style.transitionDuration') transitionDuration;
    @ViewChild('lightbox', {static: true}) lightbox: TemplateRef<any>;

    public lightboxConfig: SlideOptions = {
        enableSwipe: true,
        swipeDuration: 200,
        showInModal: false,
        flipModal: true
    };
    private imageIndexInView;
    private xValue;
    private totalImages = 0;

    public imgSrcMap = [];

    constructor(
        public renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private phxCommonService: PhxCommonService,
        private modalService: ModalService) {
            super(renderer);
            this.doReset();
            this.transitionDuration = '200ms';
    }

    ngAfterContentInit() {
        this.totalImages = this.images.length;
        this.doInit(DEFAULT_OPTIONS.mobile.modalOptions as any);
    }

    doInit(modalOptions: ModalOptions) {
        if (!this.options) {
            return;
        }
        if (this.options.enableSwipe) {
            this.bindSwipeEvent();
        }
        if (this.options.showInModal) {
            modalOptions.content = modalOptions.content || this.lightbox;
            this.bindTouchEvent(modalOptions);
        }
        this.transitionDuration = `${this.options.swipeDuration}ms`;
    }

    private doReset() {
        this.transform = 'translateX(-40px)';
        this.imageIndexInView = 0;
        this.xValue = -40;
    }

    private bindSwipeEvent() {
        let swipeleft = 'swipeleft';
        let swiperight = 'swiperight';

        if (this.options.flipModal) {
            swipeleft = 'swipeup';
            swiperight = 'swipedown';
        }

        this.images.forEach((img: ImgDirective) => {
            this.imgSrcMap.push(img.element.src);
            this.bindEvent(img.element, swiperight, () => {
                this.slide(swiperight);
            });
            this.bindEvent(img.element, swipeleft, () => {
                this.slide(swipeleft);
            });
        });

    }

    private slide(direction: string) {
        const nextImage = this.seekNext(this.imageIndexInView);
        if (!nextImage) {
            return;
        }
        const imgRect = this.phxCommonService.getOffset(nextImage.element);

        switch (direction) {
            case 'swiperight': {
                if (this.imageIndexInView <= 1) {
                    this.doReset();
                    break;
                }
                this.xValue = this.xValue + imgRect.width;
                this.imageIndexInView--;
                break;
            }

            case 'swipeleft': {
                if (this.imageIndexInView >= (this.totalImages - 1)) {
                    break;
                }
                this.xValue = this.xValue - imgRect.width + 10; // image width + gutter size
                this.imageIndexInView++;
                break;
            }

            case 'swipeup': {
                if (this.imageIndexInView >= (this.totalImages - 1)) {
                    break;
                }
                this.xValue = this.xValue - imgRect.height + 10; // image width + gutter size
                this.imageIndexInView++;
                break;
            }
            case 'swipedown': {
                if (this.imageIndexInView <= 1) {
                    this.doReset();
                    break;
                }
                this.xValue = this.xValue + imgRect.height;
                this.imageIndexInView--;
                break;
            }
            default:
                break;
        }
        this.transform = `translateX(${this.xValue}px)`;
        this.cdr.markForCheck();
    }

    private seekNext(index: number): ImgDirective {
        return this.images.find((val, $i) => index === $i);
    }

    private bindTouchEvent(modalOptions: ModalOptions) {
        this.images.forEach((img: ImgDirective) => {
            this.bindEvent(img.element, 'touch', () => {
                this.modalService.open(modalOptions);
            });
        });
    }
}
