import { Injectable } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Injectable()
export class DatepickerOverlayManagerService {

  private attachedOverlayRefSet: Set<OverlayRef> = new Set();
  constructor() { }

  addAttached(overlayRef: OverlayRef) {
    this.attachedOverlayRefSet.forEach((ref: OverlayRef) => {
      if (ref && ref.hasAttached()) {
        ref.detach();
      }
    });
    this.attachedOverlayRefSet.clear();
    this.attachedOverlayRefSet.add(overlayRef);
  }

}
