import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import { DomHandler } from '../dom/domhandler';
import { Subscription } from 'rxjs';
import { DataTableComponent as Table} from "../datatable.component";
import { TableService } from "../base/table.service";


@Component({
    selector: 'phx-rowallcheckbox',
    template: `
          <phx-checkbox [id]="id" [ngModel]="dt.selectHandler.selectedAll" (ngModelChange)="doChange($event)"></phx-checkbox>
    `
})
export class RowAllCheckbox  {

    @Input() disabled: boolean;

    @Input() id: string;

    constructor(public dt: Table) {}

    doChange(event: any) {
        this.dt.toggleAllWithCheckbox(event);
    }
}
