import {AnimationEvent} from '@angular/animations';
import {FocusTrap, FocusTrapFactory} from '@angular/cdk/a11y';
import {ComponentPortal, Portal, TemplatePortal} from '@angular/cdk/portal';
import {DOCUMENT} from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {Effects, FocusTrapService, PhxCommonService} from '@phoenix/ui/common';
import {Subject} from 'rxjs';

import {componentOrTemplate, ModalOptions, stringOrTemplate} from './modal.options.interface';

@Component({
  selector: 'phx-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="px-modal"
      #modalContainer
      cdkDrag
      [cdkDragDisabled]="!options.draggable"
      cdkDragRootElement=".cdk-overlay-pane"
      role="document"
      tabindex="-1"
    >
      <div
        class="px-modal-content"
        [ngClass]="options.styleClass"
        [@zoomInOut]="state"
        (@zoomInOut.start)="onAnimationStart($event)"
        (@zoomInOut.done)="onAnimationDone($event)"
      >
        <div class="px-modal-header" *ngIf="showHeader" cdkDragHandle>
          <h5 class="px-modal-title" #modalTitle [id]="'modal_title-' + id">
            <ng-template [cdkPortalOutlet]="modalTitlePortal"></ng-template>
          </h5>
          <button
            #closeButton
            type="button"
            class="px-close"
            data-dismiss="modal"
            [hidden]="!options.dismissable"
            (click)="onCloseModal($event)"
          >
            <span [id]="'modal_close-' + id"
                  class="px-sr-only">{{options?.closeBtnAriaLabel || ('phx.modal.close' | translate)}}</span>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="px-modal-body" #modalBody>
          <ng-template [cdkPortalOutlet]="modalBodyPortal"></ng-template>
        </div>

        <div class="px-modal-footer" #modalFooter *ngIf="showFooter">
          <div class="w-100 px-text-left">
            <ng-template [cdkPortalOutlet]="modalFooterPortal"></ng-template>
          </div>
        </div>
      </div>
    </div>
  `,
  animations: [Effects.zoomInOut]
})
export class ModalComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  public id = this.phxCommonService.getRandID('Mod');

  // @HostBinding('attr.flex') flex = '1';
  @HostBinding('attr.role') roleAttr = 'dialog';
  @HostBinding('attr.aria-modal') ariaModal = 'true';
  @HostBinding('attr.aria-labelledby') ariaLabelledBy = 'modal_title-' + this.id;

  // TODO: investigate if we need aria-labelled by on the modal component tag? This aria-labelledby also does nothing currently
  // @HostBinding('attr.aria-labelledby') uniqueId = this.uuid();

  @ViewChild('modalContainer') modalContainer: ElementRef;
  @ViewChild('modalTitle') modalTitle: ElementRef;
  @ViewChild('modalBody', {static: true}) modalBody: ElementRef;
  @ViewChild('modalFooter') modalFooter: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  @Input() options: ModalOptions;

  @Output() closeEvent = new EventEmitter<string | this>();

  public modalTitlePortal: Portal<EmbeddedViewRef<any>>;
  public modalBodyPortal: Portal<EmbeddedViewRef<any>>;
  public modalFooterPortal: Portal<EmbeddedViewRef<any>>;

  private closeReason: string;

  /** The class that traps and manages focus within the dialog. */
  private focusTrap: FocusTrap;

  /** Element that was focused before the dialog was opened. Save this to restore upon close. */
  private elementFocusedBeforeDialogOpened: HTMLElement | null = null;

  public showHeader = true;
  public showFooter = true;

  public parentRef: ComponentRef<any>;

  public destroy$ = new Subject<any>();

  /** State of the modal animation. */
  state: 'In' | 'Out';

  /** Emits when an animation state changes. */
  stateChanged$ = new EventEmitter<AnimationEvent>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private hostRef: ElementRef,
    private focusTrapFactory: FocusTrapFactory,
    private focusTrapService: FocusTrapService,
    private phxCommonService: PhxCommonService,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {
    this.renderer.setStyle(this.hostRef.nativeElement, 'flex', '1');
  }

  ngAfterContentInit() {
    this.state = 'In';
  }

  ngAfterViewInit() {
    if (this.options.title) {
      this.embedHeader(this.options.title);
    } else {
      this.showHeader = false;
    }
    if (this.options.footer) {
      this.embedFooter(this.options.footer);
    } else {
      this.showFooter = false;
    }

    if (this.options.content) {
      this.embedModalBody(this.options.content);
    }

    this.setDynamicWidthHeight();

    this.renderer.setStyle(this.modalContainer.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.modalContainer.nativeElement, 'position', 'relative');

    this.renderer.setStyle(this.modalContainer.nativeElement.firstChild, 'margin', 0);
  }

  onAnimationDone($event: AnimationEvent) {
    if ($event.toState === 'In') {
      this.savePreviouslyFocusedElement();
      this.setFocusToModal();
      this.focusTrapService.toggleCDKOverlayFocusTrap(true);
    }
    if ($event.toState === 'Out') {
      this.closeEvent.emit(this.closeReason);
      this.restoreFocusBack();
      this.focusTrapService.toggleCDKOverlayFocusTrap(false);
    }
    this.stateChanged$.emit($event);
  }

  onAnimationStart($event: AnimationEvent) {
    this.stateChanged$.emit($event);
  }

  close($event?: Event, message?: string) {
    if ($event) {
      $event.stopPropagation();
    }
    // delegate event to afterAnimation function
    this.state = 'Out';
    this.closeReason = message;
    this.cdr.markForCheck();
  }

  onCloseModal($event: Event) {
    $event.stopPropagation();
    this.closeEvent.next(this);
  }

  embedHeader(headerContent: stringOrTemplate) {
    if (typeof headerContent === 'string') {
      const textNode = document.createTextNode(`${headerContent}`);
      this.modalTitle.nativeElement.appendChild(textNode);
    } else if (headerContent instanceof TemplateRef) {
      this.modalTitlePortal = new TemplatePortal(headerContent, this.viewContainerRef, {$implicit: this.options.titleTemplateData});
    }
  }

  embedFooter(footerContent: stringOrTemplate) {
    if (typeof footerContent === 'string') {
      const textNode = document.createTextNode(`${footerContent}`);
      this.modalFooter.nativeElement.appendChild(textNode);
    } else if (footerContent instanceof TemplateRef) {
      this.modalFooterPortal = new TemplatePortal(footerContent, this.viewContainerRef, {
        $implicit: this.options.footerTemplateData
      });
    }
  }

  embedModalBody(bodyContent: componentOrTemplate) {
    if (bodyContent instanceof TemplateRef) {
      this.modalBodyPortal = new TemplatePortal(bodyContent, this.viewContainerRef, {$implicit: this.options.contentTemplateData});
    } else {
      this.modalBodyPortal = new ComponentPortal(bodyContent) as Portal<any>;
    }
  }

  setDynamicWidthHeight() {
    if (!this.options.width && !this.options.height) {
      return;
    }
    const modalBody = this.modalBody.nativeElement;
    if (this.options.height) {
      this.renderer.setStyle(modalBody, 'height', this.options.height);
      this.renderer.setStyle(modalBody, 'overflowY', 'auto');
    }
    if (this.options.width) {
      this.renderer.setStyle(modalBody, 'width', this.options.width);
      this.renderer.setStyle(modalBody, 'overflowX', 'auto');
    }
  }

  /** Saves a reference to the element that was focused before the Modal was opened. */
  private savePreviouslyFocusedElement() {
    if (this.document) {
      this.elementFocusedBeforeDialogOpened = this.document.activeElement as HTMLElement;
    }
  }

  private setFocusToModal() {
    if (!this.focusTrap) {
      // create focusable trapped zone
      this.focusTrap = this.focusTrapFactory.create(this.hostRef.nativeElement);

      this.focusTrap.focusInitialElementWhenReady().then((value: boolean) => {
        this.closeButton?.nativeElement.focus();
      });
    }
  }

  /** Restores focus to the element that was focused before the Dialog opened. */
  private restoreFocusBack() {
    const prevFocusedElem = this.elementFocusedBeforeDialogOpened;
    // IE issue
    if (prevFocusedElem && typeof prevFocusedElem.focus === 'function') {
      prevFocusedElem.focus();
    }

    // destroy focusTrap
    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  private detachPortal(portal: Portal<any>) {
    if (portal && portal.isAttached) {
      portal.detach();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.detachPortal(this.modalTitlePortal);
    this.detachPortal(this.modalBodyPortal);
    this.detachPortal(this.modalFooterPortal);
  }
}
