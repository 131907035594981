import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PhxTheme} from '../enum/themes.enum';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PhxThemeService {
  private themeSubject = new BehaviorSubject<PhxTheme>(PhxTheme.LIGHT);

  public get theme$(): Observable<PhxTheme> {
    return this.themeSubject.asObservable();
  }

  constructor() {
    this.listenToThemeChange();
  }

  private listenToThemeChange() {
    const htmlTagElement = document.getElementsByTagName('html')[0];
    let theme: PhxTheme = htmlTagElement.getAttribute('data-theme') as PhxTheme || PhxTheme.LIGHT;
    this.themeSubject.next(theme);

    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
          theme = htmlTagElement.getAttribute('data-theme') as PhxTheme || PhxTheme.LIGHT;
          this.themeSubject.next(theme);
        }
      });
    });

    observer.observe(htmlTagElement, {attributes: true, childList: false, subtree: false});
  }

  set theme(theme: PhxTheme) {
    document.documentElement.setAttribute('data-theme', theme);
  }

  get theme(): PhxTheme {
    return this.themeSubject.getValue();
  }

}
