import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { PhxCommonService } from '@phoenix/ui/common';
import { AccordionPaneDirective } from './accordion-pane.directive';
import { AccordionState } from './accordion.enum';
import { AccordionParam } from './accordion.interface';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'phx-accordion',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  constructor(private phxCommonService: PhxCommonService, private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2) { }

  static readonly DEF_ICON_CLASSES = ['px-minus-square px-rotate-180 px-fade', 'px-plus-square'];
  static readonly CHEV_ICON_CLASSES = ['px-rotate-180', 'px-chevron-down'];
  @ContentChildren(AccordionPaneDirective) accordionPanes: QueryList<AccordionPaneDirective>;

  /**
   * [Boolean] will determine if multiple accordion panes are allowed to be open
   * Default is false ( only one accordion pane can be open at one time ).
   */
  @Input() multiOpen = false;

  /**
   * [Boolean] will determine if all the accordion panes need to be open or closed.
   * Default is false ( all panes will be closed ).
   */
  @Input() expandAll = false;

  /**
   * [Boolean] determines if the icons should be chevron style or +/- style.
   * Default is false ( +/- icons will be at heading ).
   */
  @Input() chevronIcon = false;

  /**
   * [Boolean] determines if the headings will be striped.
   * Default is false ( no striping will occur ).
   */
  @Input() public striped = false;

  /**
   * [Boolean] determines if the panes will be striped.
   * Default is false ( no striping will occur ).
   */
  @Input() public stripedContent = false;

  /**
   * Determines if the icons should be placed on the right or left side of the heading.
   * Default is left ( icon(s) will be on the left side of accordion heading ).
   */
  @Input() public iconAlign: 'left' | 'right';

  /**
   * [AccordionParam] This event will be fired on the expand and collapse of each pane in
   * the accordion.
   */
  @Output() paneChange = new EventEmitter<AccordionParam>();

  accordionId = this.phxCommonService.getRandID('Acc');
  accordionState = AccordionState;

  private paneChangeSubscriptions: Array<Subscription> = [];
  private destroy$ = new Subject<never>();

  expandIcons = AccordionComponent.DEF_ICON_CLASSES;

  ngOnInit() {
    if (this.chevronIcon) {
      this.expandIcons = AccordionComponent.CHEV_ICON_CLASSES;
    }
  }

  ngAfterViewInit() {
    this.initialSetup();
    this.accordionPanes.changes
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.unsubscribeFromPanes();
        this.initialSetup();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expandAll']) {
      this.toggleAccordion(this.expandAll);
    }
  }

  // set the initial states of the accordion
  initialSetup() {
    this.accordionPanes.forEach((pane, idx) => {
      const paneSubscription$ = pane.inputChanged.subscribe(resp => {
        this.setHeight(pane.expanded, idx);
        this.changeDetectorRef.detectChanges();
      });
      this.paneChangeSubscriptions.push(paneSubscription$);
      pane.expanded = this.expandAll || pane.expanded;
      this.setHeight(pane.expanded, idx);
    });
  }

  toggleAccordion(expanded: boolean) {
    if (this.accordionPanes) {
      this.accordionPanes.forEach((pane, idx) => {
        pane.expanded = expanded;
        this.setHeight(pane.expanded, idx);
      });
    }
  }

  toggleAccordionPane(pane: AccordionPaneDirective) {
    if (pane.disabled || !pane.defaultAction) {
      // if the pane is disabled do nothing
      return;
    } else {
      pane.expanded = !pane.expanded;
      this.openPane(pane);
    }
  }

  // check if the multiopen is false
  // check if the clicked pane is expanded
  openPane(pane: AccordionPaneDirective) {
    this.accordionPanes.forEach((accordionPane, idx) => {
      if (pane !== accordionPane && !this.multiOpen && pane.expanded) {
        accordionPane.expanded = false;
        this.emitOutput(accordionPane, idx);
      } else if (pane === accordionPane) {
        this.emitOutput(accordionPane, idx);
      }
      this.setHeight(accordionPane.expanded, idx);
    });
  }

  // since the accordion hieghts are dynamic, css transitions will not work 
  // by default. So we have followed the Technique 3 javascript in the link below. 
  // https://css-tricks.com/using-css-transitions-auto-dimensions/
  setHeight(expanded, idx) {
    const id = `${this.accordionId}-${idx}-body`;
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    const parent = element.parentElement;
    const grandParent = parent.parentElement;

    if (expanded) {
      this.handleExpansion(parent, grandParent);
    } else {
      this.handleCollapsing(grandParent);
    }

    // we are using setTimout so that these changes take effect 
    // after the css animations has finished. 
    setTimeout(() => {
      if (expanded) {
        // we need to change the height to auto in case the content inside the
        // accordion changes height again. 
        grandParent.style.height = 'auto';
        // when the accordion is collapsed it has dispaly set to none to hide it contents.
        grandParent.style.display = 'block';
      } else {
        grandParent.style.height = 0 + 'px';
        grandParent.style.display = 'none';
      }
    }, 500);
  }

  // to expand an accordion all we need to do is change the display to block
  // and set the height, the css transtition will handle the animation since we are 
  // moving from 0px to fixed height.  
  // The setTimeout code in setHeight method above will set the height to auto 
  // after the css animations are done. 
  private handleExpansion(parent, grandParent) {
    grandParent.style.display = 'block';
    grandParent.style.height = parent.offsetHeight + 'px';
  }

  // in the exapnded state the accordion height is set to auto and as such the 
  // css transitions will not work, so we need to do a few steps. 
  private handleCollapsing(grandParent) {
    // step 1: remove all css transitions from the element and save the transitions
    const grandParentTransitions = grandParent.style.transition;
    grandParent.style.transition = '';
    window.requestAnimationFrame(() => {
      // step 2: in the next animation frame change the height from auto to fixed height 
      // and add the saved transitions from step 1.  
      grandParent.style.height = grandParent.offsetHeight + 'px';
      grandParent.style.transition = grandParentTransitions;

      window.requestAnimationFrame(() => {
        // step 3: in the next animation frame set the new height (which is 0) and 
        // css transitions will take care of the animation
        grandParent.style.height = 0 + 'px';
      });
    });
  }

  // emit an event about the page
  emitOutput(pane, index) {
    this.paneChange.emit({
      paneIndex: index,
      paneId: `${this.accordionId}-${index}-body`,
      state: pane.expanded
    });
  }

  private unsubscribeFromPanes() {
    this.paneChangeSubscriptions.forEach(paneSubscription => {
      paneSubscription.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.unsubscribeFromPanes();
  }
}
