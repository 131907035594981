import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CurrencyDirective } from './currency/currency.directive';
import { MaskDirective } from './custom/mask.directive';
export { MaskDirective } from './custom/mask.directive';
export { CurrencyDirective, CurrencyFormat } from './currency/currency.directive';

@NgModule({
  declarations: [MaskDirective, CurrencyDirective],
  imports: [CommonModule],
  exports: [MaskDirective, CurrencyDirective]
})
export class InputMaskModule {}
