import * as opts from '../../datatable.options';

function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

interface Filter {
    term?: any;
    noTerm?: any;
    condition?: opts.FilterMatchMode;
    flags?: any;
    startswithRE?: any;
    endswithRE?: any;
    containsRE?: any;
    exactRE?: any;
}

interface FilterData {
    col: opts.FilterEventCol,
    filter: Filter
}

function isNullOrUndefined(obj) {
    return obj === undefined || obj === null;
}

export class DataFilter {
    defaultCondition: opts.FilterMatchMode = opts.MatchMode.STARTS_WITH;

    private getTerm(filter: opts.FilterEventCol) {
        if (typeof(filter.term) === 'undefined') { return filter.term; }

        let term = filter.term;

        // Strip leading and trailing whitespace if the term is a string
        if (typeof(term) === 'string') {
            term = term.trim();
        }

        if (typeof term === 'object' && term.hasOwnProperty('value')) {
            term = term.value;
        }

        return term;
    };

    private stripTerm(filter: opts.FilterEventCol) {
        const term = this.getTerm(filter);

        if (typeof(term) === 'string') {
            return escapeRegExp(term.replace(/(^\*|\*$)/g, ''));
        } else {
            return term;
        }
    };

    private guessCondition = function guessCondition(filter) {
        if (typeof(filter.term) === 'undefined' || !filter.term) {
            return this.defaultCondition;
        }

        const term = this.getTerm(filter);

        if (/\*/.test(term)) {
            let regexpFlags = '';
            if (!filter.flags || !filter.flags.caseSensitive) {
                regexpFlags += 'i';
            }

            const reText = term.replace(/(\\)?\*/g, function ($0, $1) { return $1 ? $0 : '[\\s\\S]*?'; });
            return new RegExp('^' + reText + '$', regexpFlags);

        } else { // Otherwise default to default condition
            return this.defaultCondition;
        }
    };

    private setupFilters( filterCol: opts.FilterEventCol ): Filter {
        let result: Filter = null;

        // const filtersLength = filterCol.length;
        // for ( let i = 0; i < filtersLength; i++ ) {
            const filter = filterCol; // filterCol[i];

            if ( !isNullOrUndefined(filter.term) ) {
                const newFilter: Filter = {};

                let regexpFlags = '';
                if (!filter.caseSensitive) {
                    regexpFlags += 'i';
                }

                if ( !isNullOrUndefined(filter.term) ) {
                    // it is possible to have noTerm.
                    /*if ( filter.rawTerm ) {
                        newFilter.term = filter.term;
                    } else {
                        newFilter.term = this.stripTerm(filter);
                    }*/
                    newFilter.term = this.stripTerm(filter);
                }
                // newFilter.noTerm = filter.noTerm;

                if ( filter.matchMode ) {
                    newFilter.condition = filter.matchMode;
                } else {
                    newFilter.condition = this.guessCondition(filter);
                }

                // newFilter.flags = angular.extend( { caseSensitive: false, date: false }, filter.flags );
                newFilter.flags = { caseSensitive: false, date: false };

                if (newFilter.condition === opts.MatchMode.STARTS_WITH) {
                    newFilter.startswithRE = new RegExp('^' + newFilter.term, regexpFlags);
                }

                if (newFilter.condition === opts.MatchMode.ENDS_WITH) {
                    newFilter.endswithRE = new RegExp(newFilter.term + '$', regexpFlags);
                }

                if (newFilter.condition === opts.MatchMode.CONTAINS) {
                    newFilter.containsRE = new RegExp(newFilter.term, regexpFlags);
                }

                if (newFilter.condition === opts.MatchMode.EXACT) {
                    newFilter.exactRE = new RegExp('^' + newFilter.term + '$', regexpFlags);
                }

                result = newFilter;
            }
        // }
        return result;
    };

    private runColumnFilter(entity, column: opts.FilterEventCol, filter: Filter) {
        // Cache typeof condition
        const conditionType = typeof(filter.condition);

        // Term to search for.
        let term = filter.term;

        // Get the column value for this entity
        let value = entity[column.field];

        // If the filter's condition is a function, run it
        if (column.filterFunc) {
            return column.filterFunc(entity, column);
        }

        // If the filter's condition is a RegExp, then use it
        if (filter.condition instanceof RegExp) {
          const cond: RegExp = filter.condition as RegExp;
            return cond.test(value);
        }

        if (filter.startswithRE) {
            return filter.startswithRE.test(value);
        }

        if (filter.endswithRE) {
            return filter.endswithRE.test(value);
        }

        if (filter.containsRE) {
            return filter.containsRE.test(value);
        }

        if (filter.exactRE) {
            return filter.exactRE.test(value);
        }

        if (filter.condition === opts.MatchMode.NOT_EQUAL) {
            const regex = new RegExp('^' + term + '$');
            return !regex.exec(value);
        }

        if (typeof(value) === 'number' && typeof(term) === 'string' ) {
            // if the term has a decimal in it, it comes through as '9\.4', we need to take out the \
            // the same for negative numbers
            // TODO: check escapeRegExp at the top of this code file, maybe it's not needed?
            const tempFloat = parseFloat(term.replace(/\\\./, '.').replace(/\\\-/, '-'));
            if (!isNaN(tempFloat)) {
                term = tempFloat;
            }
        }

        if (filter.flags.date === true) {
            value = new Date(value);
            // If the term has a dash in it, it comes through as '\-' -- we need to take out the '\'.
            term = new Date(term.replace(/\\/g, ''));
        }

        if (filter.condition === opts.MatchMode.GREATER_THAN) {
            return (value > term);
        }

        if (filter.condition === opts.MatchMode.GREATER_THAN_OR_EQUAL) {
            return (value >= term);
        }

        if (filter.condition === opts.MatchMode.LESS_THAN) {
            return (value < term);
        }

        if (filter.condition === opts.MatchMode.LESS_THAN_OR_EQUAL) {
            return (value <= term);
        }

        return true;
    };

    private searchColumn(entity, column: opts.FilterEventCol, filter: Filter) {
        // const filtersLength = filters.length;
        // for (let i = 0; i < filtersLength; i++) {
            // const filter = filters[i];

            if ( !isNullOrUndefined(filter.term) && filter.term !== '' || filter.noTerm ) {
                const ret = this.runColumnFilter(entity, column, filter);
                if (!ret) {
                    return false;
                }
            }
        // }

        return true;
    };

    doFiltering(entities: Array<any>, filterCols: Array<opts.FilterEventCol>) {
        if (!entities) {
            return;
        }

        // Build list of filters to apply
        const filterDatas = new Array<FilterData>();
        let result = entities;

        const colsLength = filterCols.length;

        for (let i = 0; i < colsLength; i++) {
            const filterCol = filterCols[i];
            filterDatas.push( { col: filterCol, filter: this.setupFilters(filterCol) } );
        }

        if (filterDatas.length > 0) {
            // define functions outside the loop, performance optimisation
            // nested loop itself - foreachFilterCol, which in turn calls foreachRow

            const filterRow = (entity, col, filter) => {
                return this.searchColumn(entity, col, filter);
            };

            const foreachFilterCol = function(filterData: FilterData, items){
                const rowsLength = items.length;
                const filteredItems = [];
                for ( let i = 0; i < rowsLength; i++) {
                    if (filterRow(items[i], filterData.col, filterData.filter)) {
                        filteredItems.push(items[i]);
                    }
                }
                return filteredItems;
            };

            const filterDataLength = filterDatas.length;
            for ( let j = 0; j < filterDataLength; j++) {
                result = foreachFilterCol(filterDatas[j], result);
            }

            // drop any invisible rows
            // keeping these, as needed with filtering for trees
            // - we have to come back and make parent nodes visible if child nodes are selected in the filter
            // rows = rows.filter(function(row){ return row.visible; });
        }
        return result;
    };
}
