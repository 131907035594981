import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from './loading.component';
import { PhxTranslateModule } from '@phoenix/ui/translate';

export { LoadingComponent } from './loading.component';

@NgModule({
    declarations: [LoadingComponent],
    imports: [ CommonModule, PhxTranslateModule],
    exports: [LoadingComponent]
})
export class LoadingModule {}
