import {ComponentPortal, Portal, TemplatePortal} from '@angular/cdk/portal';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {takeUntil, tap} from 'rxjs/operators';
import {Effects, PhxUtil} from '@phoenix/ui/common';

import {asyncScheduler, Subject, Subscription} from 'rxjs';
import {ComponentBindings, TooltipContentType, TooltipPosition} from './tooltip.interface';

@Component({
  selector: 'phx-tooltip',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="px-tooltip" [ngClass]="getTooltipClass()" role="tooltip">
      <div class="px-arrow" #tooltipArrow></div>
      <button class="px-close px-btn px-btn-link" [attr.aria-label]="'phx.tooltip.close' | translate" type="button"
              *ngIf="showCloseButton" (click)="onclose($event)">
        <span aria-hidden="true">
          <i class="pxi px-times" aria-hidden="true"></i>
        </span>
      </button>
      <div class="px-tooltip-inner" #tooltipInner [id]="id" [style.maxWidth]="tooltipWidth"
           [style.minWidth]="tooltipWidth">
        <ng-template [cdkPortalOutlet]="selectedPortal" (attached)="onPortalAttached($event)"></ng-template>
      </div>
    </div>
  `,
  animations: [Effects.fadeInOut]
})
export class TooltipComponent implements OnDestroy, AfterContentInit, OnInit {
  id = '';
  selectedPortal: Portal<any>;

  private showSub: Subscription;
  private hideSub: Subscription;
  private destroy$ = new Subject<any>();

  @Input() content: TooltipContentType = '';
  @Input() componentBindings: ComponentBindings;
  @Input() tooltipPosition: TooltipPosition = 'top';
  @Input() showCloseButton = false;
  @Input() classes: string;
  @Input() tooltipWidth: string;

  @Output() oncanClose = new EventEmitter<any>();

  @ViewChild('tooltipInner', {static: true}) tooltipInnerRef: ElementRef;

  //  @HostBinding('class') classes = 'tooltip';
  @HostBinding('attr.x-placement') xPlacement = this.tooltipPosition;
  @HostBinding('attr.role') role = 'tooltip';
  @HostBinding('attr.aria-hidden') ariaHidden = false;
  @HostBinding('@fadeInOut') fadeInOut;

  @HostListener('click', ['$event']) onHostClick($event: Event) {
    $event.stopPropagation();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private hostRef: ElementRef,
    private renderer: Renderer2,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.classes) {
      this.renderer.addClass(this.hostRef.nativeElement, this.classes);
    }
  }

  ngAfterContentInit() {
    this.renderer.setStyle(this.hostRef.nativeElement.firstChild, 'position', 'relative');
    if (typeof this.content === 'string') {
      const textNode = document.createTextNode(`${this.content}`);
      this.tooltipInnerRef.nativeElement.appendChild(textNode);
    } else if (this.content instanceof TemplateRef) {
      this.selectedPortal = new TemplatePortal(this.content, this.viewContainerRef);
    } else {
      this.selectedPortal = new ComponentPortal(this.content);
    }
  }

  onPortalAttached($event: ComponentRef<any> | EmbeddedViewRef<any> | null) {
    if ($event instanceof ComponentRef && PhxUtil.isObject(this.componentBindings)) {
      const cmpInstance = $event.instance;
      if (this.componentBindings.inputs) {
        for (const params of this.componentBindings.inputs) {
          cmpInstance[params.key] = params.value;
        }
      }
      if (this.componentBindings.outputs) {
        for (const params of this.componentBindings.outputs) {
          cmpInstance[params.eventName]
            .pipe(
              tap(args => params.callback(args)),
              takeUntil(this.destroy$)
            )
            .subscribe();
        }
      }
    }
  }

  show(delay: number) {
    this.unsubscribe(this.showSub);
    this.showSub = asyncScheduler.schedule(
      () => {
        this.fadeInOut = 'Active';
      },
      delay > 200 ? delay - 200 : 0
    );
  }

  hide(delay: number) {
    this.unsubscribe(this.hideSub);
    this.hideSub = asyncScheduler.schedule(
      () => {
        this.fadeInOut = 'Inactive';
      },
      delay > 200 ? delay - 200 : 0
    );
  }

  onclose($event: Event) {
    $event.stopPropagation();
    this.oncanClose.next();
  }

  private unsubscribe(sub: Subscription) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.selectedPortal && this.selectedPortal.isAttached) {
      this.selectedPortal.detach();
    }
    this.unsubscribe(this.showSub);
    this.unsubscribe(this.hideSub);
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTooltipClass() {
    return `px-tooltip-${this.tooltipPosition}`;
  }
}
