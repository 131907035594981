import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { PhxUtil, Effects } from '@phoenix/ui/common';
import { Intro, Step } from './intro.interface';
import { IntroService } from './intro.service';
import { takeUntil } from 'rxjs/operators';

/**
 * IntroComponent
 */
@Component({
  selector: 'phx-tour',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./intro.component.scss'],
  animations: [Effects.fadeInOut],
  templateUrl: './intro.component.html',
  encapsulation: ViewEncapsulation.None
})
export class IntroComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('introClose') introClose: ElementRef;

  /**
   * Pass the Intro configuration with step definitions
   */
  @Input() config: Intro;

  /**
   * Display close icon @DefaultValue: true
   */
  @Input() canClose: boolean;

  /**
   * Display skip tour option @DefaultValue: true
   */
  @Input() canSkip: boolean;
  skipTour: boolean;

  @ViewChild('introTooltip') introTooltip: ElementRef;

  stepIndex: number;
  currentStep: Step;
  _subscriptions: Subscription[] = [];

  private destroy$ = new Subject<any>();

  constructor(public renderer: Renderer2, private changeDetector: ChangeDetectorRef, private $intro: IntroService, private componenetElementRef: ElementRef) { }

  ngOnInit() {
    this.canClose = true;
    this.canSkip = true;
    if (this.config && this.config.steps) {
      this.gotoStep(0, true);
    }
  }

  ngAfterViewInit(): void {
    this.setFocusToFirstElement();
    this.setFocusTrap();
  }

  private setFocusToFirstElement() {
    const focusable = this.componenetElementRef.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    focusable[0].focus();
  }

  private setFocusTrap() {
    this.addAriaHiddenToBody();

    // handle keyboard tabbing
    fromEvent(window, 'keyup')
      .pipe(takeUntil(this.destroy$))
      .subscribe(($event: KeyboardEvent) => {
        this.trapTabbing($event);
      });
  }

  private trapTabbing($event) {
    if (!this.componenetElementRef.nativeElement.contains($event.target) ) {
      const focusable = this.componenetElementRef.nativeElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      focusable[0].focus();
    }
  }

  private addAriaHiddenToBody() {
    const children = document.body.children;
    for(let i=0; i<children.length; i++) {
      const child = children[i];
      if (
        (child.nodeName !== 'PHX-BACKDROP') &&
        (child.nodeName !== 'SCRIPT') &&
        (child.nodeName !== 'PHX-TOUR')
      ) {
        child.setAttribute('aria-hidden', 'true');
      }
    }
  }

  private removeAriaHiddenFromBody() {
    const children = document.body.children;
    for(let i=0; i<children.length; i++) {
      children[i].removeAttribute('aria-hidden');
    }
  }

  next(stepNumber: number) {
    if (PhxUtil.isDefined(this.currentStep) && this.currentStep !== null) {
      this.$intro.resetTarget();
      this.clearSubscriptions();
    }

    this.stepIndex = stepNumber;

    this.stepIndex = Math.max(0, Math.min(this.stepIndex, this.config.steps.length - 1));
    this.currentStep = { ...this.config.steps[this.stepIndex] };

    setTimeout(() => {
      this.$intro.setTarget(this.currentStep, this.stepIndex);
    }, 0);
    this.changeDetector.markForCheck();
  }

  gotoStep(stepIndex: number, ignoreFocus?: boolean) {
    const tempStep = this.config.steps[stepIndex];
    if (this.currentStep && PhxUtil.isDefined(this.currentStep.onBeforeHide)) {
      this._subscriptions.push(
        this.currentStep.onBeforeHide.subscribe(
          () => { },
          () => { },
          () => {
            this.next(stepIndex);
          }
        )
      );
    }
    if (tempStep && PhxUtil.isDefined(tempStep.onBeforeShow)) {
      this._subscriptions.push(
        tempStep.onBeforeShow.subscribe(
          () => { },
          () => { },
          () => {
            this.next(stepIndex);
          }
        )
      );
    } else {
      this.next(stepIndex);
    }

    if (this.introClose && !ignoreFocus) {
      this.introClose.nativeElement.focus();
    }
  }

  skipTourChecked() {
    if (PhxUtil.isDefined(this.config.onSkip) && this.config.onSkip !== null) {
      this.config.onSkip(this.skipTour);
    }
  }

  skipToEnd() {
    this.skipTour = true;
    this.skipTourChecked();
    this.gotoStep(this.config.steps.length - 1);
    return false;
  }

  @HostListener('window:keyup.escape')
  closeIntro() {
    this.$intro.resetTarget();
    this.$intro.end();
    return false;
  }

  // @HostListener('window:keyup.arrowLeft')
  // arrowLeft() {
  //   this.gotoStep(this.stepIndex - 1);
  // }

  // @HostListener('window:keyup.arrowRight')
  // arrowRight() {
  //   this.gotoStep(this.stepIndex + 1);
  // }


  @HostListener('window:resize')
  resizeElements() {
    this.$intro.setTarget(this.currentStep, this.stepIndex);
  }

  clearSubscriptions() {
    this._subscriptions.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.removeAriaHiddenFromBody();
    this.clearSubscriptions();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
