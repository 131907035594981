import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BreadCrumbComponent } from './bread-crumb.component';
import { BreadCrumbItemComponent } from './breadcrumb.item.component';

export { BreadCrumbComponent } from './bread-crumb.component';
export { BreadCrumbItemComponent } from './breadcrumb.item.component';
import { PhxTranslateModule } from '@phoenix/ui/translate';
export * from './breadcrumb.interface';

@NgModule({
  declarations: [
    BreadCrumbComponent,
    BreadCrumbItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PhxTranslateModule
  ],
  exports: [
    BreadCrumbComponent,
    BreadCrumbItemComponent
  ]
})
export class BreadCrumbModule { }
