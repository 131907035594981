import { Injectable, Injector } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhxCsrfConfigService, CsrfConfig } from '../services/phx-csrf-config.service';

/**
 * PHOENIX CSRF Config Usage
 * - Static usage in index.html. Add following meta tags in header and replace content values by server
 ```html
 <!-- <meta name="csrf-header" content="____HEADE_NAME____"> -->
 <!-- <meta name="csrf-token" content="____TOKEN____"> -->
 ```
 * - Dynamic usage by `PhxCsrfConfigService`:   
 * Inject `PhxCsrfConfigService` in your code and set header(optional) and token value in runtime using `setCsrfConfigs(newConfig: PhxCsrfConfig) method`
 */

@Injectable()
export class CsrfTokenInterceptor implements HttpInterceptor {
    headerName = 'X-BFS-TOKEN';
    tokenValue: string;

    constructor(private meta: Meta, injector: Injector) {
        // Get header from meta tag or use default
        if (this.meta.getTag('name="csrf-headername"')) {
            this.headerName = this.meta.getTag('name="csrf-headername"').content;
        }

        // Get token from meta tag or ignore silently
        if (this.meta.getTag('name="csrf-token"')) {
            this.tokenValue = this.meta.getTag('name="csrf-token"').content;
        }

        const phxCsrfConfigService = injector.get(PhxCsrfConfigService);
        phxCsrfConfigService.csrfConfigs$.subscribe((csrfValues: CsrfConfig) => {
            this.headerName = csrfValues.header || this.headerName;
            this.tokenValue = csrfValues.token || this.tokenValue;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.tokenValue) {
            return next.handle(req.clone({
                headers: req.headers.set(this.headerName, this.tokenValue)
            }));
        } else {
            return next.handle(req);
        }
    }

}
