import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from './paginator.component';
import { PhxDropdownModule } from '@phoenix/ui/dropdown';
import { ButtonModule } from '@phoenix/ui/button';
import { PhxCommonModule } from '@phoenix/ui/common';

export { PaginatorComponent } from './paginator.component';
export * from './paginator.model';

@NgModule({
    imports: [CommonModule, PhxTranslateModule.forChild(), FormsModule, PhxDropdownModule, ButtonModule, PhxCommonModule],
  exports: [PaginatorComponent],
  declarations: [PaginatorComponent]
})
export class PaginatorModule {}
