import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhxCommonService } from '@phoenix/ui/common';

@Component({
  selector: 'phx-switch',
  templateUrl: './switch.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  checked = false;
  labelId: string;
  selectedLabel: string;

  public isDisabled = false;

  /**
   * id of the input element in this switch component
   */
  @Input() id = this.commonService.getRandID('Swh');

  /**
   * Label to show if the switch if off
   * Default is empty string.
   */
  @Input()
  labelOff = '';

  /**
   * Label to be show if the switch is on
   * Default is empty string.
   */
  @Input()
  labelOn = '';

  /**
   * Use this if you want to display icons within switch.
   * Default is false.
   */
  @Input()
  compact = false;

  /**
   * screenreader label text for the component. Note: one of ariaLabel, ariaLabelledBy must be provided.
   */
  @Input()
  ariaLabel: string;

  /**
   * id of element which labels this component for screenreader accessibility. Note: one of ariaLabel, ariaLabelledBy must be provided.
   */
  @Input()
  ariaLabelledBy: string;

  /**
   * used to indicate where the labels should be show. Default is right. 
   */
  @Input()
  labelPosition: 'left' | 'right' = 'right'

  onChange: any = (_: any) => { };
  onTouched: any = () => { };

  constructor(private commonService: PhxCommonService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.labelId = this.id + '-label';
  }

  // The checked flag is used to control the state of the switch component.
  writeValue(val: any) {
    if (val) {
      this.checked = true;
    } else {
      this.checked = false;
    }
    this.updateSelectedLabel();
    this.cd.detectChanges();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  // toggle the value of the checkbox,
  toggleValue() {
    if (!this.isDisabled) {
      this.checked = !this.checked;
      const val: string | boolean = this.checked;
      this.onChange(val);
      this.updateSelectedLabel();
    }
  }

  updateSelectedLabel() {
    if (this.checked) {
      this.selectedLabel = this.labelOn || '';
    } else {
      this.selectedLabel = this.labelOff || '';
    }
  }
}
