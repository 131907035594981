import {Directive, HostListener, Input} from '@angular/core';
import { DataTableComponent as Table} from "../datatable.component";


@Directive({
    selector: '[pRowToggler]'
})
export class RowToggler {

    @Input('pRowToggler') data: any;

    @Input() pRowTogglerDisabled: boolean;

    constructor(public dt: Table) { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (this.isEnabled()) {
            this.dt.toggleRow(this.data, event);
            this.dt.syncRowExpansion();
        }
        event.preventDefault();
    }

    isEnabled() {
        return this.pRowTogglerDisabled !== true;
    }
}
