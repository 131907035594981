/**
 * Forked implementation of CleaveJS (v1.5.3) with added and removed features, written in Typescript
 * Removed features: Time, Date, Credit Card, Built-in Phone Masks (Using custom mask for Phone)
 * Added features: Block-level pattern masks
 *
 * Custom additions to logic are commented with '@Addition'
 */
export class CleaveNumeralFormatter {

    static groupStyle = {
      thousand: 'thousand',
      lakh:     'lakh',
      wan:      'wan',
      none:     'none'
    };

    baseNumeralDecimalMark = '.';
    numeralDecimalMark;
    numeralIntegerScale;
    numeralDecimalScale;
    numeralThousandsGroupStyle;
    numeralPositiveOnly;
    stripLeadingZeroes;
    prefix;
    signBeforePrefix;
    delimiter;
    delimiterRE;

    constructor(numeralDecimalMark,
                numeralIntegerScale,
                numeralDecimalScale,
                numeralThousandsGroupStyle,
                numeralPositiveOnly,
                stripLeadingZeroes,
                prefix,
                signBeforePrefix,
                delimiter) {
        const owner = this;
        owner.numeralDecimalMark = numeralDecimalMark || '.';
        owner.numeralIntegerScale = numeralIntegerScale > 0 ? numeralIntegerScale : 0;
        owner.numeralDecimalScale = numeralDecimalScale >= 0 ? numeralDecimalScale : 2;
        owner.numeralThousandsGroupStyle = numeralThousandsGroupStyle || CleaveNumeralFormatter.groupStyle.thousand;
        owner.numeralPositiveOnly = !!numeralPositiveOnly;
        owner.stripLeadingZeroes = stripLeadingZeroes !== false;
        owner.prefix = (prefix || prefix === '') ? prefix : '';
        owner.signBeforePrefix = !!signBeforePrefix;
        owner.delimiter = (delimiter || delimiter === '') ? delimiter : ',';
        owner.delimiterRE = delimiter ? new RegExp('\\' + delimiter, 'g') : '';
    }

    getRawValue(value) {
          return value.replace(this.delimiterRE, '').replace(this.numeralDecimalMark, '.');
    }

    format(value) {
        const owner = this;
        let parts;
        let partSign;
        let partSignAndPrefix;
        let partInteger;
        let partDecimal = '';

        // strip alphabet letters
        value = value.replace(/[A-Za-z]/g, '')
            // replace the first decimal mark with reserved placeholder
            .replace(owner.baseNumeralDecimalMark, 'M')

            // strip non numeric letters except minus and "M"
            // this is to ensure prefix has been stripped
            .replace(/[^\dM-]/g, '')

            // replace the leading minus with reserved placeholder
            .replace(/^\-/, 'N')

            // strip the other minus sign (if present)
            .replace(/\-/g, '')

            // replace the minus sign (if present)
            .replace('N', owner.numeralPositiveOnly ? '' : '-')

            // replace decimal mark
            .replace('M', owner.numeralDecimalMark);

        // strip any leading zeros
        if (owner.stripLeadingZeroes) {
            value = value.replace(/^(-)?0+(?=\d)/, '$1');
        }

        partSign = value.slice(0, 1) === '-' ? '-' : '';
      // tslint:disable-next-line:triple-equals
        if (typeof owner.prefix != 'undefined') {
            if (owner.signBeforePrefix) {
                partSignAndPrefix = partSign + owner.prefix;
            } else {
                partSignAndPrefix = owner.prefix + partSign;
            }
        } else {
            partSignAndPrefix = partSign;
        }

        partInteger = value;

        if (value.indexOf(owner.numeralDecimalMark) >= 0) {
            parts = value.split(owner.numeralDecimalMark);
            partInteger = parts[0];
            partDecimal = owner.numeralDecimalMark + parts[1].slice(0, owner.numeralDecimalScale);
        }

        if (partSign === '-') {
            partInteger = partInteger.slice(1);
        }

        if (owner.numeralIntegerScale > 0) {
          partInteger = partInteger.slice(0, owner.numeralIntegerScale);
        }

        switch (owner.numeralThousandsGroupStyle) {
        case CleaveNumeralFormatter.groupStyle.lakh:
            partInteger = partInteger.replace(/(\d)(?=(\d\d)+\d$)/g, '$1' + owner.delimiter);

            break;

        case CleaveNumeralFormatter.groupStyle.wan:
            partInteger = partInteger.replace(/(\d)(?=(\d{4})+$)/g, '$1' + owner.delimiter);

            break;

        case CleaveNumeralFormatter.groupStyle.thousand:
            partInteger = partInteger.replace(/(\d)(?=(\d{3})+$)/g, '$1' + owner.delimiter);

            break;
        }

        return partSignAndPrefix + partInteger.toString() + (owner.numeralDecimalScale > 0 ? partDecimal.toString() : '');
    }
}
