import * as opts from '../../datatable.options';
import { DataSorter } from './datasorter';
import { DataFilter } from './datafilter';

export class DataManager {
    idField: string;
    grField: string;
    selField = 'slk';
    total: number;
    collapsedGroups: Map<string, boolean>;
    baseData: Array<any>;
    filteredData: Array<any>;
    dataSorter: DataSorter = new DataSorter();
    dataFilter: DataFilter = new DataFilter();

    constructor(data?: Array<any>) {
        this.setData(data);
    }

    setData(data: Array<any>) {
        data = data || [];
        this.baseData = this.filteredData = data;
        this.total = data.length;
        this.collapsedGroups = new Map<string, boolean>();
    }

    setRowIdField(id: string) {
        this.idField = id;
    }

    setGroupingField(groupField: string) {
        this.grField = groupField;
    }

    setSelectField(selectField: string) {
        this.selField = selectField || this.selField;
    }

    processEvents(events: Array<opts.BaseEvent>): Array<opts.BaseEvent> {
        for (let i = 0; i < events.length; i++) {
            const event = <opts.BaseEvent> events[i];

            switch (event.type) {
                case opts.ActionTypes.SORT:
                {
                    this.sort(event.info.sortCols);
                }
                    break;
                case opts.ActionTypes.LOAD_DATA:
                {
                    const evt = <opts.LoadDataEvent> event;
                    const items = this.fetchData(evt.info.rowsNumber, evt.info.firstIndex);
                    evt.result = {data: items};
                }
                    break;
                case opts.ActionTypes.FILTER:
                {
                    this.filter(event.info.filterCols);
                }
                    break;
                case opts.ActionTypes.SET_COUNTS:
                {
                    event.result = this.getSetCountResult();
                }
                    break;
                case opts.ActionTypes.SELECT_ALL_FILTERED:
                {
                    this.selectAllItems(event.info.isAll);
                }
                    break;
                case opts.ActionTypes.SELECT_ROW:
                {
                    this.selectItems(event.info);
                    /*if (!opts || !opts.ignoreSelect) {
                        this.selectItems(event.info);
                    }*/
                }
                    break;
                case opts.ActionTypes.SELECT_SINGLE_ROW:
                {
                    this.selectRadioItems(event.info);
                }
                    break;
                case opts.ActionTypes.SET_GROUP_INFO:
                {
                    event.result =  this.getGropingInfo();
                }
                    break;
                case opts.ActionTypes.GROUP_TOGGLE:
                {
                    this.toggleGroup(event.info);
                }
                    break;
                /*case esConstants.events.ROW_CONTEXT_MENU:
                {
                    $timeout(function() {
                        alert(event.info.row.id + '' row is clicked'');
                    }, 10, false);
                }
                    break;*/
                default:
                    break;
            }
        }
        return events;
    }

    fetchData(size: number, index?: number) {
        const _index = index || 0;
        const items = this.getFilteredGroupedData();
        if (index + size > items.length) {
            size = items.length - index;
        }
        const _size = Math.min(size, items.length);
        const result = new Array<any>(_size);
        for (let i = 0; i < _size; i++) {
            const ind = i + _index;
            result[i] = Object.assign({}, items[ind]); // Data from server - new object.
        }
        return result;
    };

    sort(sortCols: Array<opts.SortEventCol>) {
        this.filteredData = this.dataSorter.sort(this.filteredData, sortCols);
        this.baseData = this.dataSorter.sort(this.baseData, sortCols);
    }

    filter(filterCols: Array<opts.FilterEventCol>) {
        this.filteredData = this.dataFilter.doFiltering(this.baseData, filterCols);
    }

    selectItems(info) {
        this.filteredData.forEach(item => {
            const val = info.ids[item[this.idField]];
            if (val) {
                item[this.selField] = val === 'y';
            }
        });
    };

    selectAllItems (isAll) {
        this.filteredData.forEach(item => {
            item[this.selField] = isAll;
        });
    };

    getSetCountResult(): {filteredRowNumber; filteredSelectedCount; filteredSelectableRowNumber?; } {
        return {
            filteredRowNumber : this.getFilteredGroupedData().length, // TODO exclude grouping.
            filteredSelectedCount : this.getSelectedCount(this.getFilteredGroupedData())
        };
    }

    selectRadioItems (info) {
        this.baseData.forEach(item => {
            item[this.selField] = info.id === item[this.idField];
        });
    };


    getFilteredGroupedData(): Array<any> {
        const result = [];
        this.filteredData.forEach((item) => {
            if (!this.collapsedGroups.get(item[this.grField])) {
                result.push(item);
            }
        });
        return result;
    };

    getSelectedCount(items) {
        let count = 0;
        for (let i = 0; i < items.length; i++) {
            count += items[i][this.selField] ? 1 : 0;
        }
        return count;
    }

    getGropingInfo(): opts.GroupResultInfo {
        const groupInfo: opts.GroupResultInfo = { groups: []};
        const grField = this.grField;
        const items = this.filteredData;
        let itemIndex = 0;
        let count = 0;
        let curVal = null;

        this.baseData.forEach(item => {
            if (curVal !== item[grField]) {
                curVal = item[grField];

                count = 0;
                while (itemIndex < items.length && items[itemIndex][grField] === curVal) {
                    count++;
                    itemIndex++;
                }

                const newGroup: opts.GroupInfo = {
                    value: curVal,
                    title: 'Title_' + curVal,
                    count: count,
                    some_extra: item.country
                };
                if (this.collapsedGroups.get(curVal)) {
                    newGroup.collapsed = true;
                }
                groupInfo.groups.push(newGroup);
            }
        });
        return groupInfo;
    }

    toggleGroup(info: opts.GroupInfo) {
        this.collapsedGroups.set(info.value, info.collapsed);
    };

    eachFiltered(callback) {
        this.filteredData.forEach(item => {
            callback(item);
        });
    };

    resetSelection() {
        this.baseData.forEach(item => {
            delete item[this.selField];
        });
    }
}
