import { Component, Input, HostBinding, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'phx-announcer',
  template: `
    <div [hidden]="!announceMessage" (cdkObserveContent)="contentChanged()">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AnnouncerComponent implements OnChanges {

  /**
   * aria-live level for the message.
   * Default is polite
   */
  @Input() ariaLive="polite";

  /**
   * milliseconds the message will be present for screen readers to read. 
   * Default is 1000 ms . If the message is not being read properly try increasing the value. 
   */
  @Input() dismissTime=1000;

  /**
   * The content will be announced on the toggle of this value from false to true. 
   * The content will also be announced if it changes. 
   * Default is  false. 
   */
  @Input() announceMessage=false;

  /**
   * To provide two way binding for announceMessage.
   */
  @Output() announceMessageChange = new EventEmitter<boolean>();

  @HostBinding('attr.aria-live') hostAriaLive = this.ariaLive;

  @HostBinding('attr.aria-atomic') hostAriaAtomic = true;

  @HostBinding('class.px-sr-only') hostClass = true;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.announceMessage && changes.announceMessage.currentValue) {
      this.startCountDown();
    }
  }

  contentChanged() {
    this.announceMessage = true;
    this.cdr.detectChanges();
    this.startCountDown();
  }

  private startCountDown() {
    setTimeout(() => {
      this.announceMessage = false;
      this.announceMessageChange.emit(false);
      this.cdr.detectChanges();
    }, this.dismissTime);
  }
}
