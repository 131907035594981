import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {PaginatorModule} from '@phoenix/ui/paginator';
import {AnnouncerModule} from '@phoenix/ui/announcer';
import {PhxDropdownModule} from '@phoenix/ui/dropdown';
import {CheckboxModule} from '@phoenix/ui/checkbox';
import {PhxTranslateModule} from '@phoenix/ui/translate';
import { InputModule } from '@phoenix/ui/input';
import {TooltipModule} from '@phoenix/ui/tooltip';
import {DataTableComponent} from './datatable.component';
import {TableModule} from './base/table.module';
import {PhxTemplateDirective} from './base-isc/template.component';
import {RowCheckbox} from './base-isc/rowcheckbox.component';
import {RowAllCheckbox} from './base-isc/rowallcheckbox.component';
import {RowRadioComponent} from './base-isc/rowradio.component';
import {ButtonModule} from '@phoenix/ui/button';

export {DataTableComponent} from './datatable.component';
export * from './datatable.options';
export * from './base-isc/data-service/datasorter';
export * from './base-isc/data-service/datafilter';
export * from './base-isc/datatable.model';


@NgModule({
  imports: [FormsModule, CommonModule, TableModule, PhxDropdownModule, PaginatorModule, CheckboxModule,
    PhxTranslateModule.forChild(), AnnouncerModule, InputModule, TooltipModule, ButtonModule],
  exports: [DataTableComponent, TableModule, PhxTemplateDirective],
  declarations: [DataTableComponent, PhxTemplateDirective, RowCheckbox, RowAllCheckbox, RowRadioComponent]
})
export class DataTableModule {
}
