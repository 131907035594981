import { AfterViewInit, ElementRef, Renderer2, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { SidebarServiceService } from './sidebar-service.service';
import { SidebarOptions } from './sidebar-options.interface';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'phx-sidebar-base',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div #firstElement [attr.tabindex]="opened ? '0' : null" (focus)="handleFirstFocus($event)" class="px-sidebar-fc"
          aria-hidden="true"></div>
        <div [attr.tabindex]="opened ? '0' : null" (focus)="handleFirstFocus($event)" class="px-sidebar-fc"
          aria-hidden="true"></div>
        <ng-content></ng-content>
        <div [attr.tabindex]="opened ? '0' : null" (focus)="handleLastFocus($event)" class="px-sidebar-fc"
          aria-hidden="true"></div>  
        <div #lastElement [attr.tabindex]="opened ? '0' : null" (focus)="handleLastFocus($event)" class="px-sidebar-fc"
          aria-hidden="true"></div>    
    `,
    host: {
        'style.height': '100%'
    }
})
export class SidebarBaseComponent implements AfterViewInit, OnDestroy {
    @ViewChild('firstElement') firstElementRef: ElementRef;
    @ViewChild('lastElement') lastElementRef: ElementRef;

    public opened = false;

    private keepFocus = false;
    private beforeEl: any;
    private afterEl: any;
    private readonly FOCUS_CLASS = 'px-sidebar-fc';
    private destroy$ = new Subject<any>();

    constructor(
        private hostRef: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private sidebarService: SidebarServiceService
    ) {}

    ngAfterViewInit() {
      this.sidebarService.openChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({open, options}) => {
        if (options && options.hasBackdrop) {
          this.opened = open;
          this.cdr.detectChanges();
          this.hideBase();
        }
      });
    }

    get baseElement(): HTMLElement {
        return this.hostRef.nativeElement;
    }

    setStyle(key: string, value: string) {
        this.renderer.setStyle(this.baseElement, key, value);
    }

    hideBase() {
      if (this.opened) {
        this.baseElement.setAttribute('aria-hidden', 'true');
        this.baseElement.style.pointerEvents = 'none';
      } else {
        this.baseElement.removeAttribute('aria-hidden');
        this.baseElement.style.pointerEvents = 'auto';
      }
    }

    handleFirstFocus($event: any) {
      if (!this.beforeEl && $event.relatedTarget && this.FOCUS_CLASS != $event.relatedTarget.className) {
        this.beforeEl = $event.relatedTarget;
      }
      this.handleFocus($event, this.afterEl, this.lastElementRef.nativeElement);
    }

    handleLastFocus($event: any) {
      if (!this.afterEl && $event.relatedTarget && this.FOCUS_CLASS != $event.relatedTarget.className) {
        this.afterEl = $event.relatedTarget;
      }
      this.handleFocus($event, this.beforeEl, this.firstElementRef.nativeElement);
    }

    handleFocus(evt: any, externalFocusEl: any,  sidebarFocusEl: any) {
      if (this.keepFocus) {
        this.keepFocus = false;
        return;
      }
      if (!externalFocusEl) {
        this.keepFocus = true;
        sidebarFocusEl.focus();
      } else {
        externalFocusEl.focus();
      }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
