import {Component, Input, TemplateRef, OnInit} from '@angular/core';
import { DataTableComponent } from "../datatable.component";

@Component({
    selector: '[pTableBody]',
    template: `
        <ng-container *ngIf="!dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowEven: !!((dt.first + rowIndex)%2), rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, editing: (dt.editMode === 'row' && dt.isRowEditing(rowData))}"></ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowEven: !!((dt.first + rowIndex)%2), rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, expanded: dt.isRowExpanded(rowData), editing: (dt.editMode === 'row' && dt.isRowEditing(rowData)), expandable: dt.expandable}"></ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                  <tr [ngClass]="dt.options.smartView.stripeExpandedRows ? 'px-tbl-rw-ex-str' : 'px-tbl-rw-ex'">
                    <td [attr.colspan]="extraColsCount"></td>
                    <td [attr.colspan]="columns.length">
                    <ng-container *ngTemplateOutlet="dt.expandedRowTemplate; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
                    </td>
                  </tr>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.isEmpty() && !(dt.loading && dt.showLoader)">
            <tr class="px-tbl-rw-empty" *ngIf="!dt.options.smartView.hideAlertMessage">
                <td role="alert" [attr.colspan]="columns.length + extraColsCount">
                  <phx-message type={{dt.getAlertType()}}>
                    {{dt.getAlertMessage()}}
                  </phx-message>                  
                </td>
            </tr>
        </ng-container>
    `
})
export class TableBody implements OnInit {

    @Input("pTableBody") columns: any[];

    @Input("pTableBodyTemplate") template: TemplateRef<any>;

    @Input() frozen: boolean;

    // Bofa. Extra columns number which have to be included to col span. E.g. when we have selection, expandable feature.
    extraColsCount = 0;

    constructor(public dt: DataTableComponent) { }

    ngOnInit() {
      this.extraColsCount = this.dt.extraColCount;
    }
}
