import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
    selector: 'img[src]'
})
export class ImgDirective {
    @HostBinding('class.px-img-fluid') responsiveClass = true;
    @HostBinding('class.px-d-none') displayNoneClass = false;

    public element: HTMLImageElement;

    constructor(elementRef: ElementRef) {
        this.element = elementRef.nativeElement;
    }

    remove() {
        this.element.parentNode.removeChild(this.element);
    }
}
