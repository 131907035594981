export class PhxUtil {
    /**
     * Determines if a reference is defined
     * @param value
     * @returns boolean True if `value` is a `defined`.
     */
    public static isDefined(value: any): boolean {
        return typeof value !== 'undefined';
    }

    /**
     * Determines if a reference is undefined.
     * @param value
     * @returns boolean True if `value` is a `undefined`.
     */
    public static isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }

    /**
     * Determines if a reference is boolean.
     * @param value
     * @returns boolean True if `value` is a `boolean`.
     */
    public static isBoolean(value: any): boolean {
        return typeof value === 'boolean';
    }

    /**
     * Determines if a reference is a `String`.
     * @param value
     * @returns boolean True if `value` is a `String`.
     */
    public static isString(value) {
        return typeof value === 'string';
    }

    /**
     * Determines if a reference is a `Number`
     * @param any value Reference to check.
     * @returns boolean True if `value` is a `Number`.
     */
    public static isNumber(value) {
        return typeof value === 'number';
    }

    /**
     * Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
     * considered to be objects
     * @param value
     * @returns boolean True if `value` is a `Object`.
     */
    public static isObject(value) {
        return value !== null && typeof value === 'object';
    }

    /**
     * Determines if a reference is not empty object.
     * @param value
     */
    public static isNotEmptyObject(value): boolean {
        if (this.isObject(value)) {
            return Object.keys(value).length > 0;
        } else {
            throw new Error(`${value} is not an Object!!!`);
        }
    }

    /**
     * Determines if a reference is an `Array`.
     * @param value
     * @returns boolean True if `value` is a `Array`.
     */
    public static isArray(value): boolean {
        return Array.isArray(value) || value instanceof Array;
    }

    /**
     * Determines if a reference is a `Date`.
     * @param value
     * @returns boolean True if `value` is a `Date`.
     */
    public static isDate(value): boolean {
        const _toString = ({}).toString;
        return _toString.call(value) === '[object Date]';
    }
}
