import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultTranslations } from './default-translations';

@Injectable({
  providedIn: 'root'
})
export class TranslateInitService {

  constructor(private translateService: TranslateService) {
    // custom phx language key used as default language, ngx-translate will employ it as a fallback for missing translations
    this.translateService.setTranslation('phx_en', defaultTranslations);
    this.translateService.setDefaultLang('phx_en');
  }
}
