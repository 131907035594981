import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'searchByPipe' })
export class SearchByPipe implements PipeTransform {
    transform(inputArray: Array<any>, objKey: string, searchValue: string): Array<any> {
        if (!searchValue || !inputArray) {
            return inputArray;
        }
        const queryText = searchValue.toLowerCase();
        return inputArray.filter((item) => {
            let containText;
            if (typeof item === 'string') {
                containText = item.toLowerCase();
            } else {
                containText = item[objKey] ? item[objKey].toLowerCase() : '';
            }
            return containText.indexOf(queryText) !== -1;
        });
    }
}
