import {AfterContentInit, Component, ContentChild, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DatepickerCalendarComponent} from './datepicker-calendar.component';
import {DatepickerInputDirective} from './datepicker-input.directive';
import {DatepickerOptions} from './datepicker-options.interface';
import {PhxCommonService} from '@phoenix/ui/common';
import {DateUtilityService} from './date-utility.service';
import {DatepickerService} from './datepicker.service';

// This component is just a wrapper for input + datepicker-calendar so that usage is more convenient
// As far as overall datepicker design is concerned, this was added in alpha-2, so to minimize changes it contains minimal functionality
@Component({
  selector: 'phx-datepicker',
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [DateUtilityService, DatepickerService]
})
export class DatepickerComponent implements OnInit, AfterContentInit {
  private _datePickerCalendar: DatepickerCalendarComponent;

  @ContentChild(DatepickerInputDirective) datePickerInput: DatepickerInputDirective;

  @ViewChild(DatepickerCalendarComponent)
  set datePickerCalendar(datePickerCalendar: DatepickerCalendarComponent) {
    this._datePickerCalendar = datePickerCalendar;
    this.datePickerInput.phxDatepicker = datePickerCalendar;
    this.datePickerInput.inline = this.inline;
  }

  /**
   * options object to configure the datepicker, see DatepickerOptions interface for defaults
   * @param options options object of type DatepickerOptions
   */
  @Input() options: DatepickerOptions;

  /** @deprecated Use 'id' attribute.
   * Id for calendar card popup, @DefaultValue: a random id
   */
  @Input() calendarId: string;

  /**
   * Id to use as a base for child html elements a
   */
  @Input() id: string;

  /**
   * id of the element which labels this datepicker.
   */
  @Input() ariaLabelledBy;

  @Input() inline: boolean;

  calId: string;

  constructor(private phxCommonService: PhxCommonService) {
  }

  /**
   * Close the datepicker calendar, if opened
   */
  close() {
    if (this._datePickerCalendar) {
      this._datePickerCalendar.close();
    }
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    const inpId = this.datePickerInput ? this.datePickerInput.id : null;
    this.calId = this.calendarId || `pxCal-${this.id || inpId || this.phxCommonService.uuid()}`;
  }
}
