import {Directive, HostListener} from '@angular/core';
import { DataTableComponent as Table} from "../datatable.component";
import {EditableRow} from "./editablerow.directive";



@Directive({
    selector: '[pInitEditableRow]'
})
export class InitEditableRow {

    constructor(public dt: Table, public editableRow: EditableRow) {}

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        this.dt.initRowEdit(this.editableRow.data);
        event.preventDefault();
    }

}
