import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { PhxCommonModule } from '@phoenix/ui/common';
import { AnnouncerComponent } from './announcer.component';

@NgModule({
  declarations: [AnnouncerComponent],
  imports: [
    CommonModule,
    ObserversModule,
    PhxCommonModule,
  ],
  exports: [
    AnnouncerComponent
  ]
})
export class AnnouncerModule { }
