import {NgModule} from '@angular/core';
import {PhxCspStylesHost} from './phx.csp.styles.host';
import {ɵDomSharedStylesHost} from '@angular/platform-browser';

@NgModule({
  providers: [
    {provide: 'phxCspMetaSelector', useValue: 'meta[name="PHX-CSP-NONCE"]'},
    {provide: 'phxCspStore', useValue: 'PHX_CSP_NONCE'},
    {provide: ɵDomSharedStylesHost, useClass: PhxCspStylesHost}
  ],
})
export class PhxCspInlineStylesModule {
}
