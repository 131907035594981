import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Output,
  ViewEncapsulation
} from '@angular/core';

// TODO: replace <a> with <button type="button"> for ADA
/**
 * Simple component for the datepicker toggle button
 * Created separately for use as a dynamic component
 */
@Component({
  selector: 'phx-datepicker-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="px-input-group-append px-icon-addon">
      <button type="button"
              [attr.aria-label]="'phx.datepicker.toggleButton.ariaLabel' | translate"
              [disabled]="disabled"
              (click)="handleClick($event)">
        <i class="pxi px-calendar-alt"></i>
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class DatepickerButtonComponent {
  disabled = false;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.px-icon-component') iconWrapperClass = true;

  constructor(private cdr: ChangeDetectorRef) {
  }

  handleClick(event) {
    if (!this.disabled) {
      this.clickEmitter.emit(event);
    }
  }

  refresh() {
    this.cdr.markForCheck();
  }
}
