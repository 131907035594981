import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toBytes'
})

export class ScalarsPipe implements PipeTransform {
    transform(input: string): Number {
        if (typeof input !== 'string') {
            return input;
        }
        const inputLength = input.length;
        const scalarIndex = inputLength - 2;

        if (input.search(/kb|mb|gb/i) === scalarIndex) {
            const scalar = Number(input.substring(0, scalarIndex));
            const byteUnit = input.substring(scalarIndex).toLowerCase();
            return scalar * ByteUnitsEnum[byteUnit];
        }
        if (input.search(/b/i) === inputLength - 1) {
            return Number(input.substring(0, inputLength - 1));
        }
    }

}

enum ByteUnitsEnum {
    kb = 1024,
    mb = 1048576,
    gb = 1073741824
}
