/**
 * Forked implementation of CleaveJS (v1.5.3) with added and removed features, written in Typescript
 * Removed features: Time, Date, Credit Card, Built-in Phone Masks (Using custom mask for Phone)
 * Added features: Block-level pattern masks
 *
 * Custom additions to logic are commented with '@Addition'
 */

// Numeral Options
export type NumeralThousandsGroupStyleType = 'lakh' | 'thousand' | 'wan' | 'none';

export interface CleaveNumeralOptions extends CleaveOptions {
    numeral?: boolean;
    numeralDecimalMark?: string;
    numeralDecimalScale?: number;
    numeralIntegerScale?: number;
    numeralPositiveOnly?: boolean;
    numeralThousandsGroupStyle?: NumeralThousandsGroupStyleType;
    stripLeadingZeroes?: boolean;
}

// Extra Options
export interface CleaveOptions {
    blocks?: ReadonlyArray<number>;
    copyDelimiter?: boolean;
    delimiter?: string;
    delimiters?: ReadonlyArray<string>;
    initValue?: any;
    lowercase?: boolean;
    numericOnly?: boolean;
    prefix?: string;
    noImmediatePrefix?: boolean;
    rawValueTrimPrefix?: boolean;
    uppercase?: boolean;
    delimiterLazyShow?: boolean;
    maskPattern?: string; // @Addition
    guidingPlaceholder?: boolean // @Addition
    onValueChanged?(event: any): void;
}

// @Addition - mappings for maskPattern regex
export enum CharType {
    ALPHABET = 'alphabet',
    ALPHANUMERIC = 'alphanumeric',
    NUMERIC = 'numeric'
}

export const charTypeMapping = {
    9: CharType.NUMERIC,
    A: CharType.ALPHABET,
    '*': CharType.ALPHANUMERIC
};

