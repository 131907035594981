import {Directive, HostListener} from '@angular/core';
import { DataTableComponent as Table} from "../datatable.component";
import {EditableRow} from "./editablerow.directive";

@Directive({
    selector: '[pCancelEditableRow]'
})
export class CancelEditableRow {

    constructor(public dt: Table, public editableRow: EditableRow) {}

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        this.dt.cancelRowEdit(this.editableRow.data);
        event.preventDefault();
    }
}
