import { ModalOptions } from '@phoenix/ui/modal';
export interface IDefaultLables {
    viewLabel?: string ;
    frontLabel?: string;
    backLabel?: string;
    allLabel?: string;
}
export interface ImageViewerOptions {
    defaultLabels?: IDefaultLables;
    mobile?: {
        useDefault?: boolean;
        modalOptions?: ModalOptions;
    };
}

export const DEFAULT_OPTIONS = {
    defaultLabels: {
        viewLabel: 'View',
        frontLabel: 'Front',
        backLabel: 'Back',
        allLabel: 'Both'
    },

    mobile: {
        useDefault: true,
        modalOptions: {
            title: ' ',
            content: '',
            size: 'md',
            hasBackdrop: true,
            draggable: false
        }
    }
};
