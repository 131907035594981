import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { IFieldMessage } from './field-message.interface';

@Component({
  selector: 'phx-field-message',
  templateUrl: './field-message.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FieldMessageComponent implements OnInit, OnDestroy {
  activeMessage: string;
  type: string;
  stateSubject: ReplaySubject<string | boolean> = new ReplaySubject(1);

  private _state: string | boolean;
  private stateSubscription: Subscription;

  /**
   * active state of the FieldMessage that maps to one of the provided IFieldMessages.
   * @param state user defined state value
   */
  @Input()
  set state(state: string | boolean) {
    this._state = state;
    this.stateSubject.next(state);
  }

  /**
   * Array of user defined IFieldMessages
   */
  @Input() messages: IFieldMessage[] = [];

  @HostBinding('attr.aria-live') ariaLive = 'assertive';
  @HostBinding('attr.aria-atomic') ariaAtomic = true;

  constructor() { }

  ngOnInit() {
    this.stateSubscription = this.stateSubject.subscribe(state => {
      const matchedMsg = this.messages.find(msg => msg.state === this._state);
      if (matchedMsg) {
        this.activeMessage = matchedMsg.message;
        this.type = matchedMsg.type;
      } else {
        this.activeMessage = null;
      }
    });
  }

  ngOnDestroy() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

}
