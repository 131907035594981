import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy, OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  CdkOverlayOrigin,
  ConnectedPosition,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import {fromEvent, Subscription} from 'rxjs';
import {filter, skip} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {FocusTrap, FocusTrapFactory} from '@angular/cdk/a11y';
import {FocusTrapService} from '@phoenix/ui/common';

export declare type PopupPosition = 'left' | 'top' | 'right' | 'bottom';

@Component({
  selector: 'phx-popup, [phx-popup]',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('overlayRef') overlayRef: OverlayRef;
  @ViewChild('contentRef') contentRef: ElementRef;

  /**
   * TemplateRef of the content to appear in the popup
   */
  @Input('phx-popup') content: TemplateRef<any>;

  /**
   * Optional. If we need to specify the position for the popup.
   * Type: PopupPosition: 'left' | 'top' | 'right' | 'bottom'
   */
  @Input() popupPosition: PopupPosition = 'bottom';

  /**
   * Optional. If the templateRef needs to be populate by any data. Use this input to pass it in.
   * Type: {}
   */
  @Input() popupData = {};

  /**
   * Optional. If provided will the add the class name to the popup.
   */
  @Input() popupClass: string;

  /**
   * Optional. If popup closes on scroll.
   * Default: true
   */
  @Input() closeOnScroll = true;

  /**
   * Optional. If true it will close popup on clicking outside.
   * Type: boolean
   * Default: true
   */
  closeOnOutsideClick = true;

  /**
   * Optional. Indicates if the popup needs a transparent backdrop.
   * Eg: for dropdown within popup.
   * Type: boolean
   * Default: false
   */
  @Input() hasBackdrop = false;

  /**
   * Required. Indicates if the popup needs to be open or close.
   * Typle: boolean
   * Default: false
   */
  @Input() popupOpen = false;

  /**
   * Output for the open input.
   * Type: boolean
   */
  @Output() popupOpenChange = new EventEmitter<boolean>();

  public triggerRef: CdkOverlayOrigin;
  public isViewInitialized = false;

  private subscriptions: Subscription;
  private focusTrap: FocusTrap;
  private elementFocusedBeforePopupOpened: HTMLElement | null = null;
  scrollStrategy: RepositionScrollStrategy;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private focusTrapFactory: FocusTrapFactory,
    private focusTrapService: FocusTrapService,
    private scrollStrategyOptions: ScrollStrategyOptions,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  ngOnInit(): void {
    this.scrollStrategy = this.scrollStrategyOptions.reposition();
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.triggerRef = new CdkOverlayOrigin(this.el);
  }

  private clickHandler($event: any) {
    if (
      !this.el?.nativeElement.contains($event.target) &&
      !this.contentRef?.nativeElement.contains($event.target) &&
      this.closeOnOutsideClick
    ) {
      this.closePopup();
    }
  }

  private addSubscriptions() {
    this.subscriptions = new Subscription();

    if (this.closeOnScroll) {
      const scrollSub = fromEvent(window, 'scroll', true as any)
        .pipe(
          filter(($event: any) => $event.srcElement !== document),
          skip(1)
        ).subscribe(() => {
          this.closePopup();
        });
      this.subscriptions.add(scrollSub);
    }

    if (!this.hasBackdrop) {
      const clickSub = fromEvent(window, 'click')
      .pipe(skip(1))
      .subscribe(($event: any) => this.clickHandler($event));
      this.subscriptions.add(clickSub);
    }
  }

  private savePreviouslyFocusedElement() {
    if (this.document) {
      this.elementFocusedBeforePopupOpened = this.document.activeElement as HTMLElement;
    }
  }

  private setFocus() {
    this.savePreviouslyFocusedElement();
    setTimeout(() => {
      if (!this.focusTrap) {
        this.focusTrap = this.focusTrapFactory.create(this.contentRef.nativeElement);
        this.focusTrap.focusFirstTabbableElementWhenReady();
      }
    });
  }

  public handleAttach() {
    this.addSubscriptions();
    this.setFocus();
    this.focusTrapService.toggleCDKOverlayFocusTrap(true);
  }

  private removeSubscriptions() {
    if (this.subscriptions && this.subscriptions.unsubscribe) {
      this.subscriptions.unsubscribe();
    }
  }

  private removeFocusTrap() {
    if (this.focusTrap) {
      this.focusTrap.destroy();
      this.focusTrap = null;
    }
  }

  private restoreFocus() {
    const prevFocusedElem = this.elementFocusedBeforePopupOpened;
    if (prevFocusedElem && typeof prevFocusedElem.focus === 'function') {
      prevFocusedElem.focus();
      this.elementFocusedBeforePopupOpened = null;
    }
  }

  private closePopup() {
    if (this.popupOpen) {
      this.popupOpen = false;
      this.popupOpenChange.emit(this.popupOpen);
    }
    this.removeSubscriptions();
    this.removeFocusTrap();
    this.focusTrapService.toggleCDKOverlayFocusTrap(false);
    this.cdr.markForCheck();
  }

  get connectedPositions(): ConnectedPosition[] {
    let order = ['top', 'bottom', 'left', 'right'];
    const index = order.indexOf(this.popupPosition);
    if (index > 0) {
      order.splice(index, 1);
      order = [this.popupPosition, ...order];
    }

    return order.map(i => {
      if (i === 'right') {
        return this.generateConnectedPosition('end', 'top', 'start', 'top', 0, 0, 'right');
      } else if (i === 'bottom') {
        return this.generateConnectedPosition('start', 'bottom', 'start', 'top', 0, 0, 'bottom');
      } else if (i === 'top') {
        return this.generateConnectedPosition('start', 'top', 'start', 'bottom', 0, 0, 'top');
      } else if (i === 'left') {
        return this.generateConnectedPosition('start', 'top', 'end', 'top', 0, 0, 'left');
      }
    });
  }

  private generateConnectedPosition(
    originX: any,
    originY: any,
    overlayX: any,
    overlayY: any,
    offsetX: number,
    offsetY: number,
    panelClass?: string | string[]
  ): ConnectedPosition {
    return {originX, originY, overlayX, overlayY, offsetX, offsetY, panelClass};
  }

  public handleDetach() {
    if (this.popupOpen) {
      this.closePopup();
      this.restoreFocus();
    }
  }

  public backdropClicked() {
    if (this.closeOnOutsideClick) {
      this.closePopup();
    }
  }

  ngOnDestroy() {
    this.closePopup();
  }

}
