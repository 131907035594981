import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhxCommonModule } from '@phoenix/ui/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { FieldMessageModule } from '@phoenix/ui/field-message';
import { FileDragDropComponent } from './file-drag-drop.component';

export { FileDragDropComponent } from './file-drag-drop.component';
export { FileDragDropResponse } from './file-drag-drop-response.class';
export { FileDragDropService } from './file-drag-drop.service';
export * from './file-drag-drop.interface';

@NgModule({
  declarations: [
    FileDragDropComponent
  ],
  imports: [
    CommonModule,
    PhxCommonModule,
    PhxTranslateModule.forChild(),
    FieldMessageModule
  ],
  exports: [
    FileDragDropComponent
  ]
})
export class FileDragDropModule { }
