import * as opts from '../../datatable.options';


export interface Sorter {
    sort: (a, b) => any;
}

export class SorterBasic implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            if (a === b) {
                return 0;
            }
            if (a < b) {
                return -1;
            }
            return 1;
        }
    }
}

export class SorterString implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            const strA = a.toString().toLowerCase(),
                strB = b.toString().toLowerCase();

            return strA === strB ? 0 : strA.localeCompare(strB);
        }
    }
}

export class SorterNumber implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            return a - b;
        }
    }
}

export class SorterNumberString implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            let numA, // The parsed number form of 'a'
                numB, // The parsed number form of 'b'
                badA = false,
                badB = false;

            // Try to parse 'a' to a float
            numA = parseFloat(a.replace(/[^0-9.-]/g, ''));

            // If 'a' couldn't be parsed to float, flag it as bad
            if (isNaN(numA)) {
                badA = true;
            }

            // Try to parse 'b' to a float
            numB = parseFloat(b.replace(/[^0-9.-]/g, ''));

            // If 'b' couldn't be parsed to float, flag it as bad
            if (isNaN(numB)) {
                badB = true;
            }

            // We want bad ones to get pushed to the bottom... which effectively is "greater than"
            if (badA && badB) {
                return 0;
            }

            if (badA) {
                return 1;
            }

            if (badB) {
                return -1;
            }

            return numA - numB;
        }
    }
}

export class SorterDate implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            if (!(a instanceof Date)) {
                a = new Date(a);
            }
            if (!(b instanceof Date)) {
                b = new Date(b);
            }
            const timeA = a.getTime(),
                timeB = b.getTime();

            return timeA === timeB ? 0 : (timeA < timeB ? -1 : 1);
        }
    }
}

export class SorterBoolean implements Sorter {
    sort(a, b) {
        const nulls = SortUtil.handleNulls(a, b);
        if (nulls !== null) {
            return nulls;
        } else {
            if (a && b) {
                return 0;
            }

            if (!a && !b) {
                return 0;
            } else {
                return a ? 1 : -1;
            }
        }
    }
}

export class DataSorter {

    static sorterNumber = new SorterNumber();
    static sorterNumberString = new SorterNumberString();
    static sorterBoolean = new SorterBoolean();
    static sorterString = new SorterString();
    static sorterDate = new SorterDate();
    static sorterBasic = new SorterBasic();

    getSortFn(sortCol: opts.SortEventCol): Sorter {
        if (sortCol.sorter) {
            return sortCol.sorter;
        }
        const itemType = sortCol.sortType;
        switch (itemType) {
            case 'number':
                return DataSorter.sorterNumber;
            case 'numberString':
                return DataSorter.sorterNumberString;
            case 'boolean':
                return DataSorter.sorterBoolean;
            case 'date':
                return DataSorter.sorterDate;
            case 'object':
                return DataSorter.sorterBasic;
            case 'string':
            default:
                return DataSorter.sorterString;
        }
    };

    /*getSortFn() {
        return this.sortAlpha;
    };*/

    prioritySort(a, b) {
        // Both columns have a sort priority
        if (a.priority !== undefined && b.priority !== undefined) {

            if (a.priority < b.priority) { // A is higher priority
                return -1;
            } else if (a.priority === b.priority) { // Equal
                return 0;
            } else { // B is higher
                return 1;
            }

        } else if (a.priority !== undefined) { // Only A has a priority
            return -1;
        } else if (b.priority !== undefined) { // Only B has a priority
            return 1;
        } else { // Neither has a priority
            return 0;
        }
    };

    sort(entities, sortCols: Array<opts.SortEventCol>) {
        // first make sure we are even supposed to do work
        if (!entities) {
            return;
        }

        // Now rows to sort by, maintain original order
        if (sortCols.length === 0) {
            return entities;
        }

        // Sort the "sort columns" by their sort priority
        sortCols = sortCols.sort(this.prioritySort);


        // Re-usable variables
        let col, direction;

        // IE9-11 HACK.... the 'rows' variable would be empty where we call rowSorter.getSortFn(...) below.
        // We have to use a separate reference
        // var d = data.slice(0);
        // const r = entities.slice(0);
        // const sortFn = this.getSortFn();

        // Now actually sort the data
        const rowSortFn = (entityA, entityB) => {
            let tem = 0;
            let idx = 0;
            let sortFn;

            while (tem === 0 && idx < sortCols.length) {
                // grab the metadata for the rest of the logic
                col = sortCols[idx];
                direction = col.direction;

                sortFn = this.getSortFn(col);

                let propA, propB;

                propA = entityA[col.field];
                propB = entityB[col.field];

                tem = sortFn.sort(propA, propB);
                idx++;
            }

            // Made it this far, we don't have to worry about null & undefined
            return direction === opts.SortOrder.ASC ? tem :  -1 * tem;
        };

        return entities.sort(rowSortFn);
    };
}


class SortUtil {
    static handleNulls(a, b) {
        if ((!a && a !== 0 && a !== false) || (!b && b !== 0 && b !== false)) {
            // We want to force nulls and such to the bottom when we sort... which effectively is "greater than"
            if ((!a && a !== 0 && a !== false) && (!b && b !== 0 && b !== false)) {
                return 0;
            } else if (!a && a !== 0 && a !== false) {
                return 1;
            } else if (!b && b !== 0 && b !== false) {
                return -1;
            }
        }
        return null;
    }
}
