import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import { DomHandler } from '../dom/domhandler';

@Directive({
    selector: '[pReorderableRowHandle]'
})
export class ReorderableRowHandle implements AfterViewInit {

    @Input("pReorderableRowHandle") index: number;

    constructor(public el: ElementRef) {}

    ngAfterViewInit() {
        DomHandler.addClass(this.el.nativeElement, 'ui-table-reorderablerow-handle');
    }
}
