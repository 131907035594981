import {AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, HostListener} from '@angular/core';
import { DomHandler } from '../dom/domhandler';
import { DataTableComponent as Table} from "../datatable.component";



@Directive({
    selector: '[pResizableColumn]'
})
export class ResizableColumn implements AfterViewInit, OnDestroy {

    @Input() pResizableColumnDisabled: boolean;

    resizer: HTMLSpanElement;

    resizerMouseDownListener: any;

    documentMouseMoveListener: any;

    documentMouseUpListener: any;

    resizerClickListener: any;

    private moved: boolean;

    constructor(public dt: Table, public el: ElementRef, public zone: NgZone) { }

    ngAfterViewInit() {
        if (this.isEnabled() && this.dt.options.smartView.resizableCols) { // Bofa add options check
            DomHandler.addClass(this.el.nativeElement, 'ui-resizable-column');
            this.resizer = document.createElement('span');
            this.resizer.className = 'ui-column-resizer ui-clickable';
            this.el.nativeElement.appendChild(this.resizer);

            this.zone.runOutsideAngular(() => {
                this.resizerMouseDownListener = this.onMouseDown.bind(this);
                this.resizer.addEventListener('mousedown', this.resizerMouseDownListener);

                this.resizerClickListener = this.onClick.bind(this);
                this.resizer.addEventListener('click', this.resizerClickListener);
            });
        }
    }

    bindDocumentEvents() {
        this.zone.runOutsideAngular(() => {
            this.documentMouseMoveListener = this.onDocumentMouseMove.bind(this);
            document.addEventListener('mousemove', this.documentMouseMoveListener);

            this.documentMouseUpListener = this.onDocumentMouseUp.bind(this);
            document.addEventListener('mouseup', this.documentMouseUpListener);
        });
    }

    unbindDocumentEvents() {
        if (this.documentMouseMoveListener) {
            document.removeEventListener('mousemove', this.documentMouseMoveListener);
            this.documentMouseMoveListener = null;
        }

        if (this.documentMouseUpListener) {
            document.removeEventListener('mouseup', this.documentMouseUpListener);
            this.documentMouseUpListener = null;
        }
    }

    onMouseDown(event: Event) {
        this.moved = false;
        this.dt.onColumnResizeBegin(event);
        this.bindDocumentEvents();
        event.preventDefault();
    }

    onDocumentMouseMove(event: Event) {
        this.moved = true;
        this.dt.onColumnResize(event);
    }

    onDocumentMouseUp(event: Event) {
        if (this.moved) {
          this.dt.onColumnResizeEnd(event, this.el.nativeElement);
          this.moved = false;
        }
        this.unbindDocumentEvents();
    }

    onClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    isEnabled() {
        return this.pResizableColumnDisabled !== true;
    }

    ngOnDestroy() {
        if (this.resizerMouseDownListener) {
            this.resizer.removeEventListener('mousedown', this.resizerMouseDownListener);
        }

        if (this.resizerClickListener) {
            this.resizer.removeEventListener('click', this.resizerClickListener);
        }

        this.unbindDocumentEvents();
    }
}
