import { Component, Input } from '@angular/core';

@Component({
    selector: 'phx-countdown',
    template: `
        <div *ngIf="countdown !== undefined" class="px-input-group-append border-primary">
            <span aria-live="polite" id="{{id}}-count" class="px-input-group-text px-grey-bg">({{countdown - inputRef?.value.length}})</span>
        </div>
    `
})
export class CountdownComponent {

    @Input() countdown;
    @Input() id;
    @Input() inputRef;

    constructor() {}
}
