import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { A11yModule } from '@angular/cdk/a11y';
import { InputModule } from '@phoenix/ui/input';
import { PhxCommonModule } from '@phoenix/ui/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { CalendarBodyComponent } from './calendar/calendar-body.component';
import { DayPickerComponent } from './calendar/day-picker.component';
import { MonthPickerComponent } from './calendar/month-picker.component';
import { YearPickerComponent } from './calendar/year-picker.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateUtilityService } from './date-utility.service';
import { DatepickerButtonComponent } from './datepicker-button.component';
import { DatepickerCalendarComponent } from './datepicker-calendar.component';
import { DatepickerInputDirective } from './datepicker-input.directive';
import { DatepickerMomentInitService } from './datepicker-moment-init.service';
import { DatepickerOverlayManagerService } from './datepicker-overlay-manager.service';
import { DatepickerComponent } from './datepicker.component';

export { DatepickerComponent } from './datepicker.component';
export { DateUtilityService } from './date-utility.service';
export { DatepickerInputDirective } from './datepicker-input.directive';
export { DayPickerComponent } from './calendar/day-picker.component';
export { MonthPickerComponent } from './calendar/month-picker.component';
export { YearPickerComponent } from './calendar/year-picker.component';
export { CalendarBodyComponent } from './calendar/calendar-body.component';
export { DatepickerButtonComponent } from './datepicker-button.component';
export { DateRangeComponent } from './date-range/date-range.component';
export { DatepickerOptions, DatepickerPosition, DatepickerMode } from './datepicker-options.interface';

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, InputModule, PhxCommonModule, PhxTranslateModule.forChild(), FormsModule, A11yModule],
  declarations: [
    DatepickerInputDirective,
    DatepickerCalendarComponent,
    DayPickerComponent,
    MonthPickerComponent,
    YearPickerComponent,
    CalendarBodyComponent,
    DatepickerButtonComponent,
    DateRangeComponent,
    DatepickerComponent
  ],
  exports: [DatepickerInputDirective, DatepickerComponent, DateRangeComponent],
  providers: [DateUtilityService, DatepickerOverlayManagerService]
})
export class DatepickerModule {
  // injecting initService so that constructor inside of it is called to inialize momentJS one time across root app
  constructor(private datepickerMomentInitService: DatepickerMomentInitService) { }
}
