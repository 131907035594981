import { Logger } from '@phoenix/ui/common';
import { DataTableComponent } from '../../datatable.component';
import { BaseEvent, SelectEvent } from '../../datatable.options';
import { InternalEvent, InternalEventType } from '../datatable.model';
import { EventUtil } from '../selection/event-util';


/**
 * Base class for selection services. Keeps generic info and methods.
 */
export abstract class DataTableSelection {
    logger: Logger;

    /**
     * Row data field name which contains item id.
     */
    idField: string;

    /**
     * Row data field name which contains information where row selected or not (boolean true/false).
     */
    protected  _selField: string;

    protected selectedCount = 0;

    /**
     * Currently selected rows which are not presented in the table. E.g. user select 3 items then apply filter.
     * DataTable data after filtering will have only 1 selected item and 2 will not be presented
     * - then the value for the field will be 2. Once filter removed - 0.
     */
    protected selectedRowsNotFiltered = 0;

    /**
     * Total number of rows filtered in the table and available for selection.
     */
    totalRows = 0;

    protected eventUtil: EventUtil;

    constructor() { }

    init(table: DataTableComponent, logger: Logger) {
        this.logger = logger;
        this.idField = table.options.smartView.idField;
        this._selField = table.options.smartView.selectField;
        this.selectedCount = table.options.smartView.totalSelectedCount;
        this.eventUtil = new EventUtil(this.idField);

        this.resetPHXSelect(table);
        table.internalEventsService.eventSource$.subscribe((event: InternalEvent) => { // Bofa
            this.handleGridEvent(event, table);
        });
    }

    // API start
    public get selField() {
      return this._selField;
    }

    public abstract get selectedAll(): boolean;

    /**
     * reset all selections.
     */
    abstract resetAll(table: DataTableComponent): Array<BaseEvent>;

    abstract toggleAll(isSelected: boolean, table: DataTableComponent): Array<BaseEvent>;

    toggleRow(isSelected: boolean, rowData: any, table: DataTableComponent): Array<BaseEvent> {
      this.logger.debug(`toggle event: ${isSelected} `, rowData);

      this.selectedCount += isSelected ? 1 : -1;
      rowData[this.selField] = isSelected;

      this.syncSelectAllState(table);

      return this.eventUtil.createRowSelectEvent(isSelected, rowData);
    }

    toggleRadioRow(isSelected: boolean, rowData: any, table: DataTableComponent): SelectEvent[] {
      this.selectedCount = 1;
      table.rowsData.forEach(row => {
        row[this.selField] = false;
      });
      rowData[this.selField] = isSelected;

      return this.eventUtil.createRadioRowSelectEvent(rowData);
    }

    getSelectedCount() {
      return this.selectedCount;
    }

    isRowSelected(rowData) {
      return rowData[this.selField];
    }
    // API end

    resetPHXSelect(table: DataTableComponent) {
        const smV = table.options.smartView;
        this.totalRows = typeof smV.filteredSelectableRowNumber !== 'undefined'
            ? smV.filteredSelectableRowNumber : smV.filteredRowNumber;
        this.selectedRowsNotFiltered = this.selectedCount - smV.filteredSelectedCount;
        this.syncSelectAllState(table);

        this.logger.debug(
            `Select: reset. total: ${this.totalRows}, other: ${this.selectedRowsNotFiltered}, selected: ${this.selectedCount}`,
            smV
        );
    }

    abstract syncSelectAllState(table: DataTableComponent);

    getSelectedItems(rowsData: Array<any>) {
        const result = [];
        rowsData.forEach(row => {
            if (row[this.selField]) {
                result.push(row);
            }
        });
        return result;
    }

    //Optional override.
    protected afterLoad(table: DataTableComponent): void {}

    handleGridEvent(event: InternalEvent, table) {
        switch (event.type) {
            case InternalEventType.ON_INIT:
            case InternalEventType.ON_AFTER_FILTER:
                {
                    this.resetPHXSelect(table);
                }
                break;
            case InternalEventType.ON_REFRESH:
                {
                    if (event.info && event.info.externalEventInfo && event.info.externalEventInfo.resetSelection) {
                        this.selectedCount = 0;
                    }
                }
                break;
          case InternalEventType.ON_AFTER_LOAD:
                {
                    this.afterLoad(event.info['table']);
                }
                break;
            default:
                break;
        }
    }
}
