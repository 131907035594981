import { Renderer2 } from "@angular/core";
import { PhxUtil } from "./phx-util";

export class PhxGesture {
    /**
     *
     */

    private supportedEvents = ['swipe', 'swipeleft', 'swiperight', 'swipeup', 'swipedown', 'touch'];

    private swipeEventMap = {
        'LEFT': 'swipeleft',
        'RIGHT': 'swiperight',
        'UP': 'swipeup',
        'DOWN': 'swipedown',
        'NONE': 'touch'
    };

    constructor(public renderer: Renderer2, public element?: HTMLElement) {
    }

    bindEvent(element: HTMLElement, name: string, callback: Function, options?: any) {
        if (!element) {
            console.error('element cannot be undefined');
            return null;
        }

        if (!name) {
            console.error('event name cannot be undefined');
            return null;
        }
        if (!callback) {
            console.error('callback cannot be undefined');
            return null;
        }

        if (this.supportedEvents.indexOf(name) !== -1) {
            return this.swipeEvent(element, name, callback);
        }

        const eventListener = this.renderer.listen(element, name, () => {
            callback();
        });

        return eventListener;
    }

    on(name: string, callback: Function, options?: any) {

        if (!this.element) {
            console.error('element is null, please set element in constructor');
            return null;
        }

        return this.bindEvent(this.element, name, callback, options);
    }

    private swipeEvent(element: HTMLElement, name: string, callback: Function): void {
        let swipeDirection, startX, startY, startTime, distX, distY, elapsedTime, threshold = 150, restraint = 100, allowedTime = 300;

        this.renderer.listen(element, 'touchstart', (e: TouchEvent) => {
            const touchObj = e.changedTouches[0];
            swipeDirection = 'NONE';
            startX = touchObj.pageX;
            startY = touchObj.pageY;
            startTime = new Date().getTime();
            e.preventDefault();
        });

        this.renderer.listen(element, 'touchmove', (e: TouchEvent) => {
            e.preventDefault();
        });

        this.renderer.listen(element, 'touchend', (e: TouchEvent) => {
            const touchobj = e.changedTouches[0]
            distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime // get time elapsed
            if (elapsedTime <= allowedTime) { // first condition for swipe met
                if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // 2nd condition for horizontal swipe met
                    swipeDirection = (distX < 0) ? 'LEFT' : 'RIGHT' // if dist traveled is negative, it indicates left swipe
                }
                else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // 2nd condition for vertical swipe met
                    swipeDirection = (distY < 0) ? 'UP' : 'DOWN' // if dist traveled is negative, it indicates up swipe
                }
            }

            this.handleSwipeEvent(swipeDirection, name, callback);
            e.preventDefault();
        });
    }

    private handleSwipeEvent(swipeEventName: string, bindEventName: string, callback: Function): boolean {
        if (bindEventName === 'swipe' && this.swipeEventMap[swipeEventName]) {
            callback();
            return true;
        }
        if (this.swipeEventMap[swipeEventName] === bindEventName) {
            callback();
            return true;
        }
        return false;

    }
}