import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Input, OnInit, ViewContainerRef, HostBinding } from '@angular/core';
import { CountdownComponent } from './countdown.component';

/**
 * Countdown directive adds an input-group-addon that displays the number of characters remaining as the user types
 */
@Directive({
    selector: '[phxCountdown]'
})
export class CountdownDirective implements OnInit {

    private countdownComponentRef: ComponentRef<CountdownComponent>;
    private countdownInstance: CountdownComponent;

    @HostBinding('attr.maxlength')

    /**
     * the starting number of characters used by countdown
     */
    @Input() phxCountdown: number;
    @Input() id;

    constructor(private elRef: ElementRef,
                private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        const countdownComponentFactory = this.componentFactoryResolver.resolveComponentFactory(CountdownComponent);
        this.countdownComponentRef = this.viewContainerRef.createComponent(countdownComponentFactory);
        this.countdownInstance = this.countdownComponentRef.instance;

        this.countdownInstance.inputRef = this.elRef.nativeElement;
        this.countdownInstance.countdown = this.phxCountdown;
        this.countdownInstance.id = this.id;
    }
}
