import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DateUtilityService} from '../date-utility.service';
import {DatepickerService} from '../datepicker.service';
import {ActiveView, CalendarCell, CalendarView} from './calendar.interface';
import {DatepickerOptions} from '../datepicker-options.interface';

@Component({
  selector: 'phx-month-picker',
  templateUrl: './month-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MonthPickerComponent implements OnDestroy {
  @Input() inline: boolean;
  @Input() options: DatepickerOptions;
  @Output() closeEvent = new EventEmitter();

  monthsGrid: CalendarCell[][];
  activeView: ActiveView;
  calendarViewType = CalendarView;
  disablePrev: boolean;
  disableNext: boolean;
  private destroy$ = new Subject<any>();

  constructor(public datePickerService: DatepickerService, private dateUtilityService: DateUtilityService) {
    this.datePickerService.activeView$.pipe(takeUntil(this.destroy$)).subscribe(view => {
      if (!this.activeView || !this.isEqualMonthPickerView(view, this.activeView)) {
        this.activeView = view;
        this.monthsGrid = this.dateUtilityService.createMonthPickerGrid(view, this.datePickerService.datePickerOptions);

        const nextYear = dateUtilityService.parseMomentCompatibleDate(`01/01/${view.year + 1}`, 'MM/DD/YYYY');
        this.disableNext = !dateUtilityService.isDateEnabled(
          this.datePickerService.datePickerOptions,
          nextYear,
          CalendarView.YEAR
        );

        const prevYear = dateUtilityService.parseMomentCompatibleDate(`01/01/${view.year - 1}`, 'MM/DD/YYYY');
        this.disablePrev = !dateUtilityService.isDateEnabled(
          this.datePickerService.datePickerOptions,
          prevYear,
          CalendarView.YEAR
        );
      }
    });
  }

  isEqualMonthPickerView(view1: ActiveView, view2: ActiveView): boolean {
    return view1.year === view2.year;
  }

  showPrevYear() {
    const tmpView = Object.assign({}, this.activeView);
    if (tmpView.year === tmpView.yearRange[0]) {
      tmpView.yearRange = this.dateUtilityService.parseYearRange(tmpView.year - 1);
    }
    tmpView.year--;
    this.datePickerService.activeView = tmpView;
  }

  showNextYear() {
    const tmpView = Object.assign({}, this.activeView);
    if (tmpView.year === tmpView.yearRange[1]) {
      tmpView.yearRange = this.dateUtilityService.parseYearRange(tmpView.year + 1);
    }
    tmpView.year++;
    this.datePickerService.activeView = tmpView;
  }

  setViewMode(calendarView: CalendarView) {
    this.activeView.calendarView = calendarView;
    this.datePickerService.activeView = this.activeView;
  }

  onCloseDatepicker() {
    this.closeEvent.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
