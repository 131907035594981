import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PhxTranslateService} from '@phoenix/ui/translate';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DateUtilityService} from '../date-utility.service';
import {DatepickerService} from '../datepicker.service';
import {ActiveView, CalendarCell, CalendarView} from './calendar.interface';
import {DatepickerOptions} from '../datepicker-options.interface';

@Component({
  selector: 'phx-day-picker',
  templateUrl: './day-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DayPickerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('monthViewButton') monthViewBtn: ElementRef;
  weekdayKeys: string[];
  monthKeys: string[];

  daysGrid: CalendarCell[][];
  activeView: ActiveView;
  calendarViewType = CalendarView;

  disablePrev: boolean;
  disableNext: boolean;
  disableWeekend: boolean;

  private destroy$ = new Subject<any>();

  @Input() inline: boolean;
  @Input() options: DatepickerOptions;

  @Output() closeEvent = new EventEmitter();

  constructor(
    public datePickerService: DatepickerService,
    private dateUtilityService: DateUtilityService,
    private translateService: PhxTranslateService
  ) {
    this.weekdayKeys = dateUtilityService.weekDayKeys;
    this.monthKeys = dateUtilityService.monthKeys;

    this.datePickerService.datePickerOptions$.pipe(takeUntil(this.destroy$)).subscribe(opt => {
      this.daysGrid = this.dateUtilityService.createDayPickerGrid(this.activeView, opt);
      this.disabledMonths();
    });

    this.datePickerService.activeView$.pipe(takeUntil(this.destroy$)).subscribe(view => {
      if (!this.activeView || !this.isEqualDayPickerView(view, this.activeView)) {
        this.activeView = view;
        this.daysGrid = this.dateUtilityService.createDayPickerGrid(this.activeView, this.datePickerService.datePickerOptions);
        this.disabledMonths(view);
      }
    });
  }

  ngAfterViewInit() {
    if (this.monthViewBtn && this.datePickerService.shouldFocusOnMonth) {
      this.monthViewBtn.nativeElement.focus();
    }
    this.disableWeekend = this.datePickerService.datePickerOptions.disableWeekend;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  disabledMonths(view: ActiveView = this.activeView) {
    // view.month is 0-based index, so actual next month is view.month+2
    const nextDate = this.dateUtilityService.parseMomentCompatibleDate(`${view.month === 11 ? '01' : this.padZero(view.month + 2)}/01/${view.month === 11 ? view.year + 1 : view.year}`, 'MM/DD/YYYY');
    this.disableNext = !this.dateUtilityService.isDateEnabled(
      this.datePickerService.datePickerOptions,
      nextDate,
      CalendarView.MONTH
    );

    // view.month is 0-based index, so previous month is just view.month
    const prevDate = this.dateUtilityService.parseMomentCompatibleDate(`${view.month === 0 ? 11 : this.padZero(view.month)}/01/${view.month === 0 ? view.year - 1 : view.year}`, 'MM/DD/YYYY');
    this.disablePrev = !this.dateUtilityService.isDateEnabled(
      this.datePickerService.datePickerOptions,
      prevDate,
      CalendarView.MONTH
    );
  }

  // pad number with zero if it has length less than 2
  private padZero(target: number): string {
    const str = target.toString();
    if (str.length >= 2) {
      return str;
    } else {
      return '0' + str;
    }
  }

  private isEqualDayPickerView(view1: ActiveView, view2: ActiveView) {
    return view1.month === view2.month && view1.year === view2.year;
  }

  get activeViewDate(): string {
    if (!this.activeView) {
      return '';
    }
    return `${this.translateService.instant('phx.datepicker.months.' + this.monthKeys[this.activeView.month])} ${this.activeView.year}`;
  }

  showPrevMonth() {
    const tmpView = Object.assign({}, this.activeView);
    if (this.activeView.month === 0) {
      tmpView.month = 11;
      tmpView.year--;
    } else {
      tmpView.month--;
    }
    this.datePickerService.activeView = tmpView;
  }

  showNextMonth() {
    const tmpView = Object.assign({}, this.activeView);
    if (this.activeView.month === 11) {
      tmpView.month = 0;
      tmpView.year++;
    } else {
      tmpView.month++;
    }
    this.datePickerService.activeView = tmpView;
  }

  setViewMode(calendarView: CalendarView) {
    this.activeView.calendarView = calendarView;
    this.datePickerService.activeView = this.activeView;
    this.datePickerService.shouldFocusOnMonth = false;
  }


  onCloseDatepicker() {
    this.closeEvent.emit();
  }

}
