import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DEFAULT_OPTIONS, ImageViewerOptions } from './image-viewer.options';
import { ImgDirective } from './img.directive';
import { SlideComponent } from './phx-slide.component';
import { SlideOptions } from './slide-options.interface';

enum Action {
    ALL = -1,
    FRONT = 0,
    BACK = 1
}



/**
 * Simple component to toggle visibility of images and its limited to show upto 2 images by default.
 * Imageviewer default navigation helps to switch between first and second images and also capable to show all images
 */
@Component({
    exportAs: 'phxImageviewer',
    selector: 'phx-imageviewer',
    templateUrl: './image-viewer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
        .px-imageviewer {
            text-align: center;
            overflow-y: hidden;
        }
        .px-nav-seperator::before {
            display: inline-block;
            color: #868e96;
            content: "|";
            padding-right:0.5rem;
        }

        .px-imageviewer img:not(:first-child) {
            margin: 10px 0 0 0 !important;
        }
        .px-imageviewer[type='mobile'] img:not(:first-child) {
            margin: 0 0 0 10px !important;
        }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class ImageViewerComponent implements OnChanges, AfterContentInit, OnInit {
    isFirstSlideActive = true;
    isAllActive = false;
    isLastSlideActive = false;

    public viewerOptions = DEFAULT_OPTIONS;
    /**
     * Default navigation value is false. Set true to enable default navigation or pass your template object to render your own navigation
     */
    @Input() navigation: TemplateRef<any> | boolean = false;

    @Input() options: ImageViewerOptions;


    @ContentChildren(ImgDirective) images: QueryList<ImgDirective>;
    @ContentChildren(SlideComponent) slides: QueryList<SlideComponent>;
    @ViewChild('defaultImageviewerNav', { read: TemplateRef , static: true}) defaultImageviewerNav: TemplateRef<any>;

    private slideActiveIndex = Action.FRONT;
    navigationTemplateRef: TemplateRef<any> = null;

    constructor(
        private elementRef: ElementRef,
        public renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.navigation && !changes.navigation.firstChange) {
            this.swapTemplate(changes.navigation.currentValue);
        }
    }

    ngOnInit() {
        this.updateConfig();
        this.renderer.addClass(this.elementRef.nativeElement, 'px-flex-column');
        this.renderer.addClass(this.elementRef.nativeElement, 'px-d-flex');
        this.renderer.addClass(this.elementRef.nativeElement, 'px-imageviewer');
    }

    ngAfterContentInit() {
        this.activateSlide(this.slideActiveIndex);

        if (this.viewerOptions.mobile.useDefault && this.slides) {
            const mobileSlideOptions: SlideOptions = {
                enableSwipe: true,
                swipeDuration: 200,
                showInModal: true,
                flipModal: false
            };
            this.slides.forEach((slide) => {
                slide.options = mobileSlideOptions;
                slide.doInit(this.viewerOptions.mobile.modalOptions as any);
            });
        }

        this.swapTemplate(this.navigation);
    }




    private swapTemplate(navigation: TemplateRef<any> | boolean) {
        if (navigation instanceof TemplateRef) {
            this.navigationTemplateRef = navigation;
        } else if (typeof navigation === 'boolean' && navigation) {
            this.navigationTemplateRef = this.defaultImageviewerNav;
        } else {
            this.navigationTemplateRef = null;
        }
    }

    /**
     * Toggles visibility of all images
     */
    slideAll() {
        this.show(Action.ALL);
        return false;
    }

    /**
     * Sets the first image visibility true and rest of the images will be hidden
     */
    slideFirst() {
        this.show(Action.FRONT);
        return false;
    }

    /**
     * Sets the last image visibility true and rest of the images will be hidden
     */
    slideLast() {
        this.show(Action.BACK);
        return false;
    }

    /**
     * Lookup the images by the index passed and sets the visibility true and rest of the images will be hidden
     * @param index : Index of image to show and index starts with 0
     */
    slide(index: number): boolean {
        if (index < 0 || index >= this.images.length) {
            return false;
        }
        this.activateSlide(index);
        return true;
    }

    private activateSlide(activateIndex: number) {
        this.images.forEach((image: ImgDirective, index: number) => {
            image.displayNoneClass = true;

            if (index === activateIndex) {
                image.displayNoneClass = false;
                this.slideActiveIndex = index;
            }
        });
    }

    private show(action: number) {
        switch (action) {
            case Action.FRONT: {
                this.isFirstSlideActive = true;
                this.isLastSlideActive = false;
                this.isAllActive = false;
                this.activateSlide(0);
                break;
            }
            case Action.BACK: {
                this.isFirstSlideActive = false;
                this.isLastSlideActive = true;
                this.isAllActive = false;
                this.activateSlide(this.images.length - 1);
                break;
            }
            default: {
                this.images.forEach((image: ImgDirective) => {
                    image.displayNoneClass = false;
                });
                this.isFirstSlideActive = false;
                this.isLastSlideActive = false;
                this.isAllActive = true;
                break;
            }
        }
    }

    private updateConfig() {
        if (this.options) {
            this.viewerOptions.defaultLabels = !this.options.defaultLabels ? DEFAULT_OPTIONS.defaultLabels : {...DEFAULT_OPTIONS.defaultLabels, ...this.options.defaultLabels};
            this.viewerOptions.mobile = (!this.options.mobile ? DEFAULT_OPTIONS.mobile : {...DEFAULT_OPTIONS.mobile, ...this.options.mobile }) as any;
        }
    }
}
