/**
 * Created by NBKPYZL on 12/10/2021.
 */
import * as opts from '../../datatable.options';

/**
 * Utility to create selection related events.
 */
export class EventUtil {

  /**
   * Row data field name which contains item id.
   */
  idField: string;

  public constructor(idField: string) {
    this.idField = idField;
  }

  public createRadioRowSelectEvent(rowData: any): opts.SelectEvent[] {
    const _info: opts.SelectEventInfo = { id: rowData[this.idField] };
    const selectEvent: opts.SelectEvent = {
      type: opts.ActionTypes.SELECT_SINGLE_ROW,
      info: _info
    };
    return [selectEvent];
  }

  public createRowSelectEvent(isSelected: boolean, rowData: any): opts.BaseEvent[] {
    const ids = {};
    ids[rowData[this.idField]] = isSelected ? 'y' : 'n';
    return this.createRowsSelectEvent(ids);
  }

  public createRowsSelectEvent(rowIds: any): opts.BaseEvent[] {
    const _info: opts.SelectEventInfo = {
      ids: rowIds
    };

    const selectEvent: opts.SelectEvent = {
      type: opts.ActionTypes.SELECT_ROW,
      info: _info
    };

    return [selectEvent];
  }

  public createToggleAllEvent(isSelected: boolean): opts.BaseEvent[] {
    return [{
      type: opts.ActionTypes.SELECT_ALL_FILTERED,
      info: { isAll: isSelected }
    }];
  }
}
