import {Injectable} from '@angular/core';
import {ConnectedPosition} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class OverlayPositionService {

  static generateConnectedPosition(
    originX: any,
    originY: any,
    overlayX: any,
    overlayY: any,
    offsetX: number,
    offsetY: number,
    panelClass?: string | string[]
  ): ConnectedPosition {
    return {originX, originY, overlayX, overlayY, offsetX, offsetY, panelClass};
  }

  getConnectedPositions(preferredPosition = 'top', offset = 0, addPanelClass = false, order = [
    'bottom-left',
    'bottom-right',
    'bottom',
    'top-left',
    'top-right',
    'top',
    'Left',
    'left-above',
    'left-below',
    'right',
    'right-below',
    'right-above'
  ]): ConnectedPosition[] {
    preferredPosition = preferredPosition.toLowerCase();
    return order.map(i => i.toLowerCase())
      .sort((a) => a === preferredPosition ? -1 : 0)
      .map(i => {
        switch (i) {
          case 'right-below': {
            return OverlayPositionService.generateConnectedPosition('end', 'center', 'start', 'top', offset, -offset, addPanelClass ? 'right-below' : null);
          }
          case 'right-above': {
            return OverlayPositionService.generateConnectedPosition('end', 'center', 'start', 'bottom', offset, offset, addPanelClass ? 'right-above' : null);
          }
          case 'right': {
            return OverlayPositionService.generateConnectedPosition('end', 'center', 'start', 'center', offset, 0, addPanelClass ? 'right' : null);
          }
          case 'bottom': {
            return OverlayPositionService.generateConnectedPosition('center', 'bottom', 'center', 'top', 0, offset, addPanelClass ? 'bottom' : null);
          }
          case 'bottom-right': {
            return OverlayPositionService.generateConnectedPosition('end', 'bottom', 'end', 'top', 0, offset, addPanelClass ? 'bottom-right' : null);
          }
          case 'bottom-left': {
            return OverlayPositionService.generateConnectedPosition('start', 'bottom', 'start', 'top', 0, offset, addPanelClass ? 'bottom-left' : null);
          }
          case 'top': {
            return OverlayPositionService.generateConnectedPosition('center', 'top', 'center', 'bottom', 0, -offset, addPanelClass ? 'top' : null);
          }
          case 'top-right': {
            return OverlayPositionService.generateConnectedPosition('end', 'top', 'end', 'bottom', 0, -offset, addPanelClass ? 'top-right' : null);
          }
          case 'top-left': {
            return OverlayPositionService.generateConnectedPosition('start', 'top', 'start', 'bottom', 0, -offset, addPanelClass ? 'top-left' : null);
          }
          case 'left': {
            return OverlayPositionService.generateConnectedPosition('start', 'center', 'end', 'center', -offset, 0, addPanelClass ? 'left' : null);
          }
          case 'left-above': {
            return OverlayPositionService.generateConnectedPosition('start', 'center', 'end', 'bottom', -offset, offset, addPanelClass ? 'left-above' : null);
          }
          case 'left-below': {
            return OverlayPositionService.generateConnectedPosition('start', 'center', 'end', 'top', -offset, -offset, addPanelClass ? 'left-below' : null);
          }
          default: // top
            return OverlayPositionService.generateConnectedPosition('center', 'top', 'center', 'bottom', 0, -offset, addPanelClass ? 'top' : null);
        }
      });
  }
}
