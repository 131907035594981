import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SelectionModel} from '@angular/cdk/collections';
import {
  A,
  DOWN_ARROW,
  END,
  ENTER,
  ESCAPE,
  hasModifierKey,
  HOME,
  LEFT_ARROW,
  RIGHT_ARROW,
  SPACE,
  UP_ARROW
} from '@angular/cdk/keycodes';
import {ConnectedPosition, Overlay, ScrollStrategy, CdkConnectedOverlay} from '@angular/cdk/overlay';

import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SkipSelf,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import {ControlValueAccessor, NgControl} from '@angular/forms';
import {fromEvent, merge, Observable, Subject, Subscription} from 'rxjs';
import {startWith, switchMap, takeUntil, filter, tap} from 'rxjs/operators';
import {Animations, PhxOption, OverlayPositionService} from '@phoenix/ui/common';

export type DROPDOWN_DIRECTION = 'UP' | 'DOWN';

export const PHX_DROPDOWN_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('phx-dropdown-scroll-strategy');

export function PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER = {
  provide: PHX_DROPDOWN_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: PHX_DROPDOWN_SCROLL_STRATEGY_PROVIDER_FACTORY
};

@Component({
  selector: 'phx-dropdown-panel',
  templateUrl: 'dropdown-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(focus)': '_focus()',
    '(blur)': '_blur()'
  },
  animations: [Animations.dropdown]
})
export class DropdownPanelComponent implements ControlValueAccessor, OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChild('overlayRef') connectedOverlay: CdkConnectedOverlay;
  _triggerRect: ClientRect;
  _selectionModel: SelectionModel<PhxOption>;
  _scrollStrategyFactory: () => ScrollStrategy;
  _scrollStrategy: ScrollStrategy;
  readonly _destroy = new Subject<void>();
  _keyManager: ActiveDescendantKeyManager<PhxOption>;
  _optionIds: string;
  parentContentOptions: QueryList<PhxOption>;
  disabled = false;
  _open = false;
  private _backdropClicked = false;
  private subscriptions: Subscription = new Subscription();
  private keySelectionValues = '';
  private keySelectionTimer;

  @ViewChild('trigger') trigger: ElementRef;
  @ContentChildren(PhxOption, {descendants: true}) _contentOptions: QueryList<PhxOption>;
  @ViewChild('panel') panel: ElementRef;

  @ViewChild('triggerContainer') triggerContainerRef: ElementRef;

  get contentOptions(): QueryList<PhxOption> {
    return this.parentContentOptions || this._contentOptions;
  }

  /**
   * Dropdown value change handler
   */
  @Output() readonly modelChange: EventEmitter<any> = new EventEmitter<any>();
  optionSelectionChanges: Observable<PhxOption>;

  /** Event that is emitted when the dropdown panel is closed/opened. */
  @Output() readonly toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() filterValue: string;

  @Input() id: string;

  @Input() styleClass: string;

  @Input() closeOnScroll = false;

  _width: string;

  /**
   * width for dropdown
   * Width unit can be in px or %
   */
  @Input()
  get width(): string {
    return this._width;
  }

  set width(value: string) {
    this._width = value;
  }

  @Input() optionsPanelWidth: string;

  _ariaLabel: string;
  /**
   * ADA: Dropdown aria label
   */
  @Input()
  get ariaLabel(): string {
    return this._ariaLabel;
  }

  set ariaLabel(value: string) {
    this._ariaLabel = value;
  }

  _ariaLabelledBy: string;
  /**
   * ADA: Dropdown aria labeled by
   */
  @Input()
  get ariaLabelledBy(): string {
    return this._ariaLabelledBy;
  }

  set ariaLabelledBy(value: string) {
    this._ariaLabelledBy = value;
  }

  _ariaDescribedBy: string;
  /**
   * ADA: Dropdown aria described by
   */
  @Input()
  get ariaDescribedBy(): string {
    return this._ariaDescribedBy;
  }

  set ariaDescribedBy(value: string) {
    this._ariaDescribedBy = value;
  }

  _required = false;
  /**
   * Dropdown native required attribute
   */
  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  _multiple = false;
  /**
   * Dropdown allows for multiple selection of items, default: false
   */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  _filterable = false;
  /**
   * Dropdown allows for filter options, default: false
   */
  @Input()
  get filterable(): boolean {
    return this._filterable;
  }

  set filterable(value: boolean) {
    this._filterable = coerceBooleanProperty(value);
  }

  _placeholder = 'Please select a value';
  /**
   * Dropdown placeholder text
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  /**
   * Dropdown cdk overlay open/close
   */
  @Input()
  get toggle(): boolean {
    return this._open;
  }

  set toggle(value: boolean) {
    this._open = coerceBooleanProperty(value);
    this._open ? this.open() : this.close();
  }

  _focused = false;
  /**
   * Dropdown focused into
   */
  get focused(): boolean {
    return this._focused || this.toggle;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  _direction: DROPDOWN_DIRECTION = 'DOWN';
  /**
   * Dropdown open direction. Default: 'DOWN'
   * DROPDOWN_DIRECTION = 'UP' | 'DOWN'
   */
  @Input()
  get direction(): DROPDOWN_DIRECTION {
    return this._direction;
  }

  set direction(value: DROPDOWN_DIRECTION) {
    this._direction = value;
  }

  _maxHeight: number;

  /**
   * Allowed to change dropdowns panel style from outside.
   */
  @Input() panelStyleClass: string;

  @Input() customInputText: string;

  /**
   * Dropdown cdk overlay height
   */
  @Input()
  get maxHeight(): number {
    return this._maxHeight;
  }

  set maxHeight(value: number) {
    this._maxHeight = value;
  }

  get stateName() {
    return this.toggle ? 'show' : 'hide';
  }

  get selected(): PhxOption | PhxOption[] {
    return this.multiple ? this._selectionModel.selected : this._selectionModel.selected[0];
  }

  get ariaDescribedByString(): string {
    if (this.ariaDescribedBy) {
      return `${this.buttonId} ${this.ariaDescribedBy}`;
    }

    return `${this.buttonId}`;
  }

  get buttonId(): string {
    return `${this.id}-b`;
  }

  get positions(): ConnectedPosition[] {
    return this.overlayPositionService.getConnectedPositions(this.direction === 'UP' ? 'top-left' : 'bottom-left');
  }

  _onChange: (value: any) => void = () => {
  };
  _onTouched = () => {
  };

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(PHX_DROPDOWN_SCROLL_STRATEGY) scrollStrategyFactory: any,
    private _ngZone: NgZone,
    private _elementRef: ElementRef,
    private renderer: Renderer2,
    @SkipSelf() public ngControl: NgControl,
    private overlayPositionService: OverlayPositionService
  ) {
    this._scrollStrategyFactory = scrollStrategyFactory;
    this._scrollStrategy = this._scrollStrategyFactory();
  }

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<PhxOption>(this.multiple);
  }

  ngAfterContentInit(): void {
    this._selectionModel.changed.pipe(takeUntil(this._destroy)).subscribe(event => {
      if (this.multiple) {
        event.added.forEach(option => option.select());
      } else if (event.added.length === 1) {
        event.added[0].selected = true;
      }
      event.removed.forEach(option => option.deselect());
    });
    this.contentOptions.changes.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._initializeSelection();
    });
  }

  ngAfterViewInit(): void {
    this.contentOptions.changes
      .pipe(
        startWith(this.contentOptions),
        takeUntil(this._destroy)
      )
      .subscribe((options: QueryList<PhxOption>) => {
        if (options && options.length > 0) {
          this.optionSelectionChanges = this._getOptionsSelectionChange();
          this._initKeyManager(options);
          this._keyManager.change.pipe(takeUntil(this._destroy)).subscribe(() => {
            if (this.toggle) {
              this._scrollActiveOptionIntoView();
            } else if (!this.toggle && !this.multiple && this._keyManager.activeItem) {
              this._keyManager.activeItem._selectViaInteraction();
            }
          });

          this._keyManager.tabOut.pipe(takeUntil(this._destroy)).subscribe(() => {
            if (this.toggle) {
              setTimeout(() => {
                if (!this.panel.nativeElement.contains(document.activeElement)) {
                  this.toggle = false;
                }
              });
            }
          });

          this._resetOptions();
          this._initializeSelection();

          // this._bidToMouseEvent();
        }
      });

  }

  /*
  Note: Avoid setting the hover element as an active element instead just set active styles.
  private _bidToMouseEvent() {
    this.contentOptions.forEach((option: PhxOption, i: number) => {
      option.setInactiveStyles();
      option.mouseEnter.pipe(
        takeUntil(this._destroy)
      ).subscribe(() => {
        this._keyManager.setActiveItem(i);
      });
    });
  }*/

  _focus() {
    if (!this.disabled) {
      this._focused = true;
    }
  }

  _blur() {
    this._focused = false;
    if (!this.disabled && !this.toggle) {
      this._onTouched();
      this.cdr.markForCheck();
    }
  }

  @HostListener('keydown', ['$event'])
  _keydown(event: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }
    this.toggle ? this._handleOpenKeydown(event) : this._handleClosedKeydown(event);
  }

  close() {
    this.toggled.emit(this.toggle);
    if (this.triggerContainerRef) {
      this.renderer.removeAttribute(this.triggerContainerRef.nativeElement, 'tabindex');
    }
    if (!this._backdropClicked && this.trigger) {
      this.focus();
    }

    this.subscriptions.unsubscribe();

    this.cdr.markForCheck();
  }

  onToggleDropdown() {
    this.toggle = !this.disabled ? !this.toggle : false;
    if (!this.disabled) {
      this.ngControl.control.markAsTouched();
    }
    return false;
  }

  open() {
    this._backdropClicked = false;
    this._triggerRect = this.trigger.nativeElement.getBoundingClientRect();
  }

  private _highlightCorrectOption(): void {
    if (this._keyManager) {
      if (this.empty) {
        this._keyManager.setFirstItemActive();
      } else {
        this._keyManager.setActiveItem(this._selectionModel.selected[0]);
      }
    }
  }

  private _resetOptions(): void {
    const changedOrDestroyed = merge(this.contentOptions.changes, this._destroy);

    this.optionSelectionChanges.pipe(takeUntil(changedOrDestroyed)).subscribe(event => {
      this._onSelect(event);
      if (!this.multiple && this.toggle) { // filterValue can be 'undefined' or string value.
        this.toggle = false;
      }
    });
    this.setOptionIds();
  }

  private setOptionIds() {
    this._optionIds = this.contentOptions.map(option => option.id).join(' ');
  }

  private focus(): void {
    this.trigger.nativeElement.focus();
  }

  private _getOptionsSelectionChange(): Observable<PhxOption> {
    return this.contentOptions.changes.pipe(
      startWith(this.contentOptions),
      switchMap(() => merge(...this.contentOptions.map(option => option.selectionChange))),
      takeUntil(this._destroy)
    ) as Observable<PhxOption>;
  }

  _onSelect(option: PhxOption): void {
    if (option.value == null && !this._multiple) {
      option.deselect();
      this._selectionModel.clear();
    } else {
      if (option.selected) {
        if (!this._selectionModel.selected.find(({value}) => value === option.value)) {
          this._keyManager.setActiveItem(option);
          this._selectionModel.select(option);
        }
      } else {
        this._selectionModel.selected.forEach(modelOption => {
          if (modelOption.value === option.value) {
            this._selectionModel.deselect(modelOption);
          }
        });
        this._selectionModel.deselect(option);
        option.deselect();
      }
      let valueToEmit: any = null;

      if (this.multiple) {
        // Selection model has duplicate options since content options can change with every filter changes when filterable=true
        // find unique selection options and emit
        valueToEmit = (this.selected as PhxOption[]).filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
          .map(opt => opt.value);
      } else {
        valueToEmit = this.selected ? (this.selected as PhxOption).value : option.value;
      }

      if (option.selected) {
        this.modelChange.emit(valueToEmit);
        this._onChange(valueToEmit);
      } else if (this._multiple) {
        this.modelChange.emit(valueToEmit);
        this._onChange(valueToEmit);
      }

    }
  }

  get empty(): boolean {
    return !this._selectionModel || this._selectionModel.isEmpty();
  }

  get triggerValue(): string {
    if (this.empty) {
      return '';
    }
    if (this._multiple) {
      // Selection model has duplicate since content options can change with every filter changes when filterable=true
      const selectedOptions = this._selectionModel.selected.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i).map(option => option.viewValue);

      if (this.customInputText) {
        return this.customInputText;
      }

      return selectedOptions.join(', ');
    }

    return this._selectionModel.selected[0].viewValue;
  }

  // closed keydown navigation buttons like arrow or home/end do not work for @input options, only projected options
  private _handleClosedKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW || keyCode === LEFT_ARROW || keyCode === RIGHT_ARROW;
    const isOpenKey = keyCode === ENTER || keyCode === SPACE;
    this.ngControl?.control.markAsTouched();
    // Open the select on ALT + arrow key to match the native <select>
    if ((isOpenKey && !hasModifierKey(event)) || ((this.multiple || event.altKey) && isArrowKey)) {
      event.preventDefault(); // prevents the page from scrolling down when pressing space
      this.onToggleDropdown();
    } else if (event.key.length === 1 && event.key.match(/^[0-9a-zA-Z]+$/)) {
      // implementing debounce
      this.keySelectionValues += event.key;
      clearTimeout(this.keySelectionTimer);
      this.keySelectionTimer = setTimeout(() => {
        this.keyDownSelection(this.keySelectionValues);
        this.keySelectionValues = '';
        this.cdr.detectChanges();
      }, 200);
    } else if (!this.multiple) {
      if (keyCode === HOME || keyCode === END) {
        keyCode === HOME ? this._keyManager.setFirstItemActive() : this._keyManager.setLastItemActive();
        event.preventDefault();
      } else {
        this._keyManager.onKeydown(event);
      }
    }
  }

  /** Handles keyboard events when the selected is open. */
  private _handleOpenKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;

    if (keyCode === HOME || keyCode === END) {
      event.preventDefault();
      keyCode === HOME ? this._keyManager.setFirstItemActive() : this._keyManager.setLastItemActive();
    } else if ((isArrowKey && event.altKey) || keyCode === ESCAPE) {
      // Close the select on ALT + arrow key to match the native <select>
      event.preventDefault();
      this.toggle = false;
    } else if ((keyCode === ENTER || keyCode === SPACE) && this._keyManager.activeItem && !hasModifierKey(event)) {
      event.preventDefault();
      this._keyManager.activeItem._selectViaInteraction();
    } else if (this._multiple && keyCode === A && event.ctrlKey) {
      event.preventDefault();
      const hasDeselectedOptions = this.contentOptions.some(opt => !opt.disabled && !opt.selected);

      this.contentOptions.forEach(option => {
        if (!option.disabled) {
          hasDeselectedOptions ? option.select() : option.deselect();
        }
      });
    } else if (event.key.length === 1 && event.key.match(/^[0-9a-zA-Z]+$/)) {
      // implementing debounce
      this.keySelectionValues += event.key;
      clearTimeout(this.keySelectionTimer);
      this.keySelectionTimer = setTimeout(() => {
        this.keyDownSelection(this.keySelectionValues);
        this.keySelectionValues = '';
      }, 200);
    } else {
      const previouslyFocusedIndex = this._keyManager.activeItemIndex;

      this._keyManager.onKeydown(event);

      if (this._multiple && isArrowKey && event.shiftKey && this._keyManager.activeItem && this._keyManager.activeItemIndex !== previouslyFocusedIndex) {
        this._keyManager.activeItem._selectViaInteraction();
      }
    }
    this._scrollActiveOptionIntoView();
  }

  private keyDownSelection(value: string) {
    const optionsLen = this.contentOptions.length - 1;
    let matchedOption = null;
    const previouslyFocusedIndex = this._keyManager.activeItemIndex;
    for (let i=optionsLen; i>=0; i--) {
      if (matchedOption && (i === previouslyFocusedIndex)) {
        break;
      }
      const option = this.contentOptions.get(i);
      if (option.getLabel().toLowerCase().indexOf(value) === 0) {
        matchedOption = option;
      }
    }

    if (matchedOption) {
      this._keyManager.setActiveItem(matchedOption);
    }
  }

  /** Sets up a key manager to listen to keyboard events on the overlay panel. */
  private _initKeyManager(options: QueryList<PhxOption>) {
    this._keyManager = new ActiveDescendantKeyManager<PhxOption>(options)
      .withVerticalOrientation()
      .withHorizontalOrientation('ltr')
      .withWrap(true)
      .withAllowedModifierKeys(['shiftKey']);
  }

  _initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    Promise.resolve().then(() => {
      this._setSelectionByValue(this.ngControl.value);
      this.ngControl.control.markAsPristine();
    });
  }

  _scrollActiveOptionIntoView(): void {
    const activeOptionIndex = this._keyManager?.activeItemIndex || 0;
    const option = this.contentOptions.toArray()[activeOptionIndex];

    if (this.panel && option) {
      const element = option._getHostElement();
      const panel = this.panel.nativeElement;
      if (activeOptionIndex === 0) {
        panel.scrollTop = 0;
      } else {
        panel.scrollTop = this._getOptionScrollPosition(
          element.offsetTop,
          element.offsetHeight,
          panel.scrollTop,
          panel.offsetHeight
        );
      }
    }
  }

  _getOptionScrollPosition(optionOffset: number, optionHeight: number, currentScrollPosition: number, panelHeight: number): number {
    if (optionOffset < currentScrollPosition) {
      return optionOffset;
    }

    if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
      return Math.max(0, optionOffset - panelHeight + optionHeight);
    }

    return currentScrollPosition;
  }

  _setSelectionByValue(value: any | any[]): void {
    if (!this.filterable) {
      this._selectionModel.clear();
    }

    if (this.multiple && value) {
      if (Array.isArray(value)) {
        value.forEach((currentValue: any) => this._selectValue(currentValue));
      } else {
        this._selectValue(value);
      }
    } else {
      const correspondingOption = this._selectValue(value);
      if (correspondingOption) {
        this._keyManager.setActiveItem(correspondingOption);
      }
    }
    this.cdr.markForCheck();
  }

  writeValue(value: any): void {
    if (this.contentOptions) {
      this._selectionModel.clear();
      this.multiple ? this._setSelectionByValue(value) : this._selectValue(value);
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private _selectValue(value: any): PhxOption | undefined {
    const correspondingOption = this.contentOptions.find((option: PhxOption) => {
      try {
        return option.value != null && option.value === value;
      } catch (error) {
        return false;
      }
    });

    if (correspondingOption) {
      this._selectionModel.select(correspondingOption);
    }
    return correspondingOption;
  }

  _getAriaActiveDescendant(): string | null {
    if (this.toggle && this._keyManager && this._keyManager.activeItem) {
      return this._keyManager.activeItem.id;
    }

    return null;
  }

  panelAttached() {
    this.connectedOverlay.overlayRef.updateSize({maxHeight: this.maxHeight});
    setTimeout(() => {
      this.subscriptions.unsubscribe();
      this.subscriptions = new Subscription();
      const clickSub = fromEvent(window, 'click')
        .pipe(takeUntil(this._destroy))
        .subscribe((event: any) => {
          if (this.toggle && !(this._elementRef.nativeElement.contains(event.target) || (this.panel && this.panel.nativeElement.contains(event.target)))) {
            this._backdropClicked = true;
            this.toggle = false;
          }
        });

      const scroll$ = fromEvent(window, 'scroll', true as any)
      .pipe(
        takeUntil(this._destroy),
        filter(($event: any) =>($event.srcElement !== document) && (!this.panel.nativeElement.contains($event.target))),
        tap(() => {
          this.closeOnScroll ? this.toggle = false : this.connectedOverlay?.overlayRef?.updatePosition(); 
        })
      ).subscribe();

      this.subscriptions.add(scroll$);
      this.subscriptions.add(clickSub);
      this._highlightCorrectOption();
      this.toggled.emit(this.toggle);
      this.panel?.nativeElement.focus();
      this.cdr.markForCheck();
    });
    this.cdr.markForCheck();
    this._scrollActiveOptionIntoView();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
