import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PhxCommonModule } from '@phoenix/ui/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';

import { ClearInputButtonComponent } from './features/clear-input-button.component';
import { ClearInputDirective } from './features/clear-input.directive';
import { CountdownComponent } from './features/countdown.component';
import { CountdownDirective } from './features/countdown.directive';
import { FloatingLabelDirective } from './features/floating-label.directive';
import { ShowPasswordButtonComponent } from './features/show-password-button.component';
import { ShowPasswordToggleDirective } from './features/show-password-toggle.directive';
import { InputAutofocusDirective } from './input.autofocus.directive';
import { InputDirective } from './input.directive';

export { InputDirective } from './input.directive';
export { ClearInputDirective } from './features/clear-input.directive';
export { ClearInputButtonComponent } from './features/clear-input-button.component';
export { ShowPasswordToggleDirective } from './features/show-password-toggle.directive';
export { ShowPasswordButtonComponent } from './features/show-password-button.component';
export { CountdownComponent } from './features/countdown.component';
export { CountdownDirective } from './features/countdown.directive';

const PHX_INPUT_DIRECTIVES = [
  InputDirective,
  ClearInputDirective,
  ShowPasswordToggleDirective,
  CountdownDirective,
  FloatingLabelDirective,
  InputAutofocusDirective
];
const PHX_INPUT_COMPONENTS = [ClearInputButtonComponent, ShowPasswordButtonComponent, CountdownComponent];

@NgModule({
  imports: [CommonModule, PhxCommonModule, ReactiveFormsModule, PhxTranslateModule.forChild()],
  declarations: [PHX_INPUT_DIRECTIVES, PHX_INPUT_COMPONENTS],
  exports: [PHX_INPUT_DIRECTIVES, PHX_INPUT_COMPONENTS]
})
export class InputModule { }
