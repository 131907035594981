import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PhxCommonModule } from '@phoenix/ui/common';

import { TabDirective } from './tab.directive';
import { TabsetComponent } from './tabset.component';

export * from './tabset.interface';
export { TabsetComponent } from './tabset.component';
export { TabDirective } from './tab.directive';

@NgModule({
  declarations: [TabsetComponent, TabDirective],
  imports: [RouterModule, CommonModule, PhxCommonModule, PortalModule],
  exports: [TabsetComponent, TabDirective]
})
export class TabsetModule { }
