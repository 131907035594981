import { Pipe, PipeTransform } from '@angular/core';
/*
 * Join String comma seperate value with one character space
 * Usage:
 *   value | stringJoin
 * Example:
 *   {{ 'a,b,c' | stringJoin }}
 *   formats to: a, b, c
*/
@Pipe({ name: 'stringJoin' })
export class StringJoinPipe implements PipeTransform {
    transform(input: string): string {
        if (!input) {
            return input;
        }
        return input.split(',').join(', ');
    }
}
