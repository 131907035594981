import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PhxCommonModule } from '@phoenix/ui/common';

export { ModalService } from './modal.service';
export * from './modal.options.interface';
export { ModalComponent } from './modal.component';
export { ModalRef } from './modal-ref';
const COMPONENTS = [ModalComponent];

@NgModule({
  imports: [DragDropModule, OverlayModule, PortalModule, A11yModule, CommonModule, PhxTranslateModule.forChild(), PhxCommonModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: [ModalService]
})
export class ModalModule {}
