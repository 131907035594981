import { InvalidFileError } from './file-drag-drop.interface';

/**
 * Represents FileDragDropResponse message, it will have success and error object
 *
 */
export class FileDragDropResponse {
  /**
   *  List of only valid file object
   */
  public success: File[] = [];

  /**
   * Map contains list of invalid files. Map key is file object and value is InvalidFileError
   */
  public error: Map<File, InvalidFileError>;

  constructor(response: Map<File, InvalidFileError> = new Map<File, InvalidFileError>()) {
    this.setResponseByType(response);
  }

  setFile(file: File, fileErrors: InvalidFileError = {}): void {

    if (fileErrors.fileName || fileErrors.doctype || fileErrors.maxsize || fileErrors.maxfiles) {
      if (!this.error) {
        this.error = new Map<File, InvalidFileError>();
      }
      this.error.set(file, fileErrors);
      return;
    }
    this.success.push(file);
  }

  getSuccess(): File[] {
    return this.success;
  }

  getError(): Map<File, InvalidFileError> {
    return this.error;
  }

  private setResponseByType(response: Map<File, InvalidFileError>) {
    response.forEach((fileErrors, file) => {
      this.setFile(file, fileErrors);
    });
  }

}
