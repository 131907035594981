import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { BackToTopComponent } from './back-to-top.component';

export { BackToTopComponent } from './back-to-top.component';

@NgModule({
  declarations: [BackToTopComponent],
  imports: [CommonModule, PhxTranslateModule.forChild()],
  exports: [BackToTopComponent]
})
export class BackToTopModule { }
