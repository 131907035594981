import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateInitService } from './translate-init.service';

export { defaultTranslations } from './default-translations';

// Simply an alias for TranslateService, so that delivery teams don't have to import from @ngx-translate
@Injectable()
export class PhxTranslateService extends TranslateService {}

export {TranslationChangeEvent} from '@ngx-translate/core';

export const BUNDLE_URL_TOKEN = new InjectionToken<string>('translationBundleUrl');

export function HttpLoaderFactory(http: HttpClient, url: string) {
  return new TranslateHttpLoader(http, url);
}

/**
 * USAGE: Must be imported with forRoot() in appModule, then also imported with forChild() in sharedModule
 */
@NgModule({
  imports: [HttpClientModule, TranslateModule],
  providers: [{ provide: PhxTranslateService, useExisting: TranslateService }],
  exports: [TranslateModule]
})
export class PhxTranslateModule {
  static forRoot(): ModuleWithProviders<PhxTranslateModule> {
    return {
      ngModule: PhxTranslateModule,
      providers: [
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient, BUNDLE_URL_TOKEN]
          }
        }).providers
      ]
    };
  }

  static forChild(): ModuleWithProviders<PhxTranslateModule> {
    return {
      ngModule: PhxTranslateModule,
      providers: [
        TranslateModule.forChild({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient, BUNDLE_URL_TOKEN]
          }
        }).providers
      ]
    };
  }

  // injecting translateInitService so that constructor is called to initialize translations one time across root app
  constructor(private translateInitService: TranslateInitService) {}
}
