import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldMessageComponent } from './field-message.component';
import { FieldMessageDirective } from './field-message.directive';
import { PhxCommonModule } from '@phoenix/ui/common';

export { FieldMessageComponent } from './field-message.component';
export { FieldMessageDirective } from './field-message.directive';
export * from './field-message.interface';

@NgModule({
    declarations: [FieldMessageComponent, FieldMessageDirective],
    imports: [CommonModule, PhxCommonModule],
    exports: [FieldMessageComponent, FieldMessageDirective]
})
export class FieldMessageModule { }
