import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';
import { PhxCommonModule } from '@phoenix/ui/common';
import { A11yModule } from '@angular/cdk/a11y';

export { RadioComponent } from './radio.component';

@NgModule({
    imports: [PhxCommonModule, CommonModule, A11yModule],
    declarations: [RadioComponent],
    exports: [RadioComponent]
})
export class RadioModule { }
