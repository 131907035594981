import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { PhxCommonService } from '@phoenix/ui/common';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'phx-tab'
})
export class TabDirective {
  @ContentChild('phxTabContent') contentTpl: TemplateRef<any>;
  @ContentChild('phxTabTitle') titleTpl: TemplateRef<any>;
  @Input() heading: string;
  @Input() content: string;
  @Input() route: string;
  @Input() disabled: boolean;
  /** optional: set to true if the tabpanel content should be included in keyboard taborder. Typically a good practice for ADA if the tabpanel content has no focusable element. **/
  @Input() tabbableContent: boolean;

  @Input() id = this.phxCommonService.getRandID('Tab');

  constructor(private phxCommonService: PhxCommonService) { }
}
