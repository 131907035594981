export enum CalendarView {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

/**
 * ActiveView represents the current view in display of a calendar
 * Smallest Granularity is Month
 */
export interface ActiveView {
  month: number;
  year: number;
  yearRange: [number, number];
  calendarView?: CalendarView;
}

export interface CalendarCell {
  value: number;
  displayValue: string;
  enabled: boolean;
  ariaLabel?: string;
  cssClasses?: string[];
  hidden?: boolean;
  timestamp?: number;
}
