import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { MessageComponent } from './message.component';
export { MessageComponent } from './message.component';

@NgModule({
    declarations: [MessageComponent],
    imports: [CommonModule, PhxTranslateModule],
    exports: [MessageComponent]
})
export class MessageModule { }
