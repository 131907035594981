import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit, Optional,
  Renderer2, Self
} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

const charTypeRegexMapping = {
  9: '[0-9]',
  A: '[A-Za-z]',
  '*': '[A-Za-z]|[0-9]'
};

// As this mask implementation has different requirements and behaviour than that of currency mask, it uses PrimeNG implementation instead of Cleave
/**
 * Custom mask directive that allows user to specify the format of the mask.
 * Should be used for phone numbers and custom pattern masks.
 * Note: Please ensure when using this directive that you provide a label which describes the mask pattern for accessibility
 * For currency masking, see Currency Mask directive
 */
@Directive({
  selector: 'input[phxMask]',
  host: {
    '(blur)': 'onInputBlur($event)',
    '(keydown)': 'onKeyDown($event)',
    '(keypress)': 'onKeyPress($event)',
    '(input)': 'onInputChange($event)',
    '(focus)': 'onInputFocus($event)',
    '(paste)': 'handleInputChange($event)'
  }
})
export class MaskDirective implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly _destroy = new Subject<void>();
  value: any;

  filled: boolean;

  tests: any[];

  partialPosition: any;

  firstNonMaskPos: number;

  lastRequiredNonMaskPos: any;

  len: number;

  oldVal: string;

  buffer: any;

  defaultBuffer: string;

  focusText: string;

  androidChrome: boolean;

  focused: boolean;

  private _mask: string;

  /**
   * Custom mask format with the following rules:
   * 'A' limits to alphabetic characters,
   * '9' limits to numeric characters,
   * '*' limits to alphanumeric,
   * Non-alphanumeric characters are parsed as delimiters to be automatically added as the user types.
   * To denote optional values of the mask pattern, place a '?' directly after the mandatory mask pattern,
   * e.g. (999) 999-9999? ext. 999 , the extension part of the mask is completely optional
   */
  @Input() set phxMask(val: string) {
    this._mask = val;
    this.initMask();
  }

  get phxMask(): string {
    return this._mask;
  }

  /**
   * The unfilled slot characters to use that guide the user as they are typing.
   * E.g. 'mm/dd/yyyy', user types '12', displayed element value is then '12/dd/yyyy'.
   * When mask pattern exceeds maskSlotChars length, the first slot character will be used.
   */
  @Input() maskSlotChars = '_';

  /**
   * Clears incomplete input values on blur
   */
  @Input() maskAutoClear = true;

  // TODO: either delete below properties or uncomment input depending on if delivery teams end up needing this
  // /**
  //  * Whether to use formatted value with delimiters in bound value.
  //  * When false (default), persists unformatted value
  //  */
  // @Input()
  private persistFormattedValue = false;

  // /**
  //  * Whether or not incomplete input mask values will be persisted to control value
  //  */
  // @Input()
  private allowInvalidValue = false;

  // /**
  //  * When no placeholder is specified, default to use mask pattern as placeholder
  //  */
  // @Input() defaultMaskPlaceholder = true;

  @Input() set placeholder(value: string) {
    this.inputPlaceholder = value;
  }

  @HostBinding('attr.placeholder') inputPlaceholder;

  private _onChange = (value: any) => {
  };
  private _onTouched = () => {
  };

  constructor(private elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2, @Self() @Optional() private ngControl: NgControl) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: any) {
    if (value) {
      this.elRef.nativeElement.value = value;
      this.value = value;
      this._onChange(value);
      this.checkVal(true);
    } else {
      this.elRef.nativeElement.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elRef.nativeElement, 'disabled', isDisabled);
  }

  ngOnInit() {
    const ua = navigator.userAgent;
    this.androidChrome = /chrome/i.test(ua) && /android/i.test(ua);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      this.ngControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy))
        .subscribe((val) => {
          if (!val || val.trim() === '') {
            this.clearBuffer(0, this.len);
          }
        });
    }

    this.initMask();
    // TODO: remove or uncomment this depending on delivery team need to automatically set placeholder
    // if (!this.inputPlaceholder && this.defaultMaskPlaceholder && this.phxMask) {
    //   this.inputPlaceholder = this.phxMask;
    // }
  }

  initMask() {
    this.tests = [];
    this.partialPosition = this.phxMask.length;
    this.len = this.phxMask.length;
    this.firstNonMaskPos = null;

    const maskTokens = this.phxMask.split('');
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c === '?') {
        this.len--;
        this.partialPosition = i;
      } else if (charTypeRegexMapping[c]) {
        this.tests.push(new RegExp(charTypeRegexMapping[c]));
        if (this.firstNonMaskPos === null) {
          this.firstNonMaskPos = this.tests.length - 1;
        }
        if (i < this.partialPosition) {
          this.lastRequiredNonMaskPos = this.tests.length - 1;
        }
      } else {
        this.tests.push(null);
      }
    }

    this.buffer = [];
    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i];
      if (c !== '?') {
        if (charTypeRegexMapping[c]) {
          this.buffer.push(this.getPlaceholder(i));
        } else {
          this.buffer.push(c);
        }
      }
    }
    this.defaultBuffer = this.buffer.join('');
  }

  checkVal(allow?: boolean) {
    // try to place characters where they belong
    const test = this.elRef.nativeElement.value;
    let lastMatch = -1;
    let i;
    let c;
    let pos;

    for (i = 0, pos = 0; i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
        while (pos++ < test.length) {
          c = test.charAt(pos - 1);
          if (this.tests[i].test(c)) {
            this.buffer[i] = c;
            lastMatch = i;
            break;
          }
        }
        if (pos > test.length) {
          this.clearBuffer(i + 1, this.len);
          break;
        }
      } else {
        if (this.buffer[i] === test.charAt(pos)) {
          pos++;
        }
        if (i < this.partialPosition) {
          lastMatch = i;
        }
      }
    }
    if (allow && this.value) {
      this.writeBuffer();
    } else if (lastMatch + 1 < this.partialPosition) {
      if (this.maskAutoClear || this.buffer.join('') === this.defaultBuffer) {
        // Invalid value. Remove it and replace it with the
        // mask, which is the default behavior.
        if (this.elRef.nativeElement.value) {
          this.elRef.nativeElement.value = '';
        }
        this.clearBuffer(0, this.len);
      } else {
        // Invalid value, but we opt to show the value to the
        // user and allow them to correct their mistake.
        this.writeBuffer();
      }
    } else {
      this.writeBuffer();
      this.elRef.nativeElement.value = this.elRef.nativeElement.value.substring(0, lastMatch + 1);
    }
    return this.partialPosition ? i : this.firstNonMaskPos;
  }

  writeBuffer() {
    this.elRef.nativeElement.value = this.buffer.join('');
  }

  clearBuffer(start, end) {
    let i;
    for (i = start; i < end && i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
      }
    }
  }

  caret(first?: number, last?: number) {
    let range;
    let begin;
    let end;

    if (!this.elRef.nativeElement.offsetParent || this.elRef.nativeElement !== document.activeElement) {
      return;
    }

    if (typeof first === 'number') {
      begin = first;
      end = typeof last === 'number' ? last : begin;
      if (this.elRef.nativeElement.setSelectionRange) {
        this.elRef.nativeElement.setSelectionRange(begin, end);
      } else if (this.elRef.nativeElement['createTextRange']) {
        range = this.elRef.nativeElement['createTextRange']();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', begin);
        range.select();
      }
    } else {
      if (this.elRef.nativeElement.setSelectionRange) {
        begin = this.elRef.nativeElement.selectionStart;
        end = this.elRef.nativeElement.selectionEnd;
      } else if (document['selection'] && document['selection'].createRange) {
        range = document['selection'].createRange();
        begin = 0 - range.duplicate().moveStart('character', -100000);
        end = begin + range.text.length;
      }

      return {begin: begin, end: end};
    }
  }

  getPlaceholder(i: number) {
    if (i < this.maskSlotChars.length) {
      return this.maskSlotChars.charAt(i);
    }
    return this.maskSlotChars.charAt(0);
  }

  onInputBlur(e) {
    this._onTouched();
    this.checkVal();
    this.updateFilledState();

    if (this.elRef.nativeElement.value !== this.focusText || this.elRef.nativeElement.value !== this.value) {
      this.updateModel(e);
      const event = document.createEvent('HTMLEvents');
      event.initEvent('change', true, false);
      this.elRef.nativeElement.dispatchEvent(event);
    }
  }

  onInputFocus(event) {
    setTimeout(() => {
      const pos = this.getFirstUnfilledPosition();
      this.caret(pos);
    });
  }

  onInputChange(event) {
    if (this.androidChrome) {
      this.handleAndroidInput(event);
    } else {
      this.handleInputChange(event);
    }
    this.changeDetectorRef.detectChanges();
  }

  handleInputChange(event) {
    setTimeout(() => {
      const pos = this.checkVal(true);
      this.caret(pos);
      this.tryUpdateModel(event);
    }, 0);
  }

  handleAndroidInput(e) {
    const curVal = this.elRef.nativeElement.value;
    const pos = this.caret();
    if (this.oldVal && this.oldVal.length && this.oldVal.length > curVal.length) {
      // a deletion or backspace happened
      this.checkVal(true);
      while (pos.begin > 0 && !this.tests[pos.begin - 1]) {
        pos.begin--;
      }
      if (pos.begin === 0) {
        while (pos.begin < this.firstNonMaskPos && !this.tests[pos.begin]) {
          pos.begin++;
        }
      }

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.tryUpdateModel(e);
      }, 0);
    } else {
      this.checkVal(true);
      while (pos.begin < this.len && !this.tests[pos.begin]) {
        pos.begin++;
      }

      setTimeout(() => {
        this.caret(pos.begin, pos.begin);
        this.tryUpdateModel(e);
      }, 0);
    }
  }

  onKeyDown(e) {
    const k = e.which || e.keyCode;
    let pos;
    let begin;
    let end;
    const iPhone = /iphone/i.test(navigator.userAgent);
    this.oldVal = this.elRef.nativeElement.value;

    // backspace, delete, and escape get special treatment
    if (k === 8 || k === 46 || (iPhone && k === 127)) {
      pos = this.caret();
      begin = pos.begin;
      end = pos.end;

      if (end - begin === 0) {
        begin = k !== 46 ? this.seekPrev(begin) : (end = this.seekNext(begin - 1));
        end = k === 46 ? this.seekNext(end) : end;
      }

      this.clearBuffer(begin, end);
      this.shiftL(begin, end - 1);
      this.tryUpdateModel(e);
      e.preventDefault();
    } else if (k === 13) {
      // enter
      this.onInputBlur(e);
      this.tryUpdateModel(e);
    } else if (k === 27) {
      // escape
      this.elRef.nativeElement.value = this.focusText;
      this.caret(0, this.checkVal());
      this.tryUpdateModel(e);
      e.preventDefault();
    }
    this.changeDetectorRef.detectChanges();
  }

  onKeyPress(e) {
    const k = e.which || e.keyCode;
    const pos = this.caret();
    let p;
    let c;
    let next;
    // let completed;

    if (e.ctrlKey || e.altKey || e.metaKey || k < 32 || (k > 34 && k < 41)) {
      // Ignore
      return;
    } else if (k && k !== 13) {
      if (pos.end - pos.begin !== 0) {
        this.clearBuffer(pos.begin, pos.end);
        this.shiftL(pos.begin, pos.end - 1);
      }

      p = this.seekNext(pos.begin - 1);
      if (p < this.len) {
        c = String.fromCharCode(k);
        if (this.tests[p].test(c)) {
          this.shiftR(p);

          this.buffer[p] = c;
          this.writeBuffer();
          next = this.seekNext(p);

          if (/android/i.test(navigator.userAgent)) {
            // Path for CSP Violation on FireFox OS 1.1
            const proxy = () => {
              this.caret(next);
            };

            setTimeout(proxy, 0);
          } else {
            this.caret(next);
          }
          // if (pos.begin <= this.lastRequiredNonMaskPos) {
          //   completed = this.isCompleted();
          // }
        }
      }
      e.preventDefault();
    }
    this.tryUpdateModel(e);
    this.updateFilledState();
    this.changeDetectorRef.detectChanges();
  }

  seekNext(pos) {
    while (++pos < this.len && !this.tests[pos]) {
      // do nothing
    }
    return pos;
  }

  seekPrev(pos) {
    while (--pos >= 0 && !this.tests[pos]) {
      // do nothing
    }
    return pos;
  }

  shiftL(begin: number, end: number) {
    let i;
    let j;

    if (begin < 0) {
      return;
    }

    for (i = begin, j = this.seekNext(end); i < this.len; i++) {
      if (this.tests[i]) {
        if (j < this.len && this.tests[i].test(this.buffer[j])) {
          this.buffer[i] = this.buffer[j];
          this.buffer[j] = this.getPlaceholder(j);
        } else {
          break;
        }

        j = this.seekNext(j);
      }
    }
    this.writeBuffer();
    this.caret(Math.max(this.firstNonMaskPos, begin));
  }

  shiftR(pos) {
    let i;
    let c;
    let j;
    let t;

    for (i = pos, c = this.getPlaceholder(pos); i < this.len; i++) {
      if (this.tests[i]) {
        j = this.seekNext(i);
        t = this.buffer[i];
        this.buffer[i] = c;
        if (j < this.len && this.tests[j].test(t)) {
          c = t;
        } else {
          break;
        }
      }
    }
  }

  isCompleted(): boolean {
    for (let i = this.firstNonMaskPos; i <= this.lastRequiredNonMaskPos; i++) {
      if (this.tests[i] && this.buffer[i] === this.getPlaceholder(i)) {
        return false;
      }
    }
    return true;
  }

  updateModel(e) {
    let updatedValue;
    if (!this.isCompleted() && !this.allowInvalidValue) {
      updatedValue = '';
    } else {
      updatedValue = this.persistFormattedValue ? e.target.value : this.getUnmaskedValue();
    }
    if (updatedValue !== null && updatedValue !== undefined) {
      this.value = updatedValue;
      this._onChange(this.value);
    }
  }

  updateFilledState() {
    this.filled = this.elRef.nativeElement && this.elRef.nativeElement.value !== '';
  }

  getUnmaskedValue() {
    const unmaskedBuffer = [];
    for (let i = 0; i < this.buffer.length; i++) {
      const c = this.buffer[i];
      if (this.tests[i] && c !== this.getPlaceholder(i)) {
        unmaskedBuffer.push(c);
      }
    }

    return unmaskedBuffer.join('');
  }

  getFirstUnfilledPosition(): number {
    for (let i = 0; i < this.buffer.length; i++) {
      const c = this.buffer[i];
      if (c === this.getPlaceholder(i) && this.tests[i] !== null) {
        return i;
      }
    }
    return this.elRef.nativeElement.value.length;
  }

  tryUpdateModel(e) {
    if (this.allowInvalidValue || this.isCompleted() || this.getUnmaskedValue() === '') {
      this.updateModel(e);
    }
  }

  ngOnDestroy() {
    this._destroy.complete();
  }
}
