import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs';

export interface CsrfConfig {
    token: string,
    header?: string
}

/**
 * PHOENIX CSRF Config Usage
 * - Static usage in index.html. Add following meta tags in header and replace content values by server
 ```html
 <!-- <meta name="csrf-header" content="____HEADE_NAME____"> -->
 <!-- <meta name="csrf-token" content="____TOKEN____"> -->
 ```
 * - Dynamic usage by `PhxCsrfConfigService`:   
 * Inject `PhxCsrfConfigService` in your code and set header(optional) and token value in runtime using `setCsrfConfigs(newConfig: PhxCsrfConfig) method`
 */
@Injectable({
    providedIn: 'root',
})
export class PhxCsrfConfigService {
    readonly csrfConfigs$ = new BehaviorSubject<CsrfConfig>({ token: null, header: null });

    setCsrfConfig(newConfig: CsrfConfig) {
        this.csrfConfigs$.next({ ...this.csrfConfigs$.getValue(), ...newConfig });
    }

}
