import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@phoenix/ui/button';
import { CheckboxModule } from '@phoenix/ui/checkbox';
import { PhxCommonModule } from '@phoenix/ui/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { IntroComponent } from './intro.component';
import { IntroDirective } from './intro.directive';
import { IntroService } from './intro.service';

export { IntroComponent } from './intro.component';
export { IntroDirective } from './intro.directive';
export { Intro, Step } from './intro.interface';
export { IntroService } from './intro.service';

@NgModule({
  declarations: [IntroDirective, IntroComponent],
  imports: [CommonModule, PhxCommonModule, PhxTranslateModule.forChild(), FormsModule, ButtonModule, CheckboxModule],
  exports: [IntroDirective, IntroComponent],
  providers: [IntroService]
})
export class IntroModule { }
