import { Injectable } from '@angular/core';
import { ActiveView, CalendarCell, CalendarView } from './calendar/calendar.interface';
import { InternalDatepickerOptions } from './datepicker-options.interface';

import moment from 'moment-mini';
import { unitOfTime } from 'moment-mini';
import { PhxUtil } from '@phoenix/ui/common';

// TODO: Change name to DatePickerUtils, make all methods static since they are pure functions, UNIT TEST ALL METHODS HERE
/**
 *  Contains Datepicker helper pure functions
 *  Consolidates all usages of momentJS
 */
@Injectable()
export class DateUtilityService {
  // knownFormats = [
  //   'll',
  //   'YYYY.MM.DD',
  //   'MM/DD/YYYY',
  //   'MMM D, Y',
  //   'MMM D, YYYY'
  // ];
  weekDayKeys = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  monthKeys = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  currentFormat = 'MM/DD/YYYY';

  isSameDate(date1: Date | string, date2: Date | string): boolean {
    const d1 = this.parseDate(date1);
    const d2 = this.parseDate(date2);
    return d1 && d2 && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }

  // TODO: unit test this
  createDayPickerGrid(view: ActiveView, options?: InternalDatepickerOptions): CalendarCell[][] {
    if (!view) {
      return;
    }
    const grid = [];

    const activeMoment = moment()
      .year(view.year)
      .month(view.month)
      .date(1);
    const tmpMoment = activeMoment.clone();
    /*
            Since we want to display Sun-Mon for all weeks (potentially including values from previous/next months),
            if first day of active month is not a Sunday(0), we will shift this moment to the previous month's last Sunday
         */
    tmpMoment.subtract(tmpMoment.day(), 'd');

    const daysInMonth = activeMoment.daysInMonth();
    let dateOfMonth = 0;
    while (dateOfMonth < daysInMonth) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        let otherMonth = false;
        if (tmpMoment.month() !== activeMoment.month()) {
          otherMonth = true;
        } else {
          dateOfMonth++;
        }
        const dayLabel = `${moment.months(view.month)} ${tmpMoment.date()}, ${view.year}`;
        const daycell: CalendarCell = {
          value: tmpMoment.date(),
          displayValue: tmpMoment.date() + '',
          ariaLabel: dayLabel,
          enabled: !otherMonth && this.isDateEnabled(options, tmpMoment, CalendarView.DAY),
          hidden: otherMonth, // && !options.showOtherMonthDays
          timestamp: new Date(tmpMoment.toDate()).setHours(0, 0, 0, 0)
        };
        week.push(daycell);
        tmpMoment.add(1, 'd');
      }
      grid.push(week);
    }
    return grid;
  }

  // TODO: unit test this
  /**
   * Creates a month picker grid, Two columns and 6 rows, first column will hold Jan-June, second column July-Dec
   */
  createMonthPickerGrid(view: ActiveView, options?: InternalDatepickerOptions): CalendarCell[][] {
    if (!view) {
      return;
    }
    const activeMoment = moment().year(view.year);
    const grid = [[]];
    for (let i = 0; i < 12; i++) {
      const monthCell: CalendarCell = {
        value: i,
        displayValue: moment.months(i),
        ariaLabel: `${moment.months(i)} ${view.year}`,
        enabled: this.isDateEnabled(options, activeMoment.month(i), CalendarView.MONTH)
      };
      if (options.mode === 'month') {
        const dayLabel = moment(`${view.year}-${i + 1}-01`, 'YYYY-MM-DD');
        monthCell.timestamp = new Date(dayLabel.toDate()).setHours(0, 0, 0, 0);
      }
      grid[0].push(monthCell);
    }
    return grid;
  }

  // TODO: unit test this
  /**
   * Creates a year picker grid. Two columns and 5 rows to hold 10 years, first column holds year 1-5, second column 6-10
   */
  createYearPickerGrid(view: ActiveView, options?: InternalDatepickerOptions): CalendarCell[][] {
    if (!view) {
      return;
    }
    const activeMoment = moment();
    const grid = [];
    let yearoffset = 0;
    for (let i = 0; i < 5; i++) {
      grid[i] = [];
      for (let j = 0; j < 2; j++) {
        const year = view.yearRange[0] + yearoffset++;
        const yearcell: CalendarCell = {
          value: year,
          displayValue: year + '',
          enabled: this.isDateEnabled(options, activeMoment.year(year), CalendarView.YEAR)
        };
        grid[i].push(yearcell);
      }
    }
    return grid;
  }

  closestValidCalendarView(options: InternalDatepickerOptions): ActiveView {
    const today = moment();
    const minDate = options.minDate ? moment(options.minDate, this.currentFormat) : null;
    const maxDate = options.maxDate ? moment(options.maxDate, this.currentFormat) : null;
    if (this.isDateEnabled(options, today, CalendarView.MONTH)) {
      return this.createViewFromMoment(today);
    } else if (minDate && today.isBefore(minDate)) {
      return this.createViewFromMoment(moment(options.minDate));
    } else {
      return this.createViewFromMoment(moment(maxDate));
    }
  }

  createViewFromMoment(m: moment.Moment): ActiveView {
    return {
      month: m.month(),
      year: m.year(),
      yearRange: this.parseYearRange(m.year())
    };
  }

  parseYearRange(year: number): [number, number] {
    const start = year - ((year - 1) % 10);
    const end = start + 9;
    return [start, end];
  }

  isDateRangeEnabled(options: InternalDatepickerOptions, [start, end]): boolean {
    for (let i = start; i <= end; i++) {
      if (this.isDateEnabled(options, moment().year(i), CalendarView.YEAR)) {
        return true;
      }
    }
    return false;
  }

  isDateEnabled(
    options: InternalDatepickerOptions,
    date: moment.Moment | Date | string,
    granularity: CalendarView,
    format = this.currentFormat
  ): boolean {
    // Use default format MM/DD/YYYY if date is type string and format is undefined
    format = typeof date === 'string' && !format ? 'MM/DD/YYYY' : format;
    const target = this.parseMomentCompatibleDate(date, format);
    if (!options) {
      return true;
    }
    if (options.disableWeekend && granularity === CalendarView.DAY && (target.day() === 0 || target.day() === 6)) {
      return false;
    }
    if (options.disableDateSet && options.disableDateSet.size && granularity === CalendarView.DAY) {
      if (options.disableDateSet.has(this.getDateKey(target))) {
        return false;
      }
    }
    let minDate;
    let maxDate;
    if (options.minDate) {
      minDate = this.parseMomentCompatibleDate(options.minDate, format);
    }
    if (options.maxDate) {
      maxDate = this.parseMomentCompatibleDate(options.maxDate, format);
    }
    if (minDate && maxDate) {
      return target.isBetween(minDate, maxDate, granularity, '[]');
    } else if (minDate) {
      return target.isSameOrAfter(minDate, granularity);
    } else if (maxDate) {
      return target.isSameOrBefore(maxDate, granularity);
    }
    return true;
  }

  parseMomentCompatibleDate(date: string | Date | moment.Moment, format = this.currentFormat): moment.Moment {
    let target: moment.Moment;
    if (moment.isMoment(date)) {
      target = date;
    } else if (PhxUtil.isDate(date)) {
      const dateStr = (date as Date).toISOString().split('T')[0]; // convert it to format known by moment as ISO 8601
      target = moment(dateStr);
    } else {
      if (format) {
        // When trying to parse with different locale loaded "Jan-01-2022" results in an invalid moment object
        // Use default locale to parse model values in this case as model values are always in phx_en
        target = moment(date, format).isValid() ? moment(date, format) : moment(date, format, 'phx_en');
      } else {
        target = moment(date);
      }
    }
    return target;
  }

  parseDate(date: string | Date): Date {
    // TODO: should be able to handle all formats? why the need for knownformats array
    const dateMoment = moment(date, this.currentFormat, 'phx_en');
    return dateMoment.isValid() ? dateMoment.toDate() : null;
  }

  // TODO: revisit this because moment.isValid is not at very robust
  validate(date: string | Date, format = this.currentFormat, strict?: boolean): boolean {
    if (format) {
      return moment(date, format, !!strict).isValid();
    } else {
      return moment(date).isValid();
    }
  }

  format(date: Date | string, format = this.currentFormat, defaultLocale?: boolean): string {
    const momentObj = moment(date, format);
    if (date && momentObj.isValid()) {
      if (defaultLocale) {
        return momentObj.locale('phx_en').format(format);
      }
      return momentObj.format(format);
    }
    return '';
  }

  getDateKey(date: Date | moment.Moment) {
    if (moment.isMoment(date)) {
      return `${date.month()}${date.date()}${date.year()}`;
    } else {
      return `${date.getMonth()}${date.getDate()}${date.getFullYear()}`;
    }
  }

  addToDate(date: Date, unit: unitOfTime.Base, amount: number): Date {
    return moment(date)
      .add(amount, unit)
      .toDate();
  }

  subtractFromDate(date: Date, unit: unitOfTime.Base, amount: number): Date {
    return moment(date)
      .subtract(amount, unit)
      .toDate();
  }

  nextValidDate(date: Date, options: InternalDatepickerOptions): Date {
    let nextDate = new Date(date.getTime());
    nextDate = this.addToDate(nextDate, 'd', 1);
    while (!this.isDateEnabled(options, nextDate, CalendarView.DAY)) {
      nextDate = this.addToDate(nextDate, 'd', 1);
    }
    return nextDate;
  }

  previousValidDate(date: Date, options: InternalDatepickerOptions): Date {
    let prevDate = new Date(date.getTime());
    prevDate = this.subtractFromDate(prevDate, 'd', 1);
    while (!this.isDateEnabled(options, prevDate, CalendarView.DAY)) {
      prevDate = this.subtractFromDate(prevDate, 'd', 1);
    }
    return prevDate;
  }

  convertToDate(date: Date | string, format = this.currentFormat): Date {
    return moment(date, format).isValid() ? moment(date, format).toDate() : null;
  }
}
