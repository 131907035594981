import {Component, EventEmitter, HostBinding, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'phx-show-password-button',
  template: `
    <div class="px-input-group-append px-icon-addon">
      <button (click)="handleClick()" type="button">
        <i class="pxi" [ngClass]="{ 'px-eye': showPasswordToggled, 'px-eye-slash': !showPasswordToggled }" title="{{
          'phx.input.passwordToggle.' + (showPasswordToggled ? 'hidePasswordText' : 'showPasswordText') | translate
        }}"></i>
        <span class="sr-only">{{
          'phx.input.passwordToggle.' + (showPasswordToggled ? 'hidePasswordText' : 'showPasswordText') | translate
        }}</span>
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class ShowPasswordButtonComponent {
  showPasswordToggled = false;
  disabled;

  @HostBinding('class.px-icon-component') iconWrapperClass = true;

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  handleClick() {
    if (!this.disabled) {
      this.showPasswordToggled = !this.showPasswordToggled;
      this.clickEmitter.next();
    }
  }
}
