import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ScalarsPipe} from '@phoenix/ui/common';
import {FileDragDropResponse} from './file-drag-drop-response.class';
import {FileDragDropOptions, InvalidFileError} from './file-drag-drop.interface';

export declare type AnnouncedAction = 'click' | 'delete' | 'clear' | 'reset';

export interface IActionResponse {
  action: AnnouncedAction;
  id: string;
  fileList?: FileDragDropResponse;
}

@Injectable({
  providedIn: 'root'
})
export class FileDragDropService {
  private scalarPipe: ScalarsPipe = new ScalarsPipe();

  private announcedActionSubject = new Subject<IActionResponse>();

  public announcedAction$ = this.announcedActionSubject.asObservable();

  constructor() {
  }

  getFiles(input: HTMLInputElement): FileList {
    if (input.files) {
      return input.files;
    }
  }

  filterFiles(files: FileList, options: FileDragDropOptions, fileResponse: FileDragDropResponse = new FileDragDropResponse()): FileDragDropResponse {
    const fileLength = files.length;
    let index = -1;
    const successList: FileDragDropResponse = options.preserveFiles ? fileResponse : new FileDragDropResponse();
    const fileCount = successList.success.length + (successList.error ? successList.error.size : 0);
    while (++index < fileLength) {
      const fileErrors: InvalidFileError = {};
      const file = this.filterByDocTypeOrFileSize(files.item(index), fileErrors, options);
      if (file instanceof File) {
        if (index + fileCount >= options.maxFileCount) {
          fileErrors.maxfiles = true;
        }
        successList.setFile(file, fileErrors);
      }
    }
    return successList;
  }

  private filterByDocTypeOrFileSize(file: File, fileErrors: InvalidFileError, options: FileDragDropOptions): boolean | File {
    if (file === null) {
      return false;
    }

    const fileName = file.name;
    const dotLastIndex = fileName.lastIndexOf('.');

    let isFileNameValid = true;
    if (options.fileNameRegEx) {
      const fileNameRegEx = typeof options.fileNameRegEx === 'string' ? new RegExp(options.fileNameRegEx) : options.fileNameRegEx;
      isFileNameValid = fileNameRegEx.test(dotLastIndex  === -1 ? fileName : fileName.slice(0, dotLastIndex));
    }
    fileErrors.fileName = !isFileNameValid;

    if (dotLastIndex === -1) {
      // Allow files with no extensions if flag is set to true or return false
      return options.allowNoExtension ? file : false;
    }
    // Remove spaces in options.accepts and options.rejects and convert to lowercase for matches.
    options.accept = options.accept.toLocaleLowerCase().replace(/ /g, '');
    options.reject = options.reject.toLocaleLowerCase().replace(/ /g, '');
    const fileType = fileName.slice(dotLastIndex).toLowerCase();
    let isFileExtMatched;
    if (!options.accept || options.accept.length === 0  || options.accept === '*') {
      isFileExtMatched = true;
    } else {
      isFileExtMatched = options.accept.split(',').includes(fileType);
    }

    if (options.reject) {
      isFileExtMatched = !options.reject.split(',').includes(fileType);
    }
    const maxFileSizeInBytes = this.scalarPipe.transform(options.maxFileSize);

    fileErrors.doctype = !isFileExtMatched;
    fileErrors.maxsize = file.size > maxFileSizeInBytes;

    return file;
  }

  /**
   * Called from both the custom and the default template.
   * This is needed in case the custom template wants to delete the file
   * from the success file list.
   * @param fileResponseList: The file list of type FileDragDropResponse
   * @param index: The index number of the item in the success array of the FileDragDropResponse
   * @param id: The unique id of the file upload.
   * @param errorFile: Remove file from error list
   */
  announceDelete(fileResponseList: FileDragDropResponse, index: number, id: string, errorFile?: File) {
    if (errorFile) {
      fileResponseList.error.delete(errorFile);
    } else {
      fileResponseList.success.splice(index, 1);
    }
    this.announcedActionSubject.next({action: 'delete', id, fileList: fileResponseList});
  }

  /**
   * Called from the custom template. This is needed in case the custom template needs
   * to clear the file input.
   * @param id: The unique id of the file upload.
   */
  announceClear(id: string) {
    this.announcedActionSubject.next({action: 'clear', id});
  }

  reset(id: string) {
    this.announcedActionSubject.next({action: 'reset', id});
  }

  /**
   * Called from the custom template. If the custom template needs to open the file upload
   * dialog on click. Then they need to call this function.
   * @param id: The unique id of the file upload.
   */
  announceClick(id: string) {
    this.announcedActionSubject.next({action: 'click', id});
  }
}
