import { Injectable, Optional, Inject, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
    mockApiServer: string;
    excludedBasePaths = ['assets'];

    constructor(@Optional() @Inject("MOCK_API_SERVER") mockApiServer: string) {
        this.mockApiServer = mockApiServer;
        if (this.mockApiServer) {
            console.info('>> Mock api enabled...:', this.mockApiServer);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq;
        if (this.mockApiServer && req.url.indexOf('http://') < 0  && req.url.indexOf('https://') < 0) {
            let reqUrl = req.url;
            reqUrl = reqUrl.split('/')[0];
            if (this.excludedBasePaths.indexOf(reqUrl) === 0) {
                newReq = req.clone();
                console.info('>> NOT using Mock API Server due to exception rule of excluded list: ', req.url);
            } else {
                const newUrl = Location.joinWithSlash(this.mockApiServer, req.url);
                newReq = req.clone({ url: newUrl });
                console.info('>> Using Mock API Server', newReq);
            }
        } else {
            newReq = req.clone();
        }
        return next.handle(newReq);
    }
}
