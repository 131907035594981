import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {PhxTheme, PhxThemeService} from '@phoenix/ui/common';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'phx-text-toggle-button',
  template: `
    <button class="px-btn px-py-0 px-border-0 px-btn-sm px-btn-link">
      {{
        truncated
          ? toggleMoreLabel || ('phx.textToggle.moreLabel' | translate)
          : toggleLessLabel || ('phx.textToggle.lessLabel' | translate)
      }}
      <span class="px-sr-only">{{'phx.textToggle.ariaConnector' | translate}} {{ariaText}}</span>
      <i *ngIf="isHelix" class="pxi-la" [ngClass]="truncated ? 'px-chevron-down' : 'px-chevron-up'"></i>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '(click)': 'truncated = !truncated; clickEmitter.emit()'
  }
})
export class TextToggleButtonComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<any>();
  isHelix = false;

  @HostBinding('hidden') isHidden = true;

  @Input() truncated = true;

  @Input()
  toggleMoreLabel;

  @Input()
  toggleLessLabel;

  @Input()
  ariaText;

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public changeDetectorRef: ChangeDetectorRef, public phxThemeService: PhxThemeService) { }

  ngOnInit() {
    this.phxThemeService.theme$.pipe(takeUntil(this.destroy$)).subscribe((theme: PhxTheme) => {
      this.isHelix = theme === PhxTheme.HELIX;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
