import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhxUtil, BackdropComponent, Rectangle, PhxCommonService } from '@phoenix/ui/common';
import { IntroComponent } from './intro.component';
import { Intro, Step } from './intro.interface';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable()
export class IntroService {
  private overlayRef: OverlayRef;
  phxBackdrop: ComponentFactory<BackdropComponent>;
  phxBackdropRef: ComponentRef<BackdropComponent>;
  phxBackdropInstance: BackdropComponent;

  phxIntroElementBackdrop: ComponentFactory<BackdropComponent>;
  phxIntroElementBackdropRef: ComponentRef<BackdropComponent>;
  phxIntroElementBackdropInstance: BackdropComponent;

  phxIntro: ComponentFactory<IntroComponent>;
  phxIntroRef: ComponentRef<IntroComponent>;
  phxIntroInstance: IntroComponent;

  bodyElement: HTMLBodyElement;
  _subscriptions: Subscription[] = [];

  currentTarget: Element;
  currentStep: Step;

  config: Intro;
  triggerElement: HTMLElement;

  constructor(
    private overlay: Overlay,
    private compFactoryResolver: ComponentFactoryResolver,
    protected applicationRef: ApplicationRef,
    protected injector: Injector,
    @Inject(DOCUMENT) private $document: any,
    public phxCommonService: PhxCommonService
  ) {
    this.$document = document as Document;
    this.phxBackdrop = compFactoryResolver.resolveComponentFactory(BackdropComponent);
    this.phxIntroElementBackdrop = compFactoryResolver.resolveComponentFactory(BackdropComponent);
    this.bodyElement = document.querySelector('body') as HTMLBodyElement;
  }

  start(config: Intro, trigger: HTMLElement): void {
    this.triggerElement = trigger;
    this.config = { ...config };
    if (this.config && PhxUtil.isDefined(config.onStart) && config.onStart !== null) {
      config.onStart();
    }

    if (this.bodyElement) {
      const overlayConfig = new OverlayConfig({
        scrollStrategy: this.overlay.scrollStrategies.block()
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const portal = new ComponentPortal(BackdropComponent);
      this.overlayRef.attach(portal);
      this.attachBackdrop();
      this.attachIntroElementBackdrop();
      this.attachIntro();
    }
  }

  end(): void {
    if (this.triggerElement && this.triggerElement.focus) {
      this.triggerElement.focus();
    }
    if (this.config && PhxUtil.isDefined(this.config.onEnd) && this.config.onEnd !== null) {
      this.config.onEnd();
    }
    this.overlayRef.detach();
    this.resetTarget();
    this.removeBackdrop();
    this.removeIntroElementBackdrop();
    this.removeIntro();
    this.deRegisterEventListeners();
  }

  attachBackdrop() {
    this.phxBackdropRef = this.phxBackdrop.create(this.injector);
    this.phxBackdropInstance = this.phxBackdropRef.instance as BackdropComponent;
    this.phxBackdropInstance.renderer.addClass(this.phxBackdropRef.location.nativeElement, 'px-intro-no-bg');
    this.applicationRef.attachView(this.phxBackdropRef.hostView);
    this.bodyElement.appendChild(this.phxBackdropRef.location.nativeElement);
    this.phxBackdropRef.onDestroy(() => {
      this.phxBackdropRef = null;
      this.phxBackdropInstance = null;
    });
    this._subscriptions.push(
      (this.phxBackdropInstance as BackdropComponent).onBackdropClick.subscribe(() => {
        this.end();
      })
    );
  }

  attachIntroElementBackdrop() {
    this.phxIntroElementBackdropRef = this.phxIntroElementBackdrop.create(this.injector);
    this.phxIntroElementBackdropInstance = this.phxIntroElementBackdropRef.instance as BackdropComponent;
    this.phxIntroElementBackdropInstance.backdropVisible = false;
    this.phxIntroElementBackdropInstance.backdrop = false;
    this.phxIntroElementBackdropInstance.closeOnClick = false;
    this.phxIntroElementBackdropRef.onDestroy(() => {
      this.phxIntroElementBackdropRef = null;
      this.phxIntroElementBackdropInstance = null;
    });
    this.phxIntroElementBackdropInstance.renderer.addClass(this.phxIntroElementBackdropRef.location.nativeElement, 'px-intro-background');
    this.applicationRef.attachView(this.phxIntroElementBackdropRef.hostView);
    this.bodyElement.appendChild(this.phxIntroElementBackdropRef.location.nativeElement);
  }

  removeBackdrop() {
    if (this.phxBackdropRef) {
      this.applicationRef.detachView(this.phxBackdropRef.hostView);
    }
  }

  removeIntroElementBackdrop() {
    if (this.phxIntroElementBackdropRef) {
      this.applicationRef.detachView(this.phxIntroElementBackdropRef.hostView);
    }
  }

  removeIntro() {
    if (this.phxIntroInstance) {
      this.phxIntroInstance.clearSubscriptions();
    }
    if (this.phxIntroRef) {
      this.applicationRef.detachView(this.phxIntroRef.hostView);
      this.phxIntroRef.destroy();
    }
  }

  attachIntro() {
    this.phxIntroRef = this.phxIntro.create(this.injector);
    this.phxIntroInstance = this.phxIntroRef.instance as IntroComponent;
    this.phxIntroInstance.config = this.config;
    this.applicationRef.attachView(this.phxIntroRef.hostView);
    this.bodyElement.appendChild(this.phxIntroRef.location.nativeElement);
    this.phxIntroRef.onDestroy(() => {
      this.phxIntroInstance = null;
      this.phxIntroRef = null;
    });
  }

  // Calculate the current target and place the tooltip elements based on the elements position and preference
  setTarget(step: Step, stepIndex: number) {
    if (PhxUtil.isDefined(step) && step !== null) {
      this.currentStep = step;
      this.currentTarget = this.$document.querySelector(step.element);
      if (PhxUtil.isDefined(this.currentTarget) && this.currentTarget !== null) {
        const position: Rectangle = this.phxCommonService.getOffset(this.currentTarget);
        this.phxIntroInstance.renderer.addClass(this.currentTarget, 'phx-intro-element');
        // this.phxIntroInstance.renderer.setAttribute(this.currentTarget, 'data-step', stepIndex + 1 + '');
        if (this.phxIntroInstance.introTooltip) {
          const tooltipPosition: Rectangle = this.phxCommonService.getOffset(this.phxIntroInstance.introTooltip.nativeElement);
          let top;
          let left;
          const availablePos = [
            position.top < window.innerHeight / 2
              ? position.top + tooltipPosition.height < window.innerHeight
                ? 'bottom'
                : null
              : position.top - position.height > tooltipPosition.height + 25
                ? 'top'
                : null,
            position.left < window.innerWidth / 2
              ? position.left + position.width + tooltipPosition.width < window.innerWidth
                ? 'right'
                : null
              : position.left > tooltipPosition.width + 25
                ? 'left'
                : null
          ];
          const placement =
            step.placement &&
              ((availablePos[0] && availablePos[0].indexOf(step.placement) > -1) ||
                (availablePos[1] && availablePos[1].indexOf(step.placement) > -1))
              ? step.placement
              : availablePos[0] !== null
                ? availablePos[0]
                : availablePos[1] !== null
                  ? availablePos[1]
                  : 'auto';
          // Min max values to avoid out of bound tooltip placement
          switch (placement) {
            case 'top':
              top =
                Math.max(5, Math.min(window.innerHeight - tooltipPosition.height - 5, position.top - tooltipPosition.height - 20)) + 'px';
              left =
                Math.max(
                  5,
                  Math.min(window.innerWidth - tooltipPosition.width - 5, position.left + position.width / 2 - tooltipPosition.width / 2)
                ) + 'px';
              break;
            case 'left':
              top =
                Math.max(
                  5,
                  Math.min(window.innerHeight - tooltipPosition.height - 5, position.top + position.height / 2 - tooltipPosition.height / 2)
                ) + 'px';
              left =
                Math.max(5, Math.min(window.innerWidth - tooltipPosition.width - 5, position.left - tooltipPosition.width - 20)) + 'px';
              break;
            case 'right':
              top =
                Math.max(
                  5,
                  Math.min(window.innerHeight - tooltipPosition.height - 5, position.top + position.height / 2 - tooltipPosition.height / 2)
                ) + 'px';
              left = Math.max(5, Math.min(window.innerWidth - tooltipPosition.width - 5, position.left + position.width + 20)) + 'px';
              break;
            case 'bottom':
              top = Math.max(5, Math.min(window.innerHeight - tooltipPosition.height - 5, position.top + position.height + 20)) + 'px';
              left =
                Math.max(
                  5,
                  Math.min(window.innerWidth - tooltipPosition.width - 5, position.left + position.width / 2 - tooltipPosition.width / 2)
                ) + 'px';
              break;
            default:
              top = window.innerHeight / 2;
              left = window.innerWidth / 2;
          }
          this.phxIntroInstance.renderer.setStyle(this.phxIntroInstance.introTooltip.nativeElement, 'top', top);
          this.phxIntroInstance.renderer.setStyle(this.phxIntroInstance.introTooltip.nativeElement, 'left', left);
          this.phxIntroInstance.renderer.setAttribute(this.phxIntroInstance.introTooltip.nativeElement, 'x-placement', placement);
          // this.currentTarget.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
        }
        if (this.phxIntroElementBackdropInstance) {
          this.phxIntroElementBackdropInstance.renderer.setStyle(
            this.phxIntroElementBackdropRef.location.nativeElement,
            'width',
            position.width + 10 + 'px'
          );
          this.phxIntroElementBackdropInstance.renderer.setStyle(
            this.phxIntroElementBackdropRef.location.nativeElement,
            'height',
            position.height + 10 + 'px'
          );
          this.phxIntroElementBackdropInstance.renderer.setStyle(
            this.phxIntroElementBackdropRef.location.nativeElement,
            'top',
            position.top - 5 + 'px'
          );
          this.phxIntroElementBackdropInstance.renderer.setStyle(
            this.phxIntroElementBackdropRef.location.nativeElement,
            'left',
            position.left - 5 + 'px'
          );
        }
      } else {
        console.warn('No intro element found for selector ' + step.element);
      }
    }
  }

  resetTarget() {
    if (
      PhxUtil.isDefined(this.currentTarget) &&
      this.currentTarget !== null &&
      PhxUtil.isDefined(this.phxIntroInstance) &&
      this.phxIntroInstance !== null
    ) {
      this.phxIntroInstance.renderer.removeClass(this.currentTarget, 'phx-intro-element');
      // this.phxIntroInstance.renderer.removeAttribute(this.currentTarget, 'data-step');
    }

    if (this.phxIntroElementBackdropInstance && this.phxIntroElementBackdropRef) {
      this.phxIntroElementBackdropInstance.renderer.setStyle(this.phxIntroElementBackdropRef.location.nativeElement, 'width', '0');
      this.phxIntroElementBackdropInstance.renderer.setStyle(this.phxIntroElementBackdropRef.location.nativeElement, 'height', '0');
      this.phxIntroElementBackdropInstance.renderer.setStyle(this.phxIntroElementBackdropRef.location.nativeElement, 'top', '0');
      this.phxIntroElementBackdropInstance.renderer.setStyle(this.phxIntroElementBackdropRef.location.nativeElement, 'left', '0');
    }
  }

  deRegisterEventListeners() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }
}
