import {Pipe, PipeTransform, TemplateRef} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'phxNoSanitize' })
export class PhxNoSanitizePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(html: string | TemplateRef<any>): SafeHtml | TemplateRef<any> {
    if (typeof html === 'string') {
      return this.domSanitizer.bypassSecurityTrustHtml(html);
    } else {
      return html;
    }
  }
}
