import {Directive, HostListener, Input} from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTableComponent as Table} from "../datatable.component";
import {TableService} from "./table.service";


@Directive({
    selector: '[pContextMenuRow]',
    host: {
        '[class.ui-contextmenu-selected]': 'selected'
    }
})
export class ContextMenuRow {

    @Input("pContextMenuRow") data: any;

    @Input("pContextMenuRowIndex") index: number;

    @Input() pContextMenuRowDisabled: boolean;

    selected: boolean;

    subscription: Subscription;

    constructor(public dt: Table, public tableService: TableService) {
        if (this.isEnabled()) {
            this.subscription = this.dt.tableService.contextMenuSource$.subscribe((data) => {
                this.selected = this.dt.equals(this.data, data);
            });
        }
    }

    @HostListener('contextmenu', ['$event'])
    onContextMenu(event: Event) {
        if (this.isEnabled()) {
            this.dt.handleRowRightClick({
                originalEvent: event,
                rowData: this.data,
                rowIndex: this.index
            });

            event.preventDefault();
        }
    }

    isEnabled() {
        return this.pContextMenuRowDisabled !== true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
