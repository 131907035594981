import { Optional } from '@angular/core';
import { ConsoleLogConfig } from './log.config';
import {LoggerImpl, LoggerMessage, LoggerType, LogMode} from './logger.interface';

const noop = (): any => undefined;

export class ConsoleLoggerService implements LoggerImpl {
  private static readonly _loggerType = LoggerType.CONSOLE;

  get loggerType(): LoggerType {
    return ConsoleLoggerService._loggerType;
  }

  get mode(): LogMode {
    return window['px'] && window['px']['consolelog'] && window['px']['consolelog']['mode']
      ? window['px']['consolelog']['mode']
      : LogMode.WARN;
  }

  set mode(newMode: LogMode) {
    window['px']['consolelog']['mode'] = newMode;
  }

  constructor(@Optional() config: ConsoleLogConfig, @Optional() mode: LogMode) {
    if (!window['px']) {
      window['px'] = {};
    }
    window['px']['consolelog'] = {};
    this.mode = (config && config.logLevel) || mode || LogMode.WARN;
  }

  public setMode(newMode: LogMode) {
    this.mode = newMode;
  }


  debug(... args: LoggerMessage[]) {
    this.mode <= LogMode.DEBUG && console.log.apply(console, args);
  }

  info(... args: LoggerMessage[]) {
    this.mode <= LogMode.INFO && console.info.apply(console, args);
  }

  warn(... args: LoggerMessage[]) {
    this.mode <= LogMode.WARN && console.warn.apply(console, args);
  }

  error(... args: LoggerMessage[]) {
    this.mode <= LogMode.ERROR && console.error.apply(console, args);
  }
}
