import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { AccordionPaneDirective } from './accordion-pane.directive';
import {PhxCommonModule} from '@phoenix/ui/common';

export * from './accordion-pane.directive';
export * from './accordion.enum';
export * from './accordion.interface';
export * from './accordion.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionPaneDirective
  ],
  imports: [
    CommonModule,
    PhxCommonModule
  ],
  exports: [
    AccordionComponent,
    AccordionPaneDirective
  ]
})
export class AccordionModule { }
