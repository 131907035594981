import { Highlightable } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { PhxTranslateService } from '@phoenix/ui/translate';

export const PHX_OPTION_PARENT_COMPONENT = new InjectionToken<{ multiple?: boolean }>('PHX_OPTION_PARENT_COMPONENT');

// export declare type ISelectedOption = { [key: string]: any } | string | number;

export interface PhxOptionsParent {
  id: string;
  childCounter: number;
  multiple?: boolean;
}

@Component({
  selector: 'phx-option',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'option',
    '[attr.id]': 'id',
    '[attr.aria-selected]': 'selected',
    class: 'px-list-group-item px-dropdown-item',
    '[class.active]': 'active',
    '[class.selected]':'selected',
    '(click)': '_selectViaInteraction()',
    '(mouseenter)':'mouseEnter.next()',
    'tabindex': '-1',
    '[attr.aria-label]': 'selected ? selectedText : null'
  }
})
// tslint:disable-next-line:component-class-suffix
export class PhxOption implements Highlightable, AfterViewInit {
  private _selected = false;
  private _active = false;
  private _disabled = false;
  selectedText: string;

  @Output() mouseEnter = new EventEmitter();

  get multiple() {
    return this._parent && this._parent.multiple;
  }

  /**
   * Options if the value is selected
   */
  @Input()
  set selected(value: boolean) {
    this._selected = coerceBooleanProperty(value);
  }

  get selected(): boolean {
    return this._selected;
  }

  @Input() option: any;

  /**
   * Option value
   */
  @Input() value: any;

  /** The unique ID of the option. */
  @Input() id = `${this._parent.id}-option-${this._parent.childCounter++}`;

  /**
   * Option disabled
   */
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  /**
   * Options event emitted on selection change
   */
  @Output() readonly selectionChange = new EventEmitter<PhxOption>();

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    private translateService: PhxTranslateService,
    @Optional() @Inject(PHX_OPTION_PARENT_COMPONENT) private _parent: PhxOptionsParent
  ) {}

  ngAfterViewInit() {
    this.selectedText = this.translateService.instant('phx.option.aria.selected') + this.viewValue;
  }

  get active(): boolean {
    return this._active;
  }

  get viewValue(): string {
    return (this._getHostElement().textContent || '').trim();
  }

  select(): void {
    if (!this._selected) {
      this._selected = true;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  deselect(): void {
    if (this._selected) {
      this._selected = false;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent();
    }
    if (this._parent && this._parent.multiple) {
      this._active = false;
    }
  }

  focus(): void {
    const element = this._getHostElement();

    if (typeof element.focus === 'function') {
      element.focus();
    }
  }

  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      this.focus();
      this._changeDetectorRef.markForCheck();
    }
  }

  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  getLabel(): string {
    return this.viewValue;
  }

  /** Ensures the option is selected when activated from the keyboard. */
  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent): void {
    if ((+event.code === ENTER || +event.code === SPACE) && !hasModifierKey(event)) {
      this._selectViaInteraction();
      event.preventDefault();
    }
  }

  /**
   * `Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.`
   */
  _selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = this.multiple ? !this._selected : true;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Gets the host DOM element. */
  _getHostElement(): HTMLElement {
    return this._element.nativeElement;
  }

  /** Emits the selection change event. */
  private _emitSelectionChangeEvent(): void {
    this.selectionChange.emit(this);
  }
}
