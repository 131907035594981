import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from 'rxjs';

import {InputDirective} from '@phoenix/ui/input';
import {ActiveView, CalendarView} from './calendar/calendar.interface';
import {DateRangeOrigin} from './date-range/date-range.component';
import {DateUtilityService} from './date-utility.service';
import {DatepickerButtonComponent} from './datepicker-button.component';
import {DatepickerCalendarComponent} from './datepicker-calendar.component';
import {DatepickerService} from './datepicker.service';
import {PhxCommonService} from '@phoenix/ui/common';
import {PhxTranslateService, TranslationChangeEvent} from '@phoenix/ui/translate';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[phxDate], input[phxFromDate], input[phxToDate]',
  host: {
    '[attr.aria-owns]': '(_datePicker?.opened && _datePicker.id) || null',
    '[disabled]': 'disabled',
    '(change)': 'executeChangeFunctions($event.target.value)',
    '(blur)': 'executeTouchedFunctions()'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerInputDirective),
      multi: true
    }
  ]
})
export class DatepickerInputDirective extends InputDirective implements ControlValueAccessor, OnInit, OnDestroy {
  _datePicker: DatepickerCalendarComponent;
  private datePickerService: DatepickerService;

  // keeps track of whether we were passed a Date object or not so we can return the same type (Date or string)
  private returnDateObj: any;

  disabled: boolean;
  private dateSelectionSub: Subscription;
  private btnClickSub: Subscription;
  private datePickerBindSubj = new ReplaySubject<any>(1);
  private destroy$ = new Subject<any>();

  private datePickerButtonCmpRef: ComponentRef<DatepickerButtonComponent>;
  private datePickerButtonInstance: DatepickerButtonComponent;
  private _dateRange: DateRangeOrigin;
  private _value: Date | string;
  private _format = 'MM/DD/YYYY';
  private _inline: boolean;

  // this should get resolved before all other inputs
  /**
   * The datepicker component to be binded to this datepicker input
   */
  @Input()
  set phxDatepicker(picker: DatepickerCalendarComponent) {
    this._datePicker = picker;
    this.datePickerService = this._datePicker.registerInput(this);
    this.datePickerBindSubj.next();
  }

  /**
   * internal input
   */
  @Input() set phxFromDate(emptyParam: any) {
    this._dateRange = 'fromDate';
  }

  /**
   * internal input
   */
  @Input() set phxToDate(emptyParam: any) {
    this._dateRange = 'toDate';
  }

  /**
   * Set date format used to display and potentially persist the selected date.
   * If the model/control value of the date is a string, the date will be saved in this format as well.
   * If the model/control value is an object, the date will be saved as a Date object (unaffected by this formatting).
   * Default format is 'MM/DD/YYYY'
   * @param format the date format as string
   */
  @Input() set format(format: string) {
    if (this.inputPlaceholder === this._format) {
      this.inputPlaceholder = format;
    }
    this.dateUtilityService.currentFormat = format;
    this._format = format;
  }

  get format(): string {
    return this._format;
  }

  @Input() set placeholder(value: string) {
    this.inputPlaceholder = value;
  }

  @Input() ngModel: string;

  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  @HostBinding('attr.placeholder') inputPlaceholder = this._format;
  @HostBinding('class.px-datepicker-input') dateClass = true;

  get dateRangeInputType(): DateRangeOrigin {
    return this._dateRange;
  }

  // internal value handling
  set value(value: Date | string) {
    if (this._value === value) {
      return;
    }
    if (this.returnDateObj === undefined) {
      this.returnDateObj = value instanceof Date;
    }
    if (this.datePickerService) {
      this.setValueAfterInit(value);
    } else {
      this.datePickerBindSubj.pipe(take(1)).subscribe(() => {
        this.setValueAfterInit(value);
      });
    }
  }

  get value(): Date | string {
    return this._value;
  }

  set inline(value: boolean) {
    this._inline = value;
    if (value && this.btnClickSub) {
      this.btnClickSub.unsubscribe();
    }
  }

  private setValueAfterInit(value) {
    if (this.datePickerService.datePickerInitSubject.isStopped) {
      this.setValue(value);
    } else {
      this.datePickerService.datePickerInitSubject.pipe(take(1)).subscribe(() => this.setValue(value));
    }
  }

  private _onChange = (value: any) => {
  };
  _onTouched = () => {
  };

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dateUtilityService: DateUtilityService,
    private changeDetectorRef: ChangeDetectorRef,
    private cmService: PhxCommonService,
    private translateService: PhxTranslateService
  ) {
    super(cmService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dateUtilityService.currentFormat = this.format;
    const datePickerButtonCmpFactory = this.componentFactoryResolver.resolveComponentFactory(DatepickerButtonComponent);
    this.datePickerButtonCmpRef = this.viewContainerRef.createComponent(datePickerButtonCmpFactory);
    this.datePickerButtonInstance = this.datePickerButtonCmpRef.instance;

    this.datePickerBindSubj.pipe(take(1)).subscribe(() => {
      this.btnClickSub = this.datePickerButtonInstance.clickEmitter
        .pipe(takeUntil(this.destroy$)).subscribe(event => this.toggleDatePicker(true));

      if (this._dateRange) {
        this.dateSelectionSub = this.datePickerService.dateRangeSelection$
          .pipe(takeUntil(this.destroy$))
          .subscribe(([fromDate, toDate]) => {
            this.setValue(this.dateRangeInputType === 'fromDate' ? fromDate : toDate, true);
            this.changeDetectorRef.markForCheck();
          });
      } else {
        this.dateSelectionSub = this.datePickerService.calendarDateSelection$.pipe(takeUntil(this.destroy$)).subscribe(date => {
          this.setValue(date, true);
          if (this._inline) { // TODO only required when today's date selected to update view.
            this.updateCalendar();
          }
        });
      }
    });

    this.listenForLanguageChange();
  }

  ngAfterViewInit() {
    if (this._inline) {
      if (this.ngModel) {
        this.setValue(this.ngModel);
      }
      this.updateCalendar();
    }
  }

  ngOnDestroy() {
    if (this.dateSelectionSub) {
      this.dateSelectionSub.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  listenForLanguageChange() {
    this.translateService.onLangChange
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(() => {
      if (this._value) {
        this.setValue(new Date(this._value));
      }
    });
  }

  // this should be called only when input is focused or when toggle button is pressed
  toggleDatePicker(fromButton?) {
    // clicking on the toggle button will truly toggle the datepicker, even when in dateRange mode
    if (this._datePicker.opened && fromButton) {
      this._datePicker.close();
    } else {
      if (this._datePicker.opened) {
        // this is to avoid closing the datepicker when focus shifts automatically in a daterange
        return;
      }
      this.updateCalendar();
      if (this._dateRange) {
        this.datePickerService.dateRangeOrigin = this.dateRangeInputType;
        this.datePickerService.lastFocusedInput = this.dateRangeInputType;
      }
      this._datePicker.open(this.elementRef);
    }
  }

  setValue(value: Date | string, triggeredByCalendar = false) {
    const prev = this._value;
    if (prev === value || (!prev && !value)) {
      return;
    }
    const isValid = !!value && this.dateUtilityService.validate(value, this._format);
    this._value = isValid ? value : '';
    const formattedDate = this.dateUtilityService.format(this._value, this._format, true);
    this.elementRef.nativeElement.value = this.dateUtilityService.format(this._value, this._format);
    this.ngModelChange.emit(formattedDate);
    this.valueChange.emit(this._value as Date);
    this._value = this.returnDateObj ? this._value : formattedDate;
    // need to update form on calendar selection by executing registered controlvalueaccessor change function
    if (triggeredByCalendar) {
      this.executeChangeFunctions(this._value, true);
    }
  }

  writeValue(value: any): void {
    this.returnDateObj = value instanceof Date;
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // executes when input value is changed manually on the input (not using calendar)
  onInputChange(value: Date | string) {
    this.value = value;
    this.updateCalendar();
  }

  executeChangeFunctions(value: Date | string, skipManualChangeFn = false) {
    if (!skipManualChangeFn) {
      this.onInputChange(value);
    }
    // updated this.value will be what we want to persist
    this._onChange(this.value);
  }

  // clear input if date is disabled, then call registered onTouched fn
  executeTouchedFunctions() {
    if (this.value && !this.dateUtilityService.isDateEnabled(this._datePicker.options, this.value, CalendarView.DAY, this._format)) {
      this.executeChangeFunctions('');
    }
    this._onTouched();
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.datePickerButtonInstance) {
      this.datePickerButtonInstance.disabled = this.disabled;
      this.datePickerButtonInstance.refresh();
    }
    this.changeDetectorRef.detectChanges();
  }

  updateCalendar() {
    let date: any = this.dateUtilityService.parseDate(this.value);
    if (date && !this.dateUtilityService.isDateEnabled(this._datePicker.options, this.value, CalendarView.DAY, this._format)) {
      date = '';
    }
    if (this._dateRange) {
      this.datePickerService.updateDateRange(date);
    } else {
      this.datePickerService.setDateFromInput(date);
    }
    if (date) {
      const view = {
        month: date.getMonth(),
        year: date.getFullYear(),
        yearRange: this.dateUtilityService.parseYearRange(date.getFullYear()),
        calendarView: this._datePicker.currentView
      } as ActiveView;
      this.datePickerService.activeView = view;
    } else {
      this.datePickerService.activeView = this._datePicker.defaultActiveView;
    }
  }
}
