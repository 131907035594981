import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {asyncScheduler, ReplaySubject, Subject, Subscription} from 'rxjs';
import {delay, filter, take, takeUntil, tap} from 'rxjs/operators';

import {PHX_OPTION_PARENT_COMPONENT, PhxCommonService, PhxOption, PhxOptionsParent} from '@phoenix/ui/common';
import {DROPDOWN_DIRECTION, DropdownPanelComponent} from './dropdown-panel.component';

export type OptionsType = 'simple' | 'object' | 'complex';

@Component({
  selector: 'phx-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[attr.id]': 'id'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    },
    {provide: PHX_OPTION_PARENT_COMPONENT, useExisting: DropdownComponent}
  ]
})
export class DropdownComponent implements ControlValueAccessor, PhxOptionsParent, AfterContentInit, OnInit, OnDestroy, AfterViewInit {
  childCounter = 0;
  readonly _destroy = new Subject<void>();

  private _id: string = this.commonService.getRandID('Drdown');
  private _required = false;
  private _disabled = false;
  private _multiple = false;
  private _placeholder = 'Please select a value';
  private _open = false;
  private _focused = false;
  private _options: any[];
  private _ariaLabel: string;
  private _ariaLabelledBy: string;
  private _ariaDescribedBy: string;
  private _maxHeight: number;
  private _direction: DROPDOWN_DIRECTION = 'DOWN';
  private _optionsType: OptionsType;

  private dropdownPanelInit = new ReplaySubject<any>(1);
  private _dropdownPanel: DropdownPanelComponent;

  private toggleSubscription: Subscription;
  private taboutSubscription: Subscription;

  public filterCtrl = new FormControl('');
  public inputFilterValue: string;
  public isViewInitialized = false;

  @ViewChild(DropdownPanelComponent)
  set dropdownPanel(dropdownPanel: DropdownPanelComponent) {
    this._dropdownPanel = dropdownPanel;
    this.dropdownPanelInit.next();
    this._dropdownPanel.toggled.pipe(takeUntil(this._destroy)).subscribe((state: boolean) => {
      if (!state) {
        this.childCounter = 0;
      }
      this.toggleChange.emit(state);
    });
  }

  get dropdownPanel(): DropdownPanelComponent {
    return this._dropdownPanel;
  }

  @ViewChild('filterInput') filterInputRef: ElementRef;

  @ContentChildren(PhxOption, {descendants: true}) contentOptions: QueryList<PhxOption>;
  @ContentChild(TemplateRef) optionsGroupTemplate: TemplateRef<any>;

  /**
   * Dropdown ID
   */
  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  /**
   * Custom string to show in dropdown input for multiple selection. If nothing is provided then 
   * the comma separated values are shown.
   */
  @Input() multipleSelectedText: string;

  /**
   * Dropdown options array for rendering options based on a list.
   * Possible values boolean[], string[], number[], {label: string, value: any, disabled?: boolean}[]
   */
  @Input()
  get options(): any[] {
    return this._options;
  }

  set options(value: any[]) {
    this._options = [...value];
    this.updateType();
  }

  /**
   * Dropdown native required attribute
   */
  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Used to disable dropdown component
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  /**
   * Dropdown allows for multiple selection of items, default: false
   */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  /**
   * Dropdown placeholder text
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  /**
   * Dropdown open direction. Default: 'DOWN'
   * DROPDOWN_DIRECTION = 'UP' | 'DOWN'
   */
  @Input()
  get direction(): DROPDOWN_DIRECTION {
    return this._direction;
  }

  set direction(value: DROPDOWN_DIRECTION) {
    this._direction = value;
  }

  /**
   * ADA: Dropdown aria label
   */
  @Input()
  get ariaLabel(): string {
    return this._ariaLabel;
  }

  set ariaLabel(value: string) {
    this._ariaLabel = value;
  }

  /**
   * ADA: Dropdown aria labeled by
   */
  @Input()
  get ariaLabelledBy(): string {
    return this._ariaLabelledBy;
  }

  set ariaLabelledBy(value: string) {
    this._ariaLabelledBy = value;
  }

  /**
   * ADA: Dropdown aria described by
   */
  @Input()
  get ariaDescribedBy(): string {
    return this._ariaDescribedBy;
  }

  set ariaDescribedBy(value: string) {
    this._ariaDescribedBy = value;
  }

  /**
   * Dropdown focused into
   */
  get focused(): boolean {
    return this._focused || this.toggle;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  /**
   * @Deprecated Dropdown cdk overlay height. Using `panelStyleClass` as shown in Demo 2.
   */
  @Input()
  get maxHeight(): number {
    return this._maxHeight;
  }

  set maxHeight(value: number) {
    this._maxHeight = +value;
  }

  /**
   * width for dropdown
   * Width unit can be in px or %
   */
  @Input() width: string;

  /**
   * width for dropdown selection option panel
   * Width unit can be in px or %
   */
  @Input() optionsPanelWidth: string;

  /**
   * allow to open/close dropdown from outside. Default = false.
   */
  @Input()
  get toggle(): boolean {
    return this._open;
  }

  set toggle(value: boolean) {
    this._open = coerceBooleanProperty(value);
  }

  @Input() styleClass: string;

  /**
   * Allowed to change dropdown's panel style from outside.
   */
  @Input() panelStyleClass: string;

  /**
   * Optional. If dropdown should close when scrolling.
   * Default: false
   */
  @Input() closeOnScroll = false;

  /**
   *Allow to filter out dropdown's options. Default: false;
   */
  @Input() filterable = false;

  /**
   *filterChange
   */
  @Output() filterChange = new EventEmitter<string>();

  /**
   * Event that is emitted when the dropdown panel is opened/closed. Emitted value: true/false.
   */
  @Output() toggleChange = new EventEmitter<boolean>();

  @Input()
  set optionsType(type: OptionsType) {
    if (type) {
      this._optionsType = type;
    } else {
      this.updateType();
    }
  }

  get optionsType(): OptionsType {
    return this._optionsType;
  }

  constructor(private commonService: PhxCommonService) {
  }

  ngOnInit() {
    if (this.filterable) {
      const clonedOptions = this.options ? [...this.options] : [];
      this.filterCtrl.valueChanges.pipe(
        tap(filteredValue => {
          this.inputFilterValue = filteredValue;
          if (this.options && this.optionsType !== 'complex') {
            this.options = !!filteredValue ? clonedOptions.filter(option => {
              return this.optionsType === 'simple' ?
                option.toString().toLowerCase().includes(filteredValue.toLowerCase()) :
                option.label.toString().toLowerCase().includes(filteredValue.toLowerCase());
            }) : [...clonedOptions];
            asyncScheduler.schedule(() => this.filterInputRef.nativeElement.focus(), 10);
          } else {
            this.filterChange.next(filteredValue);
          }
        }),
        delay(200),
        takeUntil(this._destroy)
      ).subscribe();
    }
  }

  ngAfterContentInit(): void {
    if (!this.options) {
      this.onDropdownPanelInit(() => {
        this._dropdownPanel.parentContentOptions = this.contentOptions;
      });
    }
  }

  ngAfterViewInit() {
    if (this.filterable) {
      if (!this.multiple) {
        this._dropdownPanel.optionSelectionChanges?.pipe(
          filter(() => !!this.inputFilterValue),
          tap(() => {
            this.filterCtrl.setValue(null);
            if (!this.options) {
              this.filterChange.next('');
            }
          }),
          takeUntil(this._destroy)
        ).subscribe();
      }

      this._dropdownPanel.toggled.pipe(
        filter((isOpen: boolean) => isOpen),
        tap(() => {
          this.unsubscribe(this.toggleSubscription);
          this.toggleSubscription = asyncScheduler.schedule(() => this.filterInputRef.nativeElement.focus(), 10);
        }),
        takeUntil(this._destroy)
      ).subscribe();
    }
    this.isViewInitialized = true;
  }

  writeValue(value: any): void {
    this.onDropdownPanelInit(() => this._dropdownPanel.writeValue(value));
  }

  registerOnTouched(fn: any): void {
    this.onDropdownPanelInit(() => this._dropdownPanel.registerOnTouched(fn));
  }

  registerOnChange(fn: any): void {
    this.onDropdownPanelInit(() => this._dropdownPanel.registerOnChange(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    this.onDropdownPanelInit(() => this._dropdownPanel.setDisabledState(isDisabled));
  }

  onDropdownPanelInit(callbackFn: any) {
    this.dropdownPanelInit.pipe(take(1)).subscribe(() => {
      callbackFn();
    });
  }

  onKeydownFilter($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.code === 'Tab') {
      this.unsubscribe(this.taboutSubscription);
    } else if ($event.code === 'ArrowDown' || $event.code === 'ArrowUp') {
      asyncScheduler.schedule(() => this._dropdownPanel._keyManager.onKeydown($event), 10);
    } else if ($event.code === 'Enter') {
      this._dropdownPanel._keyManager.activeItem?._selectViaInteraction();
    } else if ($event.code === 'Escape') {
      this.unsubscribe(this.taboutSubscription);
      this.taboutSubscription = asyncScheduler.schedule(() => {
        this._dropdownPanel.trigger.nativeElement.focus();
        this._dropdownPanel.close();
      }, 10);
    }

  }

  unsubscribe(sub: Subscription) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }

  updateType() {
    if (!this.optionsType && this.options && this.options.length > 0) {
      switch (typeof this.options[0]) {
        case 'boolean':
        case 'string':
        case 'number':
          this._optionsType = 'simple';
          break;
        case 'object':
          this._optionsType = 'object';
      }
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
