import { Directive, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FieldMessageComponent } from './field-message.component';
import { Subscription } from 'rxjs';

const stateClass = {
    error: 'px-error-state',
    warning: 'px-warning-state',
    success: 'px-success-state',
    info: 'px-information-state'
};

/**
 * Directive that handles binding of a form element to field message component
 */
@Directive({
    selector: 'phx-field-message[forElement]'
})
export class FieldMessageDirective implements OnInit, OnDestroy {

    private stateSub: Subscription;
    private _bindedElement: HTMLInputElement;

    @Input()
    set forElement(elRef: HTMLInputElement) {
        this._bindedElement = elRef;
    }

    constructor(private fieldMessageComponent: FieldMessageComponent, private renderer: Renderer2) { }

    ngOnInit() {
        this.stateSub = this.fieldMessageComponent.stateSubject.subscribe(state => this.setClassOnInput(state));
    }

    ngOnDestroy() {
        if (this.stateSub) {
            this.stateSub.unsubscribe();
        }
    }

    setClassOnInput(state) {
        const message = this.fieldMessageComponent.messages.find(msg => msg.state === state);
        Object.keys(stateClass)
            .map(key => stateClass[key])
            .forEach(className => this.renderer.removeClass(this._bindedElement, className));
        if (message && stateClass[message.type]) {
            this.renderer.addClass(this._bindedElement, stateClass[message.type]);
        }
    }
}
