import {
    Component,
    Input,
    SecurityContext,
    ElementRef,
    ContentChild,
    TemplateRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'phx-breadcrumb-item',
    template: `<ng-content></ng-content>`
})

export class BreadCrumbItemComponent {

    /**
     * [HTML] Content to be show inside each breadcrumb item
     */
    @ContentChild('phxBreadCrumbItemContent') contentTpl: TemplateRef<any>;

    private $href: string;

    /**
     * Provide breadcrumb item link, value can be router path or href
     */
    @Input() set href(value: string) {
        if (!value) {
            return;
        }
        this.$href = this.santizer.sanitize(SecurityContext.URL, value)
    }

    get href() {
        return this.$href;
    }

    constructor(private santizer: DomSanitizer, public elementRef: ElementRef) { }
}