import { ContentChild, Directive, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'phx-pane'
})
export class AccordionPaneDirective implements OnInit {
  /**
   * [HTML] Content to be show inside each accordion pane
   */
  @ContentChild('phxAccordionContent') contentTpl: TemplateRef<any>;

  /**
   * [HTML] Content to be shown inside the each accordion page heading.
   * This is will be appended to whatever string that is being passed in the
   * title attribute
   */
  @ContentChild('phxAccordionTitle') titleTpl: TemplateRef<any>;

  /**
   * [HTML] Content to be shown beside the accordion heading.
   */
  @ContentChild('phxAccordionSubTitle') titleSubTpl: TemplateRef<any>;  

  private _title: string;

  /**
   * [String] id for each pane
   */
  @Input() id: string;

  /**
   * [String] heading for each accordion pane
   */
  @Input()
  set title(value: string) {
    if (this._title !== value) {
      this._title = value;
      this.inputChanged.emit();
    }
  }

  get title(): string {
    return this._title;
  }

  private _expanded = false;

  /**
   * [Boolean] Will determine if the accordion is expanded or collapsed.
   * Default is false(collapsed).
   */
  @Input()
  set expanded(value: boolean) {
    if (this._expanded !== value) {
      this._expanded = value;
      this.inputChanged.emit();
    }
  }

  get expanded(): boolean {
    return this._expanded;
  }

  private _disabled = false;

  /**
   * [Boolean] Will determine if the accordion pane is visually enabled or disabled.
   * Note that this does not make use of html disabled attribute. This is done to allow disabled panes to still receive tab focus for screenreader users.
   * Default is false(enabled)
   */
  @Input()
  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this.inputChanged.emit();
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  private _defaultAction = true;

  /**
   * [Boolean] Will determine if the accordion pane is clickable or not.
   * Default is false(clickable)
   */
  @Input() // Not compliant with ADA. Should review. 
  set defaultAction(value: boolean) {
    if (this._defaultAction !== value) {
      this._defaultAction = value;
      this.inputChanged.emit();
    }
  }

  get defaultAction(): boolean {
    return this._defaultAction;
  }

  private _className: string;

  /**
   * [String] custom class name that can be passed in by the user.
   * Will be added to the phx-pane.
   */
  @Input()
  set className(value: string) {
    if (this._className !== value) {
      this._className = value;
      this.inputChanged.emit();
    }
  }

  get className(): string {
    return this._className;
  }

  //
  // An output function to let the parent that the child inputs have changed.
  //
  @Output() inputChanged = new EventEmitter<any>();

  element: HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.element = this.elementRef.nativeElement;
  }
}
