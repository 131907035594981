import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { KeyCode } from '@phoenix/ui/common';
import { DateRangeComponent } from './date-range/date-range.component';
import { DatepickerInputDirective } from './datepicker-input.directive';
import { DatepickerPosition } from './datepicker-options.interface';
import { DatepickerOverlayManagerService } from './datepicker-overlay-manager.service';

const bottomPosition: ConnectedPosition = {
  originX: 'start',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top'
};

const topPosition: ConnectedPosition = {
  originX: 'start',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'bottom'
};

@Injectable()
export class DatepickerOverlayService {
  position: DatepickerPosition = 'auto';
  templateRef: TemplateRef<any>;
  // for single datepicker
  private inputDirective: DatepickerInputDirective;
  // cache of inputRef that triggered opening of the datepicker
  private inputRef: ElementRef;
  // if used in dateRange
  private dateRange: DateRangeComponent;

  private overlayRef: OverlayRef;
  private templatePortal: TemplatePortal;

  private destroy$ = new Subject<any>();

  get opened() {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  constructor(private overlay: Overlay, private datePickerOverlayManager: DatepickerOverlayManagerService) { }

  // for single datepicker
  registerInput(input: DatepickerInputDirective) {
    this.inputDirective = input;
    this.inputRef = input.elementRef;
  }

  registerDateRange(dateRangeComponent: DateRangeComponent) {
    this.dateRange = dateRangeComponent;
  }

  // inputRef param will always be provided for dateRange. For single datepickers, it isn't provided when this is called from @Input opened
  open(inputRef?: ElementRef) {
    if (inputRef) {
      this.inputRef = inputRef;
    }
    if (!this.overlayRef) {
      this.templatePortal = new TemplatePortal(
        this.templateRef,
        this.dateRange ? this.dateRange.viewContainerRef : this.inputDirective.viewContainerRef
      );
      this.overlayRef = this.setUpOverlay();
      this.registerEvents(this.overlayRef);
    }
    if (!this.overlayRef.hasAttached()) {
      this.datePickerOverlayManager.addAttached(this.overlayRef);
      this.overlayRef.attach(this.templatePortal);
      this.updateOverlayPosition();
    }
  }

  setUpOverlay(): OverlayRef {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.inputRef)
        .withFlexibleDimensions(false)
        .withPush(false)
        .withPositions(this.getConnectedPositioning()),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      disposeOnNavigation: true,
      hasBackdrop: true,
      backdropClass: ['cdk-overlay-transparent-backdrop'],
      panelClass: 'px-datepicker'
    });
    const ref = this.overlay.create(overlayConfig);
    return ref;
  }

  close() {
    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
      return;
    }
    this.overlayRef.detach();
  }

  getConnectedPositioning(): ConnectedPosition[] {
    let positions: ConnectedPosition[];

    if (this.position === 'top') {
      positions = [topPosition];
    } else if (this.position === 'bottom') {
      positions = [bottomPosition];
    } else {
      positions = [bottomPosition, topPosition];
    }
    return positions;
  }

  registerEvents(overlayRef: OverlayRef) {
    merge(
      overlayRef.backdropClick(),
      overlayRef.keydownEvents().pipe(filter(event => event.keyCode === KeyCode.ESC)),
      overlayRef.detachments()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event) {
          event.preventDefault();
        }
        this.close();
      });
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private updateOverlayPosition() {
    const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    positionStrategy.setOrigin(this.inputRef);
    positionStrategy.withPositions(this.getConnectedPositioning());
    this.overlayRef.updatePosition();
  }
}
