import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { ReactiveErrorControlComponent } from './reactive-error-control.component';
import { PhxCommonModule } from '@phoenix/ui/common';
import { AnnouncerModule } from '@phoenix/ui/announcer';

export { ReactiveErrorControlComponent } from './reactive-error-control.component';

@NgModule({
    declarations: [ ReactiveErrorControlComponent ],
    exports: [ ReactiveErrorControlComponent ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        PhxCommonModule,
        PhxTranslateModule.forChild(),
        AnnouncerModule
    ]
})
export class ReactiveErrorControlModule {}
