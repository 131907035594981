import {Component, Directive, Input, NgModule, TemplateRef} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'p-header',
    template: '<ng-content></ng-content>'
})
export class Header {}

@Component({
    selector: 'p-footer',
    template: '<ng-content></ng-content>'
})
export class Footer {}

@Directive({
    selector: '[pTemplate]',
    host: {
    }
})
export class PrimeTemplate {

    @Input() type: string;

    @Input('pTemplate') name: string;

    constructor(public template: TemplateRef<any>) {}

    getType(): string {
        return this.name;
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [Header,Footer,PrimeTemplate],
    declarations: [Header,Footer,PrimeTemplate]
})
export class SharedModule { }
