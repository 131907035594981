import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { GestureService } from '@phoenix/ui/common';
import { SidebarBaseComponent } from './sidebar-base.component';
import { SidebarOptions } from './sidebar-options.interface';
import { SidebarServiceService } from './sidebar-service.service';

/**
 * What sidebar does not support:
 *  +  `swipe up` and `swipe down`.
 *  + `side` action for 'top' | 'bottom' position.
 *  + `fullscreen` only support for 'over' action.
 *  + `topMargin` only support for `fullscreen` with `over` action and `left`| `right` position.
 */
const DEFAULT_OPTIONS: SidebarOptions = {
  position: 'left',
  animationTime: 500,
  panelWidth: '300px',
  panelHeight: '300px',
  action: 'over',
  autoFocus: true,
  hasBackdrop: false,
  closeOnBackdrop: true,
  fullscreen: false,
  topMargin: '0px',
  showCloseButton: false
};
@Component({
  selector: 'phx-sidebar',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="px-sidebar-backdrop"
      #backdrop
      *ngIf="sidebarOptions?.hasBackdrop"
      (click)="onBackdropClicked($event)"
      [ngStyle]="{ position: sidebarOptions?.fullscreen ? 'fixed' : 'absolute', 'z-index': zIndex - 1 }"
    ></div>

    <ng-content select="phx-sidebar-base"></ng-content>

    <ng-content select="phx-sidebar-content"></ng-content>
  `,
  host: {
    class: 'px-sidebar'
  },
  providers: [ SidebarServiceService ]
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Use options to configure the sidebar component
   */
  @Input() options: SidebarOptions;

  /**
   * Input to show or hide the sider bar.
   * true shows the sidebar and false hides it.
   */
  @Input() set open(isOpen: boolean) {
    this.openState = isOpen;
    this._openedChange.next(isOpen);
    this.sidebarServiceService.handleOpenChanged(isOpen, this.options);
  }

  @Input() ariaLabelledBy: string;

  @Input() ariaDescribedBy: string;

  @Input() zIndex = 10;

  /** Event emitted when the sidebar open or close */
  @Output() openChange = new EventEmitter<boolean>();

  public sidebarOptions: SidebarOptions;

  public _openedChange = new BehaviorSubject<boolean | null>(null);

  public openState = false;

  public sideFixedTopGap = '0px';

  public panStart$ = new Subject<Event>();
  public panX$ = new Subject<Event>();
  public panEnd$ = new Subject<Event>();

  private _isGestureEnabled = false;

  private destroy$ = new Subject<any>();

  @ContentChild(SidebarBaseComponent) sidebarBase: SidebarBaseComponent;
  @ViewChild('backdrop') backdrop: ElementRef<HTMLDivElement>;

  @HostListener('panstart', ['$event'])
  panStart(event: TouchEvent) {
    if (this._isGestureEnabled) {
      this.panStart$.next(event);
    }
  }
  @HostListener('panleft', ['$event'])
  onPanLeft(event: TouchEvent) {
    if (this._isGestureEnabled && this.isHorizontalPosition) {
      this.panX$.next(event);
    }
  }
  @HostListener('panright', ['$event'])
  onPanRight(event: TouchEvent) {
    if (this._isGestureEnabled && this.isHorizontalPosition) {
      this.panX$.next(event);
    }
  }

  @HostListener('panend', ['$event'])
  onEndPan(event: TouchEvent) {
    this.panEnd$.next(event);
  }

  constructor(private hostRef: ElementRef, private renderer: Renderer2, private gestureService: GestureService, private _ngZone: NgZone, private sidebarServiceService: SidebarServiceService) {
    this._ngZone.runOutsideAngular(() => {
      (fromEvent(this.hostRef.nativeElement, 'keydown') as Observable<KeyboardEvent>)
        .pipe(
          filter(event => event.keyCode === ESCAPE && this.openState && !hasModifierKey(event)),
          takeUntil(this.destroy$)
        )
        .subscribe(event =>
          this._ngZone.run(() => {
            this._openedChange.next(false);
            event.stopPropagation();
            event.preventDefault();
          })
        );
    });
  }
  ngOnInit() {
    this.sidebarOptions = { ...DEFAULT_OPTIONS, ...this.options };
    if (!this.sidebarOptions.fullscreen) {
      if (this.isHorizontalPosition) {
        this.renderer.setStyle(this.hostRef.nativeElement, 'overflow-x', 'hidden');
      } else {
        this.renderer.setStyle(this.hostRef.nativeElement, 'overflow-y', 'hidden');
      }
    }
    if (this.gestureService.hasHammerJsLoaded && !this.gestureService.isWebBrowser) {
      this._isGestureEnabled = true;
    }
  }

  ngAfterViewInit() {
    if (this.sidebarOptions.hasBackdrop) {
      this._openedChange
        .pipe(
          tap(open => {
            this.renderer.setStyle(this.backdrop.nativeElement, 'transition', this.sidebarOptions.animationTime * 0.5 + 'ms');
            this.renderer.setStyle(this.backdrop.nativeElement, 'visibility', open ? 'visible' : 'hidden');
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }

    if (
      this.sidebarOptions.fullscreen &&
      this.sidebarOptions.hasBackdrop &&
      this.sidebarOptions.action === 'over' &&
      this.isHorizontalPosition
    ) {
      this.renderer.setStyle(this.backdrop.nativeElement, 'top', this.sidebarOptions.topMargin);
    }
  }

  onBackdropClicked($event: Event) {
    if (this.sidebarOptions.closeOnBackdrop) {
      $event.stopPropagation();
      this._openedChange.next(false);
    }
  }

  get isHorizontalPosition(): boolean {
    return this.sidebarOptions.position === 'left' || this.sidebarOptions.position === 'right';
  }

  get sidebarHeight(): number {
    return this.hostRef.nativeElement.clientHeight;
  }
  get sidebarWidth(): number {
    return this.hostRef.nativeElement.clientWidth;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this._openedChange.next(false);
    this._openedChange.complete();
  }
}
