import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, OnDestroy, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Component({
  selector: 'phx-clear-btn',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button class="px-close-icon" type="button" aria-label="Clear text">
      <i class="pxi px-times" aria-hidden="true" title="Clear text"></i>
    </button>
  `,
  host: {
    '(focusout)': 'isHidden = true',
    '(click)': 'clickEmitter.emit()'
  }
})
export class ClearInputButtonComponent implements OnDestroy {
  @HostBinding('hidden') isHidden = true;

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output() destroy$: Observable<any>;
  private destroySubject: Subject<any>;

  constructor(public elRef: ElementRef) {
    this.destroySubject = new Subject<any>();
    this.destroy$ = this.destroySubject.asObservable();
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }
}
