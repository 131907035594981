import { Injectable } from '@angular/core';

/**
 * Log message levels
 */
export class LogMode {
  static DEBUG = 1;
  static INFO = 2;
  static WARN = 3;
  static ERROR = 4;
  static NOOP = 10;
}

export const LogModeMapping = new Map<LogMode, LogLevel>([
  [LogMode.DEBUG, 'debug'],
  [LogMode.INFO, 'info'],
  [LogMode.WARN, 'warn'],
  [LogMode.ERROR, 'error'],
  [LogMode.NOOP, 'noop']
]);

/**
 * string representation of logmode
 */
export type LogLevel = 'debug' | 'info' | 'warn' | 'error' | 'noop';

/**
 * loggertype to distinguish between console/server
 */
export enum LoggerType {
  CONSOLE = 'CONSOLE',
  SERVER = 'SERVER'
}

/* TODO: possibly add the following:
    document.body.connectionType
    document.title
 */
/**
 * All server logs are automatically wrapped in a LogMessage object.
 * A LogMessage object can also be created and passed to logger methods manually.
 * Properties prefixed with 'a_' represent analytics.
 */
export interface LogMessage {
  /** log message, mandatory field */
  message: string;
  /** semantic representation of logmode, type is 'debug' | 'info' | 'warn' | 'error' | 'noop' */
  level?: LogLevel;
  /** optional */
  errorCode?: string;
  /** optional */
  context?: string;
  /** analytics only, timestamp, optional */
  a_tm?: string;
  /** analytics only, operating system, optional */
  a_os?: string;
  /** analytics only, user agent string, optional */
  a_agent?: string;
  /** analytics only, user agent language, optional */
  a_lang?: string;
  /** analytics only, screenwidth, optional */
  a_scrW?: string | number;
  /** analytics only, screenheight, optional */
  a_scrH?: string | number;
  /** optional properties that can be added manually */
  [x: string]: any;
}

export type LoggerMessage = LogMessage | any;

export interface LoggerImpl {
  readonly loggerType: LoggerType;
  /** override LogMode level for this logger */
  setMode: (newMode: LogMode) => void;
  debug: (... args: LoggerMessage[]) => void;
  info: (... args: LoggerMessage[]) => void;
  warn: (... args: LoggerMessage[]) => void;
  error: (... args: LoggerMessage[]) => void;
}

/**
 * message: LogMessage | any,
 * msgOrType?: LoggerType | any - second param added to match console.log(param1, param2) case.
 * loggerType?: LoggerType
 */
@Injectable()
export abstract class Logger implements LoggerImpl {
  type: (loggerType: LoggerType) => LoggerImpl;
  setLogMode: (newMode: LogMode, loggerType?: LoggerType) => void;
  readonly loggerType: LoggerType;
  abstract debug: (... args: LoggerMessage[]) => void;
  abstract info: (... args: LoggerMessage[]) => void;
  abstract warn: (... args: LoggerMessage[]) => void;
  abstract error: (... args: LoggerMessage[]) => void;
  setMode(newMode: LogMode) {}
}
