import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';
import { asyncScheduler, Subscription } from 'rxjs';
import {PhxTranslateService} from '@phoenix/ui/translate';

@Component({
  selector: 'phx-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // animations: [Effects.fadeInOut],
  template: `
    <div #messageContent class="px-alert px-alert-{{ type }}">
      <div class="px-d-flex">
        <span class="px-icon px-mr-2">
          <i
            class="pxi"
            [ngClass]="{
              'px-info-circle': type === 'info',
              'px-check-circle': type === 'success',
              'px-minus-circle': type === 'danger',
              'px-exclamation-triangle': type === 'warning'
            }"
          ><span class="px-sr-only">{{'phx.message.' + type | translate}}</span></i>
        </span>
        <span class="px-icon-message">
          <ng-content></ng-content>
        </span>
      <div>
      <button *ngIf="dismissible" type="button" (click)="dismiss($event)" class="px-close px-mt-1" [attr.aria-label]="closeAriaLabel">
        <i aria-hidden="true" class="pxi px-times"></i>
      </button>
    </div>
  `
})
export class MessageComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('messageContent') msgContent: ElementRef;
  _ariaLive = 'polite';
  _role = 'status';
  private _type: 'info' | 'success' | 'warning' | 'danger' = 'info';
  private adaSub: Subscription;
  private schedulerSub: Subscription;

  @HostBinding('attr.type') typeAttr = 'info';
  // @HostBinding('@fadeInOut') fadeInOut;

  @HostBinding('attr.aria-live') hostAriaLive = this._ariaLive;

  @HostBinding('attr.aria-atomic') hostAriaAtomic = true;

  @HostBinding('attr.role') hostRole = this._role;

  /**
   * Allows to choose the type of message. @DefaultValue: 'info'
   */
  @Input()
  set type(type: 'info' | 'success' | 'warning' | 'danger') {
    this._type = type;
    if (type === 'danger' || type === 'warning') {
      this._ariaLive = this.hostAriaLive = 'assertive';
      this._role = this.hostRole = 'alert';
    }
  }

  get type(): 'info' | 'success' | 'warning' | 'danger' {
    return this._type;
  }

  /**
   * Allows to enable dismissible functionality. @DefaultValue: false
   */
  @Input() dismissible = false;

  /**
   * This two way binded input allows to show/hide the message. @DefaultValue: true
   */
  @Input() showMessage = true;

  /**
   * aria-label for close button. @DefaultValue: 'Close'
   */
  @Input() closeAriaLabel = this.translateService.instant('phx.message.close');

  /**
   * will hide message after dismissTime expired. default 0;
   * example: [dismissTime]="1000"  will hide message after 1 second.
   */
  @Input()dismissTime: number;

  /**
   * This event emitter option is called every time the message is shown/hidden with showMessage boolean value. @DefaultValue: optional output
   */
  @Output() showMessageChange = new EventEmitter<any>();

  constructor(private renderer2: Renderer2,
              private changeDetectorRef: ChangeDetectorRef, private translateService: PhxTranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showMessage && !changes.showMessage.firstChange) {
      this.toggleMessage(changes.showMessage.currentValue);
    }
  }

  ngOnInit() {
    this.typeAttr = this.type;
  }

  ngAfterViewInit() {
    this.toggleMessage(this.showMessage);
  }

  dismiss($event: Event) {
    $event.stopPropagation();
    // this.fadeInOut = 'Inactive';
    this.showMessageChange.emit(false);
  }

  private toggleMessage(show: boolean) {
    if (show) {
      this.renderer2.removeStyle(this.msgContent.nativeElement, 'display');
      this.setupScheduler();
      // this.fadeInOut = 'Active';
    } else {
      this.renderer2.setStyle(this.msgContent.nativeElement, 'display', 'none');
      // this.fadeInOut = 'Inactive';
    }
    this.changeDetectorRef.markForCheck();
  }

  private setupScheduler() {
    if (this.dismissTime && Number.isInteger(this.dismissTime)) {
        this.unsubscribe(this.schedulerSub);
        this.schedulerSub = asyncScheduler.schedule(() => {
            this.renderer2.setStyle(this.msgContent.nativeElement, 'display', 'none');
            this.showMessage = false;
            this.showMessageChange.next(false);
            this.changeDetectorRef.markForCheck();
        }, this.dismissTime);

    }
  }

  private unsubscribe(sub: Subscription) {
    if (sub && !sub.closed) {
      sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe(this.adaSub);
  }
}
